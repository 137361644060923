<div #modal class="show" id="confirmBookingModal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="table-view">
        <div class="table-cell-view">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div style="    border-bottom: 1px solid rgba(100,100,100,0.15);
                        border-top: 1px solid rgba(100,100,100,0.15);">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myModalLabel">
                                <img *ngIf="!isAmnetRelease()" src="assets/images/footer-logo.png" alt="logo" />
                            </h5>

                            <button *ngIf="!isAmnetRelease() && !transactionMode" type="button" class="close"
                                (click)="gotToHome()">
                                <i class="material-icons">close</i>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div *ngIf="!showHotelLink && responseType === 'ERROR_AVAILABILITY'"
                                class="modal-icon-container">
                                <span class="modal-icon" style="border:3px solid red;">
                                    <i class="material-icons" style="color:red;">error</i>
                                </span>
                            </div>
                            <div *ngIf="!(!showHotelLink && responseType === 'ERROR_AVAILABILITY') && this.flightBooking"
                                class="modal-icon-container">
                                <img src="assets/images/flgihtmsg.png" alt="logo" />
                            </div>
                            <div *ngIf="!(!showHotelLink && responseType === 'ERROR_AVAILABILITY') && !this.flightBooking"
                                class="modal-icon-container">
                                <span class="modal-icon">
                                    <i class="material-icons">check</i>
                                </span>
                            </div>
                            <div *ngIf="!transactionMode" class="modal-content-heading">
                                <h3
                                    *ngIf="!showHotelLink && responseType === 'ERROR_AVAILABILITY' && !this.flightBooking">
                                    {{getHotelBookingErrorMessage(responseType)}}</h3>
                                <h3
                                    *ngIf="!showHotelLink && responseType === 'ERROR_BOOKING_PENDING' && !this.flightBooking">
                                    {{getHotelBookingErrorMessage(responseType)}}</h3>
                                <h3 *ngIf="!showHotelLink && responseType === 'ERROR_API' && !this.flightBooking">
                                    {{getHotelBookingErrorMessage(responseType)}}</h3>
                                <h3
                                    *ngIf="showHotelLink && (responseType !== 'ERROR_AVAILABILITY' &&  responseType !== 'ERROR_BOOKING_PENDING')">
                                    {{'successModel.ThankyouforcompletingyourpassengerprofileYourbookingrequestisonitsway'
                                    | translate}}</h3>
                                <h3
                                    *ngIf="!showHotelLink && (responseType !== 'ERROR_API' || (responseType !== 'ERROR_AVAILABILITY' ||  responseType !== 'ERROR_BOOKING_PENDING')) && this.flightBooking">
                                    {{'successModel.Wehavereceivedyourflightbookingrequest.Pleaseallowus20minutestoprocessandconfirmtheflight.Ifyouhaveanyquestions,pleasecontactcustomersupport.' | translate}}
                                </h3>
                                <h3
                                    *ngIf="(!showHotelLink && responseType !== 'ERROR_API') && (responseType !== 'ERROR_AVAILABILITY' &&  responseType !== 'ERROR_BOOKING_PENDING')">
                                    {{'successModel.ThankyouforcompletingyourpassengerprofileYourbookingrequestisonitsway'
                                    | translate}}</h3>
                            </div>
                            <div *ngIf="transactionMode" class="modal-content-heading">
                                <h3> {{'successModel.Thankyouforcompletingyourpassengerprofile,yourtransactionrequestisonitsway.' | translate}}</h3>
                            </div>
                            <div *ngIf="transactionMode" class="modal-content-heading">
                                <p> {{'successModel.Pleasewait,RedirectingyoubacktoTripDetailsassoonasTransactionisgenerated.' | translate}}</p>
                            </div>
                            <div *ngIf="!transactionMode" class="modal-content-note">
                                <p>{{'successModel.Pleasenoteyourcreditcardwillnotbechargeduntilyourbookingiscompleted'
                                    | translate}}</p>
                            </div>
                            <div *ngIf="!multibooking && this.bookingResponse1 && this.bookingResponse1.bookedHotels && this.bookingResponse1.bookedHotels.length > 0 && this.bookingResponse1.bookedHotels[0].reservationCode"
                                class="modal-content-button">
                                <button class="text-button link-primary" style="width: auto;cursor:pointer"
                                    (click)=" goToItinerary()"> {{'successModel.showmeitinerary' | translate}}</button>
                            </div>
                            <div *ngIf="multibooking && this.bookingResponse1"
                                class="modal-content-button" style="display: flex;">
                                <div class="leftside">
                                <span class="text-button" style="width: auto;"
                                   > {{'successModel.showmeitinerary' | translate}}</span>
                                    </div>
                                    <div class="rightside" style="display: block;">
                                    <div  *ngFor="let item of this.bookingResponse1.multiBookingResponse">
                                      <a [routerLink]="['/bookingHistory']" [queryParams]="{type: 'detail',bookingType:'upcoming',ticketid:item.ticketId,tripid:item.tripId,transactionid:item.transactionId}" routerLinkActive="active"(click)="cancelModel()">{{item.travellerDetails.travellers[0].firstName}} {{item.travellerDetails.travellers[0].lastName}}</a>
                                    </div>
                                    </div>
                            </div>
                            <div *ngIf="this.bookingResponse1 && this.bookingResponse1.bookedHotels && this.bookingResponse1.bookedHotels.length > 0 && !this.bookingResponse1.bookedHotels[0].reservationCode"
                                class="modal-content-button">
                                <button class="text-button link-primary" style="width: auto;cursor:pointer"
                                    (click)="gotToHome()"> {{'successModel.close' | translate}}</button>
                            </div>
                            <div *ngIf="(!this.bookingResponse1) && (!transactionMode && !showHotelLink)"
                                class="modal-content-button">
                                <button class="text-button link-primary" style="width: auto;cursor:pointer"
                                    (click)="gotToHome()"> {{'successModel.close' | translate}}</button>
                            </div>
                            <div *ngIf="!showHotelLink && responseType === 'ERROR_AVAILABILITY' ">
                                <button class="text-button link-primary" style="width: auto;cursor:pointer"
                                    (click)="gotoSearchHotelAgain()">{{'successModel.searchAgain' | translate}}</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>