export class HotelEmailDTO {
    public carDistance: number;
    public hotelAdress: string;
    public hotelChain: string;
    public hotelChainName: string;
    public hotelName: string;
    public price: number;
    public walkingDistance: number;
    public inPolicy: boolean;
    public lattitude: number;
    public longitude: number;
    public currency: string;
    checkIn: string;
    checkOut: string;
    appointmentAddress: string;
    rateDetail = {
        fee: null,
        tax: null,
        resortFee: null,
        policy: []
    };
    stay: number;
    prepay: boolean;
    optionId :number;
    eventId:string;
    amenities :any;
    starRating: string;
    category: string;
    nonrefundable: boolean;
    displayPrice:number;
    cancellationPolicyInfo: string;
    displayTax:number;
    displayResortFee:number;
    hotelRooms: any;
    serviceFee: number;
    nonRefundableStayIndicator: string;
    source: string;
    isZipCodeRequired: boolean;
    loyaltyPointsSupported: boolean;
    thumbnailImage:any;
    distanceMile: any;
}