
<div *ngIf="isNavigationBarVisible()" class="app-navigation-menu" [ngStyle]="{'z-index':airportModalOpen() ? '0':'10'}">
  <div class="app-navigation-menu-items">
    <div class="col-auto app-navigation-menu-item" (click)="goToBooking()">
      <span class="{{getCurrentNavigationMenu() ==='book' ? 'select':'unselect'}}"><i class="fa fa-suitcase"
          aria-hidden="true"></i><br>
        <span> {{ 'navigation.Book' | translate }}</span>
      </span>
    </div>
    <div *ngIf="isLoggedIn()" class="col-auto app-navigation-menu-item" (click)="goToTrips()">
      <span class="{{getCurrentNavigationMenu() ==='trip' ? 'select':'unselect'}}"><i class="fa fa-calendar-check-o"
          aria-hidden="true"></i><br>
        <span> {{ 'navigation.Trips' | translate }}</span></span>
    </div>
    <div *ngIf="isLoggedIn()" class="col-auto app-navigation-menu-item" (click)="goToProfile()">
      <span class="{{getCurrentNavigationMenu() ==='profile' ? 'select':'unselect'}}"><i class="fa fa-user"
          aria-hidden="true"></i><br>
        <span> {{ 'navigation.Profile' | translate }}</span>
      </span>
    </div>
    <div *ngIf="isLoggedIn() && !isuserHascard()" class="col-auto app-navigation-menu-item" (click)="support()">
      <span class="{{getCurrentNavigationMenu() ==='support' ? 'select':'unselect'}}"><i
          class="fa fa-phone-square"></i><br>
        <span> {{ 'navigation.Support' | translate }}</span>
      </span>
    </div>
    <div *ngIf="isLoggedIn() && isuserHascard()" class="col-auto app-navigation-menu-item" (click)="goToCards()">
      <span class="{{getCurrentNavigationMenu() ==='support' ? 'select':'unselect'}}"><i class="fa fa-credit-card"
          aria-hidden="true"></i><br>
        <span> {{ 'navigation.Cards' | translate }}</span>
      </span>
    </div>
    <div *ngIf="isLoggedIn() && this.searchService.showApprovalaInMenu" class="col-auto app-navigation-menu-item" (click)="goToApproval()">
      <span class="{{getCurrentNavigationMenu() ==='approval' ? 'select':'unselect'}}"><i class="fa fa-check-square-o" aria-hidden="true"></i>
        <br>
        <span> {{ 'navigation.Approvals' | translate }}</span>
      </span>
    </div>
    <!--  <div *ngIf="!isLoggedIn()" class="col-auto app-navigation-menu-item" (click)="signIn()">
      <span class="{{getCurrentNavigationMenu()==='signIn' ? 'select':'unselect'}}" ><i class="fa fa-sign-in" aria-hidden="true"></i><br>
        <span >Sign-In</span>
      </span>
    </div>-->
  </div>
</div>