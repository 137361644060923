import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAccountService } from '../user-account.service';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { CommonUtils } from '../util/common-utils';
import { NavigationUtil } from '../util/navigation-util';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DeviceDetailsService } from '../device-details.service';
import { SearchService } from '../search.service';
import { BookingService } from '../booking.service';
declare var addNavigationComponentPadding: any;
declare var removeNavigationComponentPadding: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  bsModalRef: BsModalRef;
  isMobile: boolean = false;
  njoySpecificBuild: boolean;
  constructor(private userAccountService: UserAccountService,
    public router: Router,
   
    private modalService: BsModalService,
    public ngxSmartModalService: NgxSmartModalService,
    private deviceDetailsService: DeviceDetailsService,   public bookingService: BookingService,public searchService: SearchService,) { }

  ngOnInit() {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }
  isNavigationBarVisible() {
    if (this.njoySpecificBuild && this.isLoggedIn()) {
      if (NavigationUtil.getCurrentNavigationMenu() !== NavigationUtil.NAVIGATION_MENU_SUPPORT
        &&
        NavigationUtil.getCurrentNavigationMenu() !== NavigationUtil.NAVIGATION_MENU_BOOK

      ) {
        addNavigationComponentPadding();
      }
      return true;
    }
    return false;
  }
  ngOnDestroy() {
    removeNavigationComponentPadding();
  }
  goToBooking() {
    if(this.bookingService.previousBooking){
      return;
    }
    this.router.navigate(['/search']);
    NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_BOOK);
  }
  goToTrips() {
    if(this.bookingService.previousBooking){
      return;
    }
    this.router.navigate(['bookingHistory'], { queryParams: { type: 'list' } });
    NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_TRIPS);
  }
  goToProfile() {
    if(this.bookingService.previousBooking){
      return;
    }
    this.router.navigate(['/profile']);
    NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_PROFILE);
  }
  support() {
    if(this.bookingService.previousBooking){
      return;
    }
    //this.userAccountService.signOut();
    //localStorage.setItem("loginSession",null);
    this.router.navigate(['/support']);
    NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_SUPPORT);
  }
  airportModalOpen() {
    if (this.ngxSmartModalService.getOpenedModals() &&
      (this.ngxSmartModalService.getOpenedModals().length > 0 && this.isMobile)
    ) {
      let modals = this.ngxSmartModalService.getOpenedModals();
      if (modals[0].id.search('search') !== -1 || modals[0].id.search('dest') !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  isuserHascard(): boolean {
    return this.userAccountService.userhascard;
  }
  isLoggedIn() {
    return this.userAccountService.isLoggedIn();
  }
  signIn() {
    if(this.bookingService.previousBooking){
      return;
    }
    this.router.navigate(['/login']);
    NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_BOOK);
  }

  goToCards() {
    if(this.bookingService.previousBooking){
      return;
    }
    this.router.navigate(['/cards'], { queryParams: {} });
    NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_SUPPORT);
  }
  goToApproval(){
    if(this.bookingService.previousBooking){
      return;
    }
    this.router.navigate(['/approval'], { queryParams: {type:'pending'} });
    NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_APPROVAL);
  }
  isUserAdmin(){
    return this.userAccountService.isUserCompanyAdmin();
  }
  getCurrentNavigationMenu() {
    let currentScreen = NavigationUtil.getCurrentNavigationMenu();
    if (currentScreen && currentScreen.trim().length > 0) {
      return currentScreen;
    }
    let lPath: string = this.router.url.split('?')[0];
    if (lPath === '/search') {
      NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_BOOK);
      return 'book';
    }
    if (lPath === '/profile') {
      NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_PROFILE);
      return 'profile';
    }
    if (lPath === '/bookingHistory') {
      NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_TRIPS);
      return 'trip';
    }
    if (lPath === '/approval') {
      NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_TRIPS);
      return 'approval';
    }
  }
}
