
import { TravelerDetails } from './traveler-details';
import { TripSessionBookingData } from './trip-session-booking-data';

export class TripSessionBookingRequest {

    groupTagName:string;
	existingGroupId:string;

  bookingItems: TripSessionBookingData [] ;
  approvalItems: TripSessionBookingData [] ;
  travellerDetails: TravelerDetails;

  expensifyId: string;
  addToExpensify: boolean;
  displayPrice:number;
  displayCurrency:string;
  expenseProvider: string;
  projectTagId: string;
  attemptDirectBooking:boolean;
  noteToAdmin;string;

  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}