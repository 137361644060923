import { EmailHops } from "./email-hops";
import { AngularRouterTracking } from "ngx-analytics";

export class FlightLegEmailDTO {



	home_to_airport_time: string;
	isRedEye: boolean;
	isSudoNonStop: boolean;
	isPassportMandatory: boolean;
	combo: boolean;
	isZipcodeMandatory: boolean;
	tripType: string;


	preferredAirline: boolean;
	preferredAirport: boolean;
	lowToFairPrice: boolean;
	withinPolicy: boolean;
	meetingOverLap: boolean;
	baggageAllowance:any;
	smallLayover: boolean;
	eventId: string = "EVENTID";
	optionId: string = "OPTIONID";

	handlerName: string;
	flightDurationInDays: number;

	airlineName: string;

	airline: string;

	home_to_airport_traffic: string;
	trafficTimeFromAirport: string;


	flight_hops: Array<EmailHops>;

	num_stops: string;

	stay_date: string;

	booking_url: string;

	departure_time: string;

	arrival_time: string;


	to_airport: string;



	from_airport: string;




	from_airport_code: string;


	to_airportCode: string;

	flight_date: string;

	flight_arrival_date: string;

	price: string;
	currency: string;
	discountedPrice: string;

	flight_duration: string;

	flight_duration_in_min: string;

	arrival: string;
	travelCreditsInfo: any;

	meeting_location: string;

	destinationText: string;

	meeting_time: string;

	home_time: string;

fareAttributes:any;
	flight_num: string;

	airport_add: string;

	airlineImageUrl: string;
	serviceFee: string;
	constructor() {

	}
}