import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators, FormControl, Form, ValidationErrors } from '@angular/forms';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { FilterService } from '../filter.service';
import { BookingData, BookedOption } from '../util/bookingdata';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { DeviceDetailsService } from '../device-details.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { SearchService } from './../search.service';
import { Constants } from '../util/constants';
import { DateUtils } from '../util/date-utils';
import { FlightUtils } from '../util/flight-utils';
import { BookingHistoryService } from '../booking-history.service';
import { ConditionalExpr } from '@angular/compiler';
import { UserAccountService } from '../user-account.service';
import { splitTypescriptSuffix } from '@angular/compiler/src/aot/util';
import { CancelSuccessComponent } from '../cancel-success/cancel-success.component';
import { HotelHistoryModalComponent } from '../hotel-history-modal/hotel-history-modal.component';
import { UserAccountInfo } from '../entity/user-account-info';
import { TranslateService } from '@ngx-translate/core';
import { CommonUtils } from '../util/common-utils';
import { Location, PlatformLocation } from '@angular/common';
import { ImageRequest } from '../entity/image-request';
import { HotelSearchService } from '../hotel-search.service';
import { FlightHopResult } from '../entity/flight-hop-result';
import { NgxAnalytics } from 'ngx-analytics';
import { GallopAnalyticsUtil } from '../analytics.service';
import { NavigationUtil } from '../util/navigation-util';
import { environment } from 'src/environments/environment';
import { BaggageDetails } from '../entity/baggage-details';
import { Title } from '@angular/platform-browser';
import { DeleteCardModelComponent } from '../email-booking-flow/delete-card-model/delete-card-model.component';
import { UserCredentialsService } from '../user-credentials.service';
import { AdminPanelService } from '../admin-panel.service';
import { url } from 'inspector';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { BookingService } from '../booking.service';
import { Datasource } from 'ngx-ui-scroll';
import { Console } from 'console';
@Component({
  selector: 'booking-history-component',
  templateUrl: './booking-history.component.html',
  styleUrls: ['./booking-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class bookingHistoryComponent {
  //bookingData:BookingData;
  @Input() showAllBookings=false;
  flightPresent = false;
  hotelPresent = false;
  selectOptionForModal = '';
  isDataLoading = false;
  viewMode1 = 'tab11';
  viewMode11 ='tab22'
  eventid: any;
  viewMode = 'tab1';
  hideChanges = false;
  messageForChange = '';
  from: string;
  bookingsOption: any;
  clicked = false;
  refreshingData = false;
  classOptions = Constants.CLASS_OPTIONS;
  cancellationRequestReceived: boolean;
  y: number;
  z: number;
  tripItUrl = '';
  tripItHotelUrl = '';
  itineraryUrl = '';
  invoiceUrl: Array<string> = [];
  originalBookinngPastList = [];
  originalBookinngList = [];
  calenderUrl = '';
  calUrls = {};
  layoverShow = false;
  allowAddToExpensify = false;
  airlines;
  airports;
  imageResuestObj: ImageRequest[]
  flightList2: Array<BookedOption> = [];
  hotelList2: Array<BookedOption> = [];
  all_bookingData: any;
  modalShow = false;
  modalShow1 = false;
  expenseProviders = [];
  detailView = false;
  flightView = false;
  hotelView = false;
  combinedView = false;
  isEmpty = false;
  isEmpty2 = false;
  detailList: Array<any> = [];
  detailTransaction: any = {};
  fareList: Array<any> = [];
  hotelList: Array<any> = [];
  flightList: Array<any> = [];
  bookingValue='All'
  bookingTypeOptions=[{id:'All',value:'fuild.Allbookingtype'},{id:'flight',value:'search.Flight'},{id:'hotel',value:'bookingHistory.Hotel'},{id:'cars',value:'profilePref.Car'}]
  isMobile: boolean;
  length1 = 0;
  gallopCash = 0;
  traveler = [];
  cardType = '';
  deviceSubscription: Subscription;
  allBookingSubscription: Subscription;
  fetchImagesSubscription: Subscription;
  companyApprovalSubscription:Subscription;
  activeRouteSubscription:Subscription;
  noOfFlightLegs = [];
  bsModalRef: BsModalRef;
  pageMode: string;
  ticketid = '';
  tripid = '';
  bookingType = '';
  errorMsg = false;
  paymentMethod = '';
  sendRequestProgress = false;
  selectTransId = '';
  expensify_add = [];
  expensify_add_hotel = [];
  showRadioError = false;
  companyName = '';
  showChangeRequestError = false;
  njoySpecificBuild: boolean;
  radioSelect = '';
  checked = false;
  expensifyType = [{ name: this.translateService.instant('bookingHistory.hotel'), value: 'hotel' },
  { name: this.translateService.instant('bookingHistory.flight'), value: 'flight' }];
  expensifyEmailControl: FormControl;
  expensifyEmailControlDD: FormControl;
  expensifyEmail: string = null;
  type = ['hotel', 'flight'];
  selectOption = '';
  userName = '';
  startDate: Date = new Date();
  endDate: Date = new Date();
  minimumDate: Date = new Date();
  maximumDate1: Date = new Date();
  errorInCalenderRadio = false;
  calenderUrlHotel = '';
  daterangepickerModel = [this.startDate, this.endDate];
  private userAccountSubscription: Subscription;
  private fetchAccountInfoSubscription: Subscription;
  private userAccountInfoObj: UserAccountInfo;
  public flightsDatasource = new Datasource({
    get: (index, count, success) => {
      let data = [];
      let max = this.flightList.length;
      const lastElementIndex = Math.min(index + count, max);
      if (index < lastElementIndex) {
        data = this.flightList.slice(index, lastElementIndex);
      }
      success(data);
    },
    settings:{
      startIndex: 0,
      bufferSize: 50,
      padding: 10,
      windowViewport: true
    }
  });

  constructor(private modalService: BsModalService,
    private searchService: SearchService,
    private searchService1: HotelSearchService,
    private bookingService: BookingService,
    private userCredentials: UserCredentialsService,
    private location: Location,
    private userAccountService: UserAccountService,
    private router: Router,
    public ngxSmartModalService: NgxSmartModalService,
    private deviceDetailsService: DeviceDetailsService,
    private bookingHistoryService: BookingHistoryService,
    public translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private ngxAnalytics: NgxAnalytics,
    private titleService: Title,
    private adminPanelService: AdminPanelService,
    private cdRef: ChangeDetectorRef,
    location1: PlatformLocation) {
    location1.onPopState(() => {
      if (this.pageMode === 'list' && this.detailList.length > 0) {
        this.detailView = true;
      }
    });
  }


  ngOnInit() {

    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    this.bookingService.previousBooking =undefined;
    this.bookingService.previousTransactionId= undefined;
    this.bookingService.pageMode ='';
   // this.titleService.setTitle('Bookings');
    this.titleService.setTitle(this.translateService.instant('search.Bookings'));
    if(!this.searchService.showAllBookings){
    NavigationUtil.setCurrentNavigationMenu(NavigationUtil.NAVIGATION_MENU_TRIPS);
    }
   this.activeRouteSubscription =  this.activatedRoute.queryParams.subscribe(params => {
      this.setParameters(params);
      // if(params['type'] == 'list')
      // this.bookingHistoryComponentInit();
    });

    this.hotelList = [];
    this.hotelList2 = [];
    this.radioSelect = '';
    this.messageForChange = '';
    this.userAccountSubscription = this.userAccountService.userAccountInfoObjObserver$.subscribe((userAccountInfo: UserAccountInfo) => {
      this.userName = this.userAccountService.getUserName();
    });
    this.expensifyEmailControl = new FormControl(this.expensifyEmail, Validators.compose([this.expensifyEmailValidator.bind(this), Validators.pattern(Constants.RGEX_EMAIL)]));
    this.expensify_add = [];
    this.deviceSubscription = this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.fetchAccountInfoSubscription = this.userAccountService.userAccountInfoObjObserver$.subscribe((userAccountInfo: UserAccountInfo) => {
      if (!userAccountInfo || userAccountInfo == null) return;
      this.userAccountInfoObj = userAccountInfo;
      this.companyName = this.userAccountService.getUserCompany();
      this.userName = this.userAccountService.getUserName();
      this.expenseProviders = CommonUtils.getActiveExpenseProviders(this.userAccountInfoObj);
      if (this.expenseProviders && this.expenseProviders.length > 0) {
        this.expensifyEmailControlDD = new FormControl(this.expenseProviders[0].id);
      } else {
        this.expensifyEmailControlDD = new FormControl();
      }
      if (this.userAccountInfoObj.userInfo.expensifyId
        && this.userAccountInfoObj.userInfo.expensifyId.trim().length > 0) {
        this.expensifyEmail = this.userAccountInfoObj.userInfo.expensifyId;
        this.expensifyEmailControl.setValue(this.expensifyEmail);

      } else {
        this.expensifyEmail = this.userAccountInfoObj.userInfo.email;
        this.expensifyEmailControl.setValue(this.expensifyEmail);
      }
      if (this.userAccountService.njoySpecificBuild) {
        if (this.userAccountService.userIsDeptAdmin){
          let startDate1: Date = new Date();
          let endDate1: Date = new Date();
          let tempdate1 = DateUtils.getFormattedDateWithoutTimeZone(startDate1);
          let tempdate2 = DateUtils.getFormattedDateWithoutTimeZone(endDate1);
          let startDate = DateUtils.getDayAsLocalISODateString(tempdate1);
          let endDate = DateUtils.getDayAsLocalISODateString(tempdate2);
          this.adminPanelService.fetchCompanyApprovals(this.userAccountService.getUserCompanyId(), startDate, endDate, 'all');
        }

      }
      this.bookingHistoryComponentInit();
    });

    // let params = this.activatedRoute.snapshot.queryParams;
    // this.setParameters(params);
    this.companyApprovalSubscription = this.adminPanelService.companyApprovalResponseObservable$.subscribe((reportResponse) => {
      if (reportResponse) {
          if (reportResponse.pendingApprovals.length > 0) {
            this.searchService.showApprovalaInMenu =true;
          } else {
            this.searchService.showApprovalaInMenu =false;
          }
    
      } else {
        this.searchService.showApprovalaInMenu =false;
      }
    });
    this.searchService.browserBackOrForwardClickListener.asObservable().subscribe(data => {
      if (data) {
        if (this.bsModalRef) {
          this.bsModalRef.hide();
        }
      }
    });
  } 
  changeStyle() {
    if (!this.isMobile) {
      if (this.viewMode11 === 'tab22') {
        return { 'min-width': '230px' };
      } else {
        return { 'min-width': '730px' }
      }
    } else {
      if (this.viewMode11 === 'tab22') {
        return { 'min-width': '230px' };
      } else {
        return { 'min-width': '99vw' }
      }
    }
  }
  handleModalEvents(eventName: string, currentModalId: string) {
    CommonUtils.handleModalEvents(this.ngxSmartModalService, eventName, currentModalId);
  }
  openNgxModal(id, picker) {
    if (this.appLoader) {
      return;
    }
    setTimeout(() => {
      this.ngxSmartModalService.getModal(id).open()
    }, 100);

    setTimeout(() => {
      this.viewMode11 = 'tab22';
     // picker.show();
    }, 200);
  }
  changeStyle1() {
    if (!this.isMobile) {
      if (this.viewMode11 === 'tab22') {
        return { 'height': '300px', 'overflow': 'auto' };
      } else {
        return { 'height': '335px', 'overflow': 'hidden' }
      }
    } else {
      if (this.viewMode11 === 'tab22') {
        return { 'height': '300px', 'overflow': 'auto' };
      } else {
        return { 'height': '700px', 'overflow': 'hidden' }
      }
    }
  }
  private detectChanges(){
    try {
      this.flightsDatasource.adapter.reload(0);
      this.cdRef.reattach();
      this.cdRef.detectChanges();
    
    } catch(err) {

    }
  }
  showDateChanged(id, item) {
    this.dateType = id;
    if (id === 'NEXT7DAYS') {
      this.startDate = new Date();
      this.endDate = new Date();
      this.endDate.setDate(this.startDate.getDate() +6);
      this.startDate.setMonth(this.startDate.getMonth());
      this.daterangepickerModel = [this.startDate, this.endDate];
      // this.getPreviousTimeData();
    } else if (id === 'LAST7DAYS') {
      this.startDate = new Date();
      this.endDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - 6);
      this.daterangepickerModel = [this.startDate, this.endDate];
      // this.getPreviousTimeData();
    }
    else if (id === 'NEXT14DAYS') {
      this.startDate = new Date();
      this.endDate = new Date();
      this.endDate.setDate(this.startDate.getDate() +13);
      this.daterangepickerModel = [this.startDate, this.endDate];
      //  this.getPreviousTimeData();
    } else if (id === 'LAST14DAYS') {
      this.endDate = new Date();
      this.startDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - 13);
   //   this.startDate = new Date(curryear, 0, 1);
      this.daterangepickerModel = [this.startDate, this.endDate];
      // this.getPreviousTimeData();
    }
    else if (id === 'LAST30DAYS') {
      this.startDate = new Date();
      this.endDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - 29);
      this.daterangepickerModel = [this.startDate, this.endDate];
      // this.getPreviousTimeData();
    } else if (id === 'NEXT30DAYS') {
      this.startDate = new Date();
      this.endDate = new Date();
      this.endDate.setDate(this.startDate.getDate() +29);
      this.daterangepickerModel = [this.startDate, this.endDate];
    } 
    this.callTripsApi();
    if (this.ngxSmartModalService.getOpenedModals() &&
      (this.ngxSmartModalService.getOpenedModals().length > 0)
    ) {
      let modals = this.ngxSmartModalService.getOpenedModals();
      for (let index = 0; index < modals.length; index++) {
        if (modals[index].id === 'daterangeSelection') {
          this.ngxSmartModalService.close('daterangeSelection');
        }
      }
    }
    this.viewMode11 = 'tab22';
    // this.getChart();
  }
  filterData() {
    let originalResponse = JSON.parse(JSON.stringify(this.originalBookinngList));
    if (this.statusValue !== 'All status') {
      originalResponse = originalResponse.filter(item => this.getUserBookingStatus(item.tripStatus, item.type, item) === this.statusValue);
    }
    if (this.bookingValue !== 'All') {
      originalResponse = originalResponse.filter(item => item.type === this.bookingValue);
    }
    if (this.empNameSearchValue && this.empNameSearchValue.trim().length > 0) {
      originalResponse = this.searchByNameChanged(this.empNameSearchValue, originalResponse);
    }
    this.flightList = originalResponse;
    if(this.flightList.length > 0){
      this.isEmpty =false;
    }else{
      this.isEmpty =true;
    }
    this.detectChanges();
  }

  getFlightIconURL(flightCode: string) {
    return CommonUtils.getAirlineImageUrl(flightCode);
  }
  
  searchByNameChanged(nameString, originalResponse) {
    // let originalResponse =JSON.parse(JSON.stringify(this.originalResponse));
    if (nameString && nameString.trim().length > 0) {
      originalResponse = originalResponse.filter(item => {
        const userInfo = item.primaryTraveller;
        let confirmationNo;
       
          confirmationNo = this.getConfirmationNo(item);
        
      
        return (userInfo.userName && userInfo.userName.toLowerCase().indexOf(nameString.toLowerCase()) > -1)
        || (userInfo.userId && userInfo.userId.toLowerCase().indexOf(nameString.toLowerCase()) > -1 || (confirmationNo && confirmationNo.toLowerCase().indexOf(nameString.toLowerCase()) > -1));
      });
    }
    this.detectChanges();
    return originalResponse;
}
  showStatusChanged(event) {
    // this.statusValue = event;
    this.filterData();
    //this.buildData(originalResponse);

  }
  showBookingTypeChanged(event) {
    // this.statusValue = event;
    this.filterData();
    //this.buildData(originalResponse);

  }
  getdateType(){
    let option = this.DATE_OPTIONS.filter(item => item.id === this.dateType);
    return option[0].name;
  }
  empNameSearchValue='';
  statusValue='All status';
  statusOptions=[];
  dateType='ANYTIMEINFUTURE'
  DATE_OPTIONS = [{ id: 'ANYTIMEINFUTURE', value: _('ngOption.AnyTimeInFuture'),name:_('ngOption.AnyTimeInFuture') }, { id: 'NEXT7DAYS', value: _('ngOption.Next7Days'),name: _('ngOption.Next7Days') }, { id: 'NEXT14DAYS', value: _('ngOption.Next14Days'),name:_('ngOption.Next14Days') }, { id: 'NEXT30DAYS', value: _('ngOption.Next30Days'),name:_('ngOption.Next30Days') },{ id: 'ANYTIMEINPAST', value: _('ngOption.AnyTimeInPast'),name:_('ngOption.AnyTimeInPast') },{ id: 'LAST7DAYS', value: _('ngOption.Last7days'),name:_('ngOption.Last7days') },{ id: 'LAST14DAYS', value: _('ngOption.Last14Days'),name:_('ngOption.Last14Days') }, { id: 'LAST30DAYS', value: _('ngOption.Last30days'),name: _('ngOption.Last30days') }];
  callTripsApi(){
    if(this.showAllBookings){

      let tempdate1 = DateUtils.getFormattedDateWithoutTimeZone(this.startDate);
      let tempdate2 = DateUtils.getFormattedDateWithoutTimeZone(this.endDate);
  
      let startDate = DateUtils.getDayAsLocalISODateString(tempdate1);
      let endDate = DateUtils.getDayAsLocalISODateString(tempdate2);
      if(this.dateType==='ANYTIMEINFUTURE'){
      endDate=null;
      }else if (this.dateType==='ANYTIMEINPAST'){
        startDate =null;
      }
      this.flightList =[];
      this.appLoader =true;
   this.allBookingSubscription =   this.bookingHistoryService.getAllBookings(startDate,endDate).subscribe(resp =>{
      if(resp){
        console.log("resp",resp);
        setTimeout(() => {
          this.handleBookingHistoryListResponse(resp);
          this.detectChanges();
        }, 1000);
      }
     });
     this.detectChanges();
    }
  }
  private setParameters(params) {
    if (params['type']) {
      this.pageMode = params['type'];
      this.from = params['from'];
      this.tripid = params['tripid'];
      this.ticketid = params['ticketid'];
      this.selectTransId = params['transactionid'];
      this.bookingType = params['bookingType'];
      if (this.pageMode === 'list') {
        this.backToListWork();
        this.searchService.showAllBookings =false;
        if (this.flightList.length === 0 && this.flightList2.length === 0) {
          this.fetchBookingHistoryList();
        }
        if (this.hideChanges) {
          this.switchBookingListTab('tab2')
        }

      }else if(this.pageMode==='allbookings'){
          this.callTripsApi();
      }
    }
  }
  private bookingHistoryComponentInit() {

    if (this.pageMode === 'detail') {
      let bookeOption = { ticketid: '', tripid: '', option: { selectTransId: '' } }
      bookeOption.ticketid = this.ticketid;
      bookeOption.tripid = this.tripid;
      bookeOption.option.selectTransId = this.selectTransId;
      if (this.bookingType === 'past') {
        this.hideChanges = true;
      } else {
        this.hideChanges = false;
      }
      this.refreshingData = true;
      this.detailView = true;
      this.getDetailView(bookeOption, this.hideChanges);
    } else {
      this.detailView = false;
      //this.fetchBookingHistoryList();
    }

  }
  showMYTrip(item) {
    let userid = this.userAccountService.getUserEmail();
    let originalResponse = JSON.parse(JSON.stringify(this.originalBookinngList));
    if (item === 'myself') {
      this.viewMode1 = 'tab11';
      if (originalResponse.length > 0) {
        this.flightList = originalResponse.filter(item => item.primaryTraveller.userId === userid);
      }
    } else if (item === 'others') {
      this.viewMode1 = 'tab12';
      if (originalResponse.length > 0) {
        this.flightList = originalResponse.filter(item => item.primaryTraveller.userId !== userid);
      }
    }
    if (this.flightList.length == 0) {
      this.isEmpty = true;
    } else {
      this.isEmpty = false;
    }
    this.detectChanges();
  }
  showMYTrip1(item) {
    let userid = this.userAccountService.getUserEmail();
    let originalResponse = JSON.parse(JSON.stringify(this.originalBookinngPastList));
    if (item === 'myself') {
      this.viewMode1 = 'tab11';
      if (originalResponse.length > 0) {
        this.flightList2 = originalResponse.filter(item => item.primaryTraveller.userId === userid);
      }
    } else if (item === 'others') {
      this.viewMode1 = 'tab12';
      if (originalResponse.length > 0) {
        this.flightList2 = originalResponse.filter(item => item.primaryTraveller.userId !== userid);
      }
    }
    if (this.flightList2.length == 0) {
      this.isEmpty2 = true;
    } else {
      this.isEmpty2 = false;
    }
    this.detectChanges();
  }
  showChangesFlag(status: string, type: string, item: any){
    return CommonUtils.getStatusLabelForHideChange(type, status, item,this.translateService)
  }
  getUserBookingStatus(status: string, type: string, item: any,) {
    return CommonUtils.getStatusLabel(type, status, item,this.translateService);
    //   if(type == 'flight'){
    //     if (item.option.flight_option.cancellationRequestReceived){
    //       return 'CNCL under review';
    //     }
    //     if(status == 'ticketed' ||status == 'expensed' ) return 'Booked';
    //     else if(status == 'cancelled') return 'Cancelled';
    //     else return 'Under review'
    //   }else if(type == 'hotel'){
    //     if (item.option.hotel_option.cancellationRequestReceived){
    //       return 'CNCL under review';
    //     }
    //     if(status == 'booked' ||status == 'expensed' ) return 'Booked';
    //     else if(status == 'cancelled') return 'Cancelled';
    //     else return 'Under review'
    //   } else if(type == 'cars'){
    //     if (item.option.car_option.cancellationRequestReceived){
    //       return 'CNCL under review';
    //     }
    //   if(status == 'booked' ||status == 'expensed' ) return 'Booked';
    //   else if(status == 'cancelled') return 'Cancelled';
    //   else return 'Under review'
    // }
  }

  channgeAlignment() {
    if (this.isMobile) {
      if (this.showAllBookings){
        return { 'text-align': 'end' };
      } else if (this.viewMode1 === 'tab11') {
        return { 'text-align': 'left' };
      } else {
        return { 'text-align': 'end' };
      }
    } else {
      if (this.viewMode1 === 'tab11') {
        return { 'text-align': 'center','padding-top': '0px' };
      } else {
        return { 'text-align': 'end','padding-top': '0px' };
      }
    }
  }
  expensifyEmailValidator(control: AbstractControl): ValidationErrors | null {
    let result = null;
    if ((!control.value || control.value.trim().length === 0)) {
      return { 'required': true };
    }
    return result;
  }
  isLoggedIn() {
    return this.userAccountService.isLoggedIn();
  }
  getUrlThumbsUp() {
    return 'assets/images/Untitled_design-2_copy.png';
  }
  isThumbDown() {
    return this.userAccountService.historyThumb === 'DOWN';
  }
  isThumbUp() {
    return this.userAccountService.historyThumb === 'UP';
  }
  getUrlThumbsDown() {
    return 'assets/images/Untitled_design_copy.png';
  }
  private fetchBookingHistoryList() {
    this.bsModalRef = this.modalService.show(HotelHistoryModalComponent, {
      initialState: {

      }, backdrop: true, keyboard: false, ignoreBackdropClick: true
    });
    this.bookingHistoryService.requestBookingHistoryList().subscribe(resp => {
      setTimeout(() => {
        this.handleBookingHistoryListResponse(resp);
      }, 1000);
    }, error => {
      this.isDataLoading = true;
      setTimeout(() => {
        this.bsModalRef.hide();
      }, 1000);
      this.isEmpty2 = true;
      this.isEmpty = true;
    });
  }
appLoader =false
  private handleBookingHistoryListResponse(resp) {
    if (resp.success === true && resp.data) {
      this.isEmpty2 = false;
      this.isEmpty = false;
      this.appLoader =false
      this.detailView = false;
      let bookingHistory: BookingData = JSON.parse(JSON.stringify(resp));
      this.isDataLoading = true;
      if (!this.detailView) {
        if(this.bsModalRef){
        this.bsModalRef.hide();
        }
      }
      this.airlines = bookingHistory.data.airlineNames;
      this.airports = bookingHistory.data.airports;
      this.originalBookinngPastList = [];
      this.originalBookinngList = [];
      this.getBookingPastList(bookingHistory.data.past);
      this.getBookingList(bookingHistory.data.upcoming);
    } else {
      this.isDataLoading = true;
      if (!this.detailView) {
        if(this.bsModalRef){
          this.bsModalRef.hide();
          }
      }
      this.appLoader =false
      this.isEmpty2 = true;
      this.isEmpty = true;
    }
    this.detectChanges();
}

  showExpensifyButton() {
    if (this.allowAddToExpensify && (this.flightPresent || this.hotelPresent)) {
      return true;
    }
    return false;
  }
  showFlightHotelCheckBox() {
    if (this.flightPresent && this.hotelPresent) {
      return true;
    }
    return false;
  }
  getBookingPastList(pastList: Array<BookedOption>) {
    for (let bookedOption of pastList) {
      if (bookedOption.option.selectTransId) {
        this.originalBookinngPastList.push(bookedOption);
      }
    }
    let userid = this.userAccountService.getUserEmail();
    let originalResponse = JSON.parse(JSON.stringify(this.originalBookinngPastList));
    if (this.originalBookinngPastList.length > 0 && this.viewMode1 === 'tab11') {
      this.flightList2 = originalResponse.filter(item => item.primaryTraveller.userId === userid);
    } else {
      this.flightList2 = originalResponse.filter(item => item.primaryTraveller.userId !== userid);
    }
    if (this.flightList2.length == 0) {
      this.isEmpty2 = true;
    } else {
      this.isEmpty2 = false;
    }
  }
  switchBookingListTab(tabId) {
    this.viewMode = tabId;
    let actionName = 'upComingBookingsList';
    if (tabId === 'tab2') {
      actionName = 'pastBookingsList';
      if (this.viewMode1 == 'tab12') {
        this.showMYTrip1('others');
      } else {
        this.showMYTrip1('myself');
      }
    }else  if (tabId === 'tab1') {
      if (this.viewMode1 == 'tab12') {
        this.showMYTrip('others');
      } else {
        this.showMYTrip('myself');
      }
    }
    GallopAnalyticsUtil.trackActionWithCategory(this.ngxAnalytics,
      actionName, 'Bookings'
    );
  }
  getBookingList(upcomingList: Array<BookedOption>) {
    for (let bookedOption of upcomingList) {
      if (bookedOption.option.selectTransId) {
        this.originalBookinngList.push(bookedOption);
      }
    }
    let userid = this.userAccountService.getUserEmail();
    let originalResponse = JSON.parse(JSON.stringify(this.originalBookinngList));
    if (this.originalBookinngList.length > 0 && this.viewMode1 === 'tab11' && !this.showAllBookings) {
      this.flightList = originalResponse.filter(item => item.primaryTraveller.userId === userid);
    } else if(!this.showAllBookings){
      this.flightList = originalResponse.filter(item => item.primaryTraveller.userId !== userid);
    }else if(this.showAllBookings){
     this.filterData();
    }
    if(this.showAllBookings && originalResponse.length > 0){
      this.statusOptions = [{ value: 'All booking status', id: 'All status',name:'fuild.Allbookingstatus' }];
      for(let item of originalResponse){
        let status={value:'',name:'',id:''};
        const currItemStatus = this.getUserBookingStatus(item.tripStatus, item.type, item);
        let findIndex = this.statusOptions.findIndex(item1 => item1.id ===currItemStatus);
        if(findIndex ===-1){
          status.value = currItemStatus;
          status.name = currItemStatus;
          status.id = currItemStatus;
          this.statusOptions.push(status);
        }
      }
    }
    if (this.flightList.length == 0) {
      this.isEmpty = true;
    } else {
      this.isEmpty = false;
    }
    this.detectChanges();
  }

  cabinClass: any;
  navigateToDetailView(bookingOption: any, hideChange){
    if(!this.searchService.showAllBookings){
         this.getDetailView(bookingOption,hideChange);
    }
    let ticketid = bookingOption.ticketid;
    let tripid = bookingOption.tripid;
    let selectTransId = bookingOption.option.selectTransId;
    this.flightPresent = false;
    this.hotelPresent = false;
    this.ticketid = bookingOption.ticketid;
    this.tripid = bookingOption.tripid;
    this.selectTransId = bookingOption.option.selectTransId;
    if (!this.refreshingData) {
      if (this.hideChanges === false) {
        this.router.navigate(["bookingHistory"],
          {
            queryParams:
            {
              type: 'detail',
              bookingType: 'upcoming',
              ticketid: ticketid,
              tripid: tripid,
              transactionid: selectTransId
            }
          }
        );
      } else {
        this.router.navigate(["bookingHistory"],
          {
            queryParams:
            {
              type: 'detail',
              bookingType: 'past',
              ticketid: ticketid,
              tripid: tripid,
              transactionid: selectTransId
            }
          }
        );
      }
    }
  }
  getDetailView(bookingOption: any, hideChange) {
    GallopAnalyticsUtil.trackActionWithCategory(this.ngxAnalytics, 'bookingDetails', 'Bookings');

    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    this.bsModalRef = this.modalService.show(HotelHistoryModalComponent, {
      initialState: {

      }, backdrop: true, keyboard: false, ignoreBackdropClick: true
    });
    this.hideChanges = hideChange;
    this.detailView = true;
    this.detailList = [];
    this.fareList = [];
    this.length1 = 0;
    this.tripItUrl = '';
    this.tripItHotelUrl = '';
    this.invoiceUrl = [];
    this.calenderUrl = '';
    this.calenderUrlHotel = '';
    this.calUrls = {};
    let ticketid = bookingOption.ticketid;
    let tripid = bookingOption.tripid;
    let selectTransId = bookingOption.option.selectTransId;
    this.flightPresent = false;
    this.hotelPresent = false;
    this.ticketid = bookingOption.ticketid;
    this.tripid = bookingOption.tripid;
    this.selectTransId = bookingOption.option.selectTransId;
    let tripSessionId = bookingOption.tripSessionId
    this.bookingHistoryService.getDetail(ticketid, tripid, selectTransId,tripSessionId).subscribe(resp => {
      if (resp.success === true && resp.data) {
        let bookingHistoryDetail = JSON.parse(JSON.stringify(resp));
        this.invoiceUrl = bookingHistoryDetail.data.invoiceUrl;
        this.airlines = bookingHistoryDetail.data.airlineNames;
        this.airports = bookingHistoryDetail.data.airports;
        let bookingHistoryArray = bookingHistoryDetail.data.detail;
        let fareList = bookingHistoryDetail.data.transaction.data;

        if (bookingHistoryDetail.data.cabinClassNames) {
          this.cabinClass = bookingHistoryDetail.data.cabinClassNames
        }
        this.gallopCash = bookingHistoryDetail.data.transaction.gallopCashUsed;
        this.detailTransaction = bookingHistoryDetail.data.transaction;
        let travellerdetail = bookingHistoryDetail.data.travellersData;
        if (bookingHistoryDetail.data.calendarUrls && bookingHistoryDetail.data.calendarUrls.length > 0) {
          this.calUrls = {};
          for(let urlCounter = 0; urlCounter < bookingHistoryDetail.data.calendarUrls.length; urlCounter++) {
            const currTuple = bookingHistoryDetail.data.calendarUrls[urlCounter];
            this.calUrls[currTuple.name] = currTuple.value;
          }
        }
        if (travellerdetail) {
          this.traveler = [...travellerdetail.travellers];
        }
        window.scrollTo(0, 0);
        this.allowAddToExpensify = bookingHistoryDetail.data.allowAddToExpensify;
        if (this.userAccountInfoObj && this.userAccountInfoObj.companySettings
          && this.userAccountInfoObj.companySettings.expensifySupported) {
          this.allowAddToExpensify = this.allowAddToExpensify && this.userAccountInfoObj.companySettings.expensifySupported;
        } else {
          this.allowAddToExpensify = false;
        }
        this.length1 = bookingHistoryArray.length;
        this.cardType = this.detailTransaction.data[0].paymentSourceId;
        this.paymentMethod = this.detailTransaction.data[0].paymentSource;
        for (let item of bookingHistoryArray) {
          if (item.type == 'flight') {
            item.option.flight_option.layoverInfo = this.getLegWiseLayoverList(item);
            this.cancellationRequestReceived = item.option.flight_option.cancellationRequestReceived;
            this.hotelList.push(item);
            this.expensify_add.push('flight');
            this.selectOptionForModal = 'flight';
            this.tripItUrl = bookingHistoryDetail.data.tripItUrl;
            this.calenderUrl = bookingHistoryDetail.data.calenderUrl;
            this.flightPresent = true;
          } else if (item.type == 'cars') {
            //item.option.flight_option.layoverInfo = this.getLegWiseLayoverList(item);
            this.hotelList.push(item);
            this.cancellationRequestReceived = item.option.car_option.cancellationRequestReceived;
            this.selectOptionForModal = 'cars';
          } else {
            this.fetchImages(item);
            this.hotelList2.push(item);
            this.cancellationRequestReceived = item.option.hotel_option.cancellationRequestReceived;
            if (item.feedback) {
              this.userAccountService.feedbackMsg = 'Your feedback';
              this.userAccountService.historyFeedBack = true;
              this.userAccountService.historyThumb = item.feedback.feedback;
              this.userAccountService.hotelReason = item.feedback.negativeFeedbackReason;
            } else {
              this.userAccountService.feedbackMsg = 'Rate your experience';
              this.userAccountService.historyFeedBack = false;;
              this.userAccountService.historyThumb = '';
              this.userAccountService.hotelReason = [];
            }
            this.expensify_add_hotel.push('hotel');
            this.eventid = item.eventId;
            this.selectOptionForModal = 'hotel';
            this.tripItHotelUrl = bookingHistoryDetail.data.tripItUrlHotel;
            this.calenderUrlHotel = bookingHistoryDetail.data.calenderUrlHotel;
            this.hotelPresent = true;
          }
          this.detailList.push(item);
        }
        if (this.hotelList.length > 0 && this.hotelList2.length > 0) {
          this.selectOptionForModal = '';
          // this.expensify_add=[];
          // this.expensify_add_hotel=[];
        }
        for (let item1 of fareList) {
          if (item1.archived) continue;
          if (item1.event_type !== 'other' || this.canShowLineItems()) {
            this.fareList.push(item1);
          }
        }
        setTimeout(() => {
          this.bsModalRef.hide();
        }, 1000);
      } else {
        this.bsModalRef.hide();
        this.detailView = false;
        this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
          initialState: {
            message: this.translateService.instant('bookingHistory.NoResultsFound'),
          },
        });
      }
    
      
     
     
      this.detectChanges();
    })
  
  }
  getHeading() {
    if (this.detailTransaction.approvalStatus === 'pending') {
      return  this.translateService.instant('bookingHistory.approvalrequest');
    } else {
      if (this.detailList && this.detailList.length > 0 && this.detailList[0].type === 'cars') {
        return  this.translateService.instant('bookingHistory.car');
      } else if (this.detailList && this.detailList.length > 0 && this.detailList[0].type === 'hotel') {
        return this.translateService.instant('bookingHistory.hotel');
      } else if (this.detailList && this.detailList.length > 0 && this.detailList[0].type === 'flight') {
        return  this.translateService.instant('bookingHistory.flight');
      }
    }
  }
  showRebookButton(){
    let showButtons = true;  
    if (this.detailList) {
      for (let item of this.detailList) {
        if (item.type==='flight') {
          let bookingTime = new Date(item.option.flight_option.legs[item.option.flight_option.legs.length-1].hops[item.option.flight_option.legs[item.option.flight_option.legs.length-1].hops.length-1].ends);
          let currentTime = new Date();
          if (currentTime.getTime() > (bookingTime.getTime())) {
            showButtons = false;

          }else{
            showButtons = true;  
          }
          
        }
      }
    }
    return showButtons;
  }
  getCarCancelMsg(type, item) {
    if (type === 'cars') {
      let bookingTime = new Date(item.option.car_option.pickupDateTimeISO);
      let currentTime = new Date();
      if (currentTime.getTime() < (bookingTime.getTime())) {
        return this.translateService.instant('bookingHistory.Yourreservationisstilleligibleforfullrefund.');
      } else {
        return this.translateService.instant('bookingHistory.YourreservationmayNOTbeeligibleforrefund.Youwillreceiveanemailwithdetailsoftravelfunds.');
      }
    } else {
      return this.translateService.instant('bookingHistory.YourreservationmayNOTbeeligibleforrefund.Youwillreceiveanemailwithdetailsoftravelfunds.');
    }
  }
  getHeading1() {
    if (this.detailList && this.detailList.length > 0 && this.detailList[0].type === 'cars') {
      return this.translateService.instant('bookingHistory.car');
    } else if (this.detailList && this.detailList.length > 0 && this.detailList[0].type === 'hotel') {
      return this.translateService.instant('bookingHistory.hotel');
    } else if (this.detailList && this.detailList.length > 0 && this.detailList[0].type === 'flight') {
      return this.translateService.instant('bookingHistory.flight');
    }
  }
  getPriceItem(item){
    if(item.displayPrice){
      return item.displayPrice;
    }else{
      return item.price;
    }
  }
  getCancelMsg() {
    let bookingTime = new Date(this.detailTransaction.created);
    let currentTime = new Date();
    if (currentTime.getTime() < (bookingTime.getTime() + (24 * 3600 * 1000))) {
      return this.translateService.instant('bookingHistory.Yourreservationisstilleligibleforfullrefund.');
    } else {
      return this.translateService.instant('bookingHistory.YourreservationmayNOTbeeligibleforrefund.Youwillreceiveanemailwithdetailsoftravelfunds.');
    }
  }
  getCarPerDayPrice(item) {
    var subTotal = (item.option.car_option.price - (item.option.car_option.price - item.option.car_option.basePrice))
    return (subTotal / item.option.car_option.numberOfDay);
  }
  carUrl(car) {
    if (car.images) {
      return car.images.S;
    }
  }
  fetchImages(item) {
    item.url = 'assets/images/hotel/hotel.png';
    let hotelImagesArray;
    if (item.option.hotel_option.handleType === "TravelPort") {
      var firstRequestCodes: ImageRequest[] = [];
      var requestObj: ImageRequest = new ImageRequest();
      requestObj.code = item.option.hotel_option.hotelCode;
      requestObj.chainCode = item.option.hotel_option.hotelChain;
      firstRequestCodes.push(requestObj);
      this.fetchImagesSubscription = this.searchService1.requestAllHotelImage(firstRequestCodes).subscribe(res => {
        if (res.success === true && res.data) {
          hotelImagesArray = res.data;
          if (hotelImagesArray[0].hotelImages) {
            item.url = CommonUtils.getThumbnailImage(hotelImagesArray[0].hotelImages);
          }
        }
      });
    } else {
      item.url = CommonUtils.getThumbnailImage(item.option.hotel_option.hotelImages);
    }
  }
  isMapSupported() {
    if (environment.platform.toLowerCase() === 'ios' && environment.appStoreBuildNumber >= 27) {
      return true;
    }
    if (environment.platform.toLowerCase() === 'android') {
      return true;
    }
    return false;
  }
  mapsSelector(lat, long) {
    if (this.njoySpecificBuild) {
      window.location.href = 'maps://maps.google.com/maps?daddr=' + lat + ',' + long + '&dummy=/TripItAPI/';
    } else {
      window.open("https://maps.google.com/maps?daddr=" + lat + ',' + long);
    }
  }
  onExpensifyClicked(option, event) {
    if (event.target.checked) {
      if (option == 'flight') {
        this.expensify_add.push(option);
        this.errorMsg = false;
      } else {
        this.expensify_add_hotel.push(option);
        this.errorMsg = false;
      }
    } else {
      if (this.expensify_add.length > 0 || this.expensify_add_hotel.length > 0) {
        this.deleteMsg(option);
      }
    }
  }
  deleteMsg(msg) {
    if (msg == 'flight') {
      const index: number = this.expensify_add.indexOf(msg);
      if (index !== -1) {
        this.expensify_add.splice(index, 1);
      }
    } else {
      const index: number = this.expensify_add_hotel.indexOf(msg);
      if (index !== -1) {
        this.expensify_add_hotel.splice(index, 1);
      }
    }
  }
  getCurrencySymbol(item): string {
    if(item.displayCurrency){
      return CommonUtils.getCurrencySymbol(item.displayCurrency);
    }else{
    return CommonUtils.getCurrencySymbol(item.currency);
    }
  }

  isExpensifyChecked(option) {
    if (option == 'flight') {
      return this.expensify_add.indexOf(option) > -1;
    } else {
      return this.expensify_add_hotel.indexOf(option) > -1;
    }
  }
  backToListWork() {
    this.selectOption = '';
    this.messageForChange = '';
    this.expensify_add = [];
    this.expensify_add_hotel = [];
    this.hotelList = [];
    this.hotelList2 = [];
    this.radioSelect = '';
    this.detailView = false;
    this.gallopCash = 0;
    this.selectOptionForModal = '';
    this.detectChanges();
  }

  backToList() {
   if(this.searchService.showAllBookings){
   history.go(-1);
   }else{
   this.router.navigate(["bookingHistory"],
             {
                queryParams:
               {
                  type: 'list',
                },
                replaceUrl: false
              }
            );
   }
   
  }
  getCreditPrice(fare) {
    if(fare.displayPrice){
      return fare.displayPrice;
    }else{
    return fare.price;
    }
  }
  getTotalFare(fare, data): number {
    let totalFare = CommonUtils.calculateFare(fare, this.gallopCash);

    if (data.type === 'hotel' && data.option.hotel_option.hotelRateDetail.resortFee) {
      if(data.option.hotel_option.hotelRateDetail.displayResortFee){
        totalFare = (totalFare + parseFloat(data.option.hotel_option.hotelRateDetail.displayResortFee));
      }else{
      totalFare = (totalFare + parseFloat(data.option.hotel_option.hotelRateDetail.resortFee.substring(3)));
      }
      totalFare = ((totalFare > 0) ? totalFare : 0);
      return totalFare;
    } else if (data.type === 'flight') {
      let totalFlightPrice = 0;
      for (let lineItemCOunter = 0; lineItemCOunter < fare.length; lineItemCOunter++) {
        if (fare[lineItemCOunter].transactionEventType === 'trip_event') {
          if(fare[lineItemCOunter].displayPrice){
            totalFlightPrice += fare[lineItemCOunter].displayPrice;
          }else{
          totalFlightPrice += fare[lineItemCOunter].price;
          }
        }
      }
      const perTravellerPrice = (totalFlightPrice / this.traveler.length);
      for (let counter = 0; counter < fare.length; counter++) {
        if (fare[counter].transactionEventType === "travel_credit") {
          const item = fare[counter];
          if (Math.abs(item.displayPrice)){
            if (Math.abs(item.displayPrice) <= perTravellerPrice) {
              totalFare -= Math.abs(item.displayPrice);
            } else {
              totalFare -= perTravellerPrice;
            }
          }else{
          if (Math.abs(item.price) <= perTravellerPrice) {
            totalFare -= Math.abs(item.price);
          } else {
            totalFare -= perTravellerPrice;
          }
        }
      }
      }
      return totalFare;
    } else {
      return totalFare;
    }
  }
  getHotelPhoneNo(hotel): any {
    if (hotel.option.hotel_option.phoneNumber && hotel.option.hotel_option.phoneNumber.length !== 0) {
      return hotel.option.hotel_option.phoneNumber[0].number;
    }
  }



  getHotelPolicy(hotel): string {
    let roomPolicy = '';
    let policyArray = hotel.option.hotel_option.hotelRooms[0].hotelRates[0].cancellationPolicies;
    if (policyArray) {
      for (let policy of policyArray) {
        if (policy.cancellationRule) {
          roomPolicy = roomPolicy + policy.cancellationRule + ',';
        }
      }
      return roomPolicy;
    } else {
      return null;
    }
  }
  goToTripId(addTripitModal) {
    // console.log("hotel details",this.tripItHotelUrl,this.tripItUrl,this.hotelList)
    if (this.showFlightHotelCheckBox()) {
      this.bsModalRef = this.modalService.show(addTripitModal);
    } else {
      if (this.tripItHotelUrl !== '') {
        if (this.njoySpecificBuild) {
          window.location.href = this.tripItHotelUrl + '?dummy=/TripItAPI/';
        } else {
          window.open(this.tripItHotelUrl, "_blank");
        }
      } else {
        if (this.njoySpecificBuild) {
          window.location.href = this.tripItUrl + '?dummy=/TripItAPI/';
        } else {
          window.open(this.tripItUrl, "_blank");
        }
      }
    }
  }
  goToCalendar(addCalendarModal) {
    if (this.showFlightHotelCheckBox()) {
      this.bsModalRef = this.modalService.show(addCalendarModal);
    } else {
      this.modalShow = true;
      this.modalShow1 = false;
      this.ngxSmartModalService.getModal('calendarOptionModal').open();
    }
  }
  setTripItUrl(option) {
    if (option) {
      this.showRadioError = false;
    }
  }
  setCalendarUrl(option) {
    if (option) {
      this.errorInCalenderRadio = false;
    }
  }
  proceedtoTripIt() {
    if (!this.radioSelect) {
      this.showRadioError = true;
    } else {
      this.showRadioError = false;
      if (this.radioSelect == 'hotel') {
        window.open(this.tripItHotelUrl);
        this.bsModalRef.hide();
      } else {
        window.open(this.tripItUrl);
        this.bsModalRef.hide();
      }
    }
  }

  getConfirmationNo(item): string {
    let confirmNo = '';
    if(this.detailTransaction && this.detailTransaction.approvalStatus==='pending'){
      return 'PENDING APPROVAL';
    }
    if(item.type==='flight'){
    for (let leg of item.option.flight_option.legs) {
      for (let hop of leg.hops) {
        if (hop.pnr) {
          confirmNo = confirmNo + hop.pnr + ',';
        }
      }
    }
    var trim = confirmNo.replace(/(^,)|(,$)/g, "");
    return trim;
  }else if(item.type==='hotel'){
      return item.option.hotel_option.bookingId;
  }else{
      return item.option.car_option.bookingId;
  
  }
  }
  getLayoverDetails(item, i, j): boolean {
    if (item.option.flight_option.layoverInfo.length > 0) {
      if (item.option.flight_option.layoverInfo[i][j]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getHopSeatNo(hop): boolean {
    if (hop.seatNo && hop.seatNo != ' ') {
      return true;
    } else {
      return false;
    }
  }
  getDurationOfHop(legIndex: number): string {
    let result = '';

    var totalTime = legIndex;
    var quotient = Math.floor(totalTime / 60);
    var rem = (totalTime % 60);
    return result = quotient + 'h' + ' ' + rem + 'm';
  }
  getDuration(flight, legIndex: number) {
    return this.getFlightLegDuration(flight, legIndex);
  }
  getFlightLegDuration(flight, legIndex: number): any {
    let durationInMins = this.getFlightLegDurationInMin(flight, legIndex);
    return DateUtils.getDurationAsHrsMinObj(durationInMins);
  }

  getFlightLegDurationInMin(flight, legIndex: number): any {
    if (!flight && !flight.option.flight_option.legs[legIndex]) return 0;
    let startDate = new Date(flight.option.flight_option.legs[legIndex].hops[0].starts);
    let endDate = new Date(flight.option.flight_option.legs[legIndex].hops[flight.option.flight_option.legs[legIndex].hops.length - 1].ends);

    return DateUtils.getDateDiffInMinutes(endDate, startDate);
  }
  getAirlineFullName(code) {
    let airlineFullName = null;
    airlineFullName = this.airlines[code];
    return (airlineFullName === '' || airlineFullName === null) ? code : airlineFullName;
  }

  getAirportCity(code) {

    return code && this.airports[code] && this.airports[code]['name'] ? this.airports[code].name : code;
  }

  getDisplayDate(dateString: string, format: string): string {
    return DateUtils.getFormattedDateWithoutTimeZoneFromFormat(new Date(dateString), format);
  }
  getDisplayDateTimeForFlights(dateString: string, format: string): string {
    return DateUtils.getFormattedDateForGivenTimeZone(dateString, format);
  }

  showModal(roomGalleryModal) {
    this.checked = false;
    this.bsModalRef = this.modalService.show(roomGalleryModal, { class: 'historyModal' });
  }
  showCancelModal(modal1, modal2) {
    let roomGalleryModal;
    if (this.detailList.length > 0) {
      let onlyFlights = this.detailList.find(item => item.type !== 'flight');
      if (!onlyFlights) {
        roomGalleryModal = modal2;
      } else {
        roomGalleryModal = modal1;
      }
    }

    this.bsModalRef = this.modalService.show(roomGalleryModal, { class: 'historyModal', backdrop: true, keyboard: !false, ignoreBackdropClick: true },);
  }
  onModelCancel() {
    if (this.sendRequestProgress) {
      return;
    }
    this.bsModalRef.hide();
  }
  cancelRequest(item: any) {
    this.sendRequestProgress = true;
    let msg = this.translateService.instant('bookingHistory.Yourcancellationisunderprocess.Youwillshortlyreceiveanemailswithdetailsoffunds.')
    let msg1 = this.translateService.instant('bookingHistory.Yourapprovalrequesthasbeensuccessfullycancelled.')
    let ticketid = item[0].ticketid;
    let tripid = item[0].tripid;
    let selectTransId = item[0].option.selectTransId;
    this.bookingHistoryService.getPost(ticketid, tripid, selectTransId).subscribe(data => {

      this.bsModalRef.hide();
      this.sendRequestProgress = false;
      if (data && data.success) {
      this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
        initialState: {
          message: (this.detailTransaction.approvalStatus === 'pending') ? msg1 : msg,
          message2: 'AllowedToProceed'
        }, backdrop: true, keyboard: false, ignoreBackdropClick: true
      })
      this.bsModalRef.content.acceptClickSubject.subscribe(result => {
        if (result && data.success) {
          let bookeOption = { ticketid: '', tripid: '', option: { selectTransId: '' } }
          bookeOption.ticketid = this.ticketid;
          bookeOption.tripid = this.tripid;
          bookeOption.option.selectTransId = this.selectTransId;
          if (this.bookingType === 'past') {
            this.hideChanges = true;
          } else {
            this.hideChanges = false;
          }
          this.refreshingData = true;
          if (this.detailTransaction.approvalStatus !== 'pending') {
            this.detailView = true;
            this.getDetailView(bookeOption, this.hideChanges);
            this.flightList = [];
            this.flightList2 = [];
            setTimeout(() => {
              if(this.searchService.showAllBookings){
               this.callTripsApi();
               history.go(-1);
                this.detectChanges();
              } else {
              this.bookingHistoryService.requestBookingHistoryList().subscribe(resp => {
                this.handleBookingHistoryListResponse(resp);
              });
            }
            }, 200);


          } else {
            this.flightList = [];
            this.flightList2 = [];
            if(this.bsModalRef){
              this.bsModalRef.hide();
            }
            if(this.searchService.showAllBookings){
              history.go(-1);
              }else{
              this.router.navigate(["bookingHistory"],
                        {
                           queryParams:
                          {
                             type: 'list',
                           },
                           replaceUrl: false
                         }
                       );
              }
             
            
          //  this.titleService.setTitle('Bookings');
            this.titleService.setTitle(this.translateService.instant('search.Bookings'));
          }
          this.detectChanges();
        }else if(result){
          this.bsModalRef.hide();
        }

      });
    }else {

      if (data && !data.success  && data.error_message) {
       
      this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
        initialState: {
          message:data.error_message,
          message2: 'AllowedToProceed'
        }, backdrop: true, keyboard: false, ignoreBackdropClick: false
      })
    }
    }
    })

  }
  getChecked() {
    this.checked = false;
  }
  onRequestChangeForFlight(item: any) {
    this.bookingService.previousBooking = item[0];
    if(item.length >1){
    for(let i=1;i<item.length;i++){
      this.bookingService.previousBooking.option.flight_option.legs.push(item[i].option.flight_option.legs[0]);
    }
  }
  this.bookingService.airlines = this.airlines;
  this.bookingService.airports = this.airports;
  this.searchService.employeeEmail =[];
  this.searchService.previousSearch = null;
  this.searchService.previousSearch = undefined;
  this.bookingService.rebookingTravlleremail = this.traveler[0].email;
    this.bookingService.rebookingTravllername = this.traveler[0].firstName +" "+ this.traveler[0].lastName;
    console.log("item ",item);
    let type='';
    if(item[0].type==='cars'){
      type='car'
    }else  if(item[0].type==='flight'){
      type='flight'
    }else  if(item[0].type==='hotel'){
      type='hotel'
    }
    this.bookingService.previousTransactionId = item[0].option.selectTransId;
    if(this.bsModalRef){
      this.bsModalRef.hide();
    }
    this.router.navigate(["search"],
      {
        queryParams:
        {
          type: type,
        },
        replaceUrl: true
      }
    );
  }
  onRequestChange(item: any) { 
    if (this.messageForChange) { 
      this.showChangeRequestError = false; 
      this.checked = true; let ticketid = item[0].ticketid; 
      let tripid = item[0].tripid; let selectTransId = item[0].option.selectTransId;
       this.bookingHistoryService.getPostOnChange(ticketid, tripid, selectTransId, this.messageForChange).subscribe(data => { this.bsModalRef.hide(); this.bsModalRef = this.modalService.show(CancelSuccessComponent, { initialState: { message: this.translateService.instant('bookingHistory.Yourchangerequesthasbeensentsuccessfully'), }, }) }); } else { this.showChangeRequestError = true; } }
  onExpensifyAdd(item: any) {
    if (this.expensifyEmailControl.valid && this.expensify_add.length > 0 || this.expensify_add_hotel.length > 0) {
      let expensifyType = '';
      this.sendRequestProgress = true;
      this.errorMsg = false;
      if (this.expensify_add.length > 0 && this.expensify_add_hotel.length > 0) {
        expensifyType = 'All';
      } else {
        if (this.expensify_add.length > 0) {
          expensifyType = 'flight';
        } else if (this.expensify_add_hotel.length > 0) {
          expensifyType = 'hotel';
        }
      }
      let ticketid = item[0].ticketid;
      let tripid = item[0].tripid;
      let selectTransId = item[0].option.selectTransId;
      this.bookingHistoryService.getPostExpensifyAdd(ticketid, tripid, selectTransId, expensifyType,
        this.expensifyEmailControl.value, this.expensifyEmailControlDD.value).subscribe(data => {
          this.bsModalRef.hide();
          this.sendRequestProgress = false;
          this.bsModalRef = this.modalService.show(CancelSuccessComponent, {
            initialState: {
              message: this.translateService.instant('bookingHistory.Expensesentsuccessfully'),
            },
          });
        });
    } else if (this.expensify_add.length == 0 && this.expensify_add_hotel.length == 0) {
      this.errorMsg = true;
    }
  }
  detailViewMobile(text) {
    this.detailView = true;
    switch (text) {
      case 'flight':
        this.flightView = true;
        this.hotelView = false;
        this.combinedView = false;
        break;
      case 'hotel':
        this.flightView = false;
        this.hotelView = true;
        this.combinedView = false;
        break;
      case 'combined':
        this.flightView = false;
        this.hotelView = false;
        this.combinedView = true;
        break;
    }
  }
  getClassName(id) {
    let className = null;

    this.classOptions.map(item => {
      if (item.id.toLowerCase() == id.toLowerCase()) {
        className = item.value;
      }
    });

    if (className === null) {
      return id;
    }

    return className;

  }
  getClassNameAndBrandName(hop: any) {
    if (hop) {
      let cabinClass = CommonUtils.classNameReturnFromMap(this.cabinClass, hop.carrier, hop.type);
      if (cabinClass) {
        return cabinClass;
      }
    }
    let className = this.getClassName(hop.type);
    if (hop.brandDetail && hop.brandDetail.trim().length > 0 && hop.brandDetail.trim() != 'null') {
      let brandObj = JSON.parse(hop.brandDetail);
      if (brandObj.name && brandObj.name.trim().length > 0) {
        className = className + ' (' + brandObj.name.trim() + ')';
      }
    }
    return className;
  }

  goToSearch() {
    this.router.navigate(['search']);
  }
  getLegWiseLayoverList(flight): any[] {

    let flightLayoverInfoList = [];

    if (flight) {

      flight.option.flight_option.legs.forEach((leg) => {

        let numOfHops = leg.hops.length;

        let flightLegLayoverInfoList = [];

        if (numOfHops > 1) {

          for (let i = 0; i < numOfHops - 1; i++) {

            var layoverIn = (leg.hops[i].to == leg.hops[i + 1].from) ?
              leg.hops[i].to :
              leg.hops[i].to + "/" + leg.hops[i + 1].from;

            let flightLegLayoverInfo = { in: '', duration: {} };
            flightLegLayoverInfo.in = layoverIn;

            var hop1Date = new Date(leg.hops[i].ends);
            var hop2Date = new Date(leg.hops[i + 1].starts);

            var timeDiffInMin = DateUtils.getDateDiffInMinutes(hop2Date, hop1Date);

            flightLegLayoverInfo.duration = DateUtils.getDurationAsHrsMinObj(timeDiffInMin);

            flightLegLayoverInfoList.push(flightLegLayoverInfo);
          }
        }

        if (flightLegLayoverInfoList.length > 0) {
          flightLayoverInfoList.push(flightLegLayoverInfoList);
        }

      })

    }
    return flightLayoverInfoList;
  }


  private canShowLineItems() {
    let canShow: boolean = true;
    if (this.detailList) {
      for (let item of this.detailList) {
        if (item.type === 'flight' && !(item.option.status === 'ticketed' || item.option.status === 'expensed')) {
          canShow = false;
          break;
        } else if (item.type === 'hotel' && !(item.option.status === 'booked' || item.option.status === 'expensed')) {
          canShow = false;
          break;
        }
      }
    } else {
      canShow = false;
    }
    return canShow;
  }

  private canShowGallopCashEarning() {
    return this.userAccountService.getSubscriptionPlan()
      && this.userAccountService.getSubscriptionPlan() != null
      && this.userAccountService.getSubscriptionPlan().id.toString() !== 'subscription_free';
  }

  private isTransactionApproved() {
    return this.detailTransaction && this.detailTransaction.status === 'approved';
  }

  private getGallopCashEarning(): number {
    let earning = 0;
    if (this.detailList) {
      for (let item of this.detailList) {
        if (item.type === 'flight' && (item.option.status === 'ticketed' || item.option.status === 'expensed')) {
          earning = earning + Number.parseFloat(item.option.flight_option.price);
        } else if (item.type === 'hotel' && (item.option.status === 'selected' || item.option.status === 'ticketed' || item.option.status === 'booked' || item.option.status === 'expensed')) {
          earning = earning + Number.parseFloat(item.option.hotel_option.minPrice);
        }
        else if (item.type === 'cars' && (item.option.status === 'selected' || item.option.status === 'ticketed' || item.option.status === 'booked' || item.option.status === 'expensed')) {
          earning = earning + Number.parseFloat(item.option.car_option.price);
        }
      }
    }
    return this.userAccountService.getGallopCashBack(earning);
  }
  getOpenModal() {
    if (!this.selectOption) {
      this.errorInCalenderRadio = true;
    } else {
      this.modalShow = false;
      this.modalShow1 = true;
      this.selectOptionForModal = this.selectOption;
      this.ngxSmartModalService.getModal('calendarOptionModal').open();
    }
  }
  onSmartModelCancel(modelName: string) {
    this.ngxSmartModalService.getModal(modelName).close();
  }


  downloadInvoices() {
    if (this.invoiceUrl) {
      let count = 1;
      for (let url of this.invoiceUrl) {
        url = url+'&selectedLanguage='+this.userCredentials.getLang();
        setTimeout(() => {
          if (this.njoySpecificBuild) {
            window.location.href = url + '&dummy=/TripItAPI/';
          } else {
            window.open(url, "_blank");
          }
        }, 500 * count);
        count++;
      }
    }
  }

  showCalenderButton() {
    return (this.calenderUrl && this.calenderUrl.trim().length > 0) || (this.calenderUrlHotel && this.calenderUrlHotel.trim().length > 0);
  }

  showTripItButton() {
    if (this.flightPresent || this.hotelPresent) {
      return (this.tripItUrl && this.tripItUrl.trim().length > 0) || (this.tripItHotelUrl && this.tripItHotelUrl.trim().length > 0);
    } else {
      return false;
    }
  }

  showDownloadInvoiceButton() {
    return this.invoiceUrl && this.invoiceUrl.length > 0;
  }
  getPerNightCost(item) {
    let night = item.option.hotel_option.stay;
    if(item.option.hotel_option.hotelRateDetail && item.option.hotel_option.displayCurrency){
      if (item.option.hotel_option.hotelRateDetail && item.option.hotel_option.hotelRateDetail.displayBase) {
        if (this.showTaxes()) {
          return ((item.option.hotel_option.displayPrice - this.getTax(item)) / night);
        } else {
          return this.detailTransaction.data[0].displayPrice / night;
        }
      } else {
        return (item.option.hotel_option.displayPrice / night);
      }
    }else{
    if (item.option.hotel_option.hotelRateDetail && item.option.hotel_option.hotelRateDetail.base) {
      if (this.showTaxes()) {
        return ((item.option.hotel_option.originalPrice - this.getTax(item)) / night);
      } else {
        return this.detailTransaction.data[0].price / night;
      }
    } else {
      return (item.option.hotel_option.originalPrice / night);
    }
  }
  }
  getTax(item) {
    var numOfRooms = 1;
    if (item.option && item.option.hotel_option && item.option.hotel_option.hotelRooms
      && item.option.hotel_option.hotelRooms.length > 0) {
      numOfRooms = item.option.hotel_option.hotelRooms.length;
    }
    if(item.option.hotel_option.hotelRateDetail.displayBase){
      if (item.option.hotel_option.hotelRateDetail.displayBase) {
        let base = item.option.hotel_option.hotelRateDetail.displayBase;
        let total = item.option.hotel_option.hotelRateDetail.displayTotal;
        return (total - base) * numOfRooms;
      }
    }else{
    if (item.option.hotel_option.hotelRateDetail.base) {
      let base = item.option.hotel_option.hotelRateDetail.base.substring(3);
      let total = item.option.hotel_option.hotelRateDetail.total.substring(3);
      return (total - base) * numOfRooms;
    }
  }
    return 0;
  }
  getTaxForCar(item) {
    if(item.option.car_option.displayBasePrice){
      if (item.option.car_option.displayBasePrice) {
        let base = item.option.car_option.displayBasePrice;
        let total = item.option.car_option.displayPrice;
        return (total - base);
      } 
    }else{
    if (item.option.car_option.basePrice) {
      let base = item.option.car_option.basePrice;
      let total = item.option.car_option.price;
      return (total - base);
    }
  }
  }
  getCarPrice(item){
    if(item.displayBasePrice){
      return item.displayBasePrice
    }else{
      return item.basePrice
    }
  }
  getResortFee(item) {
    if (item.option.hotel_option.hotelRateDetail.displayResortFee){
      return item.option.hotel_option.hotelRateDetail.displayResortFee;
    }else{
    if (item.option.hotel_option.hotelRateDetail.resortFee)
      return item.option.hotel_option.hotelRateDetail.resortFee.substring(3);
    }
  }
  getTotalPassengerFromEmail(data) {
    var noOfGuest = 0;
    for (let item of data.option.hotel_option.hotelRooms) {
      noOfGuest = noOfGuest + item.hotelRates[0].roomOccupancyDTO.numberOfAdult;
    }
    return noOfGuest;
  }
  ngOnDestroy() {
    if (this.fetchAccountInfoSubscription) {
      this.fetchAccountInfoSubscription.unsubscribe();
      this.deviceSubscription.unsubscribe();
    }
    if(this.activeRouteSubscription){
      this.activeRouteSubscription.unsubscribe();
    }
    if(this.allBookingSubscription){
      this.allBookingSubscription.unsubscribe();
    }
    if(this.companyApprovalSubscription){
      this.companyApprovalSubscription.unsubscribe();
    }
  }

  showActionButtons() {
    let showButtons = true;
    if (this.detailList) {
      for (let item of this.detailList) {
        if (item.option.status === 'cancelled' || item.option.status === 'voided') {
          showButtons = false;
        }
      }
    }
    return showButtons;
  }
  showExpenseButtons() {
    let showButtons = true;
    let status = '';
    if (this.detailList) {
      for (let item of this.detailList) {
        if (item.type === 'flight') {
          status = this.getConfirmationNo(item);
        } else if (item.type === 'hotel') {
          status = item.option.hotel_option.bookingId;
        } else {
          status = item.option.car_option.bookingId;
        }
        if (status && status.toLowerCase() === 'pending') {
          showButtons = false;
        }
      }
    }
    return showButtons;
  }
  getPaymentDetails() {
    if (this.detailTransaction && this.detailTransaction.data && this.detailTransaction.data.length > 0) {
      var nonOtherTransactionCount = 0;
      for (let transactionData of this.detailTransaction.data) {
        if (transactionData.event_type.toLowerCase() !== 'other') {
          nonOtherTransactionCount++;
        }
      }
      if (nonOtherTransactionCount === 1) {
        return this.detailTransaction.data[0].paymentSourceDesc;
      }
    }
    return null;
  }
  showHotelPriceBreakUp() {
    if (this.detailTransaction && this.detailTransaction.data && this.detailTransaction.data.length > 0) {
      var hotelTransactionCount = 0;
      for (let transactionData of this.detailTransaction.data) {
        if (transactionData.event_type.toLowerCase() === 'hotel') {
          hotelTransactionCount++;
        }
      }
      if (hotelTransactionCount === 1) {
        return true;
      }
    }
    return false;
  }
  showTaxes() {
    if (this.showHotelPriceBreakUp()) {
      if (this.hotelList2[0].option.hotel_option.originalPrice === this.detailTransaction.data[0].price) {
        return true;
      }
    }
    return false;
  }
  getBaggageDetailsColor(data) {
    let baggageDetails: BaggageDetails = null;
    if (data && data.option && data.option.flight_option) {
      let baggageDetailsString: string = data.option.flight_option.legs[0].hops[0].baggageAllowed;
      if (baggageDetailsString && baggageDetailsString.trim().length > 0) {
        baggageDetails = JSON.parse(baggageDetailsString);
        if (baggageDetails.numberOfPieceAllowed && baggageDetails.numberOfPieceAllowed.trim().length > 0
          && Number.parseInt(baggageDetails.numberOfPieceAllowed) > 0) {
          return 'rgb(109, 194, 165)';
        }
        if ((baggageDetails.maxWeightAllowed && baggageDetails.maxWeightAllowed.trim().length > 0
          && Number.parseInt(baggageDetails.maxWeightAllowed) === 0) || (!baggageDetails.maxWeightAllowed)) {
          return 'rgb(237, 109, 120)';
        } else {
          return 'rgb(109, 194, 165)';
        }
      }
    }
    return null;
  }
  getBaggageDetails(data) {
    let baggageDetails: BaggageDetails = null;
    if (data && data.option && data.option.flight_option) {
      let baggageDetailsString: string = data.option.flight_option.legs[0].hops[0].baggageAllowed;
      if (baggageDetailsString && baggageDetailsString.trim().length > 0) {
        baggageDetails = JSON.parse(baggageDetailsString);
        if (baggageDetails.numberOfPieceAllowed && baggageDetails.numberOfPieceAllowed.trim().length > 0
          && Number.parseInt(baggageDetails.numberOfPieceAllowed) > 0) {
          return (Number.parseInt(baggageDetails.numberOfPieceAllowed) === 1) ? this.translateService.instant('flightChart.1checkedbagperadult')
            : baggageDetails.numberOfPieceAllowed + this.translateService.instant('flightChart.checkedbagsperadult');
        }
        if ((baggageDetails.maxWeightAllowed && baggageDetails.maxWeightAllowed.trim().length > 0
          && Number.parseInt(baggageDetails.maxWeightAllowed) === 0) || (!baggageDetails.maxWeightAllowed)) {
          return this.translateService.instant('flightChart.Nocheckedbags');
        } else {
          return baggageDetails.maxWeightAllowed + ' ' + baggageDetails.weightUnit.toLowerCase() + this.translateService.instant('flightChart.peradult');;
        }
      }
    }
    return null;
  }
  getPlaceholderText() {
    return CommonUtils.getExpensefieldPlaceholderText(this.expensifyEmailControlDD.value,this.translateService);
  }
  getLastIndex(hops){
    if(hops.length > 0)      
      return hops.length - 1;
    };

    // checkHotelImgIs(url){
    //   console.log(url)
    //   if(url == undefined){
    //     return `assets/images/hotel/hotel.png`;
    //   }else{
    //     for(var key in url)
    //     return url[key];
    //   }
    // }
};
