<ng-container *ngIf="show">
  <div class="modal-header">
    <div *ngIf="this.addEmployeeReqInProgress" class="approval_request_diaglog_bg_clickhandler"
      (click)="$event.stopPropagation();"></div>
    <h5 *ngIf="mode === 'add'" class="modal-title" id="myModalLabel">
      {{ 'employee.AddanEmployee' | translate}}
    </h5>
    <h5 *ngIf="mode === 'edit'" class="modal-title" id="myModalLabel">
      {{ 'employee.EditEmployee' | translate}}
    </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
      <i class="material-icons" style="color:#fff;">close</i>
    </button>
  </div>
  <div class="modal-body" style=" text-align: left !important;">
    <span *ngIf="mode === 'add'" class="heading">{{ 'employee.AddEmployeeDetails' | translate}}</span>
    <span *ngIf="mode === 'edit'" class="heading">{{ 'employee.EditEmployeeDetails' | translate}}</span>
    <span *ngIf="mode === 'addManager'" class="heading">Add Manager</span>
    <span *ngIf="mode === 'addAdmin'" class="heading">Add Admin</span>
    <form [formGroup]="employeeForm">
      <div class="row" style="margin-top:12px;">
        <div class="col-lg-6 col-md-12">
          <label class="name"> {{ 'employee.Department' | translate}}</label>
          <div id="policylistDiv11" class="formControl">
            <ng-select #department appendTo="#policylistDiv11" (change)="departmentSelected($event)"
              style="font-family: 'apercu-r' !important;bottom: 10px;" dropdownPosition="bottom" [searchable]="false"
              [clearable]="false" formControlName="department" [items]="departmentOptions" bindLabel="" bindValue="id">
              <span class="selectBox-remove"><span class="material-icons">clear</span></span>
              <ng-template ng-label-tmp let-item="item">
                <span class="option-title">{{item.name | translate}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-option="item">
                <span class="option-title">{{option.name | translate}}</span>

              </ng-template>
            </ng-select>
            <div
              *ngIf="employeeForm.controls['department'].hasError('required') && (employeeForm.controls['department'].touched || employeeForm.controls['department'].dirty)"
              class="error" style="position: relative;top: -35px;">{{'login.thisfieldisrequired' | translate}}
            </div>
          </div>
          <svg class="down-arrow" (click)="department.toggle()"
            style="top: -22px;position:relative;float:right;right:50px;" width="15" height="9" viewBox="0 0 15 9"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
              fill="#8936F3" />
          </svg>
        </div>
        <div class="col-lg-6 col-md-12">
          <label _ngcontent-gwe-c27="" class="name">Role</label>
          <div id="roleDropdown">
            <div class="formControl">
              <ng-select #roleDropdown appendTo="#roleDropdown" 
                dropdownPosition="bottom" [searchable]="false" [clearable]="false" formControlName="userRole"
                [items]="userRoleOptions" bindLabel="value" bindValue="id" (change)="roleChange($event)"
                >
                <ng-template ng-option-tmp let-option="item">
                    <span class="option-title">{{option.name}}</span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <span>{{item.name}}</span>
                  </ng-template>
              </ng-select>
              <div class="select-overlay"></div>
            </div>
            <svg class="down-arrow" (click)="statusDropdown.toggle()" style="top: -22px;position: relative;float: right;right: 50px;" width="15"
              height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
                fill="#8936F3" />
            </svg>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top:12px;">
        <div class="col-12 col-md-6">
          <span class="name"> {{ 'employee.FirstName' | translate}}</span>
          <!-- <div *ngIf = "isValidField('firstName')" class="formControl3">{{getValidFieldValue('firstName')}}</div> -->
          <input type="text" class="formControl" formControlName="firstName"
            placeholder="{{ 'employee.FirstName' | translate}}" />
          <div
            *ngIf="employeeForm.controls['firstName'].hasError('required') && (employeeForm.controls['firstName'].touched || employeeForm.controls['firstName'].dirty)"
            class="error"> {{ 'employee.Thisfieldisrequired' | translate}}
          </div>
          <div
            *ngIf="employeeForm.controls['firstName'].hasError('pattern') && (employeeForm.controls['firstName'].touched || employeeForm.controls['firstName'].dirty)"
            class="error"> {{ 'employee.Pleaseentervalidfirstname.' | translate}}
          </div>
        </div>
        <div class="col-12 col-md-6">
          <span class="name"> {{ 'employee.LastName' | translate}}</span>
          <!-- <div *ngIf = "isValidField('lastName')" class="formControl3">{{getValidFieldValue('lastName')}}</div> -->
          <input type="text" class="formControl" formControlName="lastName"
            placeholder="{{ 'employee.LastName' | translate}}" />
          <div
            *ngIf="employeeForm.controls['lastName'].hasError('required') && (employeeForm.controls['lastName'].touched || employeeForm.controls['lastName'].dirty)"
            class="error">{{ 'employee.Thisfieldisrequired' | translate}}
          </div>
          <div
            *ngIf="employeeForm.controls['lastName'].hasError('pattern') && (employeeForm.controls['lastName'].touched || employeeForm.controls['lastName'].dirty)"
            class="error"> {{ 'employee.Pleaseentervalidlastname.' | translate}}
          </div>
        </div>
      </div>


      <div class="row" style="margin-top:12px;">

        <div class="col-12 col-md-6">
          <label class="name"> {{ 'employee.Email' | translate}}</label>
          <!--  <div *ngIf = "isValidField('email')" class="formControl3">{{getValidFieldValue('email')}}</div>-->
          <input type="text" [attr.disabled]="((this.mode=='edit')? true:null)"
            (input)="checkEmailDomain($event.target.value)" class="formControl" [ngStyle]="changeStyle(this.mode)"
            formControlName="email" placeholder="{{'login.Email' | translate}}" />
          <div
            *ngIf="employeeForm.controls['email'].hasError('pattern') && (employeeForm.controls['email'].touched || employeeForm.controls['email'].dirty)"
            class="error">{{'login.pleaseenteravalidemail' | translate}}
          </div>
          <div
            *ngIf="employeeForm.controls['email'].hasError('required') && (employeeForm.controls['email'].touched || employeeForm.controls['email'].dirty)"
            class="error">{{'login.thisfieldisrequired' | translate}}
          </div>
        </div>
       
      </div>
      <label class="showLabel">{{ 'employee.Policy' | translate}}</label>
      <div class="row" style="margin-top:5px;height:32px;margin-left: 0px !important;">
        <div id="policylistDiv22">
          <div class="input">
            <ng-select #policylist appendTo="#policylistDiv22" style="bottom: 10px;" dropdownPosition="middle"
              [searchable]="false" [clearable]="false" formControlName="policy" (change)="onPolicyChangeClicked($event)"
              [items]="policyOptions" bindLabel="" bindValue="id">
              <ng-template ng-label-tmp let-item="item">
                <span> {{getSelectedPolicyName()}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-option="item">
                <span class="option-title">{{option.name |
                  translate}}</span>

              </ng-template>
            </ng-select>
            <div class="select-overlay"></div>

          </div>
          <svg class="down-arrow" (click)="policylist.toggle()"
            style="top: -22px;position:relative;float:right;right:20px;" width="15" height="9" viewBox="0 0 15 9"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
              fill="#8936F3" />
          </svg>
        </div>
      </div>
      <div *ngIf="this.showPolicyRefreshButton"
        style="color:blue;position: absolute;top: 295px;left: 360px;cursor: pointer;" (click)="refreshpolicy()">
        <i class="fa fa-refresh" aria-hidden="true"></i><span style="margin-left: 5px;"> {{
          'employee.Updateaspernewdepartment' | translate}}</span>
      </div>
      <label class="showLabel" style="margin-top: 10px;"> {{ 'employee.Approver' | translate}}</label>
      <div class="row" style="margin-top:5px;display: block !important;width: 353px;margin-left: 0px !important">
        <div id="selectApproverDiv11">
          <div class="input" style="overflow: hidden;">
            <ng-select #approverlist appendTo="#selectApproverDiv11" style="bottom: 5px;"
              (click)="this.dropDownopen =true;filterSelectedlist(this.multipleSelectedApprover)"
              dropdownPosition="middle" (close)="closeDropdown()" [searchable]="true" [closeOnSelect]="false"
              [clearable]="false" [items]="this.appproverList" [searchFn]="searchByApproverNameAndEmailChanged"
              [(ngModel)]="search" [ngModelOptions]="{standalone: true}">
              <ng-template ng-label-tmp let-item="item">
                <span> {{getLabelValue() | translate}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="{{item.email}}"
                  style="padding-top:15px !important;height: 36px !important;min-width: 331px !important;margin-bottom: 0px !important;padding-left:10px !important;">
                  <input type="checkbox" id="{{item.email}}" class="mdl-checkbox__input"
                    (change)="onApproverChangeClicked(item.email, $event)" [checked]="isApproverChecked(item.email)">
                  <span class="mdl-checkbox__label1"
                    style="font-family: 'apercu-r' !important;padding-left: 10px !important;font-size: 14px;top: -2px;padding-bottom: 20px;"><span
                      style="width: 100px !important;min-width: 100px !important;text-overflow: ellipsis;display: inline-block;overflow: hidden;position: relative;
                        top: 0px;">{{item.firstName}} {{item.lastName}}</span>
                    <span
                      style=" top: -5px;text-align:right;text-overflow: ellipsis;position: relative;white-space: nowrap;overflow: hidden;margin-left:20px;">
                      {{item.email}}</span></span>
                </label>

              </ng-template>
            </ng-select>
            <div class="select-overlay"></div>
            <div *ngIf="this.multipleSelectedApprover.length > 0 && !this.dropDownopen"
              (click)="this.dropDownopen =true;filterSelectedlist(this.multipleSelectedApprover);approverlist.toggle()"
              class="modalApprover1">
              <span *ngFor="let item1 of this.multipleSelectedApprover;let i=index">
                <span class="shown1">{{item1}} <span *ngIf="((i !== (this.multipleSelectedApprover.length-1)))">,
                  </span></span>
              </span>
            </div>
          </div>
          <svg class="down-arrow"
            (click)="this.dropDownopen =true;filterSelectedlist(this.multipleSelectedApprover);approverlist.toggle()"
            style="top: -22px;position:relative;float:right;right:20px;" width="15" height="9" viewBox="0 0 15 9"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
              fill="#8936F3" />
          </svg>

        </div>
      </div>
      <div *ngIf="showRefreshButton" style="color:blue;position: absolute;top: 370px;left: 360px;cursor: pointer;"
        (click)="refreshApprover()">
        <i class="fa fa-refresh" aria-hidden="true"></i><span style="margin-left: 5px;"> {{
          'employee.Updateaspernewdepartment' | translate}}</span>
      </div>
      <div class="checkbox-container1" style="margin-bottom:0px !important;padding-left:0px !important;">
        <div class="row">
          <div class="col-9" style="padding-top:10px;max-width: 430px;">
            <span class="switchLabel" style=""> {{ 'employee.Allowusingcompanyscentralpaymentmethod' |
              translate}}:</span>
          </div>
          <div class="col-3" style="padding-top:3px !important;">
            <ui-switch color="gray" formControlName="companyCardAccess" [checked]="companyCardAccess"></ui-switch>
            <span *ngIf="employeeForm.controls['companyCardAccess'].value"
              (click)="employeeForm.controls['companyCardAccess'].setValue(false)" class="styelSwitch1"> {{
              'employee.on' | translate}}</span>
            <span *ngIf="!employeeForm.controls['companyCardAccess'].value"
              (click)="employeeForm.controls['companyCardAccess'].setValue(true)" class="styelSwitch2"> {{
              'employee.off' | translate}}</span>
          </div>
        </div>
      </div>

      <div class="checkbox-container1"
        style="margin-bottom:0px !important;margin-top:0px !important;padding-left:0px !important;">
        <div class="row">
          <div class="col-6" style="padding-top:10px;max-width: 250px;">
            <span class="switchLabel"> {{ 'employee.Allowusingpersonalcard' | translate}}:</span>
          </div>
          <div class="col-6" style="padding-top:3px !important;">
            <ui-switch color="gray" formControlName="allowedCardAccess" [checked]="allowedCardAccess"></ui-switch>
            <span *ngIf="employeeForm.controls['allowedCardAccess'].value"
              (click)="employeeForm.controls['allowedCardAccess'].setValue(false)" class="styelSwitch1">{{ 'employee.on'
              | translate}}</span>
            <span *ngIf="!employeeForm.controls['allowedCardAccess'].value"
              (click)="employeeForm.controls['allowedCardAccess'].setValue(true)" class="styelSwitch2"> {{
              'employee.off' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="checkbox-container1"
        style="margin-bottom:0px !important;margin-top:0px !important;padding-left:0px !important;">
        <div class="row">
          <div class="col-6" style="padding-top:10px;max-width: 320px;">
            <span class="switchLabel"> {{ 'employee.Allowbookingforotheremployees' | translate}}:</span>
          </div>
          <div class="col-6" style="padding-top:3px !important;">
            <ui-switch color="gray" [disabled]="disbaleSwicth()" formControlName="allowBookForOthers" [checked]="allowBookForOthers"></ui-switch>
            <span *ngIf="employeeForm.controls['allowBookForOthers'].value"
              (click)="changeBookForOther(false)" class="styelSwitch1">{{
              'employee.on' | translate}}</span>
            <span *ngIf="!employeeForm.controls['allowBookForOthers'].value"
              (click)="changeBookForOther(true)" class="styelSwitch2"> {{
              'employee.off' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="checkbox-container1" style="margin-top:0px !important;padding-left:0px !important;">
        <div class="row">
          <div class="col-7" style="padding-top:10px;max-width: 370px;">
            <span class="switchLabel"> {{ 'employee.Eligibleforgovernmentratesforhotels' | translate}}:</span>
          </div>
          <div class="col-5" style="padding-top:3px !important;">
            <ui-switch color="gray" formControlName="showGovtRates" [checked]="showGovtRates"></ui-switch>
            <span *ngIf="employeeForm.controls['showGovtRates'].value"
              (click)="employeeForm.controls['showGovtRates'].setValue(false)" class="styelSwitch1">{{ 'employee.on' |
              translate}}</span>
            <span *ngIf="!employeeForm.controls['showGovtRates'].value"
              (click)="employeeForm.controls['showGovtRates'].setValue(true)" class="styelSwitch2"> {{ 'employee.off' |
              translate}}</span>
          </div>
        </div>
      </div>
      <div class="checkbox-container1" style="margin-top:0px !important;padding-left:0px !important;">
        <div class="row">
          <div class="col-7" style="padding-top:10px;max-width: 370px;">
            <span class="switchLabel"> {{ 'employee.EligibleforAAAratesforhotels' | translate}}:</span>
          </div>
          <div class="col-5" style="padding-top:3px !important;">
            <ui-switch color="gray" formControlName="showAAARates" [checked]="showAAARates"></ui-switch>
            <span *ngIf="employeeForm.controls['showAAARates'].value"
              (click)="employeeForm.controls['showAAARates'].setValue(false)" class="styelSwitch1">{{ 'employee.on' |
              translate}}</span>
            <span *ngIf="!employeeForm.controls['showAAARates'].value"
              (click)="employeeForm.controls['showAAARates'].setValue(true)" class="styelSwitch2"> {{ 'employee.off' |
              translate}}</span>
          </div>
        </div>
      </div>

      <button *ngIf="!addEmployeeReqInProgress" class="btn btn-secondary" (click)="addUserAsEmployee()"><span
          *ngIf="mode === 'add'" class="add"> {{ 'employee.ADDEMPLOYEE' | translate}}</span>
        <span *ngIf="mode === 'edit'" class="add"> {{ 'employee.UPDATE' | translate}}</span>
        <span *ngIf="mode === 'addManager'" class="add">ADD MANAGER</span>
        <span *ngIf="mode === 'addAdmin'" class="add">ADD ADMIN</span>
      </button>
      <button *ngIf="addEmployeeReqInProgress" class="btn btn-secondary"><span class="add"> {{ 'employee.Wait' |
          translate}}...</span>
      </button><span *ngIf="addEmployeeReqInProgress" class="loaderClass">
        <loader-dots class="loaderAlign"></loader-dots>
      </span>
      <div class="modal-form-button">

      </div>
    </form>
  </div>
</ng-container>



<ng-container *ngIf="!show">
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel">
      Add an Employee
    </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
      <i class="material-icons" style="color:#fff;">close</i>
    </button>
  </div>
  <div class="modal-body" style=" text-align: left !important;">
    <span class="heading">Confirm Employee Details</span>
    <form [formGroup]="employeeForm">
      <div class="row" style="margin-top:12px;">
        <div class="col-6" style=" text-align: left !important;">
          <span class="name">First Name</span>
          <div class="formControl3">Gregory</div>
        </div>
        <div class="col-6" style=" text-align: left !important;">
          <span class="name">Middle Name</span>
          <div class="formControl3">Kumar</div>
        </div>
      </div>
      <div class="row" style="margin-top:5px;">
        <div class="col-6" style=" text-align: left !important;">
          <span class="name">Last Name</span>
          <div class="formControl3">Doyle</div>
        </div>
      </div>
      <div class="row" style="margin-top:12px;">
        <div class="col-6">
          <label class="name">Gender</label>
          <div class="checkbox-container">
            <label class="mdl-radio selection-checkbox checkbox2 checkbox-seperate mdl-js-radio mdl-js-ripple-effect"
              style="background-color:blue;">
              <span class="mdl-radio__label" style="color:#fff;font-weight: normal;font-family: apercu !important;
                 letter-spacing: 1px;">Male</span>

            </label>
          </div>
        </div>
        <div class="col-6">
          <label class="name">Date Of Birth</label>
          <div class="formControl3">19/9/1984</div>
        </div>
      </div>
      <div class="row" style="margin-top:12px;">
        <div class="col-6">
          <label class="name">Email</label>
          <div class="formControl3">gdoyle@njoy.com</div>
        </div>
        <div class="col-6">
          <label class="name">Phone Number</label>
          <div class="phone-number" style="padding-top:10px;">
            <img class="flag-label"
              src="assets/flags/{{getCountryCode(employeeForm.controls['dialCode'].value)}}.png" />
            <i class="fa fa-chevron-down" style="display:none;"></i>
            <span style="padding-left:10px;font-weight:bold;">{{employeeForm.controls['dialCode'].value}}</span>
          </div>
          <div class="formControl3" style="padding-left:79px;">9871420384</div>
        </div>
      </div>
      <div class="row" style="margin-top:12px;">
        <div class="col-6" style=" text-align: left !important;">
          <span class="name">Title</span>
          <div class="formControl3">CFO</div>
        </div>
        <div class="col-6">
          <label class="name">Department</label>
          <div class="formControl3">Executive</div>
        </div>
      </div>
      <div class="row" style="margin-top:12px;">
        <div class="col-6">
          <span class="name">Manager Name</span>
          <div class="formControl3">paul Sturman</div>
        </div>
        <div class="col-6">
          <label class="name">Manager Email</label>
          <div class="formControl3">pSturman@njoy.com</div>
        </div>
      </div>
      <div class="checkbox-container1" style="padding-left:0px !important;">
        <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="term">
          <input type="checkbox" id="term" formControlName="terms" class="mdl-checkbox__input">
          <span class="mdl-checkbox__label">This employee has the permission to bill the company</span>
        </label>
      </div>
      <button class="btn btn-secondary"><span class="add">ADD & SEND INVITE</span></button>
      <div class="modal-form-button">

      </div>
    </form>
  </div>
</ng-container>

<ng-template #changeManager let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel">
      Update Approver
    </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="onApproveModelCancel()">
      <i class="material-icons" style="color:#fff;">close</i>
    </button>
  </div>
  <div *ngIf="this.showSpinner" class="approval_request_diaglog_bg_clickhandler" (click)="$event.stopPropagation();">
  </div>
  <div class="modal-body" style=" text-align: left !important;">
    <form [formGroup]="emailForm">
      <span class="heading">Approver's email</span>
      <div class="row" style="margin-top:12px;">
        <div class="col-12 col-md-5">
          <label class="name">Email</label>
          <input type="text" class="formControl" formControlName="emailUserName" placeholder=""
            (input)="emailUserNameChange($event.target.value)" />
          <div
            *ngIf="emailForm.controls['email'].hasError('pattern') && (emailForm.controls['email'].touched || emailForm.controls['email'].dirty)"
            class="error">{{'login.pleaseenteravalidemail' | translate}}
          </div>
          <div
            *ngIf="emailForm.controls['emailUserName'].hasError('required') && (emailForm.controls['emailUserName'].touched || emailForm.controls['emailUserName'].dirty)"
            class="error">{{'login.thisfieldisrequired' | translate}}
          </div>
          <div
            *ngIf="emailForm.controls['domain'].hasError('required') && (emailForm.controls['emailUserName'].touched || emailForm.controls['emailUserName'].dirty)"
            class="error">{{'Please select a domain' | translate}}
          </div>
        </div>
        <div class="col-1 d-none d-md-block"
          style="margin-top:25px !important; margin-left:15px !important;text-align:center !important;">
          <span class="symbol">@</span>
        </div>
        <div class="col-xl-12 d-block d-md-none" style="">
          <span class="symbol">@</span>
        </div>
        <div class="col-12 col-md-5">
          <div id="managerUpdateInAddEditemployee">
            <div class="formControl5">
              <ng-select #domain dropdownPosition="bottom" [searchable]="false" [clearable]="false"
                formControlName="domain" [items]="domainOptions" bindLabel="value" bindValue="value"
                (change)="emailDomainChange($event)">
                <span class="selectBox-remove"><span class="material-icons">clear</span></span>
                <span class="fa fa-chevron-down"></span>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showToUpdateManagerDetail()" class="row" style="margin-top:12px;">
        <div class="col-12 col-md-6">
          <span class="name">First Name</span>
          <!-- <div *ngIf = "isValidField('firstName')" class="formControl3">{{getValidFieldValue('firstName')}}</div> -->
          <input type="text" class="formControl" formControlName="firstName" [attr.disabled]="isDisable()"
            placeholder="First Name" />
          <div
            *ngIf="emailForm.controls['firstName'].hasError('required') && (emailForm.controls['firstName'].touched || emailForm.controls['firstName'].dirty)"
            class="error">This field is required
          </div>
          <div
            *ngIf="emailForm.controls['firstName'].hasError('pattern') && (emailForm.controls['firstName'].touched || emailForm.controls['firstName'].dirty)"
            class="error">Please enter valid first name
          </div>
        </div>
        <div class="col-12 col-md-6">
          <span class="name">Last Name</span>
          <!-- <div *ngIf = "isValidField('lastName')" class="formControl3">{{getValidFieldValue('lastName')}}</div> -->
          <input type="text" class="formControl" formControlName="lastName" [attr.disabled]="isDisable()"
            placeholder="Last Name" />
          <div
            *ngIf="emailForm.controls['lastName'].hasError('required') && (emailForm.controls['lastName'].touched || emailForm.controls['lastName'].dirty)"
            class="error">This field is required
          </div>
          <div
            *ngIf="emailForm.controls['lastName'].hasError('pattern') && (emailForm.controls['lastName'].touched || emailForm.controls['lastName'].dirty)"
            class="error">Please enter valid last name
          </div>
        </div>
      </div>
      <button *ngIf="!showToUpdateManagerDetail()" class="btn btn-secondary" style="margin-top:25px !important;"
        (click)="checkManagerDetail()"><span class="add">NEXT</span></button>
      <button *ngIf="showToUpdateManagerDetail()" class="btn btn-secondary" style="margin-top:25px !important;"
        (click)="updateManager()"><span class="add">UPDATE</span></button>
      <button class="btn btn-normal" (click)="onApproveModelCancel()"><span class="add1">GO BACK</span></button>
      <app-loader *ngIf="this.showSpinner" [spinnerStyle]="true"></app-loader>
      <div class="modal-form-button">

      </div>
    </form>
  </div>
</ng-template>