import { currencyDefs } from './countries';
import { availableIcons } from './airlines';
import { FlightLegResult } from '../entity/flight-leg-result';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { countries } from './countries';
import { PopupComponent } from '../popup/popup.component';
import { UserDetails } from '../entity/userDetails';
import { AdminPanelService } from '../admin-panel.service';
import { UserInfo } from '../entity/user-info';
import { DateUtils } from './date-utils';
import { DatePipe } from '@angular/common';
import { Constants } from './constants';
import { deserialize } from 'src/app/util/ta-json/src/methods/deserialize';
import { PhoneNumberDTO } from '../entity/phonenumber-dto';
import { AddressDTO } from '../entity/address-dto';
import { UserAccountInfo } from '../entity/user-account-info';
import { UserPreferences } from '../entity/user-prefs';
import { PassportDTO } from '../entity/passport-dto';
import { GallopHttpClient } from '../shared/gallop-httpclient.service';
import { Observable } from 'rxjs';
import { UserAccountService } from '../user-account.service';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
declare var setNgxSmartModalOpenStateClosed: any;
declare var setNgxSmartModalOpen: any;
declare var getTraflaHotelChainCode: any;
declare var sendLogToRollBar: any;
declare var configureRollBar: any;
export class CommonUtils {

  static containsObject(obj, list): boolean {
    var x;
    for (x in list) {
      if (list.hasOwnProperty(x) && list[x][0] === obj[0]) {
        return true;
      }
    }
    return false;
  }
 
  static PhoneNumberValidator(regionCode: string = undefined): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validNumber = false;
    if(control && control.value==='' || !control.value){
return;
    }
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
   // console.log("country",regionCode,control.value);
   try {
      const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
    //  console.log("success",phoneNumber);
      validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (e) { }
 //   console.log("error",validNumber,control.value);
    return validNumber ? null : { 'pattern': { value: control.value } };
  }
}
  static getCurrencySymbol(currencyCode: string) {
    let currencySymbol: string;
    if (currencyDefs[currencyCode]) {
      currencySymbol = currencyDefs[currencyCode].symbol;
    }
    if(currencySymbol){
    return currencySymbol;
    }else{
      return currencyCode;
    }
  }
  static getStartDate(item) {
    let departure: any;
    if (item.type === 'flight') {
      departure = item.option.flight_option.legs[0].hops[0].starts;
    } else if (item.type === 'hotel') {
      departure = item.option.hotel_option.checkInDate;
    } else if (item.type === 'cars') {
      if (item.option.car_option && item.option.car_option.pickUpDate) {
        departure = item.option.car_option.pickUpDate;
      }
    }
    return departure;
  }
  static getCreditersName(creditDetails, employeelist) {
    let name = '';
    if (creditDetails && creditDetails.length > 0) {
      for (let i = 0; i < creditDetails.length; i++) {
        let finindex = employeelist.findIndex(item => item.email === creditDetails[i].travellerEmail);
        if (i < creditDetails.length - 1) {
          name += employeelist[finindex].fullName + '\'s and ';
        } else {
          name += employeelist[finindex].fullName + '\'s ';
        }
      }
    }
    return name;
  }
  static getPriceAfterTravelCredits(price, creditDetails1, passennger) {
    let effectivePrice = price;
    const pricePerPassenger = price / passennger;
   // console.log("credit details11",creditDetails1);
    for (let counter = 0; counter < creditDetails1.length; counter++) {
      const item = creditDetails1[counter];
      if(item.displayCreditAmount){
        if (item.displayCreditAmount <= pricePerPassenger) {
          effectivePrice -= item.displayCreditAmount;
        }else{
          effectivePrice -= pricePerPassenger;
        }
      }else if (item.creditAmount <= pricePerPassenger) {
        effectivePrice -= item.creditAmount;
      } else {
        effectivePrice -= pricePerPassenger;
      }
    }

    return effectivePrice;
  }

  static getAppliedTravelCredits(price, creditDetails1, passennger) {
  }
  static _getAppliedTravelCredits(price, creditDetails1, passennger) {
    let effectivePrice = price;
    const pricePerPassenger = price / passennger;
    var appliedCredit = 0;
   // console.log("credit details11",creditDetails1);
    for (let counter = 0; counter < creditDetails1.length; counter++) {
      const item = creditDetails1[counter];
      if(item.displayCreditAmount){
        if (item.displayCreditAmount <= pricePerPassenger) {
          effectivePrice -= item.displayCreditAmount;
          appliedCredit = appliedCredit + item.displayCreditAmount;
        }else{
          effectivePrice -= pricePerPassenger;
          appliedCredit = appliedCredit + pricePerPassenger;
        }
      }else if (item.creditAmount <= pricePerPassenger) {
        effectivePrice -= item.creditAmount;
        appliedCredit = appliedCredit + item.creditAmount;
      } else {
        effectivePrice -= pricePerPassenger;
        appliedCredit = appliedCredit + pricePerPassenger;
      }
    }

    return appliedCredit;
  }
  static selectagDropdown(approvalTransactionDetail,tagset,tagShow,selectedTag1){
    let selectedTag = approvalTransactionDetail.split(',');
         
    for(let i=0;i<tagset.length;i++){
      for(let item of selectedTag){
      let findIndexTagId = tagShow[i].findIndex(item1 => item1.tag_id===item);
      if(findIndexTagId > -1){
        selectedTag1[i] = item;
      }
    }
  
  }
  return selectedTag1;
  }
  static totalLeftCredit(price,creditDetails,passennger){
    const pricePerPassenger = price / passennger;
    for (let counter = 0; counter < creditDetails.length; counter++) {
      const item = creditDetails[counter];
      if(item.displayCreditAmount){
        if (item.displayCreditAmount <= pricePerPassenger) {
          creditDetails[counter].displayCreditAmount =0;
        }else{
          creditDetails[counter].displayCreditAmount= (creditDetails[counter].displayCreditAmount-pricePerPassenger);
        }
      }else if (item.creditAmount <= pricePerPassenger) {
        creditDetails[counter].creditAmount =0;
      } else {
        creditDetails[counter].creditAmount = (creditDetails[counter].creditAmount-pricePerPassenger);
      }
    }
    return creditDetails;
  }

  static getPriceAfterNonComboTravelCredits(price, passennger,selectedFlight) {
    let selectedFlightsPrice=0;
    var creditDetails = JSON.parse(JSON.stringify(this.getCreditValue(selectedFlight[0],passennger)));
    for(let flight of selectedFlight){
     // selectedFlightsPrice += flight.price;
     if(flight.displayPrice){
      creditDetails = JSON.parse(JSON.stringify(this.totalLeftCredit(flight.displayPrice,creditDetails,passennger)));
     }else{
      creditDetails = JSON.parse(JSON.stringify(this.totalLeftCredit(flight.price,creditDetails,passennger)));
     }
    }
   // console.log("credit details",selectedFlight,creditDetails);
   // selecte+= price;
    let effectivePrice = price;
    const pricePerPassenger = price / passennger;
    for (let counter = 0; counter < creditDetails.length; counter++) {
      const item = creditDetails[counter];
      if(item.displayCreditAmount){
        if (item.displayCreditAmount <= pricePerPassenger) {
          effectivePrice -= item.displayCreditAmount;
        }else{
          effectivePrice -= pricePerPassenger;
        }
      }else if (item.creditAmount <= pricePerPassenger) {
        effectivePrice -= item.creditAmount;
      } else {
        effectivePrice -= pricePerPassenger;
      }
    }

    return effectivePrice;
  }

  static getAppliedCreditValueNonCombo(price, passennger,selectedFlight){
  
  }
  static _getAppliedCreditValueNonCombo(price, passennger,selectedFlight){
    let selectedFlightsPrice=0;
    var creditDetails = JSON.parse(JSON.stringify(this.getCreditValue(selectedFlight[0],passennger)));
    for(let flight of selectedFlight){
     // selectedFlightsPrice += flight.price;
     if(flight.displayPrice){
      creditDetails = JSON.parse(JSON.stringify(this.totalLeftCredit(flight.displayPrice,creditDetails,passennger)));
     }else{
      creditDetails = JSON.parse(JSON.stringify(this.totalLeftCredit(flight.price,creditDetails,passennger)));
     }
    }
   // console.log("credit details",selectedFlight,creditDetails);
   // selecte+= price;
    let effectivePrice = price;
    const pricePerPassenger = price / passennger;
    let creditApplied = 0;
    for (let counter = 0; counter < creditDetails.length; counter++) {
      const item = creditDetails[counter];
      if(item.displayCreditAmount){
        if (item.displayCreditAmount <= pricePerPassenger) {
          effectivePrice -= item.displayCreditAmount;
          creditApplied = creditApplied + item.displayCreditAmount;
        }else{
          effectivePrice -= pricePerPassenger;
          creditApplied = creditApplied + pricePerPassenger;
        }
      }else if (item.creditAmount <= pricePerPassenger) {
        effectivePrice -= item.creditAmount;
        creditApplied = creditApplied + item.displayCreditAmount;
      } else {
        creditApplied = creditApplied + pricePerPassenger;
        effectivePrice -= pricePerPassenger;
      }
    }

    return creditApplied;
  }
  
      
  static getCreditValue(flight, passennger) {
    if (flight.travelCreditsInfo && flight.travelCreditsInfo.length > 0) {
      // alert(JSON.stringify(flight.travelCreditsInfo));
      return flight.travelCreditsInfo;
    }
    return null;
  }
  static getAirlineImageUrl(airlineCode: string) {
    if (availableIcons[airlineCode]) {
      return 'https://s3.amazonaws.com/airlines.images.biztravel.ai/icons/' + airlineCode + '_128x128.png';
    } else {
      return 'https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir' + airlineCode + '.gif';
    }
  }
  static getClassStringWithBrand(hopIndex: number, leg: FlightLegResult, brandedFareCheckBox: boolean): string {
    var classString = leg.flightHops[hopIndex].cabinClass;
    if (brandedFareCheckBox && leg.brandDetails && leg.brandDetails.length > 0) {
      classString += '(' + leg.brandDetails[0].name + ')';
    }
    return classString;
  }

  static getUserPreferredLanguage(translate: TranslateService) {
    let userLang: string = translate.getBrowserLang();
    if (!userLang) {
      userLang = translate.getDefaultLang();
    }
    if (userLang && userLang === 'es') {
      return 'es';
    }
    return 'en';
  }

  static handleModalEvents(ngxSmartModalService: NgxSmartModalService, eventName: string, currentModalId: string) {
    switch (eventName) {
      case 'onOpen':

        setNgxSmartModalOpen(currentModalId);
        var modals = ngxSmartModalService.getOpenedModals();
        if (currentModalId && currentModalId.length > 0) {
          if (modals && modals.length > 1) {
            for (var index = 0; index < modals.length; index++) {
              if (modals[index].id !== currentModalId) {
                ngxSmartModalService.close(modals[index].id)
              }
            }
          }
        } else if (modals && modals.length > 0) {
          for (var index = 0; index < modals.length; index++) {
            ngxSmartModalService.close(modals[index].id)
          }
        }
        break;
      case 'onDismiss':
        setNgxSmartModalOpenStateClosed(currentModalId);
        break;
      case 'onClose':
        setNgxSmartModalOpenStateClosed(currentModalId);
        break;
      case 'onDismissFinished':

        break;
      default:

    }
  }

  static getCountryCode(code, phoneNumber, selectedCountryCode) {
    let canada_area_codes = [
      403, 587, 780, 825, 236, 250, 604, 672, 778, 506, 709, 902, 782, 204, 431,
      226, 249, 289, 343, 365, 416, 437, 519, 548, 613, 647, 705, 807, 905, 867,
      418, 438, 450, 514, 579, 581, 819, 873, 306, 639
    ]
    if (code === '+1') {
      if (phoneNumber && phoneNumber.trim().length > 2) {
        if (canada_area_codes.indexOf(Number.parseInt(phoneNumber.trim().substring(0, 3))) > -1) {
          return 'ca';
        }
        return 'us';
      } else {
        if (selectedCountryCode && selectedCountryCode.toLocaleLowerCase() === 'ca') {
          return 'ca';
        }
      }
      return 'us';
    }
    let countryCode = 'us';
    countries.map(item => {
      if (item.dial_code == code) {
        countryCode = item.code;
      }
    });
    return countryCode.toLowerCase();
  }
  static classNameReturnFromMap(cabinClass, carrier, type) {
    for (let keys in cabinClass) {
      let cabinClass1 = carrier + '-' + type;
      if (keys && keys === cabinClass1) {
        return cabinClass[keys];
      }
    }
  }
  static isValidDOBDate(dob: string) {
    if (dob && dob !== null) {
      let dobArr = dob.split('/');
      if (dobArr.length != 3) return false;
      for (let val of dobArr) {
        if (val.trim().length == 0) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  static searchByNameOrCode(term: string, item) {
    term = term.toLocaleLowerCase();
    if (item.dial_code) {
      return item['name'].toLocaleLowerCase().indexOf(term) > -1 || item['dial_code'].toLocaleLowerCase().indexOf(term) > -1;
    }
  }
  static getPhoneNumberMask(inputDialCode) {
    if (inputDialCode
      && inputDialCode.trim().length > 0
    ) {
      inputDialCode = inputDialCode.trim();
      if (inputDialCode.indexOf(' ') > -1) {
        if (inputDialCode.split(' ')[1].length === 3) {
          return '000-0000';
        } else if (inputDialCode.split(' ')[1].length === 1) {
          return '(00) 000-0000';
        }
      }
      if (inputDialCode === '+1' || inputDialCode === '+91') {
        return '(000) 000-0000';
      }
  }
    return '(000) 000-0000 000';
  }
  static getPhoneNumberPaceHolder(inputDialCode) {
    return CommonUtils.getPhoneNumberMask(inputDialCode);
  }
  static saveProfileandPreference(userInfoDTO, travellerChange, userAccountInfoService, toastr,translateService,adminPanelService,others?) {
   
    if(!others){
      userAccountInfoService.doNotSaveProfile = true;
    userAccountInfoService.promptUserTosaveProfile = false;
    userAccountInfoService.savingProfileData = true;
    }
    let p = travellerChange;
    userInfoDTO = CommonUtils.getUpdateedPassengerInnfo(p, userInfoDTO);
    let passportInfo: PassportDTO = userAccountInfoService.getAccountInfo().userInfo.passportDTO;
    passportInfo.passportNumber = p.passportNumber;
    let datePipe = new DatePipe('en-US');
    if (p.passportExpiryDate) {
      passportInfo.expiryDate = datePipe.transform(new Date(p.passportExpiryDate), 'MM/dd/yyyy');
    }
    passportInfo.countryCode = p.passportCountry && p.passportCountry.code ? p.passportCountry.code : '';
    userInfoDTO.nationality = p.passportNationality ? p.passportNationality.code : null;
    // this.userInfoDTO.seat = p.seat;
    //  if(p.emergencyContactName){
    if (!userInfoDTO.emergencyContact) {
      userInfoDTO.emergencyContact = {};
    }
    userInfoDTO.emergencyContact.name = p.emergencyContactName ? p.emergencyContactName : '';
    userInfoDTO.emergencyContact.relationship = p.relationship ? p.relationship : '';
    if (p.contactNumber) {
      if (userInfoDTO.emergencyContact.contactNumber == null) {
        userInfoDTO.emergencyContact.contactNumber = new PhoneNumberDTO();
      }
      userInfoDTO.emergencyContact.contactNumber.countryCode = p.dialCode1 ? p.dialCode1 : "+1";
      userInfoDTO.emergencyContact.contactNumber.number = p.contactNumber ? p.contactNumber : "";
    }
    //  }
    if (p.address && p.address.trim().length > 0) {
      if (userInfoDTO.home == null) {
        userInfoDTO.home = new AddressDTO();
      }
      userInfoDTO.home.address = p.address && p.address.trim().length > 0 ? p.address.trim() : "";
    }
    if (p.address && p.address.trim().length > 0) {
      if (userInfoDTO.work == null) {
        userInfoDTO.work = new AddressDTO();
      }
      userInfoDTO.work.address = p.address && p.address.trim().length > 0 ? p.address.trim() : "";
    }
    if ((p.frequentFlyerInfo && p.frequentFlyerInfo.length > 0)) {
      let found = 0;
      for (let i = 0; i < p.frequentFlyerInfo.length; i++) {
        if (userInfoDTO.ffnMapping && userInfoDTO.ffnMapping.length > 0) {
          for (let j = 0; j < userInfoDTO.ffnMapping.length; j++) {
            if (p.frequentFlyerInfo[i].airlineCode === userInfoDTO.ffnMapping[j].airline_code && p.frequentFlyerInfo[i].ffnAvailable) {
              found = found + 1;
              userInfoDTO.ffnMapping[j].airline_code = p.frequentFlyerInfo[i].airlineCode;
              userInfoDTO.ffnMapping[j].frequent_flyer_number = p.frequentFlyerInfo[i].frequent_flyer_number ? p.frequentFlyerInfo[i].frequent_flyer_number : null;
              userInfoDTO.ffnMapping[j].name = p.frequentFlyerInfo[i].name;
            } else if (p.frequentFlyerInfo[i].airlineCode === userInfoDTO.ffnMapping[j].airline_code && !p.frequentFlyerInfo[i].ffnAvailable) {
              found = found + 1;
              userInfoDTO.ffnMapping.splice(j, 1);
            }
          }
        } else {
          userInfoDTO.ffnMapping = [];
        }
        if (found === 0 && p.frequentFlyerInfo[i].frequent_flyer_number) {
          let ffnMappinng = { name: '', airline_code: '', frequent_flyer_number: '' };
          ffnMappinng.name = p.frequentFlyerInfo[i].name;
          ffnMappinng.airline_code = p.frequentFlyerInfo[i].airlineCode;
          ffnMappinng.frequent_flyer_number = p.frequentFlyerInfo[i].frequent_flyer_number;
          userInfoDTO.ffnMapping.push(ffnMappinng);
        }
      }

    }
    if ((p.carLoyalityInfo && p.carLoyalityInfo.length > 0)) {
      let found = 0;
      for (let i = 0; i < p.carLoyalityInfo.length; i++) {
        if (userInfoDTO.carLoyaltyNumbers && userInfoDTO.carLoyaltyNumbers.length > 0) {
          for (let j = 0; j < userInfoDTO.carLoyaltyNumbers.length; j++) {
            let carCode = CommonUtils.getTrflaCode(p.carLoyalityInfo[i].rentalCarCode);;
            if (carCode == userInfoDTO.carLoyaltyNumbers[j].rentalCarCode && p.carLoyalityInfo[i].clnAvailable) {
              found = found + 1;
              userInfoDTO.carLoyaltyNumbers[j].rentalCarCode = CommonUtils.getTrflaCode(p.carLoyalityInfo[i].rentalCarCode);
              userInfoDTO.carLoyaltyNumbers[j].rentalCarLoyaltyNumber = p.carLoyalityInfo[i].rentalCarLoyaltyNumber ? p.carLoyalityInfo[i].rentalCarLoyaltyNumber : null;
              userInfoDTO.carLoyaltyNumbers[j].rentalCarName = p.carLoyalityInfo[i].rentalCarName;
              //   this.userInfoDTO.ffnMapping[j].traflaPartnerCode = p.carLoyalityInfo[i].traflaPartnerCode;
            } else if (carCode == userInfoDTO.carLoyaltyNumbers[j].rentalCarCode && !p.carLoyalityInfo[i].clnAvailable) {
              found = found + 1;
              userInfoDTO.carLoyaltyNumbers.splice(j, 1);
            }
          }
        } else {
          userInfoDTO.carLoyaltyNumbers = [];
        }
        if (found === 0 && p.carLoyalityInfo[i].rentalCarLoyaltyNumber) {
          let ffnMappinng = { rentalCarName: '', rentalCarCode: '', rentalCarLoyaltyNumber: '' };
          ffnMappinng.rentalCarName = p.carLoyalityInfo[i].rentalCarName;
          ffnMappinng.rentalCarCode = CommonUtils.getTrflaCode(p.carLoyalityInfo[i].rentalCarCode);
          ffnMappinng.rentalCarLoyaltyNumber = p.carLoyalityInfo[i].rentalCarLoyaltyNumber;
          // ffnMappinng.traflaPartnerCode = p.carLoyalityInfo[i].traflaPartnerCode;
          userInfoDTO.carLoyaltyNumbers.push(ffnMappinng);
        }
      }

    }
    if ((p.hotelLoyalityInfo && p.hotelLoyalityInfo.length > 0)) {
      let found = 0;
      for (let i = 0; i < p.hotelLoyalityInfo.length; i++) {
        if (userInfoDTO.loyalityCards && userInfoDTO.loyalityCards.length > 0) {
          for (let j = 0; j < userInfoDTO.loyalityCards.length; j++) {
            let carCode =getTraflaHotelChainCode(p.hotelLoyalityInfo[i].hotelCode);
            if (carCode == userInfoDTO.loyalityCards[j].chain && p.hotelLoyalityInfo[i].hlnAvailable) {
              found = found + 1;
              userInfoDTO.loyalityCards[j].chain =getTraflaHotelChainCode(p.hotelLoyalityInfo[i].hotelCode);
              userInfoDTO.loyalityCards[j].number = p.hotelLoyalityInfo[i].hotel_loyality_number ? p.hotelLoyalityInfo[i].hotel_loyality_number : null;
              userInfoDTO.loyalityCards[j].hotel_name = p.hotelLoyalityInfo[i].name;
              //   this.userInfoDTO.ffnMapping[j].traflaPartnerCode = p.carLoyalityInfo[i].traflaPartnerCode;
            } else if (carCode == userInfoDTO.loyalityCards[j].chain && !p.hotelLoyalityInfo[i].hlnAvailable) {
              found = found + 1;
              userInfoDTO.loyalityCards.splice(j, 1);
            }
          }
        } else {
          userInfoDTO.loyalityCards = [];
        }
        if (found === 0 && p.hotelLoyalityInfo[i].hotel_loyality_number) {
          let ffnMappinng = { chain: '', hotel_name: '', number: '' };
          ffnMappinng.chain = getTraflaHotelChainCode(p.hotelLoyalityInfo[i].hotelCode);
          ffnMappinng.hotel_name = p.hotelLoyalityInfo[i].name;
          ffnMappinng.number = p.hotelLoyalityInfo[i].hotel_loyality_number;
          // ffnMappinng.traflaPartnerCode = p.carLoyalityInfo[i].traflaPartnerCode;
          userInfoDTO.loyalityCards.push(ffnMappinng);
        }
      }

    }
    userInfoDTO.known_traveller_number = p.knownTravellerNumber && p.knownTravellerNumber.trim().length > 0 ? p.knownTravellerNumber.trim() : "";
    
    let seatPrefer = userAccountInfoService.getAccountInfo().seatPref ? userAccountInfoService.getAccountInfo().seatPref : null;
    let userprefernce = userAccountInfoService.userPreferencesObj;
  
    if (seatPrefer && seatPrefer !== p.seat || (others && p.seat)) {
      if (p.seat === 'AISLE') {
        userprefernce.seatPreference = "1";
      } else if (p.seat === 'WINDOW') {
        userprefernce.seatPreference = "2";
      } else {
        userprefernce.seatPreference = "3";
      }
      userAccountInfoService.savePersonalPrefernece(others,userprefernce,userInfoDTO.email).subscribe(res => {
        if (res.status === 'success') {
          userAccountInfoService.savePersonalInformation(others,userInfoDTO).subscribe(res => {
              if (res.success == true) {
                userAccountInfoService.notToRefreshForm = true;
                userAccountInfoService.paymentPageSave = true;
                userAccountInfoService.savingProfileData = false;
                toastr.success(translateService.instant('paymentDetails.Profileinformationupdatedsuccessfully'));
                userAccountInfoService.promptUserTosaveProfile = true;
                if (res.data) {
                  let userAccountInfoObj: UserAccountInfo = deserialize(res.data, UserAccountInfo);
                  if(!others){
                  userAccountInfoService.setAccountInfo(userAccountInfoObj);
                }
              }
              }else if(res.status === 'success'){
                userAccountInfoService.notToRefreshForm = true;
                userAccountInfoService.paymentPageSave = true;
                adminPanelService.fetchEmployeesList(userAccountInfoService.getUserCompanyId());
                userAccountInfoService.savingProfileData = false;
                toastr.success(translateService.instant('paymentDetails.Profileinformationupdatedsuccessfully'));
              }
            });
          if (res.data) {
            let userPref: UserPreferences = deserialize(res.data, UserPreferences);
            if(!others){
            userAccountInfoService.setUserPreferences(userPref);
          }
          }

        }
      });
    } else if (!seatPrefer) {
      if (p.seat === 'AISLE') {
        userprefernce['seatPreference'] = "1";
      } else if (p.seat === 'WINDOW') {
        userprefernce['seatPreference'] = "2";
      } else {
        userprefernce['seatPreference'] = "3";
      }
      userAccountInfoService.savePersonalPrefernece(others,userprefernce,userInfoDTO.email).subscribe(res => {
        if (res.status === 'success') {
          userAccountInfoService.savePersonalInformation(others,userInfoDTO).subscribe(res => {
              if (res.success == true) {
                userAccountInfoService.notToRefreshForm = true;
                userAccountInfoService.paymentPageSave = true;
                userAccountInfoService.savingProfileData = false;
                toastr.success(translateService.instant('paymentDetails.Profileinformationupdatedsuccessfully'));
                userAccountInfoService.promptUserTosaveProfile = true;
                if (res.data) {
                  let userAccountInfoObj: UserAccountInfo = deserialize(res.data, UserAccountInfo);
                  if(!others){
                  userAccountInfoService.setAccountInfo(userAccountInfoObj);
                }
              }
              }else if(res.status === 'success'){
                userAccountInfoService.notToRefreshForm = true;
                userAccountInfoService.paymentPageSave = true;
                adminPanelService.fetchEmployeesList(userAccountInfoService.getUserCompanyId());
                userAccountInfoService.savingProfileData = false;
                toastr.success(translateService.instant('paymentDetails.Profileinformationupdatedsuccessfully'));
              }
            });
          if (res.data) {
            let userPref: UserPreferences = deserialize(res.data, UserPreferences);
            if(!others){
            userAccountInfoService.setUserPreferences(userPref);
          }
          }

        }
      });
    }else if (seatPrefer && seatPrefer === p.seat) {
      userInfoDTO.known_traveller_number = p.knownTravellerNumber && p.knownTravellerNumber.trim().length > 0 ? p.knownTravellerNumber.trim() : "";
      userAccountInfoService.savePersonalInformation(others,userInfoDTO).subscribe(res => {
          if (res.success == true) {
            userAccountInfoService.notToRefreshForm = true;
            userAccountInfoService.paymentPageSave = true;
            userAccountInfoService.savingProfileData = false;
            if (seatPrefer === p.seat) {
              toastr.success(translateService.instant('paymentDetails.Profileinformationupdatedsuccessfully'));
              userAccountInfoService.promptUserTosaveProfile = true;
            }
            if (res.data) {
              let userAccountInfoObj: UserAccountInfo = deserialize(res.data, UserAccountInfo);
              if(!others){
              userAccountInfoService.setAccountInfo(userAccountInfoObj);
            }
          }
          }else if(res.status === 'success'){
            userAccountInfoService.notToRefreshForm = true;
            userAccountInfoService.paymentPageSave = true;
          adminPanelService.fetchEmployeesList(userAccountInfoService.getUserCompanyId());
            userAccountInfoService.savingProfileData = false;
            toastr.success(translateService.instant('paymentDetails.Profileinformationupdatedsuccessfully'));
          }
        });
    }


  }
  static getUpdateedPassengerInnfo(p, userInfoDTO) {
    if (p.phoneNumber) {
      if (userInfoDTO.phoneNumber == null) {
        userInfoDTO.phoneNumber = new PhoneNumberDTO();
      }
      userInfoDTO.phoneNumber.countryCode = p.dialCode ? p.dialCode : "+1";
      userInfoDTO.phoneNumber.number = p.phoneNumber ? p.phoneNumber : "";
    }
    userInfoDTO.middleName = p.middleName && p.middleName.trim().length > 0 ? p.middleName.trim() : "";
    userInfoDTO.middlename = p.middleName && p.middleName.trim().length > 0 ? p.middleName.trim() : "";
    userInfoDTO.firstName = p.firstName && p.firstName.trim().length > 0 ? p.firstName.trim() : "";
    userInfoDTO.firstname = p.firstName && p.firstName.trim().length > 0 ? p.firstName.trim() : "";
    userInfoDTO.lastName = p.lastName && p.lastName.trim().length > 0 ? p.lastName.trim() : "";
    userInfoDTO.lastname = p.lastName && p.lastName.trim().length > 0 ? p.lastName.trim() : "";
    userInfoDTO.title = p.title ? p.title : "";
    let datePipe = new DatePipe('en-US');
    userInfoDTO.dob = p.dateOfBirth ? datePipe.transform(new Date(p.dateOfBirth), 'MM/dd/yyyy') : "";

    userInfoDTO.gender = p.gender;
    if (p.homeAddress && p.homeAddress.trim().length > 0) {
      if (userInfoDTO.home == null) {
        userInfoDTO.home = new AddressDTO();
      }
      userInfoDTO.home.address = p.homeAddress && p.homeAddress.trim().length > 0 ? p.homeAddress.trim() : "";
    }
    if (p.workAddress && p.workAddress.trim().length > 0) {
      if (userInfoDTO.work == null) {
        userInfoDTO.work = new AddressDTO();
      }
      userInfoDTO.work.address = p.workAddress && p.workAddress.trim().length > 0 ? p.workAddress.trim() : "";
    }
    return userInfoDTO;
  }


  static getContryName(code) {
    let countryObj = countries.find(country => {
      return country.code == code;
    });
    return countryObj.name;
  }
  static getCountryJSON(c: string) {
    return countries.find(x => x.code === c);
  }
  static showNetworkErrorPopupModal(searchService, modalService, popupId,translateService?) {

    let networkPopupRes = {
      'popUpTitle': 'dynamic title',
      'bodyMsg': 'dynamic body message',
      'link': 'dynamicLink',
      'popupId': 'NETWORK_ERROR_ON_SEARCH'
    };
    switch (popupId) {
      case PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOK:
        networkPopupRes.popUpTitle = translateService.instant('result.ConnectionLost');
        networkPopupRes.bodyMsg = translateService.instant('result.Sorrywelosttheconnectionwhilebookingwasinprogress') +
        translateService.instant('result.Pleasecheckyourconnectionandchecktripssectionorcontactsupporttoknowthestatusofyourbooking');
        networkPopupRes.link =  translateService.instant('result.CHECKTRIPS');
        networkPopupRes.popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOK;
        break;
      case PopupComponent.POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH:
        networkPopupRes.popUpTitle = translateService.instant('result.ConnectionLost');
        networkPopupRes.bodyMsg = translateService.instant('result.Sorrywelosttheconnection') +
        translateService.instant('result.Pleasetryagain');
        networkPopupRes.link = translateService.instant('result.TRYAGAIN');
        networkPopupRes.popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH;
        break;
      case PopupComponent.POPUP_ID_NETWORK_ERROR_ON_HOTELSEARCH:
        networkPopupRes.popUpTitle = translateService.instant('result.ConnectionLost');
        networkPopupRes.bodyMsg = translateService.instant('result.Sorrywelosttheconnection') +
        translateService.instant('result.Pleasetryagain');
        networkPopupRes.link = translateService.instant('result.TRYAGAIN');
        networkPopupRes.popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_HOTELSEARCH;
        break;
      case PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOKINPROGRESS:
        networkPopupRes.popUpTitle = translateService.instant('result.ConnectionLost');
        networkPopupRes.bodyMsg =  translateService.instant('result.Pleasecheckyournetworksettings')
        networkPopupRes.link = translateService.instant('result.OK');
        networkPopupRes.popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOKINPROGRESS;
        break;
      case PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOKPOLLING:
        networkPopupRes.popUpTitle =translateService.instant('result.Networkdisconnected'); 
        networkPopupRes.bodyMsg = translateService.instant('result.Sorrywelosttheconnection')  +
        translateService.instant('result.Pleasetryagain');
        networkPopupRes.link = translateService.instant('result.TRYAGAIN');
        networkPopupRes.popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOKPOLLING;
        break;
        case PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ALLFARES:
          networkPopupRes.popUpTitle = translateService.instant('result.Networkdisconnected'); 
          networkPopupRes.bodyMsg = translateService.instant('result.Sorrywelosttheconnection')  +
          translateService.instant('result.Pleasetryagain');
          networkPopupRes.link = translateService.instant('result.TRYAGAIN');
          networkPopupRes.popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ALLFARES;
          break;
      case PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ROOMLIST:
        networkPopupRes.popUpTitle = translateService.instant('result.ConnectionLost');
        networkPopupRes.bodyMsg = translateService.instant('result.Sorrywelosttheconnectionandcouldnotloadtheroom') +
        translateService.instant('result.detailsPleasetryagain');
        networkPopupRes.link = translateService.instant('result.TRYAGAIN');
        networkPopupRes.popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ROOMLIST;
        break;
      case PopupComponent.POPUP_ID_NETWORK_ERROR_ON_APPROVAL_REQUEST:
        networkPopupRes.popUpTitle = translateService.instant('result.ConnectionLost');
        networkPopupRes.bodyMsg = translateService.instant('result.Sorrywelosttheconnection') +
        translateService.instant('result.Pleasetryagain');
        networkPopupRes.link = translateService.instant('result.TRYAGAIN');
        networkPopupRes.popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_APPROVAL_REQUEST;
        break;
      default:
        networkPopupRes.popUpTitle = translateService.instant('result.ConnectionLost');
        networkPopupRes.bodyMsg = translateService.instant('result.Sorrywelosttheconnection') +
        translateService.instant('result.Pleasetryagain');
        networkPopupRes.link = translateService.instant('result.TRYAGAIN');
        networkPopupRes.popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH;
        break;
    }


    let bsModalRef = modalService.show(PopupComponent, { initialState: networkPopupRes, backdrop: true, ignoreBackdropClick: true });
    //searchService.networkPopupListener.next(bsModalRef);
    return bsModalRef;
  }

  static getThumbnailImage(imageResponseObject: any) {
    let found = false;
    var url = null;
    if (imageResponseObject && imageResponseObject.imagesByHotelTypes) {
      for (var key in imageResponseObject.imagesByHotelTypes) {
        for (let len = 0; len < imageResponseObject.imagesByHotelTypes[key].length; len++) {
          if (imageResponseObject.imagesByHotelTypes[key][len].sizeCode === 'T') {
            url = imageResponseObject.imagesByHotelTypes[key][len].url;
            found = true;
            break;
          }
          else  if (imageResponseObject.imagesByHotelTypes[key][len].sizeCode === 'S') {
            url = imageResponseObject.imagesByHotelTypes[key][len].url;
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }
    if (!found) {
      url = 'assets/images/hotel/hotel.png';
    }
    return url;
  }

  static getTaxAmountFromRateDetail(hotelRateDetail, numOfRooms,item?) {

    var taxAmount = 0;
    if (hotelRateDetail.approximateTotal != null
      && hotelRateDetail.approximateBase != null
    ) {
      if(hotelRateDetail.displayTotal && item){
        taxAmount = Number.parseFloat(hotelRateDetail.displayApproximateTotal)
        -
        Number.parseFloat(hotelRateDetail.displayApproximateBase);

      }else{
      taxAmount = Number.parseFloat(hotelRateDetail.approximateTotal.substring(3))
        -
        Number.parseFloat(hotelRateDetail.approximateBase.substring(3));
      }
    } else if (hotelRateDetail.total != null
      && hotelRateDetail.base != null
    ) {
      if(hotelRateDetail.displayTotal && item){
        taxAmount = Number.parseFloat(hotelRateDetail.displayTotal)
        -
        Number.parseFloat(hotelRateDetail.displayBase);
      }else{
      taxAmount = Number.parseFloat(hotelRateDetail.total.substring(3))
        -
        Number.parseFloat(hotelRateDetail.base.substring(3));
      }
    } else if (hotelRateDetail.tax != null) {
      if(hotelRateDetail.displayTotal && item){
        taxAmount = Number.parseFloat(hotelRateDetail.displayTax)
      }else{
      taxAmount = Number.parseFloat(hotelRateDetail.tax.substring(3))
      }
    }
    return taxAmount * numOfRooms;
  }

  static getApproverDetail(userInfo: UserInfo, adminPanelService: AdminPanelService, departmentId, managerEmail, managerName) {
    let adminDetail = adminPanelService.getDefaultAdminDetail(departmentId);
    if (adminDetail && adminDetail.length > 0) {
      return CommonUtils.getUserDetailTextWithName(adminDetail);
    }
    return CommonUtils.getUserDetailTextWithName(adminPanelService.getDefaultAdminDetail(-1));
  }
  static getUserDetailTextWithName(adminDetail) {
    if (adminDetail) {
      return adminDetail;
    }
    return [];
  }
  static getTrflaCode(item) {
    if (item === "TRFL_FX") {
      return "FX";
    } else if (item === "TRFL_ZI") {
      return "AVS";
    } else if (item === "TRFL_ZD") {
      return "BGT";
    } else if (item === "TRFL_AL") {
      return "ALM";
    } else if (item === "TRFL_ZL") {
      return "NNL";
    } else if (item === "TRFL_ET") {
      return "ENR";
    } else if (item === "TRFL_ZE") {
      return "HRZ";
    } else if (item === "TRFL_ZR") {
      return "DLR";
    } else if (item === "TRFL_ZT") {
      return "THR";
    } else if (item === "TRFL_ZA") {
      return "PYL";
    } else if (item === "TRFL_AD") {
      return "AVT";
    }

  }
  static getStatusLabel(type, status, item,translateService?) {
    if (status === 'cancelled') {
      return  translateService.instant('bookingHistory.Cancelled');
    }
    if (status === 'refund') {
      return translateService.instant('bookingHistory.Refunded');
    }
    if (status === 'voided') {
      return translateService.instant('bookingHistory.Voided');
    }
    let cancellationRequestReceivedFlag = false;
    if (type === 'flight') {
      cancellationRequestReceivedFlag = item.option.flight_option.cancellationRequestReceived;
    }
    if (type === 'hotel') {
      cancellationRequestReceivedFlag = item.option.hotel_option.cancellationRequestReceived;
    }
    if (type === 'cars') {
      cancellationRequestReceivedFlag = item.option.car_option.cancellationRequestReceived;
    }
    if (cancellationRequestReceivedFlag) {
      return   translateService.instant('bookingHistory.CNCLunderreview');
    }
    if (type === 'flight' && (status === 'ticketed' || status === 'expensed')) {
      return translateService.instant('bookingHistory.Booked');
    } else if ((type === 'hotel' || type === 'cars') && (status === 'booked' || status === 'expensed')) {
      return translateService.instant('bookingHistory.Booked');
    }
    return translateService.instant('bookingHistory.Underreview');
  }
  static getStatusLabelForHideChange(type, status, item,translateService?) {
    if (status === 'cancelled') {
     return false;
    }
    if (status === 'refund') {
     return true;
    }
    if (status === 'voided') {
      return true;
    }
    let cancellationRequestReceivedFlag = false;
    if (type === 'flight') {
      cancellationRequestReceivedFlag = item.option.flight_option.cancellationRequestReceived;
    }
    if (type === 'hotel') {
      cancellationRequestReceivedFlag = item.option.hotel_option.cancellationRequestReceived;
    }
    if (type === 'cars') {
      cancellationRequestReceivedFlag = item.option.car_option.cancellationRequestReceived;
    }
    if (cancellationRequestReceivedFlag) {
      return   false;
    }
    if (type === 'flight' && (status === 'ticketed' || status === 'expensed')) {
      return  true;
    } else if ((type === 'hotel' || type === 'cars') && (status === 'booked' || status === 'expensed')) {
      return translateService.instant('bookingHistory.Booked');
    }
    return true;
  }
  static getAllFlightNumbers(legs) {
    let flightNumbers = '';
    for (let legIndex = 0; legIndex < legs.length; legIndex++) {
      for (let hopIndex = 0; hopIndex < legs[legIndex].flightHops.length; hopIndex++) {
        flightNumbers += ',' + legs[legIndex].flightHops[hopIndex].flightNumber;
      }
    }
    return flightNumbers;
  }
  static getTravelDate(item) {
    let date = CommonUtils.getStartDate(item);
    let format = 'd MMM yy';
    if (item.type === 'flight') {
      return DateUtils.getFormattedDateForGivenTimeZone(date, format);
    }
    if (item.type === 'hotel') {
      return DateUtils.getFormattedDateWithoutTimeZoneFromFormat(new Date(date), format);
    }
    if (item.type === 'cars') {
      return DateUtils.getFormattedDateForGivenTimeZone(date, format);
    }
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(new Date(date), 'd MMM yy');
  }

  static getActiveExpenseProviders(userAccountInfoObj) {
    if (userAccountInfoObj && userAccountInfoObj.companySettings
      && userAccountInfoObj.companySettings.expenseProviders && userAccountInfoObj.companySettings.expenseProviders.length > 0) {
      let activeExpenseProviders = JSON.parse(JSON.stringify(Constants.ALL_EXPENSE_PROVIDERS));

      activeExpenseProviders = activeExpenseProviders.filter(item1 => {
        for (let item of userAccountInfoObj.companySettings.expenseProviders) {
          if (item1.id === item) {
            return true;
          }
        }
      });
      return activeExpenseProviders;
    }
    return [];
  }
  static getExpensefieldPlaceholderText(expenseProvider,translateService?) {
    if (expenseProvider === 'EXPENSE_CONCUR') {
      return translateService.instant('bookingHistory.EnteryourSAPConcurverifiedemail');
    } else if (expenseProvider === 'EXPENSE_ZOHO') {
      return translateService.instant('bookingHistory.Enteryouruniqueforwardingemail');
    }
    return translateService.instant('bookingHistory.EnteryourExpensifyverifiedemail');
  }

  static calculateFare(lineItems, gallopCash?) {
    let totalFare = 0;
    let nonAdjustable = 0;
    for (let item of lineItems) {
      if (item.adjustableWithCredits && item.transactionEventType !== "travel_credit") {
        if(item.displayPrice){
          totalFare += item.displayPrice;
        }else{
        totalFare += item.price;
        }
      } else if (item.transactionEventType !== "travel_credit") {
        if(item.displayPrice){
          nonAdjustable += item.displayPrice;
        }else{
          nonAdjustable += item.price;
        }
         
      }
    }
    if (totalFare < 0) {
      totalFare = 0;
    }
    totalFare += nonAdjustable;
    if (gallopCash) {
      totalFare = totalFare - gallopCash;
    }
    if (totalFare < 0) {
      totalFare = 0;
    }
    return totalFare;

  }
  static signout(userAccountService, gallopLocalStorage, loginService) {
    localStorage.setItem('loginSession', null);
    gallopLocalStorage.removeItem('userid');
    gallopLocalStorage.removeItem('sToken');
    loginService.userid = undefined;
    loginService.sToken = undefined;
    userAccountService.signOut();
  }
  static doesPathContain(url, token) {
    if (url) {
      const path = url.split('?')[0];
      return path.indexOf(token) > -1;
    }
  }

  static fetchSlackStatus(http: GallopHttpClient, apiForSlackStatusCheck): Observable<any> {
    return http.get(apiForSlackStatusCheck);
  }

  static checkSlackStatusFromRemote(http: GallopHttpClient, userAccountInfoService: UserAccountService, apiForSlackStatusCheck) {
    CommonUtils.fetchSlackStatus(http, apiForSlackStatusCheck).subscribe(response => {
      if (response && response.success && response.data) {
        userAccountInfoService.getAccountInfo().connectedToSlack = response.data.connected;
      }
    });

  }
  static sendDebugLogToRollBar(message){
    try {
      sendLogToRollBar(message);
    } catch (err) {

    }
  }
  static initializeRollBar(emailId, PARAM_APP_VERSION, PARAM_APPSTORE_VERSION,
    PARAM_APP_PLATFORM, PARAM_OTA_BUILD_NUMBER){
    try {
      configureRollBar(emailId, PARAM_APP_VERSION, PARAM_APPSTORE_VERSION,
        PARAM_APP_PLATFORM, PARAM_OTA_BUILD_NUMBER);
    } catch (err) {

    }
  }

  static   getHopForMaxStar(flightObj, searchService) {
    let legIndex = 0;
    let hopIndex = 0;
    let maxStar = -1;
    for(let legCounter = 0; legCounter < flightObj.legs.length ; legCounter++) {
      for(let hopCounter = 0; hopCounter < flightObj.legs[legCounter].flightHops.length; hopCounter++) {
        let fareClassName = flightObj.legs[legCounter].flightHops[hopCounter].fareClassName;
        const cabinClassName = flightObj.legs[legCounter].flightHops[hopCounter].cabinClass;
        for(let ptrnCounter = 0; ptrnCounter < Constants.FARECLASS_PTRN_TO_IGNORE.length; ptrnCounter++) {
          if (fareClassName !== Constants.FARECLASS_PTRN_TO_IGNORE[ptrnCounter]) {
            fareClassName = fareClassName.replace(Constants.FARECLASS_PTRN_TO_IGNORE[ptrnCounter], '').trim();
          }
        }
        // fareClassName = fareClassName.toUpperCase().split(' WITH ')[0];
        let currStar = null;
        if (searchService.NGS_UI_STAR_MAPPING[flightObj.legs[legCounter].flightHops[hopCounter].carrier]
            && (
              searchService.NGS_UI_STAR_MAPPING[flightObj.legs[legCounter].flightHops[hopCounter].carrier][fareClassName]
              ||
              searchService.NGS_UI_STAR_MAPPING['ALL'][fareClassName]
              )
          ) {
            currStar = searchService.NGS_UI_STAR_MAPPING[flightObj.legs[legCounter].flightHops[hopCounter].carrier][fareClassName];
            if (!currStar) {
              currStar = searchService.NGS_UI_STAR_MAPPING['ALL'][fareClassName];
            }
        } else {
          currStar = Constants.CLASS_STAR_MAP[cabinClassName];
        }
        if (currStar && currStar > maxStar) {
          maxStar = currStar;
          legIndex = legCounter;
          hopIndex = hopCounter;
        }
      }
    }
    if (maxStar !== -1) {
      flightObj.legs[legIndex].flightHops[hopIndex].ngsStars = maxStar;
      return flightObj.legs[legIndex].flightHops[hopIndex];
    }
    return null;
  }
}
