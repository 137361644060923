import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import { EmailSearchV4DTO } from './entity/email-flow/email-searchv4-dto';
import { FlightResult } from './entity/flight-result';
import { FlightLegEmailDTO } from './entity/email-flow/flight-leg-email-dto';
import { EmailHops } from './entity/email-flow/email-hops';
import { deserialize } from './util/ta-json/src/methods/deserialize';
import { Router } from '@angular/router';
import { FlightSearchQueryParam } from './entity/flight-search-query-param';
import { FlightSearchRequest } from './entity/flight-search-request';
import { SearchService } from './search.service';
import { FlightSearchOptions } from './entity/email-flow/flight-search-options';
import { FlightUtils } from './util/flight-utils';
import { DateUtils } from './util/date-utils';
import { DatePipe } from '@angular/common';
import { FlightLegSearch } from './entity/flight-leg-search';
import { JourneyDate } from './entity/journey-date';
import { IntervalType } from './enum/interval.type';
import { GallopLocalStorageService } from './gallop-local-storage.service';
import { HotelResult } from './entity/hotel-result';
import { EMAIL_QUOTE_HOTELS_RESPONSE } from './util/api-sample-hotel-results';
import { HotelQueryParam } from './entity/hotel-query-param';
import { HotelSearchService } from './hotel-search.service';
import { HotelSearchRequest } from './entity/hotel-search-request';
import { AlgoTypes } from './enum/algo-types';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { CarResult } from './entity/carResult';
import { CarBookingService } from './car-booking.service';
import { PhoneNumberDTO } from './entity/phonenumber-dto';
import { UserAccountService } from './user-account.service';
import { CarEmailDTO } from './entity/email-flow/car-email-dto';
import { HotelEmailDTO } from './entity/email-flow/hotel-email-dto';

@Injectable({
    providedIn: 'root'
})
export class EmailQuoteOptionsService {


    private searchResultOptions: EmailSearchV4DTO;
    public searchResultOptionsSubject = new BehaviorSubject<any>(null);
    public searchResultOptionsObserver$ = this.searchResultOptionsSubject.asObservable();

    savedBookingDetailsSubject = new BehaviorSubject<any>(null);
    savedBookingDetailsObserver$ = this.savedBookingDetailsSubject.asObservable();
    public policyFlagChangeListener = new BehaviorSubject<boolean>(null)

    constructor(private http: GallopHttpClient,
        private gallopLocalStorage: GallopLocalStorageService,
        private searchService: SearchService,
        public userAccountInfoService: UserAccountService,
        private hotelSearchService: HotelSearchService,
        private carBookingService: CarBookingService,
        public router: Router) {

    }

    public static getPrePayNonRefundableFlag(hotelDataObj: any) {
        let isNonRefundable = '';
        let isPrePay: boolean = true;
        if ('cancelInfo' in hotelDataObj.hotelRateDetail && hotelDataObj.hotelRateDetail.cancelInfo
            && 'nonRefundableStayIndicator' in hotelDataObj.hotelRateDetail.cancelInfo) {
            if (hotelDataObj.hotelRateDetail.cancelInfo.nonRefundableStayIndicator === 'FALSE') {
                isNonRefundable = 'FALSE';
            } else if (hotelDataObj.hotelRateDetail.cancelInfo.nonRefundableStayIndicator === 'TRUE') {
                isNonRefundable = 'TRUE';
            }
        }
        isPrePay = hotelDataObj.hotelRateDetail.prePay;
        return { isPrePay: isPrePay, isNonRefundable: isNonRefundable };
    }

    public static getHotelRatingStarsMap(hotelStars) {
        let hStar = Number.parseFloat(hotelStars);
        let starMap = [];

        for (let i = 1; i <= 5; i++) {
            if (hStar >= i) starMap.push('full');
            else if (hStar > (i - 1) && hStar < i) starMap.push('half');
            else starMap.push('none');
        }
        return starMap;
    }

    public fetchEmailQuoteOptionsInternal(ticketId: string, threadId: string, stoken: string): Observable<any> {
        return this.http.get(environment.apiEmailQuoteOptionsUrl + '/fetch?ticketId=' + ticketId + '&threadId=' + threadId);
    }

    public fetchSavedBookingDetails(userId, sToken, ticketId, tripId, eventId) {
        this.requestSavedBookingDetails(userId, sToken, ticketId, tripId, eventId).subscribe((res) => {
            if (res) {
                this.savedBookingDetailsSubject.next(res);
            }
        });
    }
    private requestSavedBookingDetails(userId, sToken, ticketId, tripId, eventId): Observable<any> {
        // let revaldiateRequest = this.getRevalidateRequest(ticketId,tripId,eventId,OptionId);
        // const userId =  this.userAccountService.getUserEmail();
        // const sToken = this.userAccountService.getSToken();
        const params = new HttpParams()
            .set('userid', userId)
            .set('sToken', sToken)
            .set('ticketId', ticketId)
            .set('tripId', tripId)
            .set('eventId', eventId)
            // .set('revalidateTestCase',"PRICE_CHANGED")
            ;
        return this.http.get(environment.apiSavedBookingDetailsUrl, { params });
    }

    public fetchEmailQuoteOptions(ticketId: string, threadId: string, sToken: string) {
        this.fetchEmailQuoteOptionsInternal(ticketId, threadId, sToken).subscribe(res => {
            if (res && res != null && res.result === 'success') {
                this.searchResultOptions = deserialize(res.data, EmailSearchV4DTO);
                if (this.searchResultOptions) {
                    let flightSearchReq: FlightSearchRequest = new FlightSearchRequest();
                    if (this.searchResultOptions.flightSearchOptions) {
                        flightSearchReq.cabinClass = this.searchResultOptions.flightSearchOptions.seatClass;
                        flightSearchReq.combo = '' + this.searchResultOptions.flightSearchOptions.combo;
                        flightSearchReq.travellers.adults = this.searchResultOptions.flightSearchOptions.adultCount;
                        flightSearchReq.travellers.children = this.searchResultOptions.flightSearchOptions.childCount;
                        flightSearchReq.travellers.infants = this.searchResultOptions.flightSearchOptions.infantCount;
                        flightSearchReq.requests = new Array();
                        for (let legReq of this.searchResultOptions.flightLegRequests) {
                            let legSearch: FlightLegSearch = new FlightLegSearch();
                            legSearch.origin = legReq.origin;
                            legSearch.destination = legReq.destination;
                            legSearch.originType = legReq.originType;
                            legSearch.destinationType = legReq.destinType;
                            legSearch.journeyDate = new JourneyDate();
                            legSearch.journeyDate.intervalStart = legReq.dateTime;
                            legSearch.journeyDate.intervalEnd = legReq.dateTime2;
                            legSearch.journeyDate.intervalType = legReq.locationQualifier === 'LOCATION_AT_DESTINATION' ? IntervalType.ARRIVAL_BASED : IntervalType.DEPARTURE_BASED;
                            flightSearchReq.requests.push(legSearch);
                            if (legReq.comboDetails && legReq.comboDetails.length > 1) {
                                let leg2Req = legReq.comboDetails[1];
                                let leg2Search: FlightLegSearch = new FlightLegSearch();
                                leg2Search.origin = leg2Req.origin;
                                leg2Search.destination = leg2Req.destination;
                                leg2Search.originType = leg2Req.originType;
                                leg2Search.destinationType = leg2Req.destinType;
                                leg2Search.journeyDate = new JourneyDate();
                                leg2Search.journeyDate.intervalStart = leg2Req.dateTime;
                                leg2Search.journeyDate.intervalEnd = leg2Req.dateTime2;
                                leg2Search.journeyDate.intervalType = leg2Req.locationQualifier === 'LOCATION_AT_DESTINATION' ? IntervalType.ARRIVAL_BASED : IntervalType.DEPARTURE_BASED;
                                flightSearchReq.requests.push(leg2Search);
                            }
                        }
                    }
                    if (this.searchResultOptions.hotelOptionsList && this.searchResultOptions.hotelOptionsList.length > 0) {
                        for (let hotelOptionItem of this.searchResultOptions.hotelOptionsList[0]) {
                            let numOfRooms = hotelOptionItem.hotelRooms.length;
                            if (hotelOptionItem.rateDetail.tax && hotelOptionItem.rateDetail.tax.length > 0) {
                                hotelOptionItem.rateDetail.tax = hotelOptionItem.rateDetail.tax.substring(0, 3)
                                    + (numOfRooms * Number.parseFloat(hotelOptionItem.rateDetail.tax.substring(3)));
                            }
                        }
                    }

                    this.gallopLocalStorage.setItem("flightSearchRequestForBooking", JSON.stringify(flightSearchReq));

                    this.searchResultOptionsSubject.next(this.searchResultOptions);
                } else {
                    this.router.navigate(['/errors'], { queryParams: { errorCode: 'unknown' } });
                }
            } else if (res && res != null && res.status == 'error') {
                this.router.navigate(['/errors'], { queryParams: { errorCode: 'unknown' } });
            }
        }, error => {
            this.router.navigate(['/errors'], { queryParams: { errorCode: '403' } });
        });

    }
    public setSearchResultOptions(selectedFlight: FlightResult, flightSearchRequest: any) {

        // this.searchResultOptions.eventIdAndTypeMap = new Map<string,string>();
        // this.searchResultOptions.eventIdAndTypeMap.set('0','flight');
        // this.searchResultOptions.eventList = new Array<string>();
        // this.searchResultOptions.eventList.push('0');
        // this.searchResultOptions.flightSearchOptions = flightSearchRequest;

        // this.searchResultOptions.multiFlightOptions = new Array<Array<Array<FlightLegEmailDTO>>>();

        // let flightLegs = new Array<FlightLegEmailDTO>();    
        // for( let leg of selectedFlight.legs){
        //   let flightLegEmail : FlightLegEmailDTO = new FlightLegEmailDTO();
        //   let flightEmailhops:Array<EmailHops> = new Array<EmailHops>();
        //   for( let hop of leg.flightHops){
        //     let emailHop : EmailHops = new EmailHops();
        //     emailHop.airlineCode = hop.carrier;
        //     emailHop.airportCode = hop.from;
        //     emailHop.arrival = hop.ends;
        //     emailHop.departure = hop.starts;
        //     emailHop.fromAirport = hop.from;
        //     emailHop.toAirport = hop.to;
        //     emailHop.flightNumber = ''+hop.flightNumber;
        //     emailHop.flightDuration = hop.duration;
        //     emailHop.type = hop.type;
        //     emailHop.flightDate = hop.starts;
        //     flightEmailhops.push(emailHop);
        //   }
        //   flightLegEmail.flight_hops = flightEmailhops;
        //   let tempLastLeg : any =  selectedFlight.legs[selectedFlight.legs.length - 1];
        //   flightLegEmail.airline = selectedFlight.legs[0].flightHops[0].carrier;
        //   flightLegEmail.combo = false;
        //   flightLegEmail.departure_time = selectedFlight.legs[0].flightHops[0].starts;
        //   flightLegEmail.destinationText = tempLastLeg.flightHops[tempLastLeg.flightHops.length - 1].to; 
        //   flightLegEmail.discountedPrice = ''+selectedFlight.discountedPrice;
        //   flightLegEmail.eventId = '0';
        //   // flightLegEmail.
        //   flightLegs.push(flightLegEmail);
        // }

        // let events : Array<any> = new Array<any>();
        // let options : Array<any> = new Array<any>();
        // options.push(flightLegs);
        // events.push(options);

        // this.searchResultOptions.multiFlightOptions.push(events);

    }


    private getRoomCancellationRules(hotelOption: any) {
        if (hotelOption.hotelRooms && hotelOption.hotelRooms != null
            && hotelOption.hotelRooms.length > 0
            && hotelOption.hotelRooms[0].hotelRates
            && hotelOption.hotelRooms[0].hotelRates != null
            && hotelOption.hotelRooms[0].hotelRates.length > 0
            && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies
            && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies != null
            && hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies.length > 0) {
            // &&  hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies[0].cancellationDate){
            // let dateStr = new Date(DateUtils.getFormattedDateWithoutTimeZone(
            //   hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies[0].cancellationDate
            // )).toDateString().split(' ');
            // return this.translateService.instant('optionSelection.CancellationPolicy').toString()
            //   + hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies[0].amountAfterCancellation
            //   + this.translateService.instant('optionSelection.spacefeeafterspace').toString()
            //   + dateStr[1] + ' ' + dateStr[2];
            let cancellationPolicyText = "";
            for (let policyText of hotelOption.hotelRooms[0].hotelRates[0].cancellationPolicies) {
                if (cancellationPolicyText.length > 0) cancellationPolicyText = cancellationPolicyText + ", ";
                cancellationPolicyText = cancellationPolicyText + policyText.cancellationRule;
            }
            return cancellationPolicyText;
        }

        // let policiesText= "";
        // if(hotelDataObj && "roomsRules" in hotelDataObj
        //     && hotelDataObj.roomsRules
        //     && hotelDataObj.roomsRules.length > 0 
        //     && hotelDataObj.roomsRules[0] != null
        //     && hotelDataObj.roomsRules[0].Cancellation){

        //     for(let text of hotelDataObj.roomsRules[0].Cancellation.text){
        //         policiesText = policiesText + (policiesText.length == 0? text : ', '+ text);
        //     }
        // }
        // return policiesText;
    }

    public mockHotelSearchResultEventOptions(item) {
        let selectedHotel: HotelResult = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedHotel")), HotelResult);
        let selectedHotelDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelDetailedObj"));
        let selectedHotelRoomNames: any = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelRoom"));
        let hotelSearchQueryParam: HotelQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequest")));
        let searchQuery: HotelSearchRequest = this.hotelSearchService.getSearchRequest(hotelSearchQueryParam);
        this.gallopLocalStorage.setItem("hotelSearchRequestForBooking", JSON.stringify(searchQuery));
        let emailSearchV4DTOJSON: any = JSON.parse(JSON.stringify(EMAIL_QUOTE_HOTELS_RESPONSE.data));
        // emailSearchV4DTOJSON.hotelOptionsList[0][0].hotelRooms[0].hotelRoomName = selectedHotelRoomNames.name ;
        let emailSearchV4DTO: EmailSearchV4DTO = new EmailSearchV4DTO();
        let eventIdAndTypeMap={};
        emailSearchV4DTO.hotelOptionsList = new Array(selectedHotelDetailsObj.length).fill(null).map(_ => []);
        let eventList = new Array();
        for(let i=0;i<selectedHotelDetailsObj.length;i++){
        let item1 = 'zU5EF'+i;
        if(Object.keys((eventIdAndTypeMap)).length >0){
            let demoObj ={};
             demoObj[item1] ='hotel'; 
         eventIdAndTypeMap = Object.assign(eventIdAndTypeMap, demoObj);
         }else{
            eventIdAndTypeMap[item1] = 'hotel';
         }
      
         eventList.push(item1);
           emailSearchV4DTO.eventList = eventList;
           emailSearchV4DTO.eventIdAndTypeMap = deserialize(eventIdAndTypeMap, Map);
           emailSearchV4DTO.hotelOptionsList[i][0]= new HotelEmailDTO();
           emailSearchV4DTO.hotelOptionsList[i][0].eventId= item1;
        emailSearchV4DTO.hotelOptionsList[i][0].rateDetail.fee = selectedHotelDetailsObj[i].fee;
        emailSearchV4DTO.hotelOptionsList[i][0].distanceMile = selectedHotelDetailsObj[i].distanceMile;
        emailSearchV4DTO.hotelOptionsList[i][0].thumbnailImage = selectedHotel[i].thumbnailImage;
        emailSearchV4DTO.hotelOptionsList[i][0].rateDetail.policy = selectedHotelDetailsObj[i].addPolicy;
        emailSearchV4DTO.hotelOptionsList[i][0].rateDetail.tax = selectedHotel[i].currency + selectedHotelDetailsObj[i].tax;
        emailSearchV4DTO.hotelOptionsList[i][0].rateDetail.resortFee = selectedHotelDetailsObj[i].resortFee;
        emailSearchV4DTO.hotelOptionsList[i][0].source = selectedHotelDetailsObj[i].handleType;
        emailSearchV4DTO.hotelOptionsList[i][0].carDistance = selectedHotel[i].drivingTime;
        emailSearchV4DTO.hotelOptionsList[i][0].hotelAdress = selectedHotelDetailsObj[i].address;
        emailSearchV4DTO.hotelOptionsList[i][0].hotelChain = selectedHotelDetailsObj[i].hotelChain;
        emailSearchV4DTO.hotelOptionsList[i][0].hotelChainName = selectedHotel[i].hotelChainName;
        emailSearchV4DTO.hotelOptionsList[i][0].hotelName = selectedHotelDetailsObj[i].hotelName;
        emailSearchV4DTO.hotelOptionsList[i][0].price = selectedHotel[i].price;
        emailSearchV4DTO.hotelOptionsList[i][0].walkingDistance = selectedHotel[i].walkingTime;
        emailSearchV4DTO.hotelOptionsList[i][0].inPolicy = selectedHotelDetailsObj[i].inPolicy;
        emailSearchV4DTO.hotelOptionsList[i][0].lattitude = selectedHotel[i].latitude;
        emailSearchV4DTO.hotelOptionsList[i][0].longitude = selectedHotel[i].longitude;
        emailSearchV4DTO.hotelOptionsList[i][0].appointmentAddress = hotelSearchQueryParam.hotelAddress;
        //    emailSearchV4DTO.hotelOptionsList[0][0].appointmentAddress = selectedHotel.hotelInfo.appointmentAddress;
        emailSearchV4DTO.hotelOptionsList[i][0].stay = selectedHotelDetailsObj[i].stay;
        emailSearchV4DTO.hotelOptionsList[i][0].checkIn = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(selectedHotel[i].checkInDate, 'MMM d, y');
        emailSearchV4DTO.hotelOptionsList[i][0].checkOut = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(selectedHotel[i].checkOutDate, 'MMM d, y');
        if(this.searchService.displayCurrency){
            emailSearchV4DTO.hotelOptionsList[i][0].currency = this.searchService.displayCurrency;
            emailSearchV4DTO.hotelOptionsList[i][0].displayPrice =this.searchService.displayPrice;
            emailSearchV4DTO.hotelOptionsList[i][0].displayResortFee = selectedHotelDetailsObj[i].displayResortFee;
            emailSearchV4DTO.hotelOptionsList[i][0].displayTax = selectedHotelDetailsObj[i].displayTax;
        }else{
        emailSearchV4DTO.hotelOptionsList[i][0].currency = selectedHotel[i].currency;
        }
        emailSearchV4DTO.hotelOptionsList[i][0].optionId  =i;
        emailSearchV4DTO.hotelOptionsList[i][0].amenities  = selectedHotel[i].amenities;
        emailSearchV4DTO.hotelOptionsList[i][0].starRating = selectedHotel[i].starRating;
        emailSearchV4DTO.hotelOptionsList[i][0].category = selectedHotel[i].starRating;
        emailSearchV4DTO.hotelOptionsList[i][0].prepay = EmailQuoteOptionsService.getPrePayNonRefundableFlag(selectedHotelDetailsObj[i]).isPrePay;
        emailSearchV4DTO.hotelOptionsList[i][0].nonRefundableStayIndicator = EmailQuoteOptionsService.getPrePayNonRefundableFlag(selectedHotelDetailsObj[i]).isNonRefundable;
        emailSearchV4DTO.hotelOptionsList[i][0].cancellationPolicyInfo = this.getRoomCancellationRules(selectedHotelDetailsObj[i]);
        emailSearchV4DTO.hotelOptionsList[i][0].hotelRooms = selectedHotelDetailsObj[i].hotelRooms;
        emailSearchV4DTO.hotelOptionsList[i][0].serviceFee = selectedHotelDetailsObj[i].serviceFee;
        emailSearchV4DTO.hotelOptionsList[i][0].isZipCodeRequired = selectedHotelDetailsObj[i].isZipCodeRequired;
        emailSearchV4DTO.hotelOptionsList[i][0].loyaltyPointsSupported = selectedHotelDetailsObj[i].loyaltyPointsSupported;

        emailSearchV4DTO.policySet = this.hotelSearchService.hotelSearchResponse.policySet;

        
    }
    let flightSearchOptions = {
        "adultCount": Number.parseInt(searchQuery.totalTravellers),
        "childCount": 0,
        "infantCount": 0,
        "seatClass": '',
        "algoType": AlgoTypes.PODUCTIVITY.toString()
    }
    emailSearchV4DTO.flightSearchOptions = deserialize(flightSearchOptions, FlightSearchOptions);
       
        this.searchResultOptionsSubject.next(emailSearchV4DTO);
       
    }

    public requestSearchResultEventOptions() {
        if(this.searchService.multiTripBooking){
            this.selectAllOptionForMultiBooking();
        }else if (this.gallopLocalStorage.getItem("selectedFlight")) {
            this.mockRequestSearchResultEventOptions(true);
        } else if (this.gallopLocalStorage.getItem("selectedHotel")) {
            this.mockHotelSearchResultEventOptions(true);
        } else if (this.gallopLocalStorage.getItem("selectedCar")) {
            this.mockCarSearchResultEventOptions(true)
        }
    }
    caroptionList:any;
    hotelOptionList:any;
    flightoptionList:any;
    public selectAllOptionForMultiBooking(){
        let eventIdAndTypeMap={};
        let emailSearchV4DTO: EmailSearchV4DTO = new EmailSearchV4DTO();
        let eventList = new Array();
        if (this.gallopLocalStorage.getItem("selectedFlight")) {
            let airlines = JSON.parse(this.gallopLocalStorage.getItem('airlineNames'));
        let airports = JSON.parse(this.gallopLocalStorage.getItem('airports'));
        let flightSearchQueryParam: FlightSearchQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequest")));
        let searchQuery1: FlightSearchRequest = this.searchService.getSearchRequest(flightSearchQueryParam);
        this.gallopLocalStorage.setItem("flightSearchRequestForBooking", JSON.stringify(searchQuery1));
        let selectedFlight1: FlightResult = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedFlight")), FlightResult);
        if (true) {
            let selectedFlight:any=[]
            let selectedFlight1: FlightResult = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedFlight")), FlightResult);
            selectedFlight[0] = (selectedFlight1);
           
            let multiFlightOptions: Array<Array<Array<FlightLegEmailDTO>>> = new Array(selectedFlight[0].length).fill(null).map(_ => []);
            let isPolicySetFlag: boolean = true;
            let i = 0;
            for (let item of selectedFlight[0]) {
                let item1 =(i+'flight').toString();
                let searchQuery: FlightSearchRequest = this.searchService.getSearchRequest(this.searchService.multiflightQuery[i]);
                let emailLegs: Array<FlightLegEmailDTO> = new Array<FlightLegEmailDTO>();
                let legIndex = 0;
                for (let leg of item.legs) {
                    let eLeg: FlightLegEmailDTO = new FlightLegEmailDTO();
                    let emailHops: Array<EmailHops> = new Array<EmailHops>();
                    let legArrival = '';
                    let legDestination = '';
                    let fareAttributes:any;
                    let hopIndex = 0;
                    for (let hop of leg.flightHops) {
                        let eHop: EmailHops = new EmailHops();
                        eHop.airlineCode = hop.carrier;
                        eHop.airlineImageUrl = hop.carrier;
                        eHop.airlineName = airlines[hop.carrier];
                        // eHop.arrival = datePipe.transform(new Date(DateUtils.getDisplayDate(hop.ends)), 'h:mm a');
                        // eHop.departure = datePipe.transform(new Date(DateUtils.getDisplayDate(hop.starts)), 'h:mm a');

                        eHop.arrival = DateUtils.getFormattedDateForGivenTimeZone(hop.ends, 'h:mm a');
                        eHop.departure = DateUtils.getFormattedDateForGivenTimeZone(hop.starts, 'h:mm a');
                        let d = DateUtils.getDurationAsHrsMinObj(hop.duration);
                        eHop.flightDuration = d.hrs + ' hr ' + d.mins + ' min';
                        eHop.flightDate = DateUtils.getFormattedDateForGivenTimeZone(hop.starts, 'EEEE, MMMM d, y');
                        eHop.flightEndDate = DateUtils.getFormattedDateForGivenTimeZone(hop.ends, 'EEEE, MMMM d, y');
                        eHop.flightNumber = '' + hop.flightNumber;
                        eHop.fromAirport = hop.from;
                        eHop.fromAirportName = airports[hop.from].name;
                        eHop.toAirport = hop.to;
                        eHop.hopDuration =hop.duration;
                        eHop.toAirportName = airports[hop.to].name;
                        eHop.type = hop.classCode;
                        eHop.typeName = hop.cabinClass;
                        eHop.fareClassName = hop.fareClassName
                        eHop.brandClass = hop.brandClass;
                        if(item.legs[0].flightHighlights){
                            eHop.flightHighlights = item.legs[0].flightHighlights;
                        } 
                        if(item.fareAttributes){
                            eHop.fareAttributes = item.fareAttributes;
                        }
                        if(leg.baggageAllowance){
                            eHop.baggageAllowance = leg.baggageAllowance;
                        }
                        if (hopIndex > 0) {
                            let dur: any = DateUtils.getDurationAsHrsMinObj(DateUtils.getDateDiffInMinutes(new Date(hop.starts), new Date(leg.flightHops[hopIndex - 1].ends)));
                            eHop.duration = dur.hrs + ' hr ' + dur.mins + ' min';
                        }
                        emailHops.push(eHop);

                        legDestination = hop.to;
                        legArrival = hop.ends;
                        hopIndex++;
                    }
                    eLeg.flight_hops = emailHops;
                    eLeg.airline = leg.flightHops[0].carrier;
                    eLeg.airlineImageUrl = leg.flightHops[0].carrier;
                    eLeg.airlineName = airlines[leg.flightHops[0].carrier];
                    eLeg.arrival = legArrival;
                    eLeg.arrival_time = DateUtils.getFormattedDateForGivenTimeZone(legArrival, 'h:mm a');
                    eLeg.booking_url = '';
                    eLeg.combo = false;
                    eLeg.departure_time = DateUtils.getFormattedDateForGivenTimeZone(leg.flightHops[0].starts, 'h:mm a');;
                    eLeg.destinationText = airports[legDestination].city;
                    if (item.fareBreakup.discountedPrice) {
                        eLeg.discountedPrice = '' + item.fareBreakup.discountedPrice;
                    }
                  
                        eLeg.eventId = item1;
                   
                    eLeg.flightDurationInDays = 0;
                    eLeg.handlerName = item.handlerType;
                    eLeg.flight_arrival_date = DateUtils.getFormattedDateForGivenTimeZone(legArrival, 'EEEE, MMMM d, y');
                    eLeg.flight_date = DateUtils.getFormattedDateForGivenTimeZone(leg.flightHops[0].starts, 'EEEE, MMMM d, y');
                    let d = FlightUtils.getFlightLegDuration(item, legIndex);
                   
                    eLeg.flight_duration = d.hrs + ' hr ' + d.mins + ' min';
                    eLeg.flight_duration_in_min = d.hrs + ' hr ' + d.mins + ' min';
                    eLeg.flight_num = '' + leg.flightHops[0].flightNumber;
                    eLeg.from_airport = airports[leg.flightHops[0].from].name;
                    eLeg.from_airport_code = leg.flightHops[0].from;
                    eLeg.isPassportMandatory = item.passportRequired;
                    eLeg.isZipcodeMandatory = item.zipCodeRequired;

                    if (item.travelCreditsInfo) {
                        eLeg.travelCreditsInfo = item.travelCreditsInfo;
                    } else {
                        eLeg.travelCreditsInfo = {};
                    }
                  
                    if (item.serviceFee && item.serviceFee > 0) {
                        eLeg.serviceFee = '' + item.serviceFee;
                    }
                    eLeg.num_stops = (leg.flightHops.length - 1) + 'Stops';
                    eLeg.optionId = "" + i;
                    if(item.displayPrice){
                        eLeg.price = '' + item.displayPrice;
                        eLeg.currency = item.displayCurrency;
                    }else{
                    eLeg.price = '' + item.fareBreakup.totalPrice;
                    eLeg.currency = item.currency;
                    }
                    eLeg.to_airport = airports[legDestination].name;
                    eLeg.to_airportCode = legDestination;
                    eLeg.tripType = searchQuery.requests.length > 0 ? "MULTI_CITY" : 'ONE_WAY_OUTGOING';
                    if (leg.flightHighlights.hasOwnProperty("withinPolicy")) {
                        isPolicySetFlag = isPolicySetFlag && true;
                        eLeg.withinPolicy = item.legs[(item.legs.length - 1)].flightHighlights.withinPolicy;
                    } else {
                        isPolicySetFlag = isPolicySetFlag && false;
                    }
                    if(item.fareAttributes){
                        eLeg.fareAttributes = item.fareAttributes;
                    }
                    emailLegs.push(eLeg);
                    legIndex++;
                }
                let events = new Array();
                let options = new Array();
                //events.push();
                
                    multiFlightOptions[i].push(emailLegs);
                    eventIdAndTypeMap[item1] = 'flight' ;
                    emailSearchV4DTO.eventList = eventList;
                    emailSearchV4DTO.eventIdAndTypeMap = deserialize(eventIdAndTypeMap, Map);
                    // eventIdAndTypeMap.set('0','flight');
                   
                    eventList.push(item1);
                    let flightSearchOptions = {
                        "adultCount": searchQuery.travellers.adults,
                        "childCount": searchQuery.travellers.children,
                        "infantCount": searchQuery.travellers.infants,
                        "seatClass": searchQuery.cabinClass,
                        "algoType": searchQuery.algoType,
                        "tripType": searchQuery.requests.length > 0 ? "MULTI_CITY" : 'ONE_WAY_OUTGOING',
                        "combo": searchQuery.requests.length > 0 ? true : false,
                    }
                    emailSearchV4DTO.flightSearchOptions = deserialize(flightSearchOptions, FlightSearchOptions);
            emailSearchV4DTO.policySet = isPolicySetFlag;
               
                i = i + 1;
            }

           

            // let eventIdAndTypeMap: Map<string, string> = new Map<string,string>();
            
           

            emailSearchV4DTO.multiFlightOptions = multiFlightOptions;
           
           
           
           
           
           
        }    
        }
         if (this.gallopLocalStorage.getItem("selectedHotel")) {
            let selectedHotel: HotelResult = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedHotel")), HotelResult);
            let selectedHotelDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelDetailedObj"));
            let selectedHotelRoomNames: any = JSON.parse(this.gallopLocalStorage.getItem("selectedHotelRoom"));
            let hotelSearchQueryParam: HotelQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequest")));
            let searchQuery1: HotelSearchRequest = this.hotelSearchService.getSearchRequest(hotelSearchQueryParam);
            this.gallopLocalStorage.setItem("hotelSearchRequestForBooking", JSON.stringify(searchQuery1));
            let emailSearchV4DTOJSON: any = JSON.parse(JSON.stringify(EMAIL_QUOTE_HOTELS_RESPONSE.data));
            // emailSearchV4DTOJSON.hotelOptionsList[0][0].hotelRooms[0].hotelRoomName = selectedHotelRoomNames.name ;
          //  let emailSearchV4DTO: EmailSearchV4DTO = new EmailSearchV4DTO();
           // let eventIdAndTypeMap={};
            emailSearchV4DTO.hotelOptionsList = new Array(selectedHotelDetailsObj.length).fill(null).map(_ => []);
          //  let eventList = new Array();
            for(let i=0;i<selectedHotelDetailsObj.length;i++){
            let item1 = 'zU5EF'+i;
            let searchQuery: HotelSearchRequest = this.hotelSearchService.getSearchRequest(this.searchService.multihotelQuery[i]);
            if(Object.keys((eventIdAndTypeMap)).length >0){
                let demoObj ={};
                 demoObj[item1] ='hotel'; 
                
                 let demoEvent = JSON.parse(JSON.stringify(eventIdAndTypeMap));
             eventIdAndTypeMap = Object.assign(demoEvent, demoObj);
             }else{
                eventIdAndTypeMap[item1] = 'hotel';
             }
          
             eventList.push(item1);
               emailSearchV4DTO.eventList = eventList;
               emailSearchV4DTO.eventIdAndTypeMap = deserialize(eventIdAndTypeMap, Map);
               emailSearchV4DTO.hotelOptionsList[i][0]= new HotelEmailDTO();
               emailSearchV4DTO.hotelOptionsList[i][0].eventId= item1;
            emailSearchV4DTO.hotelOptionsList[i][0].rateDetail.fee = selectedHotelDetailsObj[i].fee;
            emailSearchV4DTO.hotelOptionsList[i][0].distanceMile = selectedHotelDetailsObj[i].distanceMile;
            emailSearchV4DTO.hotelOptionsList[i][0].thumbnailImage = selectedHotel[i].thumbnailImage;
            emailSearchV4DTO.hotelOptionsList[i][0].rateDetail.policy = selectedHotelDetailsObj[i].addPolicy;
            emailSearchV4DTO.hotelOptionsList[i][0].rateDetail.tax = selectedHotel[i].currency + selectedHotelDetailsObj[i].tax;
            emailSearchV4DTO.hotelOptionsList[i][0].rateDetail.resortFee = selectedHotelDetailsObj[i].resortFee;
            emailSearchV4DTO.hotelOptionsList[i][0].source = selectedHotelDetailsObj[i].handleType;
            emailSearchV4DTO.hotelOptionsList[i][0].carDistance = selectedHotel[i].drivingTime;
            emailSearchV4DTO.hotelOptionsList[i][0].hotelAdress = selectedHotelDetailsObj[i].address;
            emailSearchV4DTO.hotelOptionsList[i][0].hotelChain = selectedHotelDetailsObj[i].hotelChain;
            emailSearchV4DTO.hotelOptionsList[i][0].hotelChainName = selectedHotel[i].hotelChainName;
            emailSearchV4DTO.hotelOptionsList[i][0].hotelName = selectedHotelDetailsObj[i].hotelName;
            emailSearchV4DTO.hotelOptionsList[i][0].price = selectedHotel[i].price;
            emailSearchV4DTO.hotelOptionsList[i][0].walkingDistance = selectedHotel[i].walkingTime;
            emailSearchV4DTO.hotelOptionsList[i][0].inPolicy = selectedHotelDetailsObj[i].inPolicy;
            emailSearchV4DTO.hotelOptionsList[i][0].lattitude = selectedHotel[i].latitude;
            emailSearchV4DTO.hotelOptionsList[i][0].longitude = selectedHotel[i].longitude;
            emailSearchV4DTO.hotelOptionsList[i][0].appointmentAddress = hotelSearchQueryParam.hotelAddress;
            //    emailSearchV4DTO.hotelOptionsList[0][0].appointmentAddress = selectedHotel.hotelInfo.appointmentAddress;
            emailSearchV4DTO.hotelOptionsList[i][0].stay = selectedHotelDetailsObj[i].stay;
            emailSearchV4DTO.hotelOptionsList[i][0].checkIn = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(selectedHotel[i].checkInDate, 'MMM d, y');
            emailSearchV4DTO.hotelOptionsList[i][0].checkOut = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(selectedHotel[i].checkOutDate, 'MMM d, y');
            if(this.searchService.displayCurrency){
                emailSearchV4DTO.hotelOptionsList[i][0].currency = this.searchService.displayCurrency;
                emailSearchV4DTO.hotelOptionsList[i][0].displayPrice =this.searchService.displayPrice;
                emailSearchV4DTO.hotelOptionsList[i][0].displayResortFee = selectedHotelDetailsObj[i].displayResortFee;
                emailSearchV4DTO.hotelOptionsList[i][0].displayTax = selectedHotelDetailsObj[i].displayTax;
            }else{
            emailSearchV4DTO.hotelOptionsList[i][0].currency = selectedHotel[i].currency;
            }
            emailSearchV4DTO.hotelOptionsList[i][0].optionId  =i;
            emailSearchV4DTO.hotelOptionsList[i][0].amenities  = selectedHotel[i].amenities;
            emailSearchV4DTO.hotelOptionsList[i][0].starRating = selectedHotel[i].starRating;
            emailSearchV4DTO.hotelOptionsList[i][0].category = selectedHotel[i].starRating;
            emailSearchV4DTO.hotelOptionsList[i][0].prepay = EmailQuoteOptionsService.getPrePayNonRefundableFlag(selectedHotelDetailsObj[i]).isPrePay;
            emailSearchV4DTO.hotelOptionsList[i][0].nonRefundableStayIndicator = EmailQuoteOptionsService.getPrePayNonRefundableFlag(selectedHotelDetailsObj[i]).isNonRefundable;
            emailSearchV4DTO.hotelOptionsList[i][0].cancellationPolicyInfo = this.getRoomCancellationRules(selectedHotelDetailsObj[i]);
            emailSearchV4DTO.hotelOptionsList[i][0].hotelRooms = selectedHotelDetailsObj[i].hotelRooms;
            emailSearchV4DTO.hotelOptionsList[i][0].serviceFee = selectedHotelDetailsObj[i].serviceFee;
            emailSearchV4DTO.hotelOptionsList[i][0].isZipCodeRequired = selectedHotelDetailsObj[i].isZipCodeRequired;
            emailSearchV4DTO.hotelOptionsList[i][0].loyaltyPointsSupported = selectedHotelDetailsObj[i].loyaltyPointsSupported;
    
            emailSearchV4DTO.policySet = this.hotelSearchService.hotelSearchResponse.policySet;
    
            
        }
        let flightSearchOptions = {
            "adultCount": Number.parseInt(searchQuery1.totalTravellers),
            "childCount": 0,
            "infantCount": 0,
            "seatClass": '',
            "algoType": AlgoTypes.PODUCTIVITY.toString()
        }
        emailSearchV4DTO.flightSearchOptions = deserialize(flightSearchOptions, FlightSearchOptions);
        } 
         if (this.gallopLocalStorage.getItem("selectedCar")) {
            let carSearchQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("carSearchRequest")));
        let searchQuery1 = this.carBookingService.getSearchRequest(carSearchQueryParam);
        this.gallopLocalStorage.setItem("carSearchRequestForBooking", JSON.stringify(searchQuery1));
        let selectedCar = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedCar")));
        emailSearchV4DTO.carOptionsList = new Array(selectedCar.length).fill(null).map(_ => []);
        for(let i=0;i<selectedCar.length;i++){
            let item1 =(i+1).toString();
            let searchQuery = this.carBookingService.getSearchRequest(this.searchService.multicarQuery[i]);
            emailSearchV4DTO.carOptionsList[i][0]= new CarEmailDTO();
            if(Object.keys((eventIdAndTypeMap)).length >0){
               let demoObj ={};
                demoObj[item1] ='cars'; 
                let demoEvent = JSON.parse(JSON.stringify(eventIdAndTypeMap));
            eventIdAndTypeMap = Object.assign(demoEvent, demoObj);
            }else{
               eventIdAndTypeMap[item1] = 'cars';
            }
         
           eventList.push(item1);
           emailSearchV4DTO.eventList = eventList;
           emailSearchV4DTO.eventIdAndTypeMap = deserialize(eventIdAndTypeMap, Map);
         
           emailSearchV4DTO.carOptionsList[i][0].outOfPolicyReason = selectedCar[i].outOfPolicyReason;
   
               emailSearchV4DTO.carOptionsList[i][0].displayPrice = selectedCar[i].displayPrice ? selectedCar[i].displayPrice:null;
               emailSearchV4DTO.carOptionsList[i][0].displayBasePrice = selectedCar[i].displayBasePrice ? selectedCar[i].displayBasePrice:null;
               emailSearchV4DTO.carOptionsList[i][0].displayCurrency =  selectedCar[i].displayCurrency ?selectedCar[i].displayCurrency:null ;
              
                   emailSearchV4DTO.carOptionsList[i][0].currency = selectedCar[i].currency;
           
               emailSearchV4DTO.carOptionsList[i][0].price = selectedCar[i].price;
               emailSearchV4DTO.carOptionsList[i][0].dropLocationType = selectedCar[i].dropLocationType
           emailSearchV4DTO.carOptionsList[i][0].revalidation = false;
           emailSearchV4DTO.carOptionsList[i][0].pickUpLocationType = selectedCar[i].pickUpLocationType;
           emailSearchV4DTO.carOptionsList[i][0].optionId = +i;
           emailSearchV4DTO.carOptionsList[i][0].carClass = selectedCar[i].carClass;
           emailSearchV4DTO.carOptionsList[i][0].carType = selectedCar[i].carType;
           emailSearchV4DTO.carOptionsList[i][0].freeCancellation = selectedCar[i].freeCancellation;
           emailSearchV4DTO.carOptionsList[i][0].source = selectedCar[i].handlerType;
           emailSearchV4DTO.carOptionsList[i][0].phoneNumber = selectedCar[i].phoneNumber;
           emailSearchV4DTO.carOptionsList[i][0].priceUpdated = null;
           emailSearchV4DTO.carOptionsList[i][0].isAvailable = null;
           emailSearchV4DTO.carOptionsList[i][0].airportCity = searchQuery.pickUp;
           emailSearchV4DTO.carOptionsList[i][0].pickUpLat = searchQuery.pickUpLat;
           emailSearchV4DTO.carOptionsList[i][0].pickUpLng = searchQuery.pickUpLng;
           emailSearchV4DTO.carOptionsList[i][0].dropOffLat = searchQuery.dropOffLat;
           emailSearchV4DTO.carOptionsList[i][0].dropOffLng = searchQuery.dropOffLng;
           emailSearchV4DTO.carOptionsList[i][0].dropOffTime = searchQuery.dropTime;
           emailSearchV4DTO.carOptionsList[i][0].pickUpTime = searchQuery.pickTime;
           emailSearchV4DTO.carOptionsList[i][0].pickUpDate = searchQuery.pickUpDate;
           emailSearchV4DTO.carOptionsList[i][0].dropOffDate = searchQuery.dropOffDate;
           emailSearchV4DTO.carOptionsList[i][0].eventId = (i+1).toString();
           emailSearchV4DTO.carOptionsList[i][0].handlerType = selectedCar[i].handlerType;
           emailSearchV4DTO.carOptionsList[i][0].airConditioning = selectedCar[i].airConditioning;
           emailSearchV4DTO.carOptionsList[i][0].automaticTransmission = selectedCar[i].automaticTransmission
           emailSearchV4DTO.carOptionsList[i][0].bags = selectedCar[i].bags;
           emailSearchV4DTO.carOptionsList[i][0].numberOfDay = selectedCar[i].numberOfDay;
           emailSearchV4DTO.carOptionsList[i][0].basePrice = selectedCar[i].basePrice;
           emailSearchV4DTO.carOptionsList[i][0].carMake = selectedCar[i].carMake;
           emailSearchV4DTO.carOptionsList[i][0].paymentRequired = selectedCar[i].paymentRequired;
           emailSearchV4DTO.carOptionsList[i][0].zipCodeRequired = selectedCar[i].zipCodeRequired;
           
           emailSearchV4DTO.carOptionsList[i][0].description = selectedCar[i].description;
           emailSearchV4DTO.carOptionsList[i][0].distance = selectedCar[i].distance;
           emailSearchV4DTO.carOptionsList[i][0].doors = selectedCar[i].doors;
           emailSearchV4DTO.carOptionsList[i][0].dropOffLocation = selectedCar[i].dropOffLocation;
           emailSearchV4DTO.carOptionsList[i][0].images = selectedCar[i].images;
           emailSearchV4DTO.carOptionsList[i][0].locationInformation = selectedCar[i].locationInformation;
           emailSearchV4DTO.carOptionsList[i][0].milage = selectedCar[i].milage;
           emailSearchV4DTO.carOptionsList[i][0].partnerLogo = selectedCar[i].partnerLogo;
           emailSearchV4DTO.carOptionsList[i][0].partnerName = selectedCar[i].partnerName;
           emailSearchV4DTO.carOptionsList[i][0].passengers = selectedCar[i].passengers;
           emailSearchV4DTO.carOptionsList[i][0].pickUpLocation = selectedCar[i].pickUpLocation;
           emailSearchV4DTO.carOptionsList[i][0].policy = selectedCar[i].policy;
           emailSearchV4DTO.carOptionsList[i][0].postPay = selectedCar[i].postPay;
          
           emailSearchV4DTO.carOptionsList[i][0].rateKey = selectedCar[i].rateKey;
           emailSearchV4DTO.carOptionsList[i][0].status = selectedCar[i].status;
           emailSearchV4DTO.carOptionsList[i][0].traflaPartnerCode = selectedCar[i].traflaPartnerCode;
           emailSearchV4DTO.carOptionsList[i][0].traflaVehicleCode = selectedCar[i].traflaVehicleCode;
           emailSearchV4DTO.policySet = selectedCar[i].policy;
           
        }
        this.gallopLocalStorage.setItem("selectedCarDetailedObj", JSON.stringify(emailSearchV4DTO));
        }
        this.searchResultOptionsSubject.next(emailSearchV4DTO);
    }
    public mockCarSearchResultEventOptions(item) {
        let carSearchQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("carSearchRequest")));
        let searchQuery = this.carBookingService.getSearchRequest(carSearchQueryParam);
        this.gallopLocalStorage.setItem("carSearchRequestForBooking", JSON.stringify(searchQuery));
        let selectedCar = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedCar")));
        
        let eventIdAndTypeMap={};
        let emailSearchV4DTO: EmailSearchV4DTO = new EmailSearchV4DTO();
        let eventList = new Array();
        emailSearchV4DTO.carOptionsList = new Array(selectedCar.length).fill(null).map(_ => []);
        for(let i=0;i<selectedCar.length;i++){
            let item1 =(i+1).toString();
            emailSearchV4DTO.carOptionsList[i][0]= new CarEmailDTO();
            if(Object.keys((eventIdAndTypeMap)).length >0){
               let demoObj ={};
                demoObj[item1] ='cars'; 
            eventIdAndTypeMap = Object.assign(eventIdAndTypeMap, demoObj);
            }else{
               eventIdAndTypeMap[item1] = 'cars';
            }
         
           eventList.push(item1);
           emailSearchV4DTO.eventList = eventList;
           emailSearchV4DTO.eventIdAndTypeMap = deserialize(eventIdAndTypeMap, Map);
         
           emailSearchV4DTO.carOptionsList[i][0].outOfPolicyReason = selectedCar[i].outOfPolicyReason;
   
               emailSearchV4DTO.carOptionsList[i][0].displayPrice = selectedCar[i].displayPrice ? selectedCar[i].displayPrice:null;
               emailSearchV4DTO.carOptionsList[i][0].displayBasePrice = selectedCar[i].displayBasePrice ? selectedCar[i].displayBasePrice:null;
               emailSearchV4DTO.carOptionsList[i][0].displayCurrency =  selectedCar[i].displayCurrency ?selectedCar[i].displayCurrency:null ;
              
                   emailSearchV4DTO.carOptionsList[i][0].currency = selectedCar[i].currency;
           
               emailSearchV4DTO.carOptionsList[i][0].price = selectedCar[i].price;
               emailSearchV4DTO.carOptionsList[i][0].dropLocationType = selectedCar[i].dropLocationType
           emailSearchV4DTO.carOptionsList[i][0].revalidation = false;
           emailSearchV4DTO.carOptionsList[i][0].pickUpLocationType = selectedCar[i].pickUpLocationType;
           emailSearchV4DTO.carOptionsList[i][0].optionId = +i;
           emailSearchV4DTO.carOptionsList[i][0].carClass = selectedCar[i].carClass;
           emailSearchV4DTO.carOptionsList[i][0].carType = selectedCar[i].carType;
           emailSearchV4DTO.carOptionsList[i][0].freeCancellation = selectedCar[i].freeCancellation;
           emailSearchV4DTO.carOptionsList[i][0].source = selectedCar[i].handlerType;
           emailSearchV4DTO.carOptionsList[i][0].phoneNumber = selectedCar[i].phoneNumber;
           emailSearchV4DTO.carOptionsList[i][0].priceUpdated = null;
           emailSearchV4DTO.carOptionsList[i][0].isAvailable = null;
           emailSearchV4DTO.carOptionsList[i][0].airportCity = searchQuery.pickUp;
           emailSearchV4DTO.carOptionsList[i][0].pickUpLat = searchQuery.pickUpLat;
           emailSearchV4DTO.carOptionsList[i][0].pickUpLng = searchQuery.pickUpLng;
           emailSearchV4DTO.carOptionsList[i][0].dropOffLat = searchQuery.dropOffLat;
           emailSearchV4DTO.carOptionsList[i][0].dropOffLng = searchQuery.dropOffLng;
           emailSearchV4DTO.carOptionsList[i][0].dropOffTime = searchQuery.dropTime;
           emailSearchV4DTO.carOptionsList[i][0].pickUpTime = searchQuery.pickTime;
           emailSearchV4DTO.carOptionsList[i][0].pickUpDate = searchQuery.pickUpDate;
           emailSearchV4DTO.carOptionsList[i][0].dropOffDate = searchQuery.dropOffDate;
           emailSearchV4DTO.carOptionsList[i][0].eventId = (i+1).toString();
           emailSearchV4DTO.carOptionsList[i][0].handlerType = selectedCar[i].handlerType;
           emailSearchV4DTO.carOptionsList[i][0].airConditioning = selectedCar[i].airConditioning;
           emailSearchV4DTO.carOptionsList[i][0].automaticTransmission = selectedCar[i].automaticTransmission
           emailSearchV4DTO.carOptionsList[i][0].bags = selectedCar[i].bags;
           emailSearchV4DTO.carOptionsList[i][0].numberOfDay = selectedCar[i].numberOfDay;
           emailSearchV4DTO.carOptionsList[i][0].basePrice = selectedCar[i].basePrice;
           emailSearchV4DTO.carOptionsList[i][0].carMake = selectedCar[i].carMake;
           emailSearchV4DTO.carOptionsList[i][0].paymentRequired = selectedCar[i].paymentRequired;
           emailSearchV4DTO.carOptionsList[i][0].zipCodeRequired = selectedCar[i].zipCodeRequired;
           
           emailSearchV4DTO.carOptionsList[i][0].description = selectedCar[i].description;
           emailSearchV4DTO.carOptionsList[i][0].distance = selectedCar[i].distance;
           emailSearchV4DTO.carOptionsList[i][0].doors = selectedCar[i].doors;
           emailSearchV4DTO.carOptionsList[i][0].dropOffLocation = selectedCar[i].dropOffLocation;
           emailSearchV4DTO.carOptionsList[i][0].images = selectedCar[i].images;
           emailSearchV4DTO.carOptionsList[i][0].locationInformation = selectedCar[i].locationInformation;
           emailSearchV4DTO.carOptionsList[i][0].milage = selectedCar[i].milage;
           emailSearchV4DTO.carOptionsList[i][0].partnerLogo = selectedCar[i].partnerLogo;
           emailSearchV4DTO.carOptionsList[i][0].partnerName = selectedCar[i].partnerName;
           emailSearchV4DTO.carOptionsList[i][0].passengers = selectedCar[i].passengers;
           emailSearchV4DTO.carOptionsList[i][0].pickUpLocation = selectedCar[i].pickUpLocation;
           emailSearchV4DTO.carOptionsList[i][0].policy = selectedCar[i].policy;
           emailSearchV4DTO.carOptionsList[i][0].postPay = selectedCar[i].postPay;
          
           emailSearchV4DTO.carOptionsList[i][0].rateKey = selectedCar[i].rateKey;
           emailSearchV4DTO.carOptionsList[i][0].status = selectedCar[i].status;
           emailSearchV4DTO.carOptionsList[i][0].traflaPartnerCode = selectedCar[i].traflaPartnerCode;
           emailSearchV4DTO.carOptionsList[i][0].traflaVehicleCode = selectedCar[i].traflaVehicleCode;
           emailSearchV4DTO.policySet = selectedCar[i].policy;
           
        }
        this.gallopLocalStorage.setItem("selectedCarDetailedObj", JSON.stringify(emailSearchV4DTO));
       
        this.searchResultOptionsSubject.next(emailSearchV4DTO);
        
    }
    public mockRequestSearchResultEventOptions(item) {
        let datePipe = new DatePipe('en-US');
        let airlines = JSON.parse(this.gallopLocalStorage.getItem('airlineNames'));
        let airports = JSON.parse(this.gallopLocalStorage.getItem('airports'));
        let flightSearchQueryParam: FlightSearchQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("flightSearchRequest")));
        let searchQuery: FlightSearchRequest = this.searchService.getSearchRequest(flightSearchQueryParam);
        this.gallopLocalStorage.setItem("flightSearchRequestForBooking", JSON.stringify(searchQuery));
        let selectedFlight1: FlightResult = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedFlight")), FlightResult);
        if(this.searchService.nonCombo && this.searchService.nonComboSelectedFlight.length < this.searchService.nonComboSelectedFlightForForwardArrow.length){
            this.searchService.nonComboSelectedFlight =[]
            this.searchService.nonComboSelectedFlight = [...this.searchService.nonComboSelectedFlightForForwardArrow];
        }
        if (true) {
            let selectedFlight:any=[]
            let selectedFlight1: FlightResult = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedFlight")), FlightResult);
            selectedFlight[0] = (selectedFlight1);
           
            let multiFlightOptions: Array<Array<Array<FlightLegEmailDTO>>> = new Array(selectedFlight[0].length).fill(null).map(_ => []);
            let isPolicySetFlag: boolean = true;
            let i = 0;
            let eventIdAndTypeMap = {};
            let emailSearchV4DTO: EmailSearchV4DTO = new EmailSearchV4DTO();
            let eventList = new Array();
            for (let item of selectedFlight[0]) {
                let item1 =(i+1).toString();
                let emailLegs: Array<FlightLegEmailDTO> = new Array<FlightLegEmailDTO>();
                let legIndex = 0;
                for (let leg of item.legs) {
                    let eLeg: FlightLegEmailDTO = new FlightLegEmailDTO();
                    let emailHops: Array<EmailHops> = new Array<EmailHops>();
                    let legArrival = '';
                    let legDestination = '';
                    let fareAttributes:any;
                    let hopIndex = 0;
                    for (let hop of leg.flightHops) {
                        let eHop: EmailHops = new EmailHops();
                        eHop.airlineCode = hop.carrier;
                        eHop.airlineImageUrl = hop.carrier;
                        eHop.airlineName = airlines[hop.carrier];
                        // eHop.arrival = datePipe.transform(new Date(DateUtils.getDisplayDate(hop.ends)), 'h:mm a');
                        // eHop.departure = datePipe.transform(new Date(DateUtils.getDisplayDate(hop.starts)), 'h:mm a');

                        eHop.arrival = DateUtils.getFormattedDateForGivenTimeZone(hop.ends, 'h:mm a');
                        eHop.departure = DateUtils.getFormattedDateForGivenTimeZone(hop.starts, 'h:mm a');
                        let d = DateUtils.getDurationAsHrsMinObj(hop.duration);
                        eHop.flightDuration = d.hrs + ' hr ' + d.mins + ' min';
                        eHop.flightDate = DateUtils.getFormattedDateForGivenTimeZone(hop.starts, 'EEEE, MMMM d, y');
                        eHop.flightEndDate = DateUtils.getFormattedDateForGivenTimeZone(hop.ends, 'EEEE, MMMM d, y');
                        eHop.flightNumber = '' + hop.flightNumber;
                        eHop.fromAirport = hop.from;
                        eHop.fromAirportName = airports[hop.from].name;
                        eHop.toAirport = hop.to;
                        eHop.hopDuration =hop.duration;
                        eHop.toAirportName = airports[hop.to].name;
                        eHop.type = hop.classCode;
                        eHop.typeName = hop.cabinClass;
                        eHop.fareClassName = hop.fareClassName
                        eHop.brandClass = hop.brandClass;
                        if(item.legs[0].flightHighlights){
                            eHop.flightHighlights = item.legs[0].flightHighlights;
                        } 
                        if(item.fareAttributes){
                            eHop.fareAttributes = item.fareAttributes;
                        }
                        if(leg.baggageAllowance){
                            eHop.baggageAllowance = leg.baggageAllowance;
                        }
                        if (hopIndex > 0) {
                            let dur: any = DateUtils.getDurationAsHrsMinObj(DateUtils.getDateDiffInMinutes(new Date(hop.starts), new Date(leg.flightHops[hopIndex - 1].ends)));
                            eHop.duration = dur.hrs + ' hr ' + dur.mins + ' min';
                        }
                        emailHops.push(eHop);

                        legDestination = hop.to;
                        legArrival = hop.ends;
                        hopIndex++;
                    }
                    eLeg.flight_hops = emailHops;
                    eLeg.airline = leg.flightHops[0].carrier;
                    eLeg.airlineImageUrl = leg.flightHops[0].carrier;
                    eLeg.airlineName = airlines[leg.flightHops[0].carrier];
                    eLeg.arrival = legArrival;
                    eLeg.arrival_time = DateUtils.getFormattedDateForGivenTimeZone(legArrival, 'h:mm a');
                    eLeg.booking_url = '';
                    eLeg.combo = false;
                    eLeg.departure_time = DateUtils.getFormattedDateForGivenTimeZone(leg.flightHops[0].starts, 'h:mm a');;
                    eLeg.destinationText = airports[legDestination].city;
                    if (item.fareBreakup.discountedPrice) {
                        eLeg.discountedPrice = '' + item.fareBreakup.discountedPrice;
                    }
                  
                        eLeg.eventId = item1;
                   
                    eLeg.flightDurationInDays = 0;
                    eLeg.handlerName = item.handlerType;
                    eLeg.flight_arrival_date = DateUtils.getFormattedDateForGivenTimeZone(legArrival, 'EEEE, MMMM d, y');
                    eLeg.flight_date = DateUtils.getFormattedDateForGivenTimeZone(leg.flightHops[0].starts, 'EEEE, MMMM d, y');
                    let d = FlightUtils.getFlightLegDuration(item, legIndex);
                   
                    eLeg.flight_duration = d.hrs + ' hr ' + d.mins + ' min';
                    eLeg.flight_duration_in_min = d.hrs + ' hr ' + d.mins + ' min';
                    eLeg.flight_num = '' + leg.flightHops[0].flightNumber;
                    eLeg.from_airport = airports[leg.flightHops[0].from].name;
                    eLeg.from_airport_code = leg.flightHops[0].from;
                    eLeg.isPassportMandatory = item.passportRequired;
                    eLeg.isZipcodeMandatory = item.zipCodeRequired;

                    if (item.travelCreditsInfo) {
                        eLeg.travelCreditsInfo = item.travelCreditsInfo;
                    } else {
                        eLeg.travelCreditsInfo = {};
                    }
                  
                    if (item.serviceFee && item.serviceFee > 0) {
                        eLeg.serviceFee = '' + item.serviceFee;
                    }
                    eLeg.num_stops = (leg.flightHops.length - 1) + 'Stops';
                    eLeg.optionId = "" + i;
                    if(item.displayPrice){
                        eLeg.price = '' + item.displayPrice;
                        eLeg.currency = item.displayCurrency;
                    }else{
                    eLeg.price = '' + item.fareBreakup.totalPrice;
                    eLeg.currency = item.currency;
                    }
                    eLeg.to_airport = airports[legDestination].name;
                    eLeg.to_airportCode = legDestination;
                    eLeg.tripType = searchQuery.requests.length > 0 ? "MULTI_CITY" : 'ONE_WAY_OUTGOING';
                    if (leg.flightHighlights.hasOwnProperty("withinPolicy")) {
                        isPolicySetFlag = isPolicySetFlag && true;
                        eLeg.withinPolicy = item.legs[(item.legs.length - 1)].flightHighlights.withinPolicy;
                    } else {
                        isPolicySetFlag = isPolicySetFlag && false;
                    }
                    if(item.fareAttributes){
                        eLeg.fareAttributes = item.fareAttributes;
                    }
                    emailLegs.push(eLeg);
                    legIndex++;
                }
                let events = new Array();
                let options = new Array();
                //events.push();
                
                    multiFlightOptions[i].push(emailLegs);
                    eventIdAndTypeMap[item1] = 'flight' ;
                    emailSearchV4DTO.eventList = eventList;
                    emailSearchV4DTO.eventIdAndTypeMap = deserialize(eventIdAndTypeMap, Map);
                    // eventIdAndTypeMap.set('0','flight');
                   
                    eventList.push(item1);
                    let flightSearchOptions = {
                        "adultCount": searchQuery.travellers.adults,
                        "childCount": searchQuery.travellers.children,
                        "infantCount": searchQuery.travellers.infants,
                        "seatClass": searchQuery.cabinClass,
                        "algoType": searchQuery.algoType,
                        "tripType": searchQuery.requests.length > 0 ? "MULTI_CITY" : 'ONE_WAY_OUTGOING',
                        "combo": searchQuery.requests.length > 0 ? true : false,
                    }
                    emailSearchV4DTO.flightSearchOptions = deserialize(flightSearchOptions, FlightSearchOptions);
            emailSearchV4DTO.policySet = isPolicySetFlag;
               
                i = i + 1;
            }

           

            // let eventIdAndTypeMap: Map<string, string> = new Map<string,string>();
            
           

            emailSearchV4DTO.multiFlightOptions = multiFlightOptions;
           
           
           
            
            this.searchResultOptionsSubject.next(emailSearchV4DTO);
            
        } else {
            let selectedFlight: FlightResult = deserialize(JSON.parse(this.gallopLocalStorage.getItem("selectedFlight")), FlightResult);
            let emailLegs: Array<FlightLegEmailDTO> = new Array<FlightLegEmailDTO>();
            let legIndex = 0;
            let isPolicySetFlag: boolean = true;
            for (let leg of selectedFlight.legs) {
                let eLeg: FlightLegEmailDTO = new FlightLegEmailDTO();
                let emailHops: Array<EmailHops> = new Array<EmailHops>();
                let legArrival = '';
                let legDestination = '';
                let hopIndex = 0;
                for (let hop of leg.flightHops) {
                    let eHop: EmailHops = new EmailHops();
                    eHop.airlineCode = hop.carrier;
                    eHop.airlineImageUrl = hop.carrier;
                    eHop.airlineName = airlines[hop.carrier];
                    // eHop.arrival = datePipe.transform(new Date(DateUtils.getDisplayDate(hop.ends)), 'h:mm a');
                    // eHop.departure = datePipe.transform(new Date(DateUtils.getDisplayDate(hop.starts)), 'h:mm a');

                    eHop.arrival = DateUtils.getFormattedDateForGivenTimeZone(hop.ends, 'h:mm a');
                    eHop.departure = DateUtils.getFormattedDateForGivenTimeZone(hop.starts, 'h:mm a');
                    let d = DateUtils.getDurationAsHrsMinObj(hop.duration);
                    eHop.hopDuration =hop.duration;
                    eHop.flightDuration = d.hrs + ' hr ' + d.mins + ' min';
                    eHop.flightDate = DateUtils.getFormattedDateForGivenTimeZone(hop.starts, 'EEEE, MMMM d, y');
                    eHop.flightEndDate = DateUtils.getFormattedDateForGivenTimeZone(hop.ends, 'EEEE, MMMM d, y');
                    eHop.flightNumber = '' + hop.flightNumber;
                    eHop.fromAirport = hop.from;
                    if(selectedFlight.legs[0].flightHighlights){
                        eHop.flightHighlights = selectedFlight.legs[0].flightHighlights;
                    } 
                    if(selectedFlight.fareAttributes){
                        eHop.fareAttributes = selectedFlight.fareAttributes;
                    }
                    if(leg.baggageAllowance){
                        eHop.baggageAllowance = leg.baggageAllowance;
                    }
                    eHop.fromAirportName = airports[hop.from].name;
                    eHop.toAirport = hop.to;
                    eHop.toAirportName = airports[hop.to].name;
                    eHop.type = hop.classCode;
                    eHop.typeName = hop.cabinClass;
                    eHop.fareClassName = hop.fareClassName
                    eHop.brandClass = hop.brandClass;
                    if (hopIndex > 0) {
                        let dur: any = DateUtils.getDurationAsHrsMinObj(DateUtils.getDateDiffInMinutes(new Date(hop.starts), new Date(leg.flightHops[hopIndex - 1].ends)));
                        eHop.duration = dur.hrs + ' hr ' + dur.mins + ' min';
                    }
                    emailHops.push(eHop);

                    legDestination = hop.to;
                    legArrival = hop.ends;
                    hopIndex++;
                }
                eLeg.flight_hops = emailHops;
                eLeg.airline = leg.flightHops[0].carrier;
                eLeg.airlineImageUrl = leg.flightHops[0].carrier;
                eLeg.airlineName = airlines[leg.flightHops[0].carrier];
                eLeg.arrival = legArrival;
                eLeg.arrival_time = DateUtils.getFormattedDateForGivenTimeZone(legArrival, 'h:mm a');
                eLeg.booking_url = '';
                eLeg.combo = false;
                eLeg.departure_time = DateUtils.getFormattedDateForGivenTimeZone(leg.flightHops[0].starts, 'h:mm a');;
                eLeg.destinationText = airports[legDestination].city;
                if (selectedFlight.fareBreakup.discountedPrice) {
                    eLeg.discountedPrice = '' + selectedFlight.fareBreakup.discountedPrice;
                }
                eLeg.eventId = '0';
                eLeg.flightDurationInDays = 0;
                eLeg.handlerName = selectedFlight.handlerType;
                eLeg.flight_arrival_date = DateUtils.getFormattedDateForGivenTimeZone(legArrival, 'EE, MMM d');
                eLeg.flight_date = DateUtils.getFormattedDateForGivenTimeZone(leg.flightHops[0].starts, 'EE, MMM d');
                let d = FlightUtils.getFlightLegDuration(selectedFlight, legIndex);
                eLeg.flight_duration = d.hrs + ' hr ' + d.mins + ' min';
                eLeg.flight_duration_in_min = d.hrs + ' hr ' + d.mins + ' min';
                eLeg.flight_num = '' + leg.flightHops[0].flightNumber;
                eLeg.from_airport = airports[leg.flightHops[0].from].name;
                eLeg.from_airport_code = leg.flightHops[0].from;
                eLeg.isPassportMandatory = selectedFlight.passportRequired;
                eLeg.isZipcodeMandatory = selectedFlight.zipCodeRequired;
                if (selectedFlight.travelCreditsInfo) {
                    eLeg.travelCreditsInfo = selectedFlight.travelCreditsInfo;
                } else {
                    eLeg.travelCreditsInfo = {};
                }
                if (selectedFlight.serviceFee && selectedFlight.serviceFee > 0) {
                    eLeg.serviceFee = '' + selectedFlight.serviceFee;
                }
                eLeg.num_stops = (leg.flightHops.length - 1) + 'Stops';
                eLeg.optionId = '0';
                if(this.searchService.nonCombo){
                    if(selectedFlight.displayPrice){
                        eLeg.price = '' + selectedFlight.displayPrice;
                        eLeg.currency = selectedFlight.displayCurrency;
                    }else{
                    eLeg.price = '' + selectedFlight.price;
                    eLeg.currency = selectedFlight.currency;
                    }
                }else{
                    if(selectedFlight.displayPrice){
                        eLeg.price = '' + selectedFlight.displayPrice;
                        eLeg.currency = selectedFlight.displayCurrency;
                    }else{
                    eLeg.price = '' + selectedFlight.fareBreakup.totalPrice;
                    eLeg.currency = selectedFlight.currency;
                    }
                    
                }
               
             //   eLeg.currency = selectedFlight.currency;
                eLeg.to_airport = airports[legDestination].name;
                eLeg.to_airportCode = legDestination;
                eLeg.tripType = searchQuery.requests.length > 0 ? "MULTI_CITY" : 'ONE_WAY_OUTGOING';
                if (leg.flightHighlights.hasOwnProperty("withinPolicy")) {
                    isPolicySetFlag = isPolicySetFlag && true;
                    eLeg.withinPolicy = selectedFlight.legs[((selectedFlight.legs.length) - 1)].flightHighlights.withinPolicy;
                } else {
                    isPolicySetFlag = isPolicySetFlag && false;
                }
                emailLegs.push(eLeg);
                legIndex++;
            }

            let emailSearchV4DTO: EmailSearchV4DTO = new EmailSearchV4DTO();

            // let eventIdAndTypeMap: Map<string, string> = new Map<string,string>();
            let eventIdAndTypeMap = { '0': 'flight' };
            // eventIdAndTypeMap.set('0','flight');
            let eventList = new Array();
            eventList.push('0');
            emailSearchV4DTO.eventList = eventList;
            emailSearchV4DTO.eventIdAndTypeMap = deserialize(eventIdAndTypeMap, Map);
            let multiFlightOptions: Array<Array<Array<FlightLegEmailDTO>>> = new Array();
            let events = new Array();
            let options = new Array();
            // options.push(emailLegs);
            events.push(emailLegs);
            multiFlightOptions.push(events);
            emailSearchV4DTO.multiFlightOptions = multiFlightOptions;
            let flightSearchOptions = {
                "adultCount": searchQuery.travellers.adults,
                "childCount": searchQuery.travellers.children,
                "infantCount": searchQuery.travellers.infants,
                "seatClass": searchQuery.cabinClass,
                "algoType": searchQuery.algoType,
                "tripType": searchQuery.requests.length > 0 ? "MULTI_CITY" : 'ONE_WAY_OUTGOING',
                "combo": searchQuery.requests.length > 0 ? true : false,
            }
            emailSearchV4DTO.flightSearchOptions = deserialize(flightSearchOptions, FlightSearchOptions);
            emailSearchV4DTO.policySet = isPolicySetFlag;
            if(this.searchService.multiTripBooking && item){
                if (this.gallopLocalStorage.getItem("selectedHotel")) {
                    this.mockHotelSearchResultEventOptions(false);
                   
                    emailSearchV4DTO.hotelOptionsList = this.hotelOptionList.hotelOptionsList;
            let eventIdAndTypeMap1 = Object.assign(eventIdAndTypeMap, this.hotelOptionList.eventIdAndTypeMap);
            emailSearchV4DTO.eventIdAndTypeMap = deserialize(eventIdAndTypeMap1, Map);
            emailSearchV4DTO.eventList = [...this.caroptionList.eventList,...emailSearchV4DTO.eventList];
                } 
                if (this.gallopLocalStorage.getItem("selectedCar")) {
                   
                    this.mockCarSearchResultEventOptions(false);
            emailSearchV4DTO.carOptionsList = this.caroptionList.carOptionsList;
            let eventIdAndTypeMap1 = Object.assign(eventIdAndTypeMap, this.caroptionList.eventIdAndTypeMap);
            emailSearchV4DTO.eventIdAndTypeMap = deserialize(eventIdAndTypeMap1, Map);
            emailSearchV4DTO.eventList = [...this.caroptionList.eventList,...emailSearchV4DTO.eventList];
                }
            
            }
            if(item){
            this.searchResultOptionsSubject.next(emailSearchV4DTO);
            }else{
                this.flightoptionList = emailSearchV4DTO;
            }
        }
    }
}
