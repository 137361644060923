import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CommonUtils } from '../util/common-utils';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { UserAccountService } from '../user-account.service';
@Component({
  selector: 'ngx-new-modal',
  templateUrl: './ngx-new-modal.component.html',
  styleUrls: ['./ngx-new-modal.component.scss']
})
export class ngxNewModal implements OnInit {
  @Input() flightUrl = '';
  @Input() hotelUrl = '';
  @Input() selectOption = '';
  @Input() calUrls = {};
  value = '';
  bsModalRef: BsModalRef;
  njoySpecificBuild: boolean;
  constructor(public ngxSmartModalService: NgxSmartModalService,
    private modalService: BsModalService,
    private userAccountService: UserAccountService,) {
  }
  ngOnInit() {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
  }
  handleModalEvents(eventName: string, currentModalId: string) {
    CommonUtils.handleModalEvents(this.ngxSmartModalService, eventName, currentModalId);
  }
  onSmartModelCancel(modelName: string) {
    this.ngxSmartModalService.getModal(modelName).close();
  }
  proceedtoCalendar(string) {
    if (this.calUrls && this.calUrls[string]){
      if (this.njoySpecificBuild) {
        window.location.href = this.calUrls[string] + '&dummy=/TripItAPI/';
      } else {
        window.open(this.calUrls[string]);
      }
      return;
    }
    if (this.njoySpecificBuild) {
      if (this.selectOption === 'hotel') {
        if (string === 'apple') {
          window.location.href = 'https://' + this.hotelUrl + '&dummy=/TripItAPI/';
        } else if (string === 'outlook') {
          window.location.href = 'https://' + this.hotelUrl + '&dummy=/TripItAPI/';
        } else if (string === 'google') {
          window.location.href = 'https://calendar.google.com/calendar/r?cid=http://' + this.hotelUrl + '&dummy=/TripItAPI/';
        } else {
          window.location.href = 'https://calendar.yahoo.com/subscribe?name=Gallop&ics=http://' + this.hotelUrl + '&dummy=/TripItAPI/';
        }
      } else {
        if (string === 'apple') {
          window.location.href = 'https://' + this.flightUrl + '&dummy=/TripItAPI/';
        } else if (string === 'outlook') {
          window.location.href = 'https://' + this.flightUrl + '&dummy=/TripItAPI/';
        } else if (string === 'google') {
          window.location.href = 'https://calendar.google.com/calendar/r?cid=http://' + this.flightUrl + '&dummy=/TripItAPI/';
        } else {
          window.location.href = 'https://calendar.yahoo.com/subscribe?name=Gallop&ics=http://' + this.flightUrl + '&dummy=/TripItAPI/';
        }
      }
    } else {
      if (this.selectOption === 'hotel') {
        if (string === 'apple') {
          window.open(environment.calendarPrefix + this.hotelUrl);
        } else if (string === 'outlook') {
          window.open(environment.calendarPrefix + this.hotelUrl);
        } else if (string === 'google') {
          window.open('https://calendar.google.com/calendar/r?cid=http://' + this.hotelUrl);
        } else {
          window.open('https://calendar.yahoo.com/subscribe?name=Gallop&ics=http://' + this.hotelUrl);
        }
      } else {
        if (string === 'apple') {
          window.open(environment.calendarPrefix + this.flightUrl);
        } else if (string === 'outlook') {
          window.open(environment.calendarPrefix + this.flightUrl);
        } else if (string === 'google') {
          window.open('https://calendar.google.com/calendar/r?cid=http://' + this.flightUrl);
        } else {
          window.open('https://calendar.yahoo.com/subscribe?name=Gallop&ics=http://' + this.flightUrl);
        }
      }
    }
  }
}
