import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { UserAccountService } from './user-account.service';
import { deserialize } from './util/ta-json/src/methods/deserialize';
import { GallopHttpClient } from './shared/gallop-httpclient.service';
import { DateUtils } from './util/date-utils';
import { SearchActionType } from './enum/search-action.type';
import { Constants } from './util/constants';
import { GallopLocalStorageService } from './gallop-local-storage.service';
import { RevalidateRequest } from './entity/revalidate-request';
import { BookingService } from './booking.service';
import { SearchService } from './search.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class CarBookingService {
  carSearchDetail: any = {};
  carSearchDetail1: any = {};
  temp_filter_carType=[];
  temp_filter_carBrand=[];
  temp_filter_carLocationType:string;
  temp_filter_carSpecification=[];
  temp_filter_Location:string;
  temp_filter_Location1:string;
  noFilterRequired = 0;
  locationFound = 1;
  locationTypeFound = 1;
  dropOff = 'same';
  brandValue = 0;
  pickUpType = '';
  filterByName = '';
  carAppliedFilters = [];
  originalCarSearchResponse: any;
  selectedCar: any;
  carPolicy = false;
  constructor(private userAccountService: UserAccountService,
    private bookingService: BookingService,
    private translate: TranslateService,
    private searchService: SearchService,
    private http: GallopHttpClient, private gallopLocalStorage: GallopLocalStorageService) {
    let selectedCar = this.gallopLocalStorage.getItem("selectedCar");
    if (selectedCar) {
      this.selectedCar = JSON.parse(selectedCar);
      this.selectedCarSubject.next(selectedCar);
    }
  }
  public carResponseSubject = new BehaviorSubject<any>(null);
  carResponse$ = this.carResponseSubject.asObservable();

  private carRequestSubject = new BehaviorSubject<any>(null);
  carRequest$ = this.carRequestSubject.asObservable();
  private selectedCarSubject = new BehaviorSubject<any>(null);
  selectedCar$ = this.selectedCarSubject.asObservable();


  carSearchResponse: any;
  public lastBroadcastedSearchResponse: any;
  searchType: SearchActionType;


  setPreviousSearch(hotelSearchQueryParam) {
    this.carSearchDetail = hotelSearchQueryParam;
  }
  broadCastCarRequest(hotelSearchQueryParam) {
    this.carRequestSubject.next(hotelSearchQueryParam);
  }
  broadCastCarResponse(carSearchResponse) {
    this.carResponseSubject.next(carSearchResponse);
    this.lastBroadcastedSearchResponse = carSearchResponse;

  }
  setOriginalCarSearchResults(carSearchResponse): any {
    this.originalCarSearchResponse = carSearchResponse;
  }

  searchCar(hotelSearchQueryParam, searchActionType): Observable<any> {
    let searchRequest = this.getSearchRequest(hotelSearchQueryParam);
    this.setPreviousSearch(hotelSearchQueryParam);
    this.broadCastCarRequest(hotelSearchQueryParam);
    const userid = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    let queryParam: string = '';
    queryParam = queryParam + '&car-api=' + searchRequest.currentCarHandler;
    queryParam = queryParam + '&pickDate=' + searchRequest.pickUpDate;
    queryParam = queryParam + '&dropDate=' + searchRequest.dropOffDate;
    queryParam = queryParam + '&pickLocation=' + encodeURIComponent(searchRequest.pickUp);
    queryParam = queryParam + '&dropLocation=' + encodeURIComponent(searchRequest.dropOff);
    queryParam = queryParam + '&pick_lat=' + searchRequest.pickUpLat;
    queryParam = queryParam + '&pick_long=' + searchRequest.pickUpLng;
    queryParam = queryParam + '&drop_lat=' + searchRequest.dropOffLat;
    queryParam = queryParam + '&drop_long=' + searchRequest.dropOffLng;
    queryParam = queryParam + '&pickTime=' + searchRequest.pickTime;
    queryParam = queryParam + '&dropTime=' + searchRequest.dropTime;
    queryParam = queryParam + '&sameDropOff=' + searchRequest.sameAddress;
    queryParam = queryParam + '&pickupType=' + searchRequest.pickupType;
    queryParam = queryParam + '&dropOffType=' + searchRequest.dropOffType;
    queryParam = queryParam + '&travellerEmail=' + searchRequest.travellerEmail;
    this.pickUpType = searchRequest.pickupType;

    return this.http.get(environment.apiForCarSearch + '?'
      + 'action=' + searchActionType
      + queryParam
      + '&userid=' + userid + '&sToken=' + sToken);
  }
  getSearchRequest(hotelSearchQueryParam) {
    let searchRequest: any = {};
    searchRequest.currentCarHandler = 'TravelPort';
    searchRequest.pickUp = hotelSearchQueryParam.pickUp;
    searchRequest.dropOff = (hotelSearchQueryParam.dropOff && hotelSearchQueryParam.dropOff.trim().length > 0) ? hotelSearchQueryParam.dropOff : '';
    searchRequest.dropOffLat = hotelSearchQueryParam.dropOff === '' ? 0 : hotelSearchQueryParam.dropOffLat;
    searchRequest.dropOffLng = hotelSearchQueryParam.dropOff === '' ? 0 : hotelSearchQueryParam.dropOffLng;
    searchRequest.pickUpLat = hotelSearchQueryParam.pickUpLat;
    searchRequest.pickUpLng = hotelSearchQueryParam.pickUpLng;
    searchRequest.pickTime = hotelSearchQueryParam.pickTime;
    searchRequest.dropTime = hotelSearchQueryParam.dropTime;
    searchRequest.sameAddress = hotelSearchQueryParam.dropOff !== '' ? false : true;
    searchRequest.pickUpDate = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(hotelSearchQueryParam.pickUpDate, 'MM/dd/yyyy');
    searchRequest.dropOffDate = DateUtils.getFormattedDateWithoutTimeZoneFromFormat(hotelSearchQueryParam.dropOffDate, 'MM/dd/yyyy');
    searchRequest.pickupType = hotelSearchQueryParam.pickupType;
    searchRequest.dropOffType = hotelSearchQueryParam.dropOffType;
    if (hotelSearchQueryParam.travellerEmail) {
      searchRequest.travellerEmail = hotelSearchQueryParam.travellerEmail;
    }
    if (hotelSearchQueryParam.travellerEmail && hotelSearchQueryParam.travellerEmail.length > 0) {
      if (this.searchService.employeeEmail.length > 1 && this.searchService.employeeEmail[0].email !== hotelSearchQueryParam.travellerEmail) {
        this.searchService.employeeEmail = [];
        let employee1 = { Name: '', Id: -1, Type: '' };
        employee1['email'] = hotelSearchQueryParam.travellerEmail;
        this.searchService.employeeEmail.push(employee1)
      } else if (this.searchService.employeeEmail.length === 0) {
        this.searchService.employeeEmail = [];
        let employee1 = { Name: '', Id: -1, Type: '' };
        employee1['email'] = hotelSearchQueryParam.travellerEmail;
        this.searchService.employeeEmail.push(employee1)
      }
    }

    return searchRequest;
  }

  setCarSearchResponse(carSearchResponse, overrideOriginalSearchRes) {
    if (overrideOriginalSearchRes && carSearchResponse && (carSearchResponse &&
      carSearchResponse.length > 0)) {
      this.carSearchResponse = this.processCarSearchResults(carSearchResponse);
      this.setOriginalCarSearchResults(this.carSearchResponse);
      // this.gallopLocalStorage.setItem('airports',JSON.stringify(this.flightSearchResponse.airports));
      // this.gallopLocalStorage.setItem('airlineNames',JSON.stringify(this.flightSearchResponse.airlineNames));
      // this.sortResultsAsRecommended(this.carSearchResponse);
      // this.broadCastHotelResponse(hotelSearchResponse);
    } else {
      this.broadCastCarResponse(carSearchResponse);
    }
  }


  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  getCarType(carSearchResponse) {
    let carTypeArray = [{ Name: this.translate.instant('carresult.Any'), value: 'Any', Type: this.translate.instant('carresult.Any') }];
    let truckDone = false;
    let vanDone = false;
    let suvDone = false;
    for (let carItem of carSearchResponse) {
      let car = carItem.carType;
      let carType = { Name: '', value: '', Type: '' };
      carType.Name = car;
      carType.value = car;
      carType.Type =  this.translate.instant('carresult.CarOptions');
      carTypeArray.push(carType);
      // if (!vanDone && carItem.description.toLowerCase().indexOf(' van') !== -1){
      //   let carType={Name:'',value:'',Type:''};
      //   carType.Name=this.titleCaseWord('van');
      //   carType.value=this.titleCaseWord('van');;
      //   carType.Type='Car Options';
      //   carTypeArray.push(carType);
      //   vanDone = true;
      // }
      // if (!suvDone && carItem.description.toLowerCase().indexOf(' suv') !== -1){
      //   let carType={Name:'',value:'',Type:''};
      //   carType.Name=this.titleCaseWord('suv');
      //   carType.value=this.titleCaseWord('suv');;
      //   carType.Type='Car Options';
      //   carTypeArray.push(carType);
      //   suvDone = true;
      // }
    }
    carTypeArray = carTypeArray.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.Name === test.Name
      ));
    carTypeArray.sort((item1, item2) => {
      if (item1.value > item2.value) { return 1; }
      if (item1.value < item2.value) { return -1; }
      return 0;
    });

    return carTypeArray;
  }
  getCarAddressType(carSearchResponse) {
    let carTypeArray = [{ Name: this.translate.instant('carresult.Drop-offlocation'), value: 'All', Type: '' }];
    let carTypeArray1 = [{ Name: this.translate.instant('carresult.Drop-offlocation'), value: 'All', Type: '' }];
    carSearchResponse = this.sortResultsByDuration(carSearchResponse);
    for (let carItem of carSearchResponse) {
      let carType = { Name: '', value: '', Type: '' };
      let str = carItem.pickUpLocation.replace(/,/g, "");
      if (carItem.pickUpLocationType) {
        carType.Name = (carItem.pickUpLocationType + ',' + str + ',' + (CarBookingService.getNumeric(carItem.distance).toFixed(2)) + this.translate.instant('carresult.miles'));
        carType.value = carItem.pickUpLocation;
        carType.Type =  this.translate.instant('carresult.Pick-uplocation');
        carTypeArray.push(carType);
      } else {
        carType.Name = (str + ',' + (CarBookingService.getNumeric(carItem.distance).toFixed(2)) + this.translate.instant('carresult.miles'));
        carType.value = carItem.pickUpLocation;
        carType.Type = this.translate.instant('carresult.Pick-uplocation')
        carTypeArray.push(carType);
      }
      if (carItem.dropOffLocation !== 'Same as pick-up location' && carItem.dropOffLocation !== carItem.pickUpLocation) {
        let str1 = carItem.dropOffLocation.replace(/,/g, "");
        if (carItem.dropLocationType) {
          this.dropOff = this.translate.instant('carresult.notsame');
          let carType1 = { Name: '', value: '', Type: '' };
          carType1.Name = (carItem.dropLocationType + ',' + str1 + ',' + (CarBookingService.getNumeric(carItem.dropDistance).toFixed(2)) + this.translate.instant('carresult.miles'));
          carType1.value = carItem.dropOffLocation;
          carType1.Type = this.translate.instant('carresult.Drop-offlocation');
          carTypeArray.push(carType1);
        } else {
          this.dropOff = this.translate.instant('carresult.notsame');
          let carType1 = { Name: '', value: '', Type: '' };
          carType1.Name = (str1 + ',' + (CarBookingService.getNumeric(carItem.dropDistance).toFixed(2)) + this.translate.instant('carresult.miles'));
          carType1.value = carItem.dropOffLocation;
          carType1.Type =this.translate.instant('carresult.Drop-offlocation');
          carTypeArray.push(carType1);
        }
      }
    }
    carTypeArray = carTypeArray.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.Name.toLowerCase() === test.Name.toLowerCase()
      )
    );
    if (carTypeArray.length >= 1) {
      this.locationFound = 1;
    }
    return carTypeArray;
  }

  getCarLocationType(carSearchResponse) {
    let carTypeArray = [{ Name: this.translate.instant('carresult.Any'), value: 'Any', Type: '' }];
    let carTypeArray1 = [];
    for (let carItem of carSearchResponse) {
      let carType = { Name: this.translate.instant('carresult.Any'), value: 'Any', Type: '' };
      if (carItem.pickUpLocationType) {
        if (carItem.pickUpLocationType === ('Airport') && carItem.locationInformation === 'Terminal') {
          carType.Name = this.translate.instant('carresult.Airportlocationsatterminal');
          carType.value = 'Pick-up location' + "," + carItem.pickUpLocationType + "," + carItem.locationInformation;
          carType.Type = this.translate.instant('carresult.Pick-uplocation')
          carTypeArray.push(carType);
        } else if ((carItem.pickUpLocationType === ('Airport' || 'Shuttle')) && carItem.locationInformation === 'Shuttle') {
          carType.Name = this.translate.instant('carresult.Airportlocationsoutsideairport');
          carType.value ='Pick-up location' + "," + carItem.pickUpLocationType + "," + carItem.locationInformation;
          carType.Type = this.translate.instant('carresult.Pick-uplocation')
          carTypeArray.push(carType);
        } else if (carItem.pickUpLocationType === 'Railway Station') {
          carType.Name = this.translate.instant('carresult.Trainstationlocations');
          carType.value = 'Pick-up location' + "," + carItem.pickUpLocationType;
          carType.Type = this.translate.instant('carresult.Pick-uplocation')
          carTypeArray.push(carType);
        } else if (carItem.pickUpLocationType === 'Port Or Ferry') {
          carType.Name = this.translate.instant('carresult.Portlocations');
          carType.value = 'Pick-up location' + "," + carItem.pickUpLocationType;
          carType.Type = this.translate.instant('carresult.Pick-uplocation')
          carTypeArray.push(carType);
        } else if (carItem.pickUpLocationType === 'City Location') {
          carType.Name = this.translate.instant('carresult.Citylocations');
          carType.value = 'Pick-up location' + "," + carItem.pickUpLocationType;
          carType.Type = this.translate.instant('carresult.Pick-uplocation')
          carTypeArray.push(carType);
        }
      }
      if (carItem.dropOffLocation !== 'Same as pick-up location' && carItem.dropOffLocation !== carItem.pickUpLocation) {
        if (carItem.dropLocationType) {
          this.dropOff = this.translate.instant('carresult.notsame');
          let carType1 = { Name: '', value: '', Type: '' };
          if ((carItem.dropLocationType === ('Airport' || 'Shuttle'))) {
            carType1.Name = this.translate.instant('carresult.Airportlocations');;
            carType1.value = 'Drop-off location' + "," + carItem.dropLocationType;
            carType1.Type = this.translate.instant('carresult.Drop-offlocation');
            carTypeArray1.push(carType1);
          } else if (carItem.dropLocationType === 'Railway Station') {
            carType1.Name = this.translate.instant('carresult.Trainstationlocations');
            carType1.value = 'Drop-off location' + "," + carItem.dropLocationType;
            carType1.Type = this.translate.instant('carresult.Drop-offlocation');
            carTypeArray1.push(carType1);
          } else if (carItem.dropLocationType === 'Port Or Ferry') {
            carType1.Name =this.translate.instant('carresult.Portlocations');
            carType1.value ='Drop-off location' + "," + carItem.dropLocationType;
            carType1.Type = this.translate.instant('carresult.Drop-offlocation');
            carTypeArray1.push(carType1);
          } else if (carItem.dropLocationType === 'City Location') {
            carType1.Name = this.translate.instant('carresult.Citylocations');
            carType1.value = 'Drop-off location' + "," + carItem.dropLocationType;
            carType1.Type = this.translate.instant('carresult.Drop-offlocation');
            carTypeArray1.push(carType1);
          }
        }
      }
    }
    carTypeArray = carTypeArray.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.Name === test.Name
      ));
    carTypeArray1 = carTypeArray1.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.Name === test.Name
      ));
    if (carTypeArray1.length > 0) {
      for (let carItem of carTypeArray1) {
        carTypeArray.push(carItem);
      }
    }

    if (carTypeArray.length > 1) {
      this.locationTypeFound = 1;
    }

    return carTypeArray;
  }
  getCarBrand(carSearchResponse) {
    let carTypeArray = [{ value: "Any", label: this.translate.instant('carresult.Any'), Type: this.translate.instant('carresult.Any') }];
    for (let carItem of carSearchResponse) {
      let car = carItem.partnerName.split(' ')[0];
      let carType = { value: '', label: '', Type: '' };
      carType.label = this.titleCaseWord(car);
      carType.value = this.titleCaseWord(car);;
      carType.Type =  this.translate.instant('carresult.BrandType');
      carTypeArray.push(carType);
    }
    carTypeArray = carTypeArray.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.label === test.label
      )
    );
    if (carTypeArray.length === 2) {
      this.brandValue = 1;
    }
    carTypeArray = carTypeArray.sort(function (a, b) {
      if (a.value === 'Any') {
        return -100000;
      }
      if (b.value === 'Any') {
        return 100000;
      }
      return a.value.localeCompare(b.value);
    })
    return carTypeArray;
  }
  getCarSpecification(carSearchResponse) {
    let carTypeArray = [{ Name: this.translate.instant('carresult.Any'), value: 'Any', Type: this.translate.instant('carresult.Any') }];
    for (let carItem of carSearchResponse) {
      let carType = { Name: '', value: '', Type: '' };
      if (carItem.automaticTransmission && carItem.airConditioning) {
        carTypeArray.push({ Name:  this.translate.instant('carresult.AutomaticTransmission'), value: 'Automatic Transmission', Type: this.translate.instant('carresult.Specification') });
        carTypeArray.push({ Name:  this.translate.instant('carresult.AirConditioning'), value: 'Air Conditioning', Type: this.translate.instant('carresult.Specification') });
      } else if (carItem.automaticTransmission) {
        carType.Name =  this.translate.instant('carresult.AutomaticTransmission');
        carType.value = 'Automatic Transmission';
        carType.Type =  this.translate.instant('carresult.Specification');
        this.noFilterRequired = this.noFilterRequired + 1;
        carTypeArray.push(carType);
      } else if (carItem.airConditioning) {
        carType.Name =  this.translate.instant('carresult.AirConditioning');
        carType.value = 'Air Conditioning';
        carType.Type =  this.translate.instant('carresult.Specification');
        this.noFilterRequired = this.noFilterRequired + 1;
        carTypeArray.push(carType);
      }
    }
    carTypeArray = carTypeArray.filter((test, index, array) =>
      index === array.findIndex((findTest) =>
        findTest.value === test.value
      )
    );
    return carTypeArray;
  }
  processCarSearchResults(carSearchResponse) {
    let index = 0;
    for (let carResultItem of carSearchResponse) {
      carResultItem.recommendedIdx = index;
      index++;
    }
    return carSearchResponse;
  }

  filterByCarType(carType: Array<string>, carSearchResponse) {

    let carSearchRes = carSearchResponse;

    if (carType && carType.length > 0
      && carSearchRes) {

      carSearchRes = JSON.parse(JSON.stringify(carSearchResponse));
      carSearchRes = carSearchRes.filter(car => {
        for (let code of carType) {
          if (carType.indexOf('Any') > -1) {
            return true;
          } else {
            if (car.carType === code) {
              return true;
            }
          }
        }
        return false;
      });
    }
    return carSearchRes;
  }
  filterByCarSpecification(carType: Array<string>, carSearchResponse) {
    let carSearchRes = carSearchResponse;

    if (carType && carType.length > 0
      && carSearchRes) {

      carSearchRes = JSON.parse(JSON.stringify(carSearchResponse));
      carSearchRes = carSearchRes.filter(car => {
        let carType1 = car.description.split(' ')[0];
        for (let code of carType) {
          if (carType.indexOf('Any') > -1) {
            return true;
          } else {
            if (code === 'Air Conditioning') {
              return (car.airConditioning === true);
            }
            if (code === 'Automatic Transmission') {
              return (car.automaticTransmission === true);
            }
          }
        }
        return false;
      });
    }
    return carSearchRes;

  }
  filterCarByName(searchText: string, carSearchResponse) {

    let carSearchRes = carSearchResponse;

    if (carSearchRes && carSearchRes.length > 0) {

      carSearchRes = JSON.parse(JSON.stringify(carSearchResponse));
      carSearchRes = carSearchRes.filter(car => {
        if (this.dropOff === 'notsame') {
          if (car.pickUpLocation.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 || car.dropOffLocation.toUpperCase().indexOf(searchText.toUpperCase()) >= 0) return true;
          else return false;
        } else {
          if (car.pickUpLocation.toUpperCase().indexOf(searchText.toUpperCase()) >= 0) return true;
          else return false;
        }
      });
    }
    return carSearchRes;
  }
  filterByCarLocation(carType: string, carSearchResponse) {
    let carSearchRes = carSearchResponse;

    if (carType && carType.length > 0
      && carSearchRes) {

      carSearchRes = JSON.parse(JSON.stringify(carSearchResponse));
      carSearchRes = carSearchRes.filter(car => {
        var carType1 = car.pickUpLocation;
        var carType2;
        if (car.dropOffLocation !== 'Same as pick-up location') {
          carType2 = car.dropOffLocation;
        }
        if (carType === 'All') {
          return true;
        } else if (carType1 === carType) {
          return true;
        } else if (carType2 && carType2 === carType) {
          return true;
        }
      });
    }
    return carSearchRes;
  }
  filterByCarBrand(carType: Array<string>, carSearchResponse) {

    let carSearchRes = carSearchResponse;

    if (carType && carType.length > 0
      && carSearchRes) {

      carSearchRes = JSON.parse(JSON.stringify(carSearchResponse));
      carSearchRes = carSearchRes.filter(car => {
        let carType1 = car.partnerName.split(' ')[0];
        carType1 = this.titleCaseWord(carType1);
        for (let code of carType) {
          if (carType.indexOf('Any') > -1) {
            return true;
          } else {
            if (carType1 === code) {
              return true;
            }
          }
        }
        return false;
      });
    }
    return carSearchRes;
  }
  filterByCarLcationType(carType: string, carSearchResponse) {
    //let temp1=true;
    //let temp2=true;
    //for(let code of carType){
    //if(code.search('Pick-up location')!== -1){
    //   temp1=false;
    //} 
    //if(code.search('Drop-off location')!== -1){
    //temp2=false;
    //} 
    //}
    let carSearchRes = carSearchResponse;

    if (carType && carType.length > 0
      && carSearchRes) {
      carSearchRes = carSearchRes.filter(car => {
        var carType1 = car.pickUpLocationType;
        var locationType1 = car.locationInformation;
        var carType2 = car.dropLocationType;
        let code1;
        let code2;
        if (carType.toLowerCase().search(',') !== -1) {
          code1 = carType.split(",", 2);
        }
        if (code1 && (code1[1] === 'Airport' || code1[1] === 'Shuttle')) {
          code2 = carType.split(",", 3);
        }
        if (carType === 'Any') {
          return true;
        } else if (code1[0] == 'Pick-up location') {
          if (code1[1] === carType1) {
            if (code2 && code2.length === 3 && code2[2] === locationType1) {
              return true;
            } else if (!code2) {
              return true;
            }
          }
        } else if (code1[0] == 'Drop-off location') {
          if (carType2 && code1[1] === carType2) {
            return true;
          }
        }
      });
    }
    // carSearchRes = JSON.parse(JSON.stringify(carSearchResponse));
    //if(!temp1 && temp2){
    //carSearchRes = this.filterByPickUp(carSearchRes,carType);
    //}else if(temp1 && !temp2){
    //carSearchRes = this.filterByDropOff(carSearchRes,carType);
    //}else if(!temp1 && !temp2){
    //carSearchRes = this.filterByPickUp(carSearchRes,carType);
    //carSearchRes = this.filterByDropOff(carSearchRes,carType);
    //}
    return carSearchRes;
  }
  filterByDropOff(carSearchRes, carType) {
    carSearchRes = carSearchRes.filter(car => {
      let carType1 = car.dropLocationType;
      for (let code of carType) {
        let code1;
        if (code.toLowerCase().search(',') !== -1) {
          code1 = code.split(",", 2);
        }
        if (carType.indexOf('Any') > -1) {
          return true;
        } else if (code1[0] === 'Drop-off location') {
          if (carType1 === code1[1]) {
            return true;
          }
        }
      }
      return false;
    });
    return carSearchRes;
  }
  filterByPickUp(carSearchRes, carType) {
    carSearchRes = carSearchRes.filter(car => {
      let carType1 = car.pickUpLocationType;
      for (let code of carType) {
        let code1;
        if (code.toLowerCase().search(',') !== -1) {
          code1 = code.split(",", 2);
        }
        if (carType.indexOf('Any') > -1) {
          return true;
        } else if (code1[0] === 'Pick-up location') {
          if (carType1 === code1[1]) {
            return true;
          }
        }
      }
      return false;
    });
    return carSearchRes;
  }
  currentSortOptionId = 'distance';
  public applySorting(carSearchResponse) {
    if (this.pickUpType === 'AIRPORT') {
      this.currentSortOptionId = 'price';

    }
    setTimeout(() => {
      if (this.currentSortOptionId === 'price') {
        carSearchResponse = this.sortResultsByPrice(carSearchResponse);
      } else if (this.currentSortOptionId === 'distance') {
        carSearchResponse = this.sortResultsByDuration(carSearchResponse);
      } else {
        this.setCarSearchResponse(carSearchResponse, false);
      }
      this.setCarSearchResponse(carSearchResponse, false);
    }, 200);

  }
  sortResultsByPrice(carSearchResponse) {
    carSearchResponse = carSearchResponse.sort(function (a, b) {
      let aPrice = a.price;
      let bPrice = b.price;
      if (aPrice < bPrice) return -1;
      else if (aPrice > bPrice) return 1;
      else return 0;
    });
    return carSearchResponse;
  }
  static getNumeric(distanceString) {
    try {
      return parseFloat(distanceString);
    } catch (err) {
      return 0;
    }
  }
  sortResultsByDuration(carSearchResponse) {
    carSearchResponse = carSearchResponse.sort(function (a, b) {

      if (CarBookingService.getNumeric(a.distance) < CarBookingService.getNumeric(b.distance)) {
        return -1;
      } else if (CarBookingService.getNumeric(a.distance) > CarBookingService.getNumeric(b.distance)) {
        return 1;
      } else {
        return 0;
      }
    });
    return carSearchResponse;
  }
  revalidateCar(ticketId, tripId, eventId, OptionId, threadId, car): Observable<any> {
    let revaldiateRequest = this.getRevalidateRequest(ticketId, tripId, eventId, OptionId, threadId, car);
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken)
      // .set('revalidateTestCase',"PRICE_CHANGED")
      ;
    return this.http.post(environment.apiForCarRevalidation, revaldiateRequest, { params });
  }
  bookCar(includeExpensifyDetails, paymentMethod, details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, bookingStatusId, selectedTag?): Observable<any> {
    let bookingRequest = this.getCarBookingRequest(details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, selectedTag);

    if (includeExpensifyDetails) {
      let expensifyDetails = JSON.parse(this.gallopLocalStorage.getItem("expensifyDetails"));
      bookingRequest.expensifyId = expensifyDetails && expensifyDetails.expensifyEmail && expensifyDetails.expensifyEmail.trim().length > 0 ?
        expensifyDetails.expensifyEmail : undefined;
      bookingRequest.addToExpensify = expensifyDetails && expensifyDetails.addToExpensify ? expensifyDetails.addToExpensify : false;
      let expenseDetails = this.gallopLocalStorage.getItem("expenseDetails");
      if (bookingRequest.expensifyId) {
        bookingRequest.expenseProvider = expenseDetails;
      }
    } else {
      bookingRequest.addToExpensify = false;
    }
    if (paymentMethod) {
      bookingRequest.paymentDetails.paymentMethod = paymentMethod;
    } else {
      bookingRequest.paymentDetails = null;
    }
    const userId = this.userAccountService.getUserEmail();
    const sToken = this.userAccountService.getSToken();
    const params = new HttpParams()
      .set('userid', userId)
      .set('sToken', sToken)
      .set('bookingStatusId', bookingStatusId)
      ;
    // if (bookingRequest.flightsToBook.flights[0].currency !== 'USD') {
    //   params = params.set('revalidateTestCase','HANDOVER_TO_HUMAN');
    // }
    return this.http.post(environment.apiForCarBooking, bookingRequest, { params });
  }
  getCarBookingRequest(details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, selectedTag) {

    var bookingRequest: any = {};

    bookingRequest.travellerDetails = this.bookingService.getTravelerDetails('WebSearch');
    bookingRequest.paymentDetails = this.bookingService.getPaymentDetails(details, cardType, gallopCash);
    if (selectedTag !== '') {
      bookingRequest.projectTagId = selectedTag.toString();
    }
    let carsList: any[] = [];
    let selectedCarDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedCarForBooking"));
    for (let car of selectedCarDetailsObj)
      carsList.push(car);

    // let flights = {'flights': flightsList};
    if(this.searchService.displayPrice){
      bookingRequest.displayPrice = this.searchService.displayPrice;
      bookingRequest.displayCurrency = this.searchService.displayCurrency;
    }
    bookingRequest.eventIdAndOptionMap = eventIdAndOptions;
    bookingRequest.carsToBook = carsList;
    bookingRequest.tripId = tripId;
    bookingRequest.ticketId = ticketId;
    bookingRequest.searchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("carSearchRequestForBooking")));
    // if(this.gallopLocalStorage.getItem("hotelBookingResponse") && this.gallopLocalStorage.getItem("hotelBookingResponse")!=null){
    // let bookingRespose : HotelBookingResponse = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelBookingResponse")));
    //this.bookingRequest.lastTransactionId = bookingRespose.transactionId;
    //this.bookingRequest.ticketId = bookingRespose.ticketId;
    //this.bookingRequest.tripId = bookingRespose.tripId;
    //this.bookingRequest.eventIdAndOptionMap = bookingRespose.eventIdAndOptions;
    //}
    return bookingRequest;
  }

  buildCarBookingRequest(paymentMethod,details, cardType, gallopCash, ticketId, tripId, eventIdAndOptions, selectedTag,carIndex) {

    var bookingRequest: any = {};

    //bookingRequest.travellerDetails = this.bookingService.getTravelerDetails('WebSearch');
    bookingRequest.paymentDetails = this.bookingService.getPaymentDetails(details, cardType, gallopCash);
    if (selectedTag !== '') {
      bookingRequest.projectTagId = selectedTag.toString();
    }
    let carsList: any[] = [];
    let selectedCarDetailsObj: any = JSON.parse(this.gallopLocalStorage.getItem("selectedCar"))[carIndex];
    // for (let car of selectedCarDetailsObj)
      carsList.push(selectedCarDetailsObj);

    // let flights = {'flights': flightsList};
    if(this.searchService.displayPrice){
      bookingRequest.displayPrice = this.searchService.displayPrice;
      bookingRequest.displayCurrency = this.searchService.displayCurrency;
    }
    bookingRequest.eventIdAndOptionMap = eventIdAndOptions;
    bookingRequest.carsToBook = carsList;
    bookingRequest.tripId = tripId;
    bookingRequest.ticketId = ticketId;
    bookingRequest.searchQuery  = this.getSearchRequest(this.searchService.multicarQuery[carIndex]);
    if (paymentMethod) {
      bookingRequest.paymentDetails.paymentMethod = paymentMethod;
    } 
    // bookingRequest.searchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("carSearchRequestForBooking")));
    // if(this.gallopLocalStorage.getItem("hotelBookingResponse") && this.gallopLocalStorage.getItem("hotelBookingResponse")!=null){
    // let bookingRespose : HotelBookingResponse = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelBookingResponse")));
    //this.bookingRequest.lastTransactionId = bookingRespose.transactionId;
    //this.bookingRequest.ticketId = bookingRespose.ticketId;
    //this.bookingRequest.tripId = bookingRespose.tripId;
    //this.bookingRequest.eventIdAndOptionMap = bookingRespose.eventIdAndOptions;
    //}
    return bookingRequest;
  }

  getRevalidateRequest(ticketId, tripId, eventId, optionId, threadId, car) {

    let revalidateRequest: any = {}
    let cars = car
    let carsList = [];
    carsList.push(cars);
    revalidateRequest.carsToBook = carsList;
    revalidateRequest.tripId = tripId;
    revalidateRequest.ticketId = ticketId;
    revalidateRequest.eventId = eventId;
    revalidateRequest.optionId = optionId;
    revalidateRequest.threadId = threadId;
    revalidateRequest.searchQuery = deserialize(JSON.parse(this.gallopLocalStorage.getItem("carSearchRequestForBooking")));
    return revalidateRequest;
  }

}
