import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { SearchResultService } from '../search-result.service';
import { SearchService } from '../search.service';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { Constants } from '../util/constants';
import { FlightUtils } from '../util/flight-utils';
import { FlightResult } from '../entity/flight-result';
import { IntervalType } from '../enum/interval.type';
import { DateUtils } from '../util/date-utils';
import { CommonUtils } from '../util/common-utils';
import { FlightLegResult } from '../entity/flight-leg-result';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { TranslateService } from "@ngx-translate/core";
import { BaggageDetails } from '../entity/baggage-details';
import { FlightSearchRequest } from '../entity/flight-search-request';
import { UserAccountService } from '../user-account.service';
import { DeviceDetailsService } from '../device-details.service';
import { Subscription } from 'rxjs';
declare var getWindowWidth: any;

@Component({
  selector: 'flight-selected',
  templateUrl: './flight-selected.component.html',
  styleUrls: ['./flight-selected.component.scss']
})
export class flightSelected implements OnInit {

  // @ViewChild('searchModal') modal: ModalDirective;
  // constructor(
  //   private modalService: BsModalService) { }

  //   public ngOnInit(): void {

  // }

  // showFlightDetailModal(flightDetail){
  //     this.modalService.show(flightDetail, {class: 'modalSearchFlightInfo'});
  // }


  // }
  showMuticity = false;
  cabinClasses;
  @Input() index: number;
  @Input() noOfPassengers: number;
  @Input() flight: FlightResult;
  
  constructor(private searchResultService: SearchResultService,
    private gallopLocalStorage: GallopLocalStorageService,
    public searchService: SearchService,
    public userAccountInfoService: UserAccountService,
    private deviceDetailsService: DeviceDetailsService,
    public translateService: TranslateService,
    private modalService: BsModalService) { }
  @Output() deSelectEmitter = new EventEmitter();


  noOfFlightLegs: number[] = [0];
  creditDetails: any;
  flightLayoverInfoList: any[] = [];
  deviceSubscription: Subscription;
  isMobile: boolean;
  classOptions = Constants.CLASS_OPTIONS;
  airlines;
  airports;
  bsModalRef: BsModalRef;
  ngOnInit() {
  
    let searchRequest: FlightSearchRequest = this.searchService.getSearchRequest(this.searchService.getPreviousSearch());
    console.log(searchRequest)
    this.deviceSubscription = this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.cabinClasses = JSON.parse(this.gallopLocalStorage.getItem('cabinClassNames'));
    if (searchRequest.tripType === 'MULTICITY') {
      this.showMuticity = true;
    } else {
      this.showMuticity = false;
    }
    this.airlines = JSON.parse(this.gallopLocalStorage.getItem('airlineNames'));
    this.airports = JSON.parse(this.gallopLocalStorage.getItem('airports'));
    var selectedFlight = this.searchService.getOutgoingSelectedFlight();
    //this.flight = selectedFlight;
    this.flightLayoverInfoList = FlightUtils.getLegWiseLayoverList(this.flight);
    // 
  }

  ngOnChanges() {
    this.flightLayoverInfoList = FlightUtils.getLegWiseLayoverList(this.flight);
    // 
  }

  onCancel() {
    this.bsModalRef.hide();
  }
  setFlightLegs(count) {
    // this.noOfFlightLegs = Array(count||1).fill(1).map((x, i)=>i)
  }

  onSelect() {
    // this.bookRequest.emit({'flight':this.flight,'index':this.index,'type':this.type});    
  }
  getPrice() {
    let upsellPriceDiff = 0;
    if(!this.searchService.nonCombo && this.userAccountInfoService.typeOfBooking === 'BOOK_FOR_SELF'){
      var creditDetails = CommonUtils.getCreditValue(this.flight, this.noOfPassengers);
      this.creditDetails = creditDetails;
    }
    else if (this.searchService.nonCombo && this.userAccountInfoService.typeOfBooking === 'BOOK_FOR_SELF' && this.searchService.seletedIndex ===1) {
      var creditDetails = CommonUtils.getCreditValue(this.flight, this.noOfPassengers);
      this.creditDetails = creditDetails;
     // console.log("travel credits seelcted flight", this.creditDetails);
    }else if(this.searchService.nonCombo && this.searchService.seletedIndex >0){
      var creditDetails11 = CommonUtils.getCreditValue(this.searchService.nonComboSelectedFlight[0], this.noOfPassengers);
      if(creditDetails11 && creditDetails11.length >0){
      let price = CommonUtils.getPriceAfterTravelCredits(this.searchService.nonComboSelectedFlight[0].price, creditDetails11, this.noOfPassengers);
      //if(price===0){
        this.creditDetails = creditDetails11;
     // }
    }
    }
    if (this.searchService.brandedFareCheckBox
      && this.searchService.getOutgoingSelectedFlight().legs[0].brandDetails
      && this.searchService.getOutgoingSelectedFlight().legs[0].brandDetails.length > 0) {
      let len = this.searchService.getOutgoingSelectedFlight().legs[0].brandDetails.length;
      upsellPriceDiff = this.searchService.getOutgoingSelectedFlight().legs[len - 1].brandDetails[0].priceDiff;
    }
    if(this.flight.displayPrice){
      if(this.searchService.nonCombo && this.searchService.seletedIndex >1){
        if(this.creditDetails && this.creditDetails.length > 0){
        if(this.searchService.nonComboSelectedFlight[0].legs[0].flightHops[0].carrier === this.flight.legs[0].flightHops[0].carrier){
          let price=this.searchService.nonComboSelectedFlight[0].displayPrice;
        let price1=0
       let carrier = this.searchService.nonComboSelectedFlight[0].legs[0].flightHops[0].carrier;
       for(let i=1;i< this.searchService.nonComboSelectedFlight.length;i++){
        if(carrier === this.searchService.nonComboSelectedFlight[i].legs[0].flightHops[0].carrier){
        price += this.searchService.nonComboSelectedFlight[i].displayPrice; 
        }else{
          price1 += this.searchService.nonComboSelectedFlight[i].displayPrice; 
        }
     }
       price = CommonUtils.getPriceAfterTravelCredits(price, this.creditDetails, this.noOfPassengers);
        return (price +price1);
        }else{
          let price=this.searchService.nonComboSelectedFlight[0].displayPrice;
          let price1=0
       let carrier = this.searchService.nonComboSelectedFlight[0].legs[0].flightHops[0].carrier;
       for(let i=1;i< this.searchService.nonComboSelectedFlight.length;i++){
        if(carrier === this.searchService.nonComboSelectedFlight[i].legs[0].flightHops[0].carrier){
        price += this.searchService.nonComboSelectedFlight[i].displayPrice; 
        }else{
          price1 += this.searchService.nonComboSelectedFlight[i].displayPrice; 
        }
     }
       price = CommonUtils.getPriceAfterTravelCredits(price, this.creditDetails, this.noOfPassengers);
        return (price +price1);
        }
        }else{
          let price=0;
          for(let flight of this.searchService.nonComboSelectedFlight){
             price += flight.displayPrice; 
          }
       return (price);
        }
      }else if (this.creditDetails && this.creditDetails.length > 0) {
        if (this.flight.discountedPrice) {
          return CommonUtils.getPriceAfterTravelCredits(this.flight.discountedPrice, this.creditDetails, this.noOfPassengers)
  
  
        } else {
          let price = (this.flight.displayPrice + upsellPriceDiff);
          return CommonUtils.getPriceAfterTravelCredits(price, this.creditDetails, this.noOfPassengers)
        }
  
      } else {
        if (this.flight.discountedPrice) {
          if (creditDetails && ((this.flight.fareBreakup.discountedPrice + upsellPriceDiff) > creditDetails.creditAmount)) {
            return (this.flight.fareBreakup.discountedPrice + upsellPriceDiff - creditDetails.creditAmount)
          } else if (creditDetails && ((this.flight.fareBreakup.discountedPrice + upsellPriceDiff) <= creditDetails.creditAmount)) {
            return (0);
          } else {
            return this.flight.fareBreakup.discountedPrice + upsellPriceDiff;
          }
        } else {
          if (creditDetails && ((this.flight.displayPrice + upsellPriceDiff) > creditDetails.creditAmount)) {
            return (this.flight.displayPrice + upsellPriceDiff - creditDetails.creditAmount)
          } else if (creditDetails && ((this.flight.displayPrice + upsellPriceDiff) <= creditDetails.creditAmount)) {
            return (0);
          } else {
            return this.flight.displayPrice + upsellPriceDiff;
          }
        }
      } 
    }
    else{
    if(this.searchService.nonCombo && this.searchService.seletedIndex >1){
      if(this.creditDetails && this.creditDetails.length > 0){
      if(this.searchService.nonComboSelectedFlight[0].legs[0].flightHops[0].carrier === this.flight.legs[0].flightHops[0].carrier){
        let price=this.searchService.nonComboSelectedFlight[0].price;
        let price1=0
       let carrier = this.searchService.nonComboSelectedFlight[0].legs[0].flightHops[0].carrier;
       for(let i=1;i< this.searchService.nonComboSelectedFlight.length;i++){
        if(carrier === this.searchService.nonComboSelectedFlight[i].legs[0].flightHops[0].carrier){
        price += this.searchService.nonComboSelectedFlight[i].price; 
        }else{
          price1 += this.searchService.nonComboSelectedFlight[i].price; 
        }
     }
       price = CommonUtils.getPriceAfterTravelCredits(price, this.creditDetails, this.noOfPassengers);
        return (price +price1);
      }else{
        let price=this.searchService.nonComboSelectedFlight[0].price;
        let price1=0
       let carrier = this.searchService.nonComboSelectedFlight[0].legs[0].flightHops[0].carrier;
       for(let i=1;i< this.searchService.nonComboSelectedFlight.length;i++){
        if(carrier === this.searchService.nonComboSelectedFlight[i].legs[0].flightHops[0].carrier){
        price += this.searchService.nonComboSelectedFlight[i].price; 
        }else{
          price1 += this.searchService.nonComboSelectedFlight[i].price; 
        }
     }
       price = CommonUtils.getPriceAfterTravelCredits(price, this.creditDetails, this.noOfPassengers);
        return (price +price1);
      }
      }else{
        let price=0;
        for(let flight of this.searchService.nonComboSelectedFlight){
           price += flight.price; 
        }
     return (price);
      }
    }else if (this.creditDetails && this.creditDetails.length > 0) {
      if (this.flight.discountedPrice) {
        return CommonUtils.getPriceAfterTravelCredits(this.flight.discountedPrice, this.creditDetails, this.noOfPassengers)


      } else {
        let price = (this.flight.fareBreakup.totalPrice + upsellPriceDiff);
        return CommonUtils.getPriceAfterTravelCredits(price, this.creditDetails, this.noOfPassengers)
      }

    } else {
      if (this.flight.discountedPrice) {
        if (creditDetails && ((this.flight.fareBreakup.discountedPrice + upsellPriceDiff) > creditDetails.creditAmount)) {
          return (this.flight.fareBreakup.discountedPrice + upsellPriceDiff - creditDetails.creditAmount)
        } else if (creditDetails && ((this.flight.fareBreakup.discountedPrice + upsellPriceDiff) <= creditDetails.creditAmount)) {
          return (0);
        } else {
          return this.flight.fareBreakup.discountedPrice + upsellPriceDiff;
        }
      } else {
        if (creditDetails && ((this.flight.fareBreakup.totalPrice + upsellPriceDiff) > creditDetails.creditAmount)) {
          return (this.flight.fareBreakup.totalPrice + upsellPriceDiff - creditDetails.creditAmount)
        } else if (creditDetails && ((this.flight.fareBreakup.totalPrice + upsellPriceDiff) <= creditDetails.creditAmount)) {
          return (0);
        } else {
          return this.flight.fareBreakup.totalPrice + upsellPriceDiff;
        }
      }
    }
  }
  }
  getPrice1() {
    let upsellPriceDiff = 0;
    if (this.searchService.brandedFareCheckBox
      && this.searchService.getOutgoingSelectedFlight().legs[0].brandDetails
      && this.searchService.getOutgoingSelectedFlight().legs[0].brandDetails.length > 0) {
      let len = this.searchService.getOutgoingSelectedFlight().legs[0].brandDetails.length;
      upsellPriceDiff = this.searchService.getOutgoingSelectedFlight().legs[len - 1].brandDetails[0].priceDiff;
    }
    if(this.flight.displayPrice){
      if(this.searchService.nonCombo && this.searchService.seletedIndex >1){
        if(this.creditDetails && this.creditDetails.length > 0){
          let price=0
          for(let flight of this.searchService.nonComboSelectedFlight){
             price += flight.displayPrice; 
          }
       return (price);
        }
      }else if (this.flight.discountedPrice) {
        return this.flight.fareBreakup.discountedPrice + upsellPriceDiff;
      } else {
        return this.flight.displayPrice + upsellPriceDiff;
      }
    }
    else{
    if(this.searchService.nonCombo && this.searchService.seletedIndex >1){
      if(this.creditDetails && this.creditDetails.length > 0){
        let price=0
        for(let flight of this.searchService.nonComboSelectedFlight){
           price += flight.price; 
        }
     return (price);
      }
    }else if (this.flight.discountedPrice) {
      return this.flight.fareBreakup.discountedPrice + upsellPriceDiff;
    } else {
      return this.flight.fareBreakup.totalPrice + upsellPriceDiff;
    }
  }
  }

  showFlightDetailModal(flightDetail) {
    this.bsModalRef = this.modalService.show(flightDetail, { class: 'modalSearchFlightInfo' });
  }
  getNUmberOfdigitOfPrice(symbol,price){
    let str = symbol+price;
    if(!this.isMobile){
     if(str.length >=8){
      return {'font-size':'12px'}
    }else if(str.length <8){
      return {'font-size':'14px'}
    }else if(str.length <=6){
      return {'font-size':'16px'}
    }
  } else{
    if(str.length >=8){
      return {'font-size':'8px'}
    }else if(str.length <=6){
      return {'font-size':'10px'}
    }else if(str.length <=5){
      return {'font-size':'12px'}
    }
  }
  }
  getClassStringWithBrand(hopIndex: number, leg: FlightLegResult, flighthop?) {
    if (flighthop) {
      let cabinClass = CommonUtils.classNameReturnFromMap(this.cabinClasses, flighthop.carrier, flighthop.cabinClass);
      if (cabinClass) {
        return cabinClass;
      }
    }
    return CommonUtils.getClassStringWithBrand(hopIndex, leg, this.searchService.brandedFareCheckBox);
  }

  getFlightDuration(num) {
    return FlightUtils.getFlightLegDuration(this.flight, num);
  }

  getFlightIconURL(flightCode: string) {
    return CommonUtils.getAirlineImageUrl(flightCode);
  }

  getNoOfHops(flightLegIndex: number): number {
    if (this.flight.legs[flightLegIndex].flightHops) {
      return this.flight.legs[flightLegIndex].flightHops.length;
    }
  }


  getFlightLegSource(flightLegIndex: number): string {
    if (this.flight && this.flight.legs[flightLegIndex]) {
      let from = this.flight.legs[flightLegIndex].flightHops[0].from;
      return from;
      // return from + ', ' + this.searchService.getAirportName(from);
    }
  }

  getFlightLegDestination(flightLegIndex: number): string {

    if (this.flight && this.flight.legs[flightLegIndex]) {
      let noOfHops = this.getNoOfHops(flightLegIndex);
      let to = this.flight.legs[flightLegIndex].flightHops[noOfHops - 1].to;
      return to;
      //return to + ', ' + this.searchService.getAirportName(to);
    }
  }

  getFlightLegConstraint(flightLegIndex: number): string {

    if (flightLegIndex == 0) {
      return this.searchService.previousSearch.constraint;
    }
    return this.searchService.previousSearch.flights[flightLegIndex - 1].constraint
  }

  isFlightLegDepartureBased(flightLegIndex: number): boolean {
    return this.getFlightLegConstraint(flightLegIndex) == IntervalType.DEPARTURE_BASED ? true : false;
  }

  isSelectedFlight(): boolean {
    return true;
  }

  deSelectFlight(flight) {
    var index = this.searchService.multipleOutgoingSelectedFlight.findIndex(item => item.flightId===flight.flightId);
    // this.searchService.seletedIndex = this.searchService.seletedIndex--;
    this.deSelectEmitter.emit(index);
  }

  getClassName(id) {
    let className;

    this.classOptions.map(item => {
      if (item.id.toLowerCase() == id.toLowerCase()) {
        className = item.value;
      }
    });

    if (className.includes("Class")) {
      return className;
    } else {
      return className + " Class";
    }
  }

  getDisplayDate(dateString: string): string {
    return DateUtils.getDisplayDate(dateString);
  }



  toggleExpand() {
    // this.isExpanded = !this.isExpanded;
  }

  getHopDuration(durationInMins) {
    return DateUtils.getDurationAsHrsMinObj(durationInMins);
  }

  getAirlineFullName(code) {
    let airlineFullName = null;
    airlineFullName = this.airlines[code];
    return (airlineFullName === '' || airlineFullName === null) ? code : airlineFullName;
  }

  getAirportCity(code) {
    let airportCity = null;
    airportCity = this.airports[code].name;
    return (airportCity === '' || airportCity === null) ? code : airportCity;
  }

  get isStatic() {
    return false;
  }


  getInPolicyBlockVisibility() {
    if (this.flight.legs[0].flightHighlights.hasOwnProperty("withinPolicy")) {
      if (this.flight.legs[0].flightHighlights.withinPolicy) {
        return '';
      }
    }
    return 'hidden';
  }
  getOutPolicyBlockVisibility() {
    if (this.flight.legs[0].flightHighlights.hasOwnProperty("withinPolicy")) {
      if (!(this.flight.legs[0].flightHighlights.withinPolicy)) {
        return '';
      }
    }
    return 'hidden';
  }
  getNoPolicyBlockVisibility() {
    if (this.flight.legs[0].flightHighlights.hasOwnProperty("withinPolicy")) {
      return "hidden";
    }
    return '';
  }
  getCurrencySymbol(flight): string {
    if(flight && flight.displayCurrency){
      return CommonUtils.getCurrencySymbol(flight.displayCurrency);
    }else{
    return CommonUtils.getCurrencySymbol(flight.currency);
    }
  }
  getStopsUIText(legIndex: number) {
    let stopsText = this.translateService.instant('flightSelected.non-stop').toString();
    if (this.flightLayoverInfoList[legIndex].length > 0) {
      if (this.flightLayoverInfoList[legIndex].length == 1) {
        stopsText = this.translateService.instant('flightSelected.1stop').toString();
      } else {
        stopsText = this.flightLayoverInfoList[legIndex].length + this.translateService.instant('flightSelected.spacestops').toString();
      }

      let stopAirports = undefined;
      for (let layover of this.flightLayoverInfoList[legIndex]) {
        if (stopAirports) {
          stopAirports = stopAirports + ', ' + layover.in;
        } else {
          stopAirports = layover.in;
        }
      }
      stopsText = stopsText + ' ' + stopAirports;
    }
    return stopsText;
  }

  isPreferredAirline() {
    let isPreferred = true;
    for (let leg of this.flight.legs) {
      isPreferred = isPreferred && leg.flightHighlights.hasPreferredAirline;
    }
    return this.isPreferredAlliance() ? false : isPreferred;
  }

  isPreferredAlliance() {
    let isPreferred = true;
    for (let leg of this.flight.legs) {
      isPreferred = isPreferred && leg.flightHighlights.hasPreferredAlliance;
    }
    return isPreferred;
  }

  isPreferredAirport() {
    let isPreferred = true;
    for (let leg of this.flight.legs) {
      isPreferred = isPreferred && leg.flightHighlights.hasPreferredAirport;
    }
    return isPreferred;
  }

  isRedEye() {
    let isPreferred = false;
    for (let leg of this.flight.legs) {
      isPreferred = isPreferred || leg.flightHighlights.redEyeFlight;
    }
    return isPreferred;
  }

  isOverNightFlight() {
    let isPreferred = true;
    for (let leg of this.flight.legs) {
      isPreferred = isPreferred && leg.flightHighlights.hasOvernightLayover;
    }
    return isPreferred;
  }

  isSmallLayoverFlight() {
    let isPreferred = true;
    for (let leg of this.flight.legs) {
      isPreferred = isPreferred && leg.flightHighlights.hasShortLayover;
    }
    return isPreferred;
  }

  isLongLayoverFlight() {
    let isPreferred = true;
    for (let leg of this.flight.legs) {
      isPreferred = isPreferred && leg.flightHighlights.hasLongLayover;
    }
    return isPreferred;
  }

  isChangeOfAirport() {
    let isPreferred = false;
    for (let leg of this.flight.legs) {
      isPreferred = isPreferred || leg.flightHighlights.hasChangeOfAirport;
    }
    return isPreferred;
  }

  isTopRecommendedFlight() {
    return this.flight.legs[0].flightHighlights.topFlightReason ? true : false;
  }

  getTopRecommendedReason() {
    return this.flight.legs[0].flightHighlights.topFlightReason;
  }
  showWithinPolicy() {
    return this.flight.legs[0].flightHighlights.hasOwnProperty("withinPolicy");
  }
  isWithinPolicy() {
    if (this.flight.legs[0].flightHighlights.hasOwnProperty("withinPolicy")) {
      return this.flight.legs[0].flightHighlights.withinPolicy;
    }
  }

  toStringFromJSON(json: any) {
    return JSON.stringify(json);
  }
  getBaggageDetailsColor() {
    let baggageDetails: BaggageDetails = this.flight.legs[0].baggageAllowance;
    if (this.searchService.brandedFareCheckBox && this.flight.legs[0].brandDetails && this.flight.legs[0].brandDetails.length > 0) {
      baggageDetails = this.flight.legs[0].brandDetails[0].baggageAllowance;
    }
    if (baggageDetails) {
      if (baggageDetails.numberOfPieceAllowed && baggageDetails.numberOfPieceAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.numberOfPieceAllowed) > 0) {
        return 'rgb(109, 194, 165)';
      }
      if ((baggageDetails.maxWeightAllowed && baggageDetails.maxWeightAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.maxWeightAllowed) === 0) || (!baggageDetails.maxWeightAllowed)) {
        return 'rgb(237, 109, 120)';
      } else {
        return 'rgb(109, 194, 165)';
      }
    }
    return null;
  }
  getBaggageDetails() {
    let baggageDetails: BaggageDetails = this.flight.legs[0].baggageAllowance;
    if (this.searchService.brandedFareCheckBox && this.flight.legs[0].brandDetails && this.flight.legs[0].brandDetails.length > 0) {
      baggageDetails = this.flight.legs[0].brandDetails[0].baggageAllowance;
    }
    if (baggageDetails) {
      if (baggageDetails.numberOfPieceAllowed && baggageDetails.numberOfPieceAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.numberOfPieceAllowed) > 0) {
        return (Number.parseInt(baggageDetails.numberOfPieceAllowed) === 1) ? '1 checked bag (per adult)'
          : baggageDetails.numberOfPieceAllowed + ' checked bags (per adult)';
      }
      if ((baggageDetails.maxWeightAllowed && baggageDetails.maxWeightAllowed.trim().length > 0
        && Number.parseInt(baggageDetails.maxWeightAllowed) === 0) || (!baggageDetails.maxWeightAllowed)) {
        return 'No checked bags';
      } else {
        return baggageDetails.maxWeightAllowed + ' ' + baggageDetails.weightUnit.toLowerCase() + ' (per adult)';
      }
    }
    return null;
  }


}

