import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { throwError as _throw, Observable } from 'rxjs';
import { ErrorHandlingComponent } from '../error-handling/error-handling.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RouterModule, Router, Routes } from '@angular/router';
import { catchError } from 'rxjs/operators';


import { UserAccountService } from '../user-account.service';
export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;
@Injectable()
export class ErrorHandlingService
  implements HttpInterceptor {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError(err => this.handleError(err)))
      ;
  }
  handleError(error: HttpErrorResponse) {
    // const userAccountService = this.injector.get(UserAccountService);
    //     // auth.clearLoggedInData();
    // //To know the version of RxJS npm list --depth=0 (I for this example im on version 5.5)
    // if (userAccountService && userAccountService.isLoggedIn()){
    //   userAccountService.clearLoggedInData();
    //   userAccountService.signOut();
    // }
    if (error.status === 403) {
      // A client-side or network error occurred. Handle it accordingly.
      if (this.bsModalRef) {
        this.bsModalRef.hide();
      }
      this.bsModalRef =
        this.modalService.show(ErrorHandlingComponent, { initialState: {}, backdrop: true, keyboard: false, ignoreBackdropClick: true });
    }
    // return an observable with a user-facing error message
    return _throw(error);
  }

}