
import { TravelerDetails } from './traveler-details';
import { PaymentInstrumentDetails } from './payment-instrument-details';
import { FlightResult } from './flight-result';
import { FlightSearchRequest } from './flight-search-request';
import { SeatSelect } from './seatSelected';
import { HotelDetailResult } from './hotel-detail-result';
import { HotelSearchRequest } from './hotel-search-request';
import { CarResult } from './carResult';

export class TripSessionBookingData {


  paymentDetails: PaymentInstrumentDetails;

  flights: FlightResult[] ;
  searchQuery: FlightSearchRequest;

  hotels: HotelDetailResult[];
  hotelSearchQuery: HotelSearchRequest;

  carSearchQuery : any;
  cars: any[];

  eventIdAndOptionMap: Map<string, string>;
  tripId: string;
  ticketId: String;
  rebookingInfo:any;
  previousBookingCodes: Array<any> = [];
  displayPrice:number;
  displayCurrency:string;
  lastTransactionId: string;
  selectedSeat: SeatSelect[];

  bookingType:string;

  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}