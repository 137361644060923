import * as moment from 'moment';
import { DatePipe } from '@angular/common';

export class DateUtils {

  //  static getFormattedDateWithTimeZone(date:Date):string{
  //    return new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000).toISOString().split('.')[0];
  //  }

  static getFormattedDateWithoutTimeZone(date: Date): string {
    return new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
  }
  static getFormattedDateWithoutTimeZoneFromFormat(date: Date, format: string): string {
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(new Date(date), format, 'UTC');
  }
  static getFormattedDateForGivenTimeZone(isoDate: string, format: string): string {
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(new Date(isoDate), format, isoDate.substring(23));
  }

  static getDayAsLocalISODateString(input) {
    // Assumption input is yyyy-mm-dd
    let localTImeZoneOffset = new DatePipe('en-US').transform(new Date(), 'ZZZZZ');
    return new Date(input).toISOString().substring(0, 23) + localTImeZoneOffset;

  }

  static getLocalDateAsYYYYMMDD(input, format) {
    var correction = (new Date().getTimezoneOffset() - new Date(input).getTimezoneOffset()) * 60 * 1000;
    var dateParts = new Date(new Date(input).getTime() - correction).toDateString().split(' ');
    var dateLabel = dateParts[2] + ' ' + dateParts[1] + ' ' + dateParts[3].substring(2);
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(new Date(dateLabel), format);
  }


  static getDateAsDatePickerObject(searchDate: any): any {
    if (searchDate instanceof Date) {
      searchDate = DateUtils.getFormattedDateWithoutTimeZone(searchDate) + 'T00:00:00.000';
    }
    let dateFields: any[] = searchDate.split('T')[0].split('-');

    let dateObj = {
      year: dateFields[0],
      month: dateFields[1],
      day: dateFields[2]
    };
    return dateObj;
  }

  static getAsUTCDate(date: Date): Date {
    return new Date(Date.parse(date.toUTCString()))
  }

  static getCurrentDateAsUTCDate() {
    let date = new Date();
    return this.getAsUTCDate(date);
  }

  //  static getAPIFormattedDateWithTimeZone(date:Date):string{
  //
  //    var dateStringFields:string[]= this.getFormattedDateWithTimeZone(date).split(':');
  //
  //    return dateStringFields[0]+':'+dateStringFields[1];
  //  }

  static getDateDiffInMinutes(endDate: Date, startDate: Date): number {
    return Math.round((endDate.getTime() - startDate.getTime()) / 60000);
  }

  static getDurationAsHrsMinObj(duration) {
    return {
      hrs: Math.floor(duration / 60),
      mins: Math.floor(duration % 60)
    }
  }

  static getDateStringAsYYYMMDD(date: string): string {
    return this.getFormattedDateWithoutTimeZone(new Date(date));
  }

  static getDisplayDate(dateString: string): string {
    //ignoring the TimeZone part for displaying
    return dateString.substring(0, (dateString.length - 6));
  }

  static getYearsDiffFromNow(dateString: string): number {
    let yearsDiff = -1;
    const date = moment(DateUtils.getDateStringAsYYYMMDD(dateString)).startOf('day');
    if (date.isValid()) {
      const now = moment().startOf('day');
      yearsDiff = now.diff(date, 'years');
    }
    return yearsDiff;
  }

  static getDaysDiff(startDateYYYYMMDD: string, endtDateYYYYMMDD) {
    let timeDiff = (new Date(endtDateYYYYMMDD).getTime()) - (new Date(startDateYYYYMMDD).getTime());
    return Math.round(timeDiff / (1000 * 60 * 60 * 24))
  }
}
