<ngx-smart-modal (onOpen)="handleModalEvents('onOpen', 'calendarOptionModal')" [hideDelay]="0"
  (onClose)="handleModalEvents('onClose', 'calendarOptionModal')"
  (onDismiss)="handleModalEvents('onDismiss', 'calendarOptionModal')" [closable]="false" #calendarOptionModal
  identifier="calendarOptionModal">

  <div class="modal-container flight-modal-container filter-modal modalAirportFilterInfo" style="min-width:240px"
    (click)="$event.stopPropagation();">

    <div class="modal-body">
      <div class="calendar"><a style="cursor:pointer;" (click)="this.value='apple';proceedtoCalendar(this.value)"> 
        <div class="icon_img_div"><img
            class="image" style="height: 30px;" src="assets/images/apple.png" alt="" /> </div>
            <div class="icon_label_div"><span
            class="trends">Apple</span></div></a> </div>
      <div class="calendar"> <a style="cursor:pointer;" (click)="this.value='outlook';proceedtoCalendar(this.value)">
        <div class="icon_img_div"><img class="image" style="height: 30px;" src="assets/images/outlook.png" alt="" /> 
          </div><div class="icon_label_div">
          <span
            class="trends">Outlook</span></div></a> </div>
      <div class="calendar"> <a style="cursor:pointer;" (click)="this.value='outlook.com';proceedtoCalendar(this.value)">
        <div class="icon_img_div"><img class="image" style="height: 30px;" src="assets/images/icon-outlookcom.svg" alt="" /> 
          </div><div class="icon_label_div"><span
            class="trends">Outlook.com</span></div></a> </div>
      <div class="calendar"><a style="cursor:pointer;" (click)="this.value='google';proceedtoCalendar(this.value)">
        <div class="icon_img_div"><img
            class="image" style="height: 30px;" src="assets/images/google-icon.png" alt="" /> 
            </div><div class="icon_label_div"><span class="trends">Google</span></div></a></div>
      <div class="calendar"><a style="cursor:pointer;" (click)="this.value='yahoo';proceedtoCalendar(this.value)">
        <div class="icon_img_div"><img
            class="image" style="height: 30px;" src="assets/images/yahoo.png" alt="" />
            </div><div class="icon_label_div"><span
            class="trends">Yahoo</span></div></a></div>
    </div>
    <div class="modal-footer">

    </div>
  </div>
</ngx-smart-modal>