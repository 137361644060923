<email-header *ngIf="!this.showAllBookings"></email-header>
<div class="main-wrapper">
	<div class="content">
        <div class="container" [ngClass]="{'':showAllBookings===true, 'container': showAllBookings===false}">

			<div class="tab" *ngIf="!detailView">
				<div class="tab-list top-strip" *ngIf=" !this.showAllBookings">

					<ul>
                        <li class="tab-list-item active" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="switchBookingListTab('tab1')"><a href="javascript:void(0)" attr.data-track="TripsTab" attr.data-params="tab=Upcoming">
								{{'bookingHistory.UpcomingTrips' |
								translate}} </a></li>
                        <li class="tab-list-item" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="switchBookingListTab('tab2')"><a href="javascript:void(0)" attr.data-track="TripsTab" attr.data-params="tab=Past"> {{'bookingHistory.PastTrips' |
								translate}} </a></li>
					</ul>

				</div>
				<div class="tab-content" [ngSwitch]="viewMode" [ngStyle]="{'box-shadow' : this.showAllBookings ? 'none':'0 0 10px 0 rgba(0, 0, 0, 0.14)'}">
					<div id="tab1" class="tab-content-item" *ngSwitchCase="'tab1'">
							<app-loader *ngIf="this.appLoader &&  this.showAllBookings" style="position: relative;top: 20px;bottom: 10px;" [spinnerStyle]="true"></app-loader>
							<div  *ngIf="this.showAllBookings && !this.appLoader" class="filter-row" style="margin-top: 30px;">
									<div class="col-auto" style="height: 40px;width: 253px;">
									
								   <div class="filter custom-selectbox">
										<label class="labelTime">
												{{ 'search.Traveldates' | translate}}:
											 </label>
										<div class="input-box" style="margin-right:5px;position: relative; top: 3px;">
                                        <input class="input" id="daterangeSelection" (click)="openNgxModal('daterangeSelection',chartDatePicker);" style="background: #F7F7F9 !important;" readonly=""><span class="dateShow" (click)="openNgxModal('daterangeSelection',chartDatePicker);">{{ getdateType()  |  translate}}</span>
                                        <svg
                                            class="down-Arrow" style="" (click)="openNgxModal('daterangeSelection',chartDatePicker);" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
											  fill="#8936F3" />
										  </svg>
					  
										</div>
                                    <ngx-smart-modal (onOpen)="handleModalEvents('onOpen', 'daterangeSelection')" [hideDelay]="0" (onClose)="handleModalEvents('onClose', 'daterangeSelection')" (onDismiss)="handleModalEvents('onDismiss', ' daterangeSelection')" [closable]="false" #daterangeSelection
                                        identifier="daterangeSelection">
                                        <div class="modal-container flight-modal-container filter-modal1 modalAirportFilterInfo" [ngStyle]="changeStyle()" (click)="$event.stopPropagation();">
                                            <div class="modal-header" style="background-color: #fff !important;padding-top:10px !important;overflow:visible;">
											  <div class="tab-list top-strip" style="background: #fff !important;">
					  
												<ul style="overflow: visible !important;">
                                                        <li class="{{ viewMode11 == 'tab22' ? 'select':'unselect'}}" [class.active]="viewMode11 == 'tab22'" rel="tab22" style="margin-left:0px !important;margin-right:0px !important;">{{ 'report.Presets' | translate }}
                                                            <div *ngIf="viewMode11 == 'tab22'" class="underline"></div>
												  </li>
                                                        <li *ngIf="false" class="{{ viewMode1 == 'tab11' ? 'select':'unselect'}}" [class.active]="viewMode11 == 'tab11'" rel="tab14" (click)="customTabClicked()">{{ 'report.Custom' | translate }}
                                                            <div *ngIf="viewMode11 == 'tab11'" class="underline"></div>
												  </li>
												</ul>
											  </div>
                                                <input class="input" [hidden]="viewMode11=='tab22'" style="width:100% !important;position: relative;top:8px;" readonly=""><span class="dateShow1" [hidden]="viewMode11=='tab22'" style="top:24px !important;">{{daterangepickerModel[0] | date :
												'dd MMM yyyy'}} - {{daterangepickerModel[1] | date: 'dd MMM yyyy'}}</span>
											</div>
											<hr>
										
											<div class="modal-body" [ngStyle]="changeStyle1()">
											  <div [hidden]="viewMode11=='tab21'">
                                                    <div *ngFor="let item of this.DATE_OPTIONS;let i=index" style="width: auto;min-height: 15px;display:flex;line-height: 3em;min-height: 3em;">
                                                        <div style="font-size:14px;color:#5f6368;padding-left:22px;margin-bottom:7px;cursor: pointer;" (click)="showDateChanged(item.id,true)">{{item.name | translate}}</div>
												</div>
					  
											  </div>
											</div>
										  </div>
										</ngx-smart-modal>
									  </div>
									</div>
									<div *ngIf="this.statusOptions.length >0"  class="col-auto" style="height: 40px;">
                                <div id="statusDropdown" class="statusDrop">
													<div class="input" style="background: #F7F7F9 !important;">
                                        <ng-select #statusDropdown appendTo="#statusDropdown" [disabled]="this.appLoader" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [(ngModel)]="statusValue" [items]="statusOptions" bindLabel="value" bindValue="id" (change)="showStatusChanged($event.id)">
														<ng-template ng-option-tmp let-option="item">
															<span class="option-title">{{option.name |
																translate}}</span>
														   
														</ng-template>
														<ng-template ng-label-tmp let-item="item">
															<span style="text-transform: capitalize;"> {{item.name | translate}}</span>
														  </ng-template>
													  </ng-select>
													  <div class="select-overlay"></div>
													</div>
                                    <svg class="down-arrow" (click)="statusDropdown.toggle()" style="" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
													  <path fill-rule="evenodd" clip-rule="evenodd"
														d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
														fill="#8936F3" />
													</svg>
												  </div>
									   </div>
									   <div *ngIf="this.originalBookinngList.length >0" class="col-auto" style="height: 40px;">
											<div id="bookingDropdown" style="margin-top: 5px;margin-left: 0px;width: 253px;">
													<div class="input" style="background: #F7F7F9 !important;">
                                        <ng-select #bookingDropdown appendTo="#bookingDropdown" [disabled]="this.appLoader" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [(ngModel)]="bookingValue" [items]="bookingTypeOptions" bindLabel="value" bindValue="id" (change)="showBookingTypeChanged($event.id)">
														<ng-template ng-option-tmp let-option="item">
															<span class="option-title">{{option.value |
																translate}}</span>
														   
														</ng-template>
														<ng-template ng-label-tmp let-item="item">
															<span style="text-transform: capitalize;"> {{item.value | translate}}</span>
														  </ng-template>
													  </ng-select>
													  <div class="select-overlay"></div>
													</div>
                                    <svg class="down-arrow" (click)="statusDropdown.toggle()" style="" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
													  <path fill-rule="evenodd" clip-rule="evenodd"
														d="M6.31802 8.43934L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934C1.02513 -0.146446 1.97487 -0.146446 2.56066 0.43934L7.37868 5.25736L12.1967 0.43934C12.7825 -0.146447 13.7322 -0.146447 14.318 0.43934C14.9038 1.02513 14.9038 1.97487 14.318 2.56066L8.43934 8.43934C7.85355 9.02513 6.90381 9.02513 6.31802 8.43934Z"
														fill="#8936F3" />
													</svg>
												  </div>
									   </div>
									  
								</div>
								<div  *ngIf="this.showAllBookings && !this.appLoader" class="filter-row" style="margin-top: 20px;">
									
										<div *ngIf="this.originalBookinngList.length >0" class="col-auto">
                                <input [(ngModel)]="empNameSearchValue" type='text' class="search-box" placeholder="{{ 'activeTraveler.SearchbynameConfirmation' | translate}}" (input)="filterData()" />
											   </div>
										  
									</div>
								<div class="empty-container" *ngIf="this.showAllBookings && this.flightList.length ===0 && !this.appLoader">
										<div class="empty-img">
											<img src="assets/images/no-flight-found.png" />
										</div>
										<div class="empty-text">{{'bookingHistory.NoBookings'	| translate}}</div>
										<div class="empty-button">
											<!--         <button class="btn primary-button">{{'bookingHistory.BOOKNOW' | translate}}</button>-->
										</div>
									</div>
						<div *ngIf=" !this.showAllBookings" class="" style="background: #fff !important;display: flex;">
                       
							<ul style="display: flex;margin-top:10px;margin-bottom: 10px;">
                                <li class="{{ viewMode1 == 'tab11' ? 'tab-list-item11':'tab-list-item12'}}" [class.active]="viewMode1 == 'tab11'" (click)="showMYTrip('myself')">
									{{'bookingHistory.BookedForMyself' | translate}}
								<li>
                                        <li class="{{ viewMode1 == 'tab12' ? 'tab-list-item11':'tab-list-item12'}}" [class.active]="viewMode1 == 'tab12'" (click)="showMYTrip('others')">{{'bookingHistory.BookedForOthers' | translate}}
								</li>
							</ul>

						</div>
						<div class="booking-container" *ngIf="!isEmpty">
							<div class="trip-search-div">
								<div class="trips-count">Showing 3 Trips</div>
								<div class="search-input-div">
									<input type="text" placeholder="Search Trips" class="search-input">
								</div>
							</div>
                            <div *uiScroll="let flight of flightsDatasource;let i = index" [style.minHeight]="'122px'">
                                <!-- <div *ngFor="let flight of flightList;let i=index"> -->
                                <div class="result-card-box" [style.minHeight]="'106px'">
									<div *ngIf="flight.type=='flight'">
										<div>
											<div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">
												<div class="booking-details">
													<div class="booking-details-inner">
														<div class="booking-img">
															<img src="assets/images/flight-circle.png" />
														</div>
														<div class="booking-text">
															<p>Depart</p>
															<p class="font-bold">
																{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].starts, 'EE MMM d ,yyyy')}}
															</p>
														</div>
													</div>
												</div>
												<div class="show-destination">
													<div>
														<p class="font-bold primary-text">{{flight.option.flight_option.legs[0].hops[0].from}}</p>
													</div>
													<span class="destination-devider">-</span>
													<div>
														<p class="font-bold primary-text">{{flight.option.flight_option.legs[0].hops[flight.option.flight_option.legs[0].hops.length-1].to}}</p>
													</div>
												</div>
												<div>
													<p class="font-bold primary-text">{{flight.option.flight_option.legs[0].hops[0].allianceName}}</p>
												</div>
												<!-- <div class="origin-destination flight">
													<div class="origin-destination-section">
														<p class="font-bold primary-text">
															{{flight.option.flight_option.legs[0].hops[0].from}}</p>
														<p>{{getAirportCity(flight.option.flight_option.legs[0].hops[0].from)}}
														</p>
													</div>
													<div class="origin-destination-switch">
														<span class="icon-arrow"></span>
													</div> 
													<div>-</div>
													<div class="origin-destination-section">
														<p class="font-bold primary-text">
															{{flight.option.flight_option.legs[0].hops[flight.option.flight_option.legs[0].hops.length-1].to}}
														</p>
														<p>{{getAirportCity(flight.option.flight_option.legs[0].hops[flight.option.flight_option.legs[0].hops.length-1].to)}}
														</p>
													</div>
	
												</div> -->

												<div class="flight-timing">
													<div>{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].starts,'h:mm a')}}</div>
													<span>-</span>
													<div>{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].ends,'h:mm a')}}</div>
													
												</div>
												<div class="origin-destination1">
	
													<!-- <div *ngIf="(viewMode1 !== 'tab11' || this.showAllBookings) && flight.primaryTraveller" class="showUserDetail"><span class="showinUSerdetails">{{flight.primaryTraveller.userName}}</span><span style="font-family: 'apercu-r' !important;" class="showinUSerdetails">{{flight.primaryTraveller.userId}}</span>
													</div> -->
													<div [ngStyle]="channgeAlignment()" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>
	
												</div>
												
												
											</div>
											<div class="view-details-div">
												<div>
													<span class="font-bold" >Trip:</span> Salt Lake City May 19, 2023
												</div>
												<div>
													<span class="font-bold">Traveler: </span>{{flight.primaryTraveller.userName}}
												</div>
												<div class="booking-view-button">
													<button class="btn primary-button" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">{{'bookingHistory.ViewDetail'
														| translate}}</button>
												</div>
											</div>
										</div>
									</div>
									<div *ngIf="flight.type =='hotel'">
                                        <div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">
											<div class="booking-details">
												<div class="booking-details-inner">
													<div class="booking-img">
														<img src="assets/images/hotel-circle.png" />
													</div>
													<div class="booking-text">
														<p style="margin-right: 2px;">{{'bookingHistory.CheckIn' | translate}}</p>
														<p class="font-bold">{{getDisplayDate(flight.option.hotel_option.checkInDate, 'EE MMM d ,yyyy')}}</p>
														<!-- <p class="font-bold">  {{flight.option.hotel_option.checkInDate | date:'EE MMM d'}}</p> -->
													</div>
												</div>
											</div>
											<!-- <div class="origin-destination hotel">
												<div class="destination-hotel">
													<p class="font-bold primary-text">
														{{flight.option.hotel_option.hotelName}}</p>
													<p>{{flight.option.hotel_option.address}}</p>
												</div>
												<div class="hotel-nights-count">
                                                    <span style="white-space: nowrap;">{{flight.option.hotel_option.stay}}-{{flight.option.hotel_option.stay
														> 1 ? ('bookingHistory.Nights' | translate)
														:('bookingHistory.Night' | translate)}}</span>
												</div>

											</div> -->
											<div class="origin-destination hotel">
												<div class="destination-hotel">
													<p class="font-bold primary-text">{{flight.option.hotel_option.hotelRooms[0].hotelRoomName}}</p>
													<span style="white-space: nowrap;">{{flight.option.hotel_option.stay}}-{{flight.option.hotel_option.stay
														> 1 ? ('bookingHistory.Nights' | translate)
														:('bookingHistory.Night' | translate)}}</span>
												</div>
											</div>
											<div>
												<p class="font-bold primary-text">{{flight.option.hotel_option.hotelName}}</p>
											</div>
											<div class="flight-timing">
												<p>
													{{flight.option.hotel_option.address}}
													
												</p>
												
											</div>
											<div class="origin-destination1">

                                                <!-- <div *ngIf="viewMode1 !== 'tab11' || this.showAllBookings" class="showUserDetail"><span class="showinUSerdetails">{{flight.primaryTraveller.userName}}</span><span style="font-family: 'apercu-r' !important;" class="showinUSerdetails">{{flight.primaryTraveller.userId}}</span>
                                                </div> -->
                                                <div [ngStyle]="channgeAlignment()" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>

											</div>
											
											
										</div>
										<div class="view-details-div">
											<div>
												<span class="font-bold" >Trip:</span> {{flight.option.hotel_option.address}}{{getDisplayDate(flight.option.hotel_option.checkInDate, ' MMM d ,yyyy')}}
											</div>
											<div>
												<span class="font-bold">Traveler: </span>{{flight.primaryTraveller.userName}}
											</div>
											<div class="booking-view-button">
												<button class="btn primary-button" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">{{'bookingHistory.ViewDetail'
													| translate}}</button>
											</div>
										</div>
									</div>
									<div *ngIf="flight.type =='cars'">
                                        <div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">
											<div class="booking-details">
												<div class="booking-details-inner">
													<div class="booking-img">
                                                        <!-- <i class="fa fa-car" style="margin-right:5px;color:blue !important;" aria-hidden="true"></i> -->
														<img src="assets/images/car-circle.png" />
													</div>
													<div class="booking-text">
														<p style="margin-right: 2px;">{{'bookingHistory.Pick-update'| translate}}: </p>
                                                        <p *ngIf="flight.option.car_option.pickUpDate!==null" class="font-bold">
                                                            {{getDisplayDateTimeForFlights(flight.option.car_option.pickUpDate, 'EE MMM d,yyyy')}}</p>
														<!-- <p class="font-bold">  {{flight.option.hotel_option.checkInDate | date:'EE MMM d'}}</p> -->
													</div>
												</div>
											</div>
											<div class="origin-destination hotel">
												<div class="destination-hotel">
													<p class="font-bold primary-text">
														{{flight.option.car_option.description}}</p>
													<p>{{flight.option.car_option.numberOfDay}}-{{flight.option.car_option.numberOfDay
														> 1 ? ('bookingHistory.Days'| translate):('bookingHistory.Day'| translate) }}</p>
												</div>
												<div class="hotel-nights-count">
                                                    <span class="font-bold">{{flight.option.partnerName}}</span>
												</div>

											</div>
											<div><p>{{flight.option.car_option.pickUpLocation}}</p></div>
											<div class="origin-destination1">

                                                <!-- <div *ngIf="viewMode1 !== 'tab11' || this.showAllBookings" class="showUserDetail"><span class="showinUSerdetails">{{flight.primaryTraveller.userName}}</span><span style="font-family: 'apercu-r' !important;" class="showinUSerdetails">{{flight.primaryTraveller.userId}}</span>
                                                </div> -->
                                                <div [ngStyle]="channgeAlignment()" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>

											</div>
											
										</div>
										<div class="view-details-div">
											<div>
												<span class="font-bold" >Trip:</span> {{flight.option.car_option.dropOffLocation}}{{getDisplayDateTimeForFlights(flight.option.car_option.pickUpDate, ' MMM d ,yyyy')}}
											</div>
											<div>
												<span class="font-bold">Traveler: </span>{{flight.primaryTraveller.userName}}
											</div>
											<div class="booking-view-button">
                                                <button class="btn primary-button" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">{{'bookingHistory.ViewDetail'
													| translate}}</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="empty-container" *ngIf="!this.showAllBookings && isEmpty">
							<div class="empty-img">
								<img src="assets/images/no-flight-found.png" />
							</div>
                            <div class="empty-text">{{'bookingHistory.Youdonthaveanyupcomingtrips' | translate}}</div>
							<div class="empty-button">
								<!--         <button class="btn primary-button">{{'bookingHistory.BOOKNOW' | translate}}</button>-->
							</div>
						</div>
					</div>
					<div id="tab2" class="tab-content-item" *ngSwitchCase="'tab2'">
						<div class="" style="background: #fff !important;display: flex;">

							<ul style="display: flex;margin-top:10px;margin-bottom: 10px;">
                                <li class="{{ viewMode1 == 'tab11' ? 'tab-list-item11':'tab-list-item12'}}" [class.active]="viewMode1 == 'tab11'" (click)="showMYTrip1('myself')">{{'bookingHistory.BookedForMyself' | translate}}
								<li>
                                        <li class="{{ viewMode1 == 'tab12' ? 'tab-list-item11':'tab-list-item12'}}" [class.active]="viewMode1 == 'tab12'" (click)="showMYTrip1('others')">{{'bookingHistory.BookedForOthers' | translate}}</li>
							</ul>

						</div>
						<div class="booking-container" *ngIf="!isEmpty2">
							<div *ngFor="let flight of flightList2;let i=index">
								<div class="result-card-box">
									<div *ngIf="flight.type=='flight'">
                                        <div>
											<div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">
												<div class="booking-details">
													<div class="booking-details-inner">
														<div class="booking-img">
															<img src="assets/images/flight-circle.png" />
														</div>
														<div class="booking-text">
															<p>Depart</p>
															<p class="font-bold">
																{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].starts, 'EE MMM d ,yyyy')}}
															</p>
														</div>
													</div>
												</div>
												<div class="show-destination">
													<div>
														<p class="font-bold primary-text">{{flight.option.flight_option.legs[0].hops[0].from}}</p>
													</div>
													<span class="destination-devider">-</span>
													<div>
														<p class="font-bold primary-text">{{flight.option.flight_option.legs[0].hops[flight.option.flight_option.legs[0].hops.length-1].to}}</p>
													</div>
												</div>
												<div>
													<p class="font-bold primary-text">{{flight.option.flight_option.legs[0].hops[0].allianceName}}</p>
												</div>
												<!-- <div class="origin-destination flight">
													<div class="origin-destination-section">
														<p class="font-bold primary-text">
															{{flight.option.flight_option.legs[0].hops[0].from}}</p>
														<p>{{getAirportCity(flight.option.flight_option.legs[0].hops[0].from)}}
														</p>
													</div>
													<div class="origin-destination-switch">
														<span class="icon-arrow"></span>
													</div> 
													<div>-</div>
													<div class="origin-destination-section">
														<p class="font-bold primary-text">
															{{flight.option.flight_option.legs[0].hops[flight.option.flight_option.legs[0].hops.length-1].to}}
														</p>
														<p>{{getAirportCity(flight.option.flight_option.legs[0].hops[flight.option.flight_option.legs[0].hops.length-1].to)}}
														</p>
													</div>
	
												</div> -->

												<div class="flight-timing">
													<div>{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].starts,'h:mm a')}}</div>
													<span>-</span>
													<div>{{getDisplayDateTimeForFlights(flight.option.flight_option.legs[0].hops[0].ends,'h:mm a')}}</div>
													
												</div>
												<div class="origin-destination1">
	
													<!-- <div *ngIf="(viewMode1 !== 'tab11' || this.showAllBookings) && flight.primaryTraveller" class="showUserDetail"><span class="showinUSerdetails">{{flight.primaryTraveller.userName}}</span><span style="font-family: 'apercu-r' !important;" class="showinUSerdetails">{{flight.primaryTraveller.userId}}</span>
													</div> -->
													<div [ngStyle]="channgeAlignment()" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>
	
												</div>
												
												
											</div>
											<div class="view-details-div">
												<div>
													<span class="font-bold" >Trip:</span> Salt Lake City May 19, 2023
												</div>
												<div>
													<span class="font-bold">Traveler: </span>{{flight.primaryTraveller.userName}}
												</div>
												<div class="booking-view-button">
													<button class="btn primary-button" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">{{'bookingHistory.ViewDetail'
														| translate}}</button>
												</div>
											</div>
										</div>
									</div>
									<div *ngIf="flight.type =='hotel'">
                                        <div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">
											<div class="booking-details">
												<div class="booking-details-inner">
													<div class="booking-img">
														<img src="assets/images/hotel-circle.png" />
													</div>
													<div class="booking-text">
														<p style="margin-right: 2px;">{{'bookingHistory.CheckIn' | translate}}</p>
														<p class="font-bold">{{getDisplayDate(flight.option.hotel_option.checkInDate, 'EE MMM d ,yyyy')}}</p>
														<!-- <p class="font-bold">  {{flight.option.hotel_option.checkInDate | date:'EE MMM d'}}</p> -->
													</div>
												</div>
											</div>
											<!-- <div class="origin-destination hotel">
												<div class="destination-hotel">
													<p class="font-bold primary-text">
														{{flight.option.hotel_option.hotelName}}</p>
													<p>{{flight.option.hotel_option.address}}</p>
												</div>
												<div class="hotel-nights-count">
                                                    <span style="white-space: nowrap;">{{flight.option.hotel_option.stay}}-{{flight.option.hotel_option.stay
														> 1 ? ('bookingHistory.Nights' | translate)
														:('bookingHistory.Night' | translate)}}</span>
												</div>

											</div> -->
											<div class="origin-destination hotel">
												<div class="destination-hotel">
													<p class="font-bold primary-text">{{flight.option.hotel_option.hotelRooms[0].hotelRoomName}}</p>
													<span style="white-space: nowrap;">{{flight.option.hotel_option.stay}}-{{flight.option.hotel_option.stay
														> 1 ? ('bookingHistory.Nights' | translate)
														:('bookingHistory.Night' | translate)}}</span>
												</div>
											</div>
											<div>
												<p class="font-bold primary-text">{{flight.option.hotel_option.hotelName}}</p>
											</div>
											<div class="flight-timing">
												<p>
													{{flight.option.hotel_option.address}}
													
												</p>
												
											</div>
											<div class="origin-destination1">

                                                <!-- <div *ngIf="viewMode1 !== 'tab11' || this.showAllBookings" class="showUserDetail"><span class="showinUSerdetails">{{flight.primaryTraveller.userName}}</span><span style="font-family: 'apercu-r' !important;" class="showinUSerdetails">{{flight.primaryTraveller.userId}}</span>
                                                </div> -->
                                                <div [ngStyle]="channgeAlignment()" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>

											</div>
											
											
										</div>
										<div class="view-details-div">
											<div>
												<span class="font-bold" >Trip:</span> {{flight.option.hotel_option.address}}{{getDisplayDate(flight.option.hotel_option.checkInDate, ' MMM d ,yyyy')}}
											</div>
											<div>
												<span class="font-bold">Traveler: </span>{{flight.primaryTraveller.userName}}
											</div>
											<div class="booking-view-button">
												<button class="btn primary-button" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">{{'bookingHistory.ViewDetail'
													| translate}}</button>
											</div>
										</div>
									</div>
									<div *ngIf="flight.type =='cars'">
                                        <div class="result-card-box-inner" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">
											<div class="booking-details">
												<div class="booking-details-inner">
													<div class="booking-img">
                                                        <!-- <i class="fa fa-car" style="margin-right:5px;color:blue !important;" aria-hidden="true"></i> -->
														<img src="assets/images/car-circle.png" />
													</div>
													<div class="booking-text">
														<p style="margin-right: 2px;">{{'bookingHistory.Pick-update'| translate}}: </p>
                                                        <p *ngIf="flight.option.car_option.pickUpDate!==null" class="font-bold">
                                                            {{getDisplayDateTimeForFlights(flight.option.car_option.pickUpDate, 'EE MMM d,yyyy')}}</p>
														<!-- <p class="font-bold">  {{flight.option.hotel_option.checkInDate | date:'EE MMM d'}}</p> -->
													</div>
												</div>
											</div>
											<div class="origin-destination hotel">
												<div class="destination-hotel">
													<p class="font-bold primary-text">
														{{flight.option.car_option.description}}</p>
													<p>{{flight.option.car_option.numberOfDay}}-{{flight.option.car_option.numberOfDay
														> 1 ? ('bookingHistory.Days'| translate):('bookingHistory.Day'| translate) }}</p>
												</div>
												<div class="hotel-nights-count">
                                                    <span class="font-bold">{{flight.option.partnerName}}</span>
												</div>

											</div>
											<div><p>{{flight.option.car_option.pickUpLocation}}</p></div>
											<div class="origin-destination1">

                                                <!-- <div *ngIf="viewMode1 !== 'tab11' || this.showAllBookings" class="showUserDetail"><span class="showinUSerdetails">{{flight.primaryTraveller.userName}}</span><span style="font-family: 'apercu-r' !important;" class="showinUSerdetails">{{flight.primaryTraveller.userId}}</span>
                                                </div> -->
                                                <div [ngStyle]="channgeAlignment()" class="showinUSerdetails">{{getUserBookingStatus(flight.tripStatus,flight.type, flight) | translate}}</div>

											</div>
											
										</div>
										<div class="view-details-div">
											<div>
												<span class="font-bold" >Trip:</span> {{flight.option.car_option.dropOffLocation}}{{getDisplayDateTimeForFlights(flight.option.car_option.pickUpDate, ' MMM d ,yyyy')}}
											</div>
											<div>
												<span class="font-bold">Traveler: </span>{{flight.primaryTraveller.userName}}
											</div>
											<div class="booking-view-button">
                                                <button class="btn primary-button" (click)="this.refreshingData=false;navigateToDetailView(flight,false)">{{'bookingHistory.ViewDetail'
													| translate}}</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="empty-container" *ngIf="!this.showAllBookings && isEmpty2">
							<div class="empty-img">
								<img src="assets/images/no-flight-found.png" />
							</div>
                            <div class="empty-text">{{'bookingHistory.Youdonthaveanypasttrips' | translate}}</div>
							<div class="empty-button">
								<!--  <button class="btn primary-button">{{'bookingHistory.BOOKNOW' | translate}}</button>-->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="booking-history-detail" *ngIf="detailView">
				<div class="top-strip booking-history-detail-heading">
                    <a class="booking-history-heading-link" href="javascript:void(0)" attr.data-track="BackToTrips" (click)="backToList()"> <img attr.data-track="BackToTrips" src="assets/images/hotel/backarrow.svg" alt="">
					</a>
					<h4 attr.data-track="BackToTrips" *ngIf="!this.searchService.showAllBookings"> {{'bookingHistory.BacktoTrips' | translate}}</h4>
					<h4 attr.data-track="BackToTrips" *ngIf="this.searchService.showAllBookings"> {{'bookingHistory.BacktoBookings' | translate}}</h4>
				</div>

				<div class="booking-history-detail-content">
					<div class="booking-history-detail-content-inner">
						<div class="booking-history-detail-content-left">
							<div *ngFor="let item of detailList;let i=index">
								<div class="booking-left-inner" *ngIf="item.type =='flight' && i== 0">
									<!-- <div class="booking-detail-header">
										<div class="text-left">
                                            <p>{{item.option.flight_option.legs[0].hops[0].from}} - {{getAirportCity(item.option.flight_option.legs[0].hops[0].from)}}
											</p>
											<p class="font-bold">
                                                {{item.option.flight_option.legs[0].hops[item.option.flight_option.legs[0].hops.length-1].to}} -{{getAirportCity(item.option.flight_option.legs[0].hops[item.option.flight_option.legs[0].hops.length-1].to)}}
											</p>
										</div>
										<div class="text-right">
											<p>{{'bookingHistory.Confirmation' | translate}}</p>
                                            <p class="font-bold">{{getConfirmationNo(item) | uppercase}}
                                            </p>
										</div>
									</div> -->
									<div class="booking-history-upper-header">
										<div class="font-bold" >
											<span>My Trip to </span> 
											<span>{{getAirportCity(item.option.flight_option.legs[0].hops[item.option.flight_option.legs[0].hops.length-1].to)}}</span>
											<img src="assets/images/edit-fill.svg" alt="" srcset="" class="pancel-img" >
										</div>
										<div>
											<div>We’ve pre-filled the trip with a name but feel free to modify it or,</div>
											<select name="" id="">
												<option value="">add to an existing trip</option>
											</select>
										</div>
									</div>
									<div class="booking-detail-data">
										<div *ngFor="let num of item.option.flight_option.legs;let legIndex=index">

											<div class="d-flex">
												<div>
													<div class="booking-img">
														<img src="assets/images/flight-circle.png" />
													</div>
													<div></div>
												</div>
												<div style="border-radius: 6px;width: 95%;box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);float: right;">
															<div class="d-flex justify-content-between">
																<div style="padding: 30px 5px;" >
																	<div *ngFor="let newItem of detailList;">
																		<div class="d-flex" style="margin-top: 20px;">
																			<div class="company-img" >
																				<img onerror="this.onerror = null; this.src = 'https://s3.amazonaws.com/images.biztravel.ai/template/default.gif';"
																					[src]="getFlightIconURL(newItem.option.flight_option.legs[0].hops[0].carrier)" />
																			</div>
																			<div class="w-10">
																				<div  class="d-flex position-relative w-10 ">
																					<div class="flight-start-timing">
																						<div>{{getDisplayDateTimeForFlights(newItem.option.flight_option.legs[0].hops[0].starts,'h:mm a')}}</div>
																						<div>{{getDisplayDate(newItem.option.flight_option.legs[0].hops[0].starts,'EEEE, MMM d,yyyy')}}</div>
																					</div>
																					
																						<div class="flight-arrow">
																							<div></div>
																						</div>
																					<div class="flight-start-timing">
																						<div>{{getDisplayDateTimeForFlights(newItem.option.flight_option.legs[0].hops[getLastIndex(newItem.option.flight_option.legs[0].hops)].ends,'h:mm a')}}</div> 
																						<div>{{getDisplayDate(newItem.option.flight_option.legs[0].hops[getLastIndex(newItem.option.flight_option.legs[0].hops)].ends,'EEEE, MMM d,yyyy')}}</div>
																					</div>
																				</div>
																				
																				<div>
																					{{newItem.option.flight_option.legs[0].stops}} • 
																					<span>{{getDuration(newItem,0)?.hrs
																						+ 'h ' + getDuration(newItem,0)?.mins + 'm'}}</span> 
																					• 
																					<span>{{newItem.option.flight_option.legs[0].hops[0].from}} - {{newItem.option.flight_option.legs[0].hops[getLastIndex(newItem.option.flight_option.legs[0].hops)].to}}</span>
																				</div>
																				<div>{{newItem.option.flight_option.legs[0].hops[0].allianceName}}</div>
																				<div class="see-details-button">See details</div>
																			</div>
																		</div>
																	</div>
																</div>

																<div class="d-flex justify-content-center align-items-center" style="background-color: #E3E3E3;width: 190px">
																	<div class="text-center">
																		<p class="price-show">{{item.option.flight_option.legs[0].price | currency : getCurrencySymbol(item.option.flight_option.legs[0]) :'code': '1.2-2'}}</p>
																		<p class="policy-show" *ngIf="item.option.flight_option.withinPricePolicy == true">WITHIN POLICY</p>
																		<p class="policy-show" style="color: red;" *ngIf="item.option.flight_option.withinPricePolicy == false">OUTSIDE POLICY</p>
																		<div class="confirmation-text">
																			<p>{{'bookingHistory.Confirmation' | translate}}#</p>
																			<p>{{getConfirmationNo(item) | uppercase}}</p>
																		</div>
																	</div>
																</div>
															</div>
															<div>
	
															</div>
														<div  class="booking-details-icons">
															<div class="item">
																<div>
																	<img src="assets/images/calender.svg" alt="" />
																</div>

																<div>add to calendar</div>
															</div>
															<div class="dividing-line"></div>
															<div class="item">
																<div><img src="assets/images/tripit.png" alt=""></div>
																<div>add to tripit</div> 
															</div>
															<div class="dividing-line"></div>
															<div class="item">
																<div>
																	<img src="assets/images/Expense.svg" alt="" />
																</div>
																<div>Expense </div>
															</div>
															
															<div class="dividing-line"></div>
															<div class="item">
																<div><img src="assets/images/ic_receipt.svg" alt="" /></div>
																<div>download invoice</div>
															</div>
															<div class="dividing-line"></div>
															<div class="item">
																<div><img src="assets/images/ic_edit.svg" alt="" /></div>
																<div>rebook and cancel</div>
															</div>
															<div class="dividing-line"></div>
															<div class="item">
																<div>
																	<img src="assets/images/ic_cancel.svg" alt="" />
																</div>
																<div> cancel reservation</div>
															</div>
															
															
															
															
														</div>


												</div>
											</div>
											<div class="booking-detail-data-inner" style="display: none;">
												<div class="date-duration">
													<div class="date">
                                                        {{getDisplayDateTimeForFlights(item.option.flight_option.legs[0].hops[0].starts, 'MMM d EE h:mm a')}}</div>
													<div class="duration">
														<label>{{'bookingHistory.TotalDuration' |
															translate}}</label> <span>{{getDuration(item,0)?.hrs
															+ 'h ' + getDuration(item,0)?.mins + 'm'}}</span>
													</div>
												</div>
												<div>
													<div class="flight-schedule">
                                                        <div *ngFor="let hop of item.option.flight_option.legs[0].hops;let j=index ">
															<div class="flight-box">
																<div class="flight-box-left">
																	<img src="{{hop.imageUrl}}" />
																</div>
																<div class="flight-box-right">
																	<div *ngIf="getHopSeatNo(hop)">
                                                                        <div class="seat-no">{{'bookingHistory.Seat' | translate}} {{hop.seatNo | uppercase}}</div>
																	</div>
																	<div class="flight-modal-name-duration">
                                                                        <span class="flight-modal-name">{{hop.carrier}}{{hop.flightNumber}}</span> <span class="flight-modal-duration">{{getDurationOfHop(hop.duration)}}</span>
																	</div>
																	<div class="flight-ticket-detail">
                                                                        {{ item.option.flight_option.legs[0].hops[j].fareClassName | translate}}, {{'bookingHistory.operatedby' | translate}} {{getAirlineFullName(hop.ocarrier)}}
                                                                    </div>
																	<div class="flight-timing-stops">
																		<ul>
																			<li><label>{{getDisplayDateTimeForFlights(hop.starts,
																					'h:mm a')}}</label>
																				<span>{{getAirportCity(hop.from)}}({{hop.from}})</span>
																			</li>
																			<li><label>{{getDisplayDateTimeForFlights(hop.ends,
																					'h:mm a')}}</label>
																				<span>{{getAirportCity(hop.to)}}({{hop.to}})</span>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div *ngIf="getLayoverDetails(item,legIndex,j)">
																<div class="flight-layover">
                                                                    <span class="flight-layover-left">{{'bookingHistory.Layoverin'
																		| translate}}
																		{{item.option.flight_option.layoverInfo[legIndex][j].in}}</span>
																	<span class="flight-layover-right">
																		{{item.option.flight_option.layoverInfo[legIndex][j].duration.hrs
																		+ 'h ' +
																		item.option.flight_option.layoverInfo[legIndex][j].duration.mins
																		+ 'm'}}</span>
																</div>
															</div>
														</div>
													</div>


													<div *ngIf="getBaggageDetails(item)" class="not-active">
                                                        <i class="fa fa-suitcase" aria-hidden="true" [ngStyle]="{color: getBaggageDetailsColor(item), 'margin-right': '5px', 'margin-bottom': '5px'}"></i>
														<span>{{getBaggageDetails(item)}}</span>
													</div>




												</div>
											</div>
											<div *ngIf="i==length1-1">
												<div class="price-section">
													<div *ngFor="let fare of fareList">
														<div *ngIf="fare.price != 0" class="price-section-content" style="display:none" >
															<div class="price-row" *ngIf="fare.event_type=='flight'">
																<label class="text-right">{{'bookingHistory.FLIGHTFARE'
																	| translate}}</label> <span>{{getPriceItem(fare) | currency
																	:
																	getCurrencySymbol(item.option.flight_option)
																	:
																	'code': '1.2-2'}}</span>
															</div>
															<div class="price-row" *ngIf="fare.event_type =='hotel'">
																<label class="text-right">{{'bookingHistory.HOTELFARE'
																	| translate}}</label> <span>{{getPriceItem(fare) | currency
																	:
																	getCurrencySymbol(item.option.flight_option)
																	:
																	'code': '1.2-2'}}</span>
															</div>
															<div class="price-row" *ngIf="fare.event_type =='cars'">
																<label class="text-right">Car Fare<span
																		*ngIf="length1===1">:</span>
																</label> <span>{{getPriceItem(fare) | currency :
																	getCurrencySymbol(item.option.flight_option)
																	:
																	'code': '1.2-2'}}</span>
															</div>
                                                            <div class="price-row" *ngIf="fare.event_type=='other' && fare.price != 0 && fare.transactionEventType==='travel_credit'">
																<label class="text-right">{{fare.description
																	|uppercase}}</label>
																<span>{{getCreditPrice(fare) | currency :
																	getCurrencySymbol(item.option.flight_option)
																	:
																	'code': '1.2-2'}}</span>
															</div>
                                                            <div class="price-row" *ngIf="fare.event_type=='other' && fare.price != 0 && fare.transactionEventType!=='travel_credit'">
																<label class="text-right">{{fare.description
																	|uppercase}}</label> <span>{{getPriceItem(fare) | currency :
																	getCurrencySymbol(item.option.flight_option)
																	:
																	'code': '1.2-2'}}</span>
															</div>
														</div>
													</div>
													<div class="price-section-total">
														<div class="price-row" *ngIf="this.gallopCash > 0">
															<label class="text-right"> {{'bookingHistory.GallopCashApplied' | translate}}</label>
															<span>{{this.gallopCash
																| currency :
																getCurrencySymbol(item.option.flight_option) :
																'code': '1.2-2'}}</span>
														</div>
														<div class="price-row">
															<label class="text-right">{{'bookingHistory.TotalPrice'
																| translate}}</label> <span class="totalPrice">{{getTotalFare(fareList,item)
																| currency :
																getCurrencySymbol(item.option.flight_option) :
																'code': '1.2-2'}}</span>
														</div>
													</div>
													<div *ngIf="canShowGallopCashEarning()" class="gallopCash">
                                                        <span *ngIf="isTransactionApproved()">{{'bookingHistory.YouhaveearnedUSD'
															| translate}}  {{this.getCurrencySymbol(item.option.flight_option)}} {{getGallopCashEarning() | number
															:'1.1-2'}} GallopCash {{'bookingHistory.onthisitinerary'
															| translate}}.</span> <span *ngIf="!isTransactionApproved()">{{'bookingHistory.YouwillearnUSD'
															| translate}} {{this.getCurrencySymbol(item.option.flight_option)}}  {{getGallopCashEarning() | number
															:'1.1-2'}} GallopCash {{'bookingHistory.onthisitinerary'
															| translate}}.</span>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>



								<div class="booking-left-inner" *ngIf="item.type =='hotel'">
									<div class="booking-history-upper-header">
										<div class="font-bold" >
											<span>My Trip to </span> 
											<span>{{getAirportCity(item.option.hotel_option.hotelName)}}</span>
											<img src="assets/images/edit-fill.svg" alt="" srcset="" class="pancel-img" >
										</div>
										<div>
											<div>We’ve pre-filled the trip with a name but feel free to modify it or,</div>
											<select name="" id="">
												<option value="">add to an existing trip</option>
											</select>
										</div>
									</div>
									<div class="booking-detail-data">
										<div>
											<div class="d-flex">
												<div>
													<div class="booking-img">
														<img src="assets/images/hotel-circle.png" />
													</div>
													<div></div>
												</div>
												<div style="border-radius: 6px;width: 95%;box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);float: right;">
															<div class="d-flex justify-content-between">
																<div style="padding: 30px 5px;" >
																	<div >
																		<div class="d-flex" style="margin-top: 20px;">
																			<div class="company-img" >
																				<img onerror="this.onerror = null; this.src = 'assets/images/hotel/hotel.png';"
																					src="assets/images/hotel/hotel.png" />
																			</div>
																			<div class="w-10">
																				<div  class="d-flex position-relative w-10 ">
																					<div class="flight-start-timing">
																						<div>{{'bookingHistory.CheckIn' | translate}}</div>
																						<div>{{getDisplayDate(item.option.hotel_option.checkInDate,'EEEE, MMM d,yyyy')}}</div>
																					</div>
																					
																						<div class="flight-arrow">
																							<div></div>
																						</div>
																					<div class="flight-start-timing">
																						<div>{{'bookingHistory.CheckOut' | translate}}</div> 
																						<div>{{getDisplayDate(item.option.hotel_option.checkOutDate,'EEEE, MMM d,yyyy')}}</div>
																					</div>
																				</div>
																				
																				<div>{{item.option.hotel_option.address}}</div>
																				<div>{{item.option.hotel_option.hotelRooms.length}} {{'bookingHistory.Room' | translate}} {{item.option.hotel_option.hotelRooms[0].hotelRoomName}}</div>
																				<div class="see-details-button">See details</div>
																			</div>
																		</div>
																	</div>
																</div>

																<div class="d-flex justify-content-center align-items-center" style="background-color: #E3E3E3;width: 190px">
																	<div class="text-center">
																		<div >
																			<div *ngFor="let fare of fareList">
																				<div *ngIf="fare.price != 0">
																					<div class="price-show" *ngIf="fare.event_type =='hotel'">
																						<span>{{getPriceItem(fare) | currency :getCurrencySymbol(item.option.hotel_option):'code': '1.2-2'}}</span>
																					</div>
																					
																				</div>
																			</div>
																		</div>
																		<p class="policy-show" *ngIf="item.option.hotel_option.inPolicy == true">WITHIN POLICY</p>
																		<p class="policy-show" style="color: red;" *ngIf="item.option.hotel_option.inPolicy == false">OUTSIDE POLICY</p>
																		<div class="confirmation-text">
																			<p>{{'bookingHistory.Confirmation' | translate}}#</p>
																			<p>{{getConfirmationNo(item) | uppercase}}</p>
																		</div>
																	</div>
																</div>
															</div>
															<div>
	
															</div>
														<div  class="booking-details-icons">
															<div class="item">
																<div>
																	<img src="assets/images/calender.svg" alt="" />
																</div>

																<div>add to calendar</div>
															</div>
															<div class="dividing-line"></div>
															<div class="item">
																<div><img src="assets/images/tripit.png" alt=""></div>
																<div>add to tripit</div> 
															</div>
															<div class="dividing-line"></div>
															<div class="item">
																<div>
																	<img src="assets/images/Expense.svg" alt="" />
																</div>
																<div>Expense </div>
															</div>
															
															<div class="dividing-line"></div>
															<div class="item">
																<div><img src="assets/images/ic_receipt.svg" alt="" /></div>
																<div>download invoice</div>
															</div>
															<div class="dividing-line"></div>
															<div class="item">
																<div><img src="assets/images/ic_edit.svg" alt="" /></div>
																<div>rebook and cancel</div>
															</div>
															<div class="dividing-line"></div>
															<div class="item">
																<div>
																	<img src="assets/images/ic_cancel.svg" alt="" />
																</div>
																<div> cancel reservation</div>
															</div>
															
															
															
															
														</div>


												</div>
											</div>
									<div class="booking-detail-header" style="display: none;">
										<div style="padding-right: 5px;" class="text-left">
											<p class="font-bold">{{item.option.hotel_option.hotelName}}</p>
										</div>
										<div class="text-right">
											<p>{{'bookingHistory.Confirmation' | translate}}</p>
                                            <p class="font-bold">{{getConfirmationNo(item) | uppercase}}</p>
										</div>
									</div>
									<div class="booking-detail-data" style="display: none;">
										<div class="booking-detail-data-inner">
											<div class="hotel-booking-detail">
												<div class="hotel-booking-img">
                                                    <img onerror="this.onerror=null;this.src='assets/images/hotel/hotel.png'" style="width: 71px !important; height: 71px !important;" *ngIf="item.url !==''" src="{{item.url}}" /> <img *ngIf="item.url===''"
                                                        src="assets/images/hotel/hotel.png" />
												</div>
												<div class="hotel-booking-content">
													<div class="book-data book-data1">
														<label style="margin-right: 2px;">{{'bookingHistory.CheckIn' | translate}}</label> <span>
															{{getDisplayDate(item.option.hotel_option.checkInDate,
															'EE, MMM d')}}</span>
													</div>
													<div class="book-data book-data2">
														<label>{{'bookingHistory.CheckOut' | translate}}</label> <span>
															{{getDisplayDate(item.option.hotel_option.checkOutDate,
															'EE, MMM d')}}</span>
													</div>
													<div class="book-data book-data1">
														<label>
															{{item.option.hotel_option.stay}}-{{item.option.hotel_option.stay
															> 1 ? ('bookingHistory.Nights' | translate)
															:('bookingHistory.Night' | translate)}}</label>
													</div>
													<div class="book-data book-data2">
														<label>{{item.option.hotel_option.hotelRooms.length}}
															{{'bookingHistory.Room' | translate}}</label>
													</div>
													<label>{{item.option.hotel_option.hotelRooms[0].hotelRoomName}}</label>
												</div>
											</div>

											<div class="hotel-contact">
												<div>
													<span class="contact-icon"><img
															src="assets/images/ic_place.svg" /></span> <span class="contact-text">{{item.option.hotel_option.address}}</span>
                                                    <ng-container *ngIf="isMapSupported()"> <a (click)="mapsSelector(item.option.hotel_option.latitude,item.option.hotel_option.longitude)" style="margin-left: 8px; color: blue; cursor: pointer; white-space: nowrap;">
															{{'bookingHistory.OPENMAP' | translate}}</a> </ng-container>
												</div>
												<div>
													<span class="contact-icon"><img
															src="assets/images/ic_phone.svg" /></span> <span class="contact-text"> <a *ngIf="getHotelPhoneNo(item)"
															href="{{'tel:'+ this.getHotelPhoneNo(item)}}"
															data-rel="external">{{getHotelPhoneNo(item)}}</a></span><span *ngIf="!getHotelPhoneNo(item)"> {{'bookingHistory.Phonenumberisnotavailable' | translate}}</span>
												</div>
											</div>

											<div *ngIf="getHotelPolicy(item)" class="hotel-about-detail-text">
												{{getHotelPolicy(item)}}</div>
										</div>
										<div *ngIf="i==length1-1">
											<div class="price-section">
												<div class="price-section-content">
													<div class="price-row" *ngIf="length1===1">
														<label class="text-left">{{'bookingHistory.Roomcost(pernight)' | translate}}:</label> <span>{{
															getPerNightCost(item) |currency :
															getCurrencySymbol(item.option.hotel_option) :
															'code': '1.2-2'}}</span>
													</div>
													<div class="price-row" *ngIf="length1===1">
														<label class="text-left">{{'bookingHistory.Nights' | translate}}:</label>
														<span>{{item.option.hotel_option.stay}}</span>
													</div>
													<div class="price-row" *ngIf="length1===1">
														<label class="text-left">{{'bookingHistory.Rooms' | translate}}:</label>
														<span>{{item.option.hotel_option.hotelRooms.length}}</span>
													</div>

													<div class="price-row" *ngIf="length1===1">
														<label class="text-left">{{'bookingHistory.Guests' | translate}}:</label>
														<span>{{getTotalPassengerFromEmail(item)}}</span>
													</div>
                                                    <div class="price-row" *ngIf="length1===1 && getTax(item) && showTaxes()">
														<label class="text-left">{{'bookingHistory.TaxesandFees' | translate}}:</label>
														<span>{{getTax(item)
															|currency :
															getCurrencySymbol(item.option.hotel_option) :
															'code': '1.2-2'}}</span>
													</div>
													<div *ngFor="let fare of fareList">
														<div *ngIf="fare.price != 0">
															<div class="price-row" *ngIf="fare.event_type=='flight'">
																<label class="text-left">{{'bookingHistory.FLIGHTFARE'
																	| translate}}</label> <span>{{getPriceItem(fare) | currency
																	:
																	getCurrencySymbol(item.option.hotel_option)
																	:
																	'code': '1.2-2'}}</span>
															</div>
															<div class="price-row" *ngIf="fare.event_type =='cars'">
																<label class="text-left">{{'bookingHistory.CarFare' | translate}}<span
																		*ngIf="length1===1">:</span>
																</label> <span>{{getPriceItem(fare) | currency :
																	getCurrencySymbol(item.option.hotel_option)
																	:
																	'code': '1.2-2'}}</span>
															</div>
															<div class="price-row" *ngIf="fare.event_type =='hotel'">
																<label class="text-left">{{'bookingHistory.HOTELFARE'
																	| translate}}<span *ngIf="length1===1">:</span>
																</label> <span>{{getPriceItem(fare) | currency :
																	getCurrencySymbol(item.option.hotel_option)
																	:
																	'code': '1.2-2'}}</span>
															</div>
                                                            <div class="price-row" *ngIf="fare.event_type=='other' && fare.prie != 0">
																<label class="text-left">{{fare.description |
																	uppercase}}</label> <span>{{getPriceItem(fare) |currency :
																	getCurrencySymbol(item.option.hotel_option)
																	:
																	'code': '1.2-2'}}</span>
															</div>
														</div>
													</div>
													<div class="price-row" *ngIf="length1===1 && getResortFee(item)">
														<label class="text-left">{{'bookingHistory.Resortfees' | translate}}:</label>
														<span>{{getResortFee(item)
															|currency :
															getCurrencySymbol(item.option.hotel_option) :
															'code': '1.2-2'}}</span>
													</div>
												</div>
												<div class="price-section-total">
													<div class="price-row" *ngIf="this.gallopCash > 0">
														<label class="text-left">{{'bookingHistory.GallopCashApplied' | translate}}</label>
														<span>{{this.gallopCash
															| currency :
															getCurrencySymbol(item.option.hotel_option) :
															'code': '1.2-2'}}</span>
													</div>
												</div>
												<div class="price-row">
													<label class="text-right">{{'bookingHistory.TotalPrice'
														| translate}}</label> <span class="totalPrice">{{getTotalFare(fareList,item)
														|currency :
														getCurrencySymbol(item.option.hotel_option) :
														'code': '1.2-2'}}</span>
												</div>
											</div>
                                            <div class="row" *ngIf="getPaymentDetails()" style="font-size: 12px !important; margin-top: 8px !important;float:left;width:100%;">
												<label class="text-left"><b>{{'bookingHistory.Paymentdetails' | translate}}</b><br>{{
													getPaymentDetails() }}</label>
											</div>
											<div *ngIf="canShowGallopCashEarning()" class="gallopCash">
                                                <span *ngIf="isTransactionApproved()">{{'bookingHistory.YouhaveearnedUSD'
													| translate}} {{this.getCurrencySymbol(item.option.hotel_option)}} {{getGallopCashEarning() | number :'1.1-2'}}
													GallopCash {{'bookingHistory.onthisitinerary' |
													translate}}.</span> <span *ngIf="!isTransactionApproved()">{{'bookingHistory.YouwillearnUSD'
													| translate}} {{this.getCurrencySymbol(item.option.hotel_option)}} {{getGallopCashEarning() | number :'1.1-2'}}
													GallopCash {{'bookingHistory.onthisitinerary' |
													translate}}.</span>
											</div>
										</div>
									</div>
								</div>
								<div class="booking-left-inner" *ngIf="item.type =='cars'">
									<div class="booking-detail-header">
										<div style="padding-right: 5px;" class="text-left">
											<p class="font-bold">{{item.option.car_option.description}}</p>
										</div>
										<div class="text-right">
											<p>{{'bookingHistory.Confirmation' | translate}}</p>
                                            <p class="font-bold">{{getConfirmationNo(item) | uppercase}}</p>
										</div>
									</div>
									<div class="booking-detail-data" style="padding: 0 10px 11px 20px !important;">
										<div class="booking-detail-data-inner">
											<div class="hotel-booking-detail">
												<div class="hotel-booking-img" style="width: 120px !important;
													height: 140px !important">
                                                    <div><img *ngIf="carUrl(item.option.car_option)" src="{{carUrl(item.option.car_option)}}" onerror="this.src='https://s3.amazonaws.com/images.biztravel.ai/ota/carrentals/vehicle_notavailable_S.jpg' " />
													</div>
                                                    <div> <img style="margin-top:5px;height:auto!important;width:auto !important;border-radius: 6px" *ngIf="item.option.car_option.partnerLogo" src="{{ item.option.car_option.partnerLogo }}" /></div>
												</div>
												<div class="hotel-booking-content">
													<div class="book-data book-data1">
														<label>{{'bookingHistory.Pick-up' | translate}}</label> <span>
															{{getDisplayDateTimeForFlights(item.option.car_option.pickUpDate,
															'EE, MMM d')}}</span>
													</div>
													<div class="book-data book-data2">
														<label> {{'bookingHistory.Drop-off' | translate}}</label> <span>
															{{getDisplayDateTimeForFlights(item.option.car_option.dropOffDate,
															'EE, MMM d')}}</span>
													</div>
													<div class="book-data book-data1">
														<label> {{'bookingHistory.Pick-uptime' | translate}}</label> <span>
															{{item.option.car_option.pickUpTime}}</span>
													</div>
													<div class="book-data book-data2">
														<label>{{'bookingHistory.Drop-Offtime' | translate}}</label> <span>
															{{item.option.car_option.dropOffTime}}</span>
													</div>
													<div>
                                                        <span *ngIf="item.option.car_option.doors" class="duration-section car-duration">
															<span class="inlineblock icon-margin "><img class="door"
																	src="assets/images/car_gate.svg"></span>
                                                        <span class="inlineblock" style="margin-left:4px !important;margin-right:6px !important;">{{item.option.car_option.doors}}</span>
														</span>

                                                        <span *ngIf="item.option.car_option.passengers" class="duration-section car-duration">
															<span class="inlineblock icon-margin "><i class="fa fa-user"
																	aria-hidden="true"></i></span>
                                                        <span class="inlineblock" style="margin-left:4px !important;margin-right:6px !important;">{{item.option.car_option.passengers}}</span>
														</span>

                                                        <span *ngIf="item.option.car_option.bags" class="duration-section walk-duration">
															<span class="inlineblock icon-margin"><i
																	class="fa fa-suitcase"
																	aria-hidden="true"></i></span>
                                                        <span class="inlineblock " style="margin-left:4px !important;margin-right:6px !important;">{{item.option.car_option.bags}}</span>
														</span>
													</div>


												</div>
											</div>

											<div class="hotel-contact">
												<div>
													<span style="margin-left:3px !important;font-weight: bold;">{{'bookingHistory.Pick-up' | translate}}:
													</span> <span class="contact-text">{{item.option.car_option.pickUpLocation}}</span>
												</div>
												<div>
                                                    <span style="margin-left:3px !important;font-weight: bold;">{{'bookingHistory.Drop-off' | translate}}:
													</span> <span class="contact-text" *ngIf="item.option.car_option.pickUpLocation !== item.option.car_option.dropOffLocation">{{item.option.car_option.dropOffLocation}}</span>
                                                    <span class="contact-text" *ngIf="item.option.car_option.pickUpLocation === item.option.car_option.dropOffLocation">
														{{'bookingHistory.Sameaspick-up' | translate}}</span>
												</div>
												<div>
												</div>
											</div>

											<div class="hotel-about-detail-text">
												{{item.option.car_option.carMake}}</div>
										</div>
										<div *ngIf="i==length1-1">
											<div class="price-section">
												<div class="price-section-content">
													<div class="price-row" *ngIf="length1===1">
														<!-- <label class="text-left"> Daily rate ({{item.option.car_option.numberOfDay}}x{{getCarPerDayPrice(item) | number : '1.2-2'}}):</label>  -->
														<label class="text-left"> {{'bookingHistory.BasePrice' | translate}} :</label>
														<span>
															{{getCarPrice(item.option.car_option) |currency :
															getCurrencySymbol(item.option.car_option) :
															'code': '1.2-2'}}</span>
													</div>
													<!--<div class="price-row" *ngIf="length1===1">
															<label class="text-left">Days:</label> <span>{{item.option.car_option.numberOfDay}}</span>
														</div>-->

													<div class="price-row" *ngIf="length1===1">
														<label class="text-left"> {{'bookingHistory.TaxesandFees' | translate}}:</label>
														<span>{{getTaxForCar(item)
															| currency :
															getCurrencySymbol(item.option.car_option) :
															'code': '1.2-2'}}</span>
													</div>
													<div *ngFor="let fare of fareList">
														<div *ngIf="fare.price != 0">
															<div class="price-row" *ngIf="fare.event_type=='flight'">
																<label class="text-left">{{'bookingHistory.FLIGHTFARE'
																	| translate}}</label> <span>{{getPriceItem(fare) | currency
																	:
																	getCurrencySymbol(item.option.car_option) :
																	'code': '1.2-2'}}</span>
															</div>
															<div class="price-row" *ngIf="fare.event_type =='hotel'">
																<label class="text-left">{{'bookingHistory.HOTELFARE'
																	| translate}}<span
																		*ngIf="length1===1">:</span>
																</label> <span>{{getPriceItem(fare) | currency :
																	getCurrencySymbol(item.option.car_option) :
																	'code': '1.2-2'}}</span>
															</div>
															<div class="price-row" *ngIf="fare.event_type =='cars'">
																<label class="text-left">{{'bookingHistory.CarFare'
																	| translate}}<span
																		*ngIf="length1===1">:</span>
																</label> <span>{{getPriceItem(fare)  | currency :
																	getCurrencySymbol(item.option.car_option) :
																	'code': '1.2-2'}}</span>
															</div>
                                                            <div class="price-row" *ngIf="fare.event_type=='other' && fare.prie != 0">
																<label class="text-left">{{fare.description |
																	uppercase}}</label> <span>{{getPriceItem(fare) |currency :
																	getCurrencySymbol(item.option.car_option) :
																	'code': '1.2-2'}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="price-section-total">
													<div class="price-row" *ngIf="this.gallopCash > 0">
														<label class="text-left">{{'bookingHistory.GallopCashApplied'
															| translate}}</label>
														<span>{{this.gallopCash
															| currency :
															getCurrencySymbol(item.option.car_option) :
															'code': '1.2-2'}}</span>
													</div>
												</div>
												<div class="price-row">
													<label class="text-right">{{'bookingHistory.TotalPrice'
														| translate}}</label> <span class="totalPrice">{{getTotalFare(fareList,item)
														|currency :
														getCurrencySymbol(item.option.car_option) :
														'code': '1.2-2'}}</span>
												</div>
											</div>
                                            <div class="row" *ngIf="getPaymentDetails() && item.option.car_option.paymentRequired" style="font-size: 12px !important; margin-top: 8px !important;float:left;width:100%;">
												<label class="text-left"><b>{{'bookingHistory.Paymentdetails' | translate}}</b><br>{{getPaymentDetails()
													}}</label>
											</div>
											<div *ngIf="canShowGallopCashEarning()" class="gallopCash">
                                                <span *ngIf="isTransactionApproved()">{{'bookingHistory.YouhaveearnedUSD'
													| translate}} {{this.getCurrencySymbol(item.option.car_option)}} {{getGallopCashEarning() | number :'1.1-2'}}
													GallopCash {{'bookingHistory.onthisitinerary' |
													translate}}.</span> <span *ngIf="!isTransactionApproved()">{{'bookingHistory.YouwillearnUSD'
													| translate}} {{this.getCurrencySymbol(item.option.car_option)}} {{getGallopCashEarning() | number :'1.1-2'}}
													GallopCash {{'bookingHistory.onthisitinerary' |
													translate}}.</span>
											</div>
										</div>
									</div>
								</div>


							</div>
                            <div class="booking-left-inner" *ngIf="this.detailList.length > 0 && (this.traveler && this.traveler.length>0)">
								<div class="booking-detail-header">

								</div>
								<div class="booking-detail-data" style="display: none;">
									<div class="booking-detail-data-inner">
										<div class="duration" style="padding-top: 20px;">
											<p class="font-bold" style="font-size:16px !important;">{{'bookingHistory.Travelers' | translate}}</p>
										</div>
										<hr>
										<div *ngFor="let item of this.traveler;let i=index">
											<div style="font-family: 'apercu-r';">{{item.firstName}} {{item.lastName}}
											</div>
											<hr *ngIf="i!==(this.traveler.length-1)">
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="booking-history-detail-content-right" style="display: none;">
                            <div *ngIf=" !this.hideChanges && (showCalenderButton() && showActionButtons())" class="booking-button-container">
                                <button id="calenderModalButton" class="button-white" (click)="goToCalendar(addCalendarModal)">
									<span class="button-white-img"> <img style="width: 18px;"
											src="assets/images/calendar.png" alt="" />
									</span> <span attr.data-track="TripDetailsPageButton"
										attr.data-params="button=AddToCalendar">{{'bookingHistory.AddtoCalender' |
										translate}}</span>
								</button>
								<div [hidden]="!this.modalShow">
                                    <ngx-new-modal [flightUrl]="this.calenderUrl" [hotelUrl]="this.calenderUrlHotel" [selectOption]="this.selectOptionForModal" [calUrls]="this.calUrls"></ngx-new-modal>
								</div>
							</div>

                            <div *ngIf="!this.hideChanges && (showTripItButton() && showActionButtons())" class="booking-button-container">
								<button class="button-white" (click)="goToTripId(addTripitModal)">
									<span class="button-white-img" attr.data-track="TripDetailsPageButton"
										attr.data-params="button=AddToTrips"> <img
											attr.data-track="TripDetailsPageButton" attr.data-params="button=AddToTrips"
											src="assets/images/tripit.png" alt="" />
									</span> <span attr.data-track="TripDetailsPageButton"
										attr.data-params="button=AddToTrips">{{'bookingHistory.AddtoTripIt' |
										translate}}</span>
								</button>
							</div>
							<div *ngIf="showExpensifyButton() && showExpenseButtons()" class="booking-button-container">
								<button class="button-white" (click)="showModal(addExpensifyModal)">
									<span class="button-white-img">
										<i class="fa fa-file-text" aria-hidden="true"></i>
									</span>
									<span> {{'bookingHistory.Expense' | translate}}</span>
								</button>
							</div>
                            <div *ngIf="showDownloadInvoiceButton() && (showActionButtons() && showExpenseButtons())" class="booking-button-text-container">
								<button class="button-text">
									<span class="button-text-img"> <img src="assets/images/ic_receipt.svg" alt="" />
									</span> <span attr.data-track="TripDetailsPageButton"
										attr.data-params="button=DownloadInvoice"
										(click)="downloadInvoices()">{{'bookingHistory.DOWNLOADINVOICE'
										| translate}}</span>
								</button>
							</div>
							<div *ngIf="!this.hideChanges && showActionButtons() && showRebookButton()" class="booking-button-text-container">
								<button *ngIf="this.detailList.length > 0  && this.detailList[0].type==='flight'" class="button-text" (click)="showModal(requestChangeModalFlight)">
									<span class="button-text-img"> <img src="assets/images/ic_edit.svg" alt="" />
									</span> <span attr.data-track="TripDetailsPageButton"
										attr.data-params="button=RequestChangesr">{{'bookingHistory.REBOOKANDCANCEL' |
										translate}}</span>
								</button>
								<button *ngIf="this.detailList.length > 0 && showChangesFlag(this.detailList[0].tripStatus,this.detailList[0].type,this.detailList[0]) &&  this.detailList[0].type!=='flight'" class="button-text" (click)="showModal(requestChangeModal)">
										<span class="button-text-img"> <img src="assets/images/ic_edit.svg" alt="" />
										</span> <span attr.data-track="TripDetailsPageButton"
											attr.data-params="button=RequestChangesr">{{'bookingHistory.REQUESTCHANGES' |
											translate}}</span>
									</button>
							</div>

							<div *ngIf="!this.hideChanges && showActionButtons()  && showRebookButton()" class="booking-button-text-container">
                                <button *ngIf="!this.cancellationRequestReceived" class="button-text" [ngStyle]="{'color':this.cancellationRequestReceived ? 'gray':'#315EF6'}" [disabled]="this.cancellationRequestReceived" (click)="showCancelModal(requestCancelModal,requestFlightCancelModal)"
                                    style="text-wrap:wrap">
									<span class="button-text-img"> <img src="assets/images/ic_cancel.svg" alt="" />
									</span> <span attr.data-track="TripDetailsPageButton"
										attr.data-params="button=CancelReservation">{{'bookingHistory.CANCELRESERVATION'
										|
										translate}}</span>
								</button>
							</div>
                            <div *ngIf="this.hideChanges && (this.hotelList2.length > 0 &&  showActionButtons())" class="booking-button-text-container1">
                                <h4 *ngIf="this.userAccountService.historyThumb!=='NONE'" style="width: 100%;white-space: nowrap;text-align:center;">
									{{this.userAccountService.feedbackMsg}}</h4>
                                <app-hotelfeedback [tripId]="this.tripid" [ticketId]="this.ticketid" [transactionId]="this.selectTransId" [eventId]="this.eventid" [component]="'booking'"></app-hotelfeedback>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


	<ng-template #addExpensifyModal let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel">
				{{'bookingHistory.Expense' | translate}}</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body">
			<div *ngIf="this.showFlightHotelCheckBox()">
				<label>{{'bookingHistory.ExpensifyAddDetails' | translate}}</label>
				<div *ngFor="let option of expensifyType">
					<div class="header-inner">
						<div class="header-left">
                            <input type="checkbox" id="{{option.name}}" class="mdl-checkbox" (change)="onExpensifyClicked(option.value, $event)" [checked]="isExpensifyChecked(option.value)">
						</div>
						<div class="header-right">
							<span class="mdl-checkbox__label">{{option.name |
								translate}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="input-field">
				<form method="post" id="expensifyForm" class="expensifyForm">
					<div class="row">

						<div style="width:100%;margin-top:10px;padding-left:10px;padding: 0 8px;">
							<label style="color:gray;margin-top:0px;margin-bottom: 0px;"> {{'bookingHistory.Selectyourexpensingtool' | translate}}:</label>
							<div class="formControl99" id="expennsify">
                                <ng-select #expense [items]="expenseProviders" bindLabel="" dropdownPosition="bottom" bindValue="id" [searchable]="false" [clearable]="false" [formControl]="expensifyEmailControlDD" class="booking-history-expense-form">
									<ng-template ng-header-tmp>
										<div class="selectox-header">
											<span> {{'bookingHistory.Expensingtool' | translate}}</span>
											<span class="selectBox-remove" (click)="expense.toggle()"><span
													class="material-icons">clear</span></span>
										</div>
									</ng-template>
									<ng-template ng-label-tmp let-item="item">
										<img class="expense-image" src="{{item.url}}" />
									</ng-template>
									<ng-template ng-option-tmp let-item="item" let-index="index">
										<img class="expense-image" src="{{item.url}}" />
									</ng-template>
								</ng-select>
							</div>
						</div>
						<div class="col-lg-12  col-md-12 col-sm-12 col-xs-12">
							<div class="input-box">
                                <input [formControl]="expensifyEmailControl" style="max-width: 100% !important;" class="input-textfield input-textfield-lg" id="expensifyLoginEmail" name="expensifyLoginEmail" placeholder="{{this.getPlaceholderText()}}" type="text">
                            </div>
                            <div *ngIf="expensifyEmailControl.hasError('required') && (expensifyEmailControl.touched || expensifyEmailControl.dirty)" class="error">{{'bookingHistory.Thisfieldisrequired' | translate}}
                            </div>
                            <div *ngIf="expensifyEmailControl.hasError('pattern') && (expensifyEmailControl.touched || expensifyEmailControl.dirty)" class="error">{{'bookingHistory.Pleaseenteravalidemail' | translate}}
							</div>
						</div>
					</div>
				</form>
			</div>
			<div *ngIf="this.errorMsg">
				<span class="text-danger">{{'bookingHistory.Pleaseselectanyofcheckboxdefineabove'
					| translate}}</span>
			</div>
			<div class="modal-form-button">
                <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="onExpensifyAdd(detailList)">{{'bookingHistory.SendReceipttoExpense' | translate}}</button>
				<button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'
					| translate}}</button>
			</div>
		</div>
	</ng-template>


	<ng-template #requestChangeModalFlight let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
				{{'bookingHistory.RebookAndCancel' | translate}}</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body requestModalBody">
            <div class="input-field">
				<label style="font-size: 16px;font-family:'apercu-r';text-align: left;
				margin-left: 13px;">{{'bookingHistory.Pleasenoteonceyourebookthisreservationwillbecancelledanditcannotbeundone.'
					| translate}}</label>
		
			</div>
			
			<div class="modal-form-button" style="margin-bottom: 30px;">
                <button class="btn primary-button" (click)="onRequestChangeForFlight(detailList)" [disabled]="this.checked">{{'bookingHistory.PROCEEDTOREBOOK'
					| translate}}</button>
			</div>
		</div>
		<div class="modal-footer">
			
		</div>
	</ng-template>


	<ng-template #requestCancelModal let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
				{{'bookingHistory.Cancelyour' | translate}} {{getHeading()}}?</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body requestModalBody" style="text-align: left !important;">
			<div *ngFor="let item of detailList;let i=index;">
				<div class="input-field" style="font-size: 15px;float: left;padding-left: 12px;text-align: left;">
                    <label *ngIf="i===0 && getHeading()!=='approval request'">{{getCarCancelMsg(item.type,item)}}</label>
				</div>
				<div *ngIf="item.type =='flight'" style="text-align: left;">
                    <label style="float: left;padding-left: 12px;margin-top:10px;">{{getAirlineFullName(item.option.flight_option.legs[0].hops[0].ocarrier)}}:{{getDisplayDateTimeForFlights(item.option.flight_option.legs[0].hops[0].starts,
						'EE, MMM d')}} {{'bookingHistory.from' | translate}}
						{{item.option.flight_option.legs[0].hops[0].from}}
						{{'bookingHistory.to' | translate}}
						{{item.option.flight_option.legs[0].hops[item.option.flight_option.legs[0].hops.length-1].to}}
					</label>
				</div>
				<div *ngIf="item.type =='hotel'">
					<label style="float: left;padding-left: 12px;margin-top:10px;">{{'bookingHistory.Hotel' | translate}}:
						{{item.option.hotel_option.hotelName}},{{item.option.hotel_option.address}}
					</label>
					<label style="float: left;padding-left: 12px;margin-top:10px;">{{'bookingHistory.CheckIn' |
						translate}}:
						{{getDisplayDate(item.option.hotel_option.checkInDate,'MMM d')}} ; {{'bookingHistory.CheckOut' |
						translate}}:
						{{getDisplayDate(item.option.hotel_option.checkOutDate,'MMM d')}}</label>
				</div>
				<div *ngIf="item.type =='cars'">
					<label style="float: left;padding-left: 12px;margin-top:10px;"> {{'bookingHistory.Pick-Up' | translate}}:
						{{getDisplayDateTimeForFlights(item.option.car_option.pickUpDate,
						'MMM d')}}, {{item.option.car_option.pickUpTime}} - {{item.option.car_option.pickUpLocation}}
					</label>
					<label style="float: left;padding-left: 12px;margin-top:10px;"> {{'bookingHistory.Drop-off' | translate}}:
						{{getDisplayDateTimeForFlights(item.option.car_option.dropOffDate,
						'MMM d')}}, {{item.option.car_option.dropOffTime}} - {{item.option.car_option.dropOffLocation}}
					</label>
				</div>
			</div>
			<div class="modal-form-button">
                <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="cancelRequest(detailList)"><span style="margin-right: 4px;">{{'bookingHistory.Confirm' | translate}}</span> <span
						*ngIf="getHeading()!=='approval request'" >{{getHeading()}}</span> {{'bookingHistory.Cancellation' | translate}}</button>
				<button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'
					| translate}}</button>
			</div>
		</div>
        <div class="modal-footer" style="text-align: center;padding-top:15px;padding-bottom:25px;display:block !important;color:gray;">
			{{'bookingHistory.Pleasenoteonce' | translate}} {{getHeading1()}} {{'bookingHistory.iscancelleditcannotbeundone.' | translate}}
		</div>
	</ng-template>
	<ng-template #requestFlightCancelModal let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
				{{'bookingHistory.Cancelyour' | translate}} {{getHeading()}}?</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body requestModalBody">
            <div *ngIf="getHeading()!=='approval request'" style="font-size: 15px;float: left;padding-left: 12px;text-align: left;">
				{{getCancelMsg()}}</div>
			<div *ngFor="let item of detailList;let i=index;">
				<div *ngIf="item.type =='flight'" style="text-align: left;">
					<label style="float: left;padding-left: 12px;margin-top:10px;">{{'bookingHistory.flight' | translate}} {{i+1}}:
						{{getDisplayDateTimeForFlights(item.option.flight_option.legs[0].hops[0].starts,
						'MMM d')}} {{'bookingHistory.from' | translate}}
						{{item.option.flight_option.legs[0].hops[0].from}}
						({{getAirportCity(item.option.flight_option.legs[0].hops[0].from)}}
						)
						{{'bookingHistory.to' | translate}}
						{{item.option.flight_option.legs[0].hops[item.option.flight_option.legs[0].hops.length-1].to}}
						({{getAirportCity(item.option.flight_option.legs[0].hops[item.option.flight_option.legs[0].hops.length-1].to)}})
					</label>
				</div>
			</div>
			<div class="modal-form-button">
                <button *ngIf="!sendRequestProgress" class="btn primary-button" (click)="cancelRequest(detailList)"><span style="margin-right: 4px;">{{'bookingHistory.Confirm' | translate}}</span><span
						*ngIf="getHeading()!=='approval request'" >{{getHeading()}}</span> {{'bookingHistory.Cancellation' | translate}}</button>
				<button *ngIf="sendRequestProgress" class="btn primary-button">{{'paymentDetails.Pleasewait'
					| translate}}</button>
			</div>
		</div>
        <div class="modal-footer" style="text-align: center;padding-top:15px;padding-bottom:25px;display:block !important;color:gray;">
			{{'bookingHistory.Pleasenoteonceflightiscancelleditcannotbeundone.' | translate}}

		</div>
	</ng-template>
	<ng-template #requestChangeModal let-modal>
			<div class="modal-header">
				<h5 class="modal-title" id="myModalLabel" style="width: 100% !important; text-align: left">
					{{'bookingHistory.RequestChanges' | translate}}</h5>
				<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
					<i class="material-icons">close</i>
				</button>
			</div>
			<div class="modal-body requestModalBody">
            <div class="input-field">
					<label>{{'bookingHistory.PleasetelluswhatchangeyouwouldlikeforyourTrip'
						| translate}}</label>
					<div *ngFor="let item of detailList">
						<div *ngIf="item.type =='flight'">
                        <label class="font-bold">{{getAirlineFullName(item.option.flight_option.legs[0].hops[0].ocarrier)}}:{{getDisplayDateTimeForFlights(item.option.flight_option.legs[0].hops[0].starts,
								'EE, MMM d')}} {{'bookingHistory.from' | translate}}
								{{item.option.flight_option.legs[0].hops[0].from}}
								{{'bookingHistory.to' | translate}}
								{{item.option.flight_option.legs[0].hops[item.option.flight_option.legs[0].hops.length-1].to}}
							</label>
						</div>
						<div *ngIf="item.type =='hotel'">
							<label class="font-bold"> {{'bookingHistory.CheckIn' | translate}} {{getDisplayDate(item.option.hotel_option.checkInDate ,
								'EE, MMM d')}}-{{item.option.hotel_option.hotelName}},{{item.option.hotel_option.address}}
							</label>
						</div>
						<div *ngIf="item.type =='cars'">
							<label class="font-bold">
								{{'bookingHistory.Pick-Up' | translate}}:{{getDisplayDateTimeForFlights(item.option.car_option.pickUpDate,
								'EE, MMM
								d')}}-{{item.option.car_option.description}},{{item.option.car_option.pickUpLocation}}
							</label>
						</div>
					</div>
					<div style="position: relative;float: left;
					width: 100%;">
							<span  class="showNumber">
									{{messageForChange.length}}/255
								  </span>
                    <textarea placeholder="{{'bookingHistory.Adddetailsforyourchange' | translate}}" [(ngModel)]="messageForChange" maxlength="255" (focus)="getChecked()" style="position: relative;padding-right: 55px;padding-top: 20px;" class="modal-textarea input-textfield"></textarea>
							  </div>
				</div>
				<div *ngIf="showChangeRequestError">
					<span class="text-danger">{{'bookingHistory.youmusthavetowritethechangemessage'
						| translate}} </span>
				</div>
				<div class="modal-form-button">
                <button class="btn primary-button" (click)="onRequestChange(detailList)" [disabled]="this.checked">{{'bookingHistory.SubmitChangeRequest'
						| translate}}</button>
				</div>
			</div>
			<div class="modal-footer">
				<p>
                <span>{{'bookingHistory.Note' | translate}}</span> {{'bookingHistory.Sendingarequestwillnotimpactormodifyyourreservationwithoutfurtheraction.YouwillreceiveanemailfromTripwiththebestoptionsandapplicablechangefeeorfaredifferencesforyoutorespondtobeforeachangeisexecuted'
					| translate}}
				</p>
			</div>
		</ng-template>
	<ng-template #addTripitModal let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel">
				{{'bookingHistory.AddtoTripIt' | translate}}</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body">
			<div *ngFor="let option of expensifyType">
				<div class="header-inner">
					<div class="header-left">
                        <input id="{{option.name}}" value="{{option.value}}" class="mdl-radio__outer-circle" type="radio" name="{{option.name}}" [(ngModel)]="radioSelect" (change)="setTripItUrl(option.value)">
					</div>
					<div class="header-right">
						<span class="mdl-radio__label"> {{option.name | translate}}</span>
					</div>
				</div>
			</div>
			<div *ngIf="this.showRadioError">
				<span class="text-danger">{{'bookingHistory.youmusthavetoselectoneoftheabove'
					| translate}}</span>
			</div>
			<div class="modal-form-button">
				<button class="btn primary-button" (click)="proceedtoTripIt()">{{'bookingHistory.AddtoTripIt'
					| translate}}</button>
			</div>
		</div>
	</ng-template>
	<ng-template #addCalendarModal let-modal style="max-width: 310px !important;">
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel">
				{{'bookingHistory.AddtoCalender' | translate}}</h5>
			<button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
				<i class="material-icons">close</i>
			</button>
		</div>
		<div class="modal-body" (click)="onSmartModelCancel('calendarOptionModal')">
			<div *ngFor="let option of expensifyType">
				<div class="header-inner">
					<div class="header-left">
                        <input id="option_answer_{{option.name}}" [value]='option.value' type="radio" name="type" [(ngModel)]="selectOption" class="mdl-radio__outer-circle" (change)="setCalendarUrl(option.value)">
					</div>
					<div class="header-right">
						<span class="mdl-radio__label"> {{option.name | translate}}</span>
					</div>
				</div>
			</div>
			<div *ngIf="this.errorInCalenderRadio">
				<span class="text-danger">{{'bookingHistory.youmusthavetoselectoneoftheabove'
					| translate}} </span>
			</div>
			<div class="modal-form-button">
                <button class="btn primary-button" (click)="getOpenModal();$event.stopPropagation();">{{'bookingHistory.AddtoCalender'
					| translate}}</button>
			</div>
			<div [hidden]="!this.modalShow1">
                <ngx-new-modal [flightUrl]="this.calenderUrl" [hotelUrl]="this.calenderUrlHotel" [selectOption]="this.selectOptionForModal" [calUrls]="this.calUrls"></ngx-new-modal>
			</div>
		</div>
	</ng-template>
	<app-navigation></app-navigation>