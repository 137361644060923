import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { SearchService } from '../search.service';
import { Location } from '@angular/common';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { FilterService } from '../filter.service';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  public static POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH = 'NETWORK_ERROR_ON_FLIGHTSEARCH';
  public static POPUP_ID_NETWORK_ERROR_ON_HOTELSEARCH = 'NETWORK_ERROR_ON_HOTELSEARCH';
  public static POPUP_ID_NETWORK_ERROR_ON_BOOK = 'NETWORK_ERROR_ON_BOOK';
  public static POPUP_ID_NETWORK_ERROR_ON_ROOMLIST = 'NETWORK_ERROR_ON_ROOMLIST';
  public static POPUP_ID_NETWORK_ERROR_ON_APPROVAL_REQUEST = 'NETWORK_ERROR_ON_APPROVAL_REQUEST';
  public static POPUP_ID_NETWORK_ERROR_ON_BOOKPOLLING = 'NETWORK_ERROR_ON_BOOKPOLLING';
  public static POPUP_ID_NETWORK_ERROR_ON_BOOKINPROGRESS = 'NETWORK_ERROR_ON_BOOKINPROGRESS';
  public static POPUP_ID_NETWORK_ERROR_ON_ALLFARES = 'NETWORK_ERROR_ON_ALLFARES';






  // bsModalRef: BsModalRef;

  @Input() popUpTitle = 'Connection Lost';
  @Input() bodyMsg = 'Sorry, we lost the connection.Please try again';
  @Input() link = 'Try Again';
  @Input() popupId = PopupComponent.POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH;
  constructor(
    private modalService: BsModalService,
    public searchService: SearchService,
    public router: Router,
    private filterService: FilterService,
    public bsModalRef: BsModalRef,
    private gallopLocalStorage: GallopLocalStorageService,
    private location: Location,
  ) {

    this.searchService.networkPopupListener.asObservable().subscribe(
      input => {
        if (this.bsModalRef) {
          this.bsModalRef.hide();
        }
        if (input != null) {
          this.bsModalRef = input;
        }
      }
    );
  }

  ngOnInit() {
  }

  onPopUPCTAClick() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH) {
      this.router.navigate(['/search']);
    }
    if(this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ALLFARES){
     
      //this.filterService.broadcastGetFiltersResults();  
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_HOTELSEARCH) {
      this.router.navigate(['/search'],
        {
          queryParams:
          {
            type: 'hotel',
          },
          replaceUrl: true
        });
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOK) {
      this.router.navigate(['bookingHistory'],
        {
          queryParams:
          {
            type: 'list',
          },
          replaceUrl: true
        }
      );
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ROOMLIST) {
      let hotelSearchQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequest")));
      if (hotelSearchQueryParam) {
        this.router.navigate(["hotels"],
          {
            queryParams:
            {
              query: encodeURIComponent(JSON.stringify(hotelSearchQueryParam)),
              step: 0,
              resultFound: 1
            },
            replaceUrl: true
          });
      }
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_APPROVAL_REQUEST) {
      window.location.reload()
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOKPOLLING) {
      this.bsModalRef.hide();
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOKINPROGRESS) {
    }
  }

  onModelCancel() {
   if(this.bsModalRef){
     this.bsModalRef.hide();
   }
    if(this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ALLFARES){
      
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_FLIGHTSEARCH) {
      this.router.navigate(['/search']);
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_HOTELSEARCH) {
      this.router.navigate(['/search'],
        {
          queryParams:
          {
            type: 'hotel',
          },
          replaceUrl: true
        });
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_ROOMLIST) {
      let hotelSearchQueryParam = deserialize(JSON.parse(this.gallopLocalStorage.getItem("hotelSearchRequest")));
      if (hotelSearchQueryParam) {
        this.router.navigate(["hotels"],
          {
            queryParams:
            {
              query: encodeURIComponent(JSON.stringify(hotelSearchQueryParam)),
              step: 0,
              resultFound: 1
            },
            replaceUrl: true
          });
      }
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_BOOK) {
      this.router.navigate(['/search']);
    }
    if (this.popupId === PopupComponent.POPUP_ID_NETWORK_ERROR_ON_APPROVAL_REQUEST) {
      this.router.navigate(['/search']);
    }
  }

}
