export enum Role {
	ADMIN = 'ADMIN',
	DEPT_ADMIN = 'DEPT_ADMIN',
	MANAGER = 'MANAGER',
	APPROVER = 'APPROVER',
	TRAVEL_MANAGER = 'TRAVEL_MANAGER'
}
export class EmployeeInfo {
	public employeeId: number;

	public departmentId: number;
	public policyId: number;
	public agentRole: string;
	public companyId: number;
	public approvers: Array<any>;

	public userId: String;

	public jobTitle: String;

	public managerName: String;

	public managerEmail: String;

	public role: Role;

	public companyCardAccess: boolean;
	public showGovtRates: boolean;
	public showAAARates: boolean;
	public verified: boolean;
	public policyViolationFlag: string;
	public allowReimburse: boolean;

}
