import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { AppLoaderComponent } from './email-booking-flow/app-loader/app-loader.component';
import { AddCardWidgetComponent } from './email-booking-flow/add-card-widget/add-card-widget.component';
import { LoaderDotsComponent } from './loader-dots/loader-dots.component';
import { ngxNewModal } from './ngx-new-modal/ngx-new-modal.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { EmailHeaderComponent } from './email-booking-flow/header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HotelfeedbackComponent } from './hotelfeedback/hotelfeedback.component';
import { SearchComponent } from './search/search.component';
import { PaymentComponent } from './payment/payment.component';
import { flightSelected } from './flight-selected/flight-selected.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FilterComponent } from './filter/filter.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateOptionsPipe } from './util/translate-option';
import { SafeUrlPipe } from './util/safeUrl';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SwitchComponentComponent } from './switch-component/switch-component.component';
import { AirportsComponent } from './airports/airports.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CarSearchComponent } from './car-search/car-search.component';
import { AutocompleteModule } from 'ng2-input-autocomplete';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxMaskModule } from 'ngx-mask';
import { PassengerDetailComponent } from './passenger-detail/passenger-detail.component';
import { HotelRequestViewComponent } from './hotel-request-view/hotel-request-view.component';
import { FlightBoxComponent } from './search-result/flight-box/flight-box.component';
import { RouterModule } from '@angular/router';
import { SuperHeaderComponent } from './super-header/super-header.component';
import { ApprovalDetailComponent } from './approval-detail/approval-detail.component';


import { OnboardingComponent } from './onboarding/onboarding.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { bookingHistoryComponent } from './booking-history/booking-history.component';
import { UiScrollModule } from "ngx-ui-scroll";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxSmartModalModule,
    ReactiveFormsModule,
    TranslateModule,
    Ng5SliderModule,
    CollapseModule,
    NgxMaskModule,
    AccordionModule,
    NgSelectModule,
    RouterModule,
    BsDatepickerModule,
    GooglePlaceModule,
    NgxSliderModule,
    UiSwitchModule, AutocompleteModule,
    UiScrollModule,
    AutocompleteLibModule,
  ],
  declarations: [OnboardingComponent,FlightBoxComponent, HotelRequestViewComponent, CarSearchComponent, HotelfeedbackComponent, FilterComponent, RangeSliderComponent,SafeUrlPipe, AirportsComponent, TranslateOptionsPipe, SwitchComponentComponent,
    AppLoaderComponent, LoaderDotsComponent, AddCardWidgetComponent, ngxNewModal, bookingHistoryComponent,
    EmailHeaderComponent, NavigationComponent, SearchComponent, PaymentComponent,  ApprovalDetailComponent,flightSelected,SuperHeaderComponent,DropdownComponent
  ],

  exports: [OnboardingComponent,ApprovalDetailComponent,FlightBoxComponent, NgxMaskModule, HotelRequestViewComponent, GooglePlaceModule, bookingHistoryComponent, AutocompleteLibModule, AutocompleteModule, SafeUrlPipe,CarSearchComponent, CollapseModule, AirportsComponent, SwitchComponentComponent, Ng5SliderModule, TranslateOptionsPipe, RangeSliderComponent, FilterComponent, flightSelected, PaymentComponent, SearchComponent, HotelfeedbackComponent, TranslateModule, AppLoaderComponent, LoaderDotsComponent, AddCardWidgetComponent, ngxNewModal, EmailHeaderComponent, NavigationComponent,SuperHeaderComponent,DropdownComponent
  ]
})
export class ShareModule {

}