<form [formGroup]="searchCarForm">
    <div [ngClass]="{'search-wrapper': true, 'result': true}">
        <div class="server-side-filters">
            <div class="filter-row">
                <div class="filter-row-left">
                    <div class="col-auto">
                        <!--    <input  type="text"class="box"[attr.disabled]="true"  ><span class="{{this.searchCarForm.controls['sameAddress'].value ? 'checkbox':'checkbox1'}}"  (click)="boxChecked()"></span> 
                  <label class="checkboxLabel">Drop off location same a pickUp.</label>-->
                    </div>
                </div>
                <!--       <div class="filter-row-right">
                    <div class="col-auto" id="car-type">
                        <div class="filter custom-selectbox" (click)="car.toggle()">
                            <div class="select-input">
                                <ng-select #car appendTo="#car-type" dropdownPosition="bottom" [searchable]="false"
                                    [clearable]="false" [closeOnSelect]="true" [items]="carType" bindLabel="Name"
                                    bindValue="value" formControlName="carType">
                                    <ng-template ng-header-tmp>
                                        <div class="selectox-header">
                                            <span>Select car</span>
                                            <span class="selectBox-remove" (click)="car.toggle()"><span
                                                    class="material-icons">clear</span></span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                                <div class="select-overlay"></div>
                            </div>
                            <div class="field-value custom-selectbox-value colorSecondary">
                                {{ searchCarForm.controls['carType'].value }}</div>
                            <span class="control-icon icon-triangle"></span>
                        </div>
                    </div>
                    <div class="col-auto" id="car-brand">
                        <div class="filter custom-selectbox" (click)="carBrand.toggle()">
                            <div class="select-input">
                                <ng-select #carBrand appendTo="#car-brand" dropdownPosition="bottom"
                                    [searchable]="false" [clearable]="false" [closeOnSelect]="true"
                                    [items]="preDefinedCarBrands" bindLabel="label" bindValue="label"
                                    formControlName="carRentalCompany">
                                    <ng-template ng-header-tmp>
                                        <div class="selectox-header">
                                            <span>Select Car Brand</span>
                                            <span class="selectBox-remove" (click)="carBrand.toggle()"><span
                                                    class="material-icons">clear</span></span>
                                        </div>
                                    </ng-template>
                                </ng-select>
                                <div class="select-overlay"></div>
                            </div>
                            <div class="field-value custom-selectbox-value colorSecondary">
                                {{ searchCarForm.controls['carRentalCompany'].value }}</div>
                            <span class="control-icon icon-triangle"></span>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
        <div class="search-container">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div
                            class="{{ this.searchCarForm.controls['sameAddress'].value ? 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12':'col-6 col-lg' }}">

                            <div class="text-input-wrapper" style="margin-right: 1px;">
                                <div [ngClass]="{'text-input': true, 'invert': true, 'ng-autocomplete': true}"
                                    style="z-index: 12 !important;margin-bottom: 0px !important;height:auto !important;">
                                    <input type="hidden" formControlName="source" (ngModel)="pickUpModal" />
                                    <div id="inputFieldIDSourceAirport" class="autocompleteAirports">
                                        <ng-autocomplete [data]="autoCompleteSourceItems"
                                            [initialValue]="searchCarForm.controls['sourceAirportCode']?.value"
                                            [placeHolder]="getPlaceHolder()" [searchKeyword]="'label'"
                                            (selected)="onAutoCompleteSelect($event,'source', 'inputFieldIDSourceAirport')"
                                            (inputChanged)="searchAutoComplete($event,'source')"
                                            (inputFocused)="onFocused($event,'source')"
                                            (closed)="onAutoCompleteClose($event, 'source', 'inputFieldIDSourceAirport')"
                                            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                            (ngModel)="pickUpModal" (inputCleared)="clearAirportField('source')">
                                        </ng-autocomplete>

                                        <ng-template #itemTemplate let-item>
                                            <ng-container *ngIf="item.type === 'AIRPORT' ">
                                                <div style="display: inline-block;">
                                                    <i class="fa fa-plane" aria-hidden="true"
                                                        style="font-size: 90%;padding-right: 2px;"></i>
                                                    <span [innerHTML]="item.code"></span>
                                                </div>
                                                <span class="airport-search-dropdown" [innerHTML]="item.label"></span>
                                            </ng-container>
                                            <ng-container *ngIf="item.type === 'ADDRESS' ">
                                                <div style="display: inline-block;">
                                                    <i class="fa fa-location-arrow" aria-hidden="true"
                                                        style="font-size: 90%;padding-right: 2px;"></i>
                                                    <span class="airport-search-dropdown"
                                                        [innerHTML]="item.label"></span>
                                                </div>
                                            </ng-container>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                    <input type="hidden" formControlName="sourceAirportCode" />
                                    <div *ngIf="searchCarForm.controls['sourceAirportCode'].hasError('required') && (searchCarForm.controls['source'].touched || searchCarForm.controls['source'].dirty)"
                                        class="error">{{'search.Pleaseenteravalidlocationorairport' | translate }}
                                    </div>
                                </div>
                            </div>




                            <!-- <div class="text-input-wrapper" style="margin-right: 1px;">
                            <div [ngClass]="{'text-input': true, 'invert': true}">

                                <input type="text" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event,'pickUp')"
                                    placeholder="Picking-up"  formControlName="pickUp" />
                                    <div *ngIf="isSearchCarFormNonEmpty()" class="x1" (click)="clearCarTextField()"><i class="material-icons">close</i></div>
                                <div *ngIf="searchCarForm.controls['pickUp'].hasError('required') && (searchCarForm.controls['pickUp'].touched || searchCarForm.controls['pickUp'].dirty)"
                                    class="error">Please enter city address
                                </div>
                            </div>
                        </div> -->
                            <div class="" *ngIf=" this.searchCarForm.controls['sameAddress'].value===false">
                                <div class="switch2">
                                    <span class="icon-arrow"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg" *ngIf=" this.searchCarForm.controls['sameAddress'].value===false">




                            <div class="text-input-wrapper" style="margin-right: 1px;">
                                <div [ngClass]="{'text-input': true, 'invert': true, 'ng-autocomplete': true}"
                                    style="z-index: 12 !important;margin-bottom: 0px !important;height:auto !important;">
                                    <input type="hidden" formControlName="destination" (ngModel)="dropOffModal" />
                                    <div id="inputFieldIDDestAirport" class="autocompleteAirports">
                                        <ng-autocomplete [data]="autoCompleteDestItems"
                                            [initialValue]="searchCarForm.controls['destinationAirportCode']?.value"
                                            [placeHolder]="getPlaceHolder1()" [searchKeyword]="'label'"
                                            (selected)="onAutoCompleteSelect($event,'destination', 'inputFieldIDDestAirport')"
                                            (inputChanged)="searchAutoComplete($event,'destination')"
                                            (inputFocused)="onFocused($event,'destination')"
                                            (closed)="onAutoCompleteClose($event, 'destination', 'inputFieldIDDestAirport')"
                                            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                            (ngModel)="dropOffModal" (inputCleared)="clearAirportField('destination')">
                                        </ng-autocomplete>

                                        <ng-template #itemTemplate let-item>
                                            <ng-container *ngIf="item.type === 'AIRPORT' ">
                                                <div style="display: inline-block;">
                                                    <i class="fa fa-plane" aria-hidden="true"
                                                        style="font-size: 90%;padding-right: 2px;"></i>
                                                    <span [innerHTML]="item.code"></span>
                                                </div>
                                                <span class="airport-search-dropdown" [innerHTML]="item.label"></span>
                                            </ng-container>
                                            <ng-container *ngIf="item.type === 'ADDRESS' ">
                                                <div style="display: inline-block;">
                                                    <i class="fa fa-location-arrow" aria-hidden="true"
                                                        style="font-size: 90%;padding-right: 2px;"></i>
                                                    <span class="airport-search-dropdown"
                                                        [innerHTML]="item.label"></span>
                                                </div>
                                            </ng-container>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                    <input type="hidden" formControlName="destinationAirportCode" />
                                    <div *ngIf="searchCarForm.controls['destinationAirportCode'].hasError('required') && (searchCarForm.controls['destination'].touched || searchCarForm.controls['destination'].dirty)"
                                        class="error">{{'search.Pleaseenteravalidlocationorairport' | translate }}
                                    </div>
                                </div>
                            </div>









                            <!-- <div class="text-input-wrapper" style="margin-left: 1px;">
                            <div [ngClass]="{'text-input': true, 'invert': true}">
                                <input type="text" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event,'dropOff')"
                                    placeholder="Same as pick-up" formControlName="dropOff" />
                                    <div *ngIf="isSearchCarFormNonEmpty1()" class="x1" (click)="clearCarTextField1()"><i class="material-icons">close</i></div>
                                <div *ngIf="searchCarForm.controls['dropOff'].hasError('required') && (searchCarForm.controls['dropOff'].touched || searchCarForm.controls['dropOff'].dirty)"
                                    class="error">Please enter city address.
                                </div>
                            </div>
                        </div> -->
                        </div>
                    </div>
                    <div class="filter-row-left">
                        <div class="col-auto" style="margin-top: 8px;margin-bottom: 10px;">
                            <span (click)="boxChecked()" style="cursor:pointer !important;"> <input type="checkbox"
                                    class="" style="display:none;" [attr.disabled]="true"><span
                                    class="{{this.searchCarForm.controls['sameAddress'].value ? 'checkbox12':'checkbox13'}}"></span>
                                <label class="checkboxLabel1">{{'search.Addadifferentdrop-offlocation' | translate }}</label></span>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="row">
                        <div class="col-6 col-lg">
                            <div class="date-input">
                                <span class="input-icon material-icons"
                                    (click)="pickUpDatePicker.toggle();">event</span>
                                <div [ngClass]="{'text-input': true, 'invert': true}"
                                    (click)="pickUpDatePicker.toggle();">
                                    <input class="textfield-with-icon" style="padding-right:0px !important;" type="text"
                                        placeholder="{{ 'search.Pick-upDate' | translate }}" value="{{pickUpDate| date: 'EEE MMM d'}}"
                                        readonly="" />
                                </div>
                                <input bsDatepicker #pickUpDatePicker="bsDatepicker"
                                    style="padding-right:0px !important;" formControlName="pickUpDate"
                                    [minDate]="minimumDate" [maxDate]="maximumDate" [outsideClick]="true"
                                    [bsConfig]="{showWeekNumbers: false}"
                                    (onShown)="onShowPicker($event, pickUpDatePicker)"
                                    (bsValueChange)="setLeg1Date($event)" (onHidden)="onHidePicker()" readonly />
                            </div>
                            <!--   <div class="time-type" id="time-type">
                                <div class="filter custom-selectbox" (click)="timeTypeFilter.toggle()">
                                    <div class="select-input">
                                        <ng-select #timeTypeFilter appendTo="#time-type" dropdownPosition="bottom"
                                            [searchable]="false" bindLabel="value" bindValue="id" [clearable]="false"
                                            [items]="departureTimeTypeOptions | translateOptions"
                                            formControlName="timeTypeFilter"
                                            (change)="setDepartureConstraint($event.id)">
                                            <ng-template ng-header-tmp>
                                                <div class="selectox-header">
                                                    <span>{{'search.SelectTimeType' | translate }}</span>
                                                    <span class="selectBox-remove"
                                                        (click)="timeTypeFilter.toggle()"><span
                                                            class="material-icons">clear</span></span>
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                        <div class="select-overlay"></div>
                                    </div>-->

                            <!-- <span class="icon-triangle control-icon"></span>
                                </div>
                            </div>-->
                            <div class="constraint-time" id="constraint-time" style="position:relative;">
                                <div class="control" (click)="constraintTimeSelect.toggle()" style="position:relative;">
                                    <div class="select-input">
                                        <div class="field-value custom-selectbox-value">
                                             {{'search.Pickup' | translate }}:
                                            <span class="value custom-selectbox-value"
                                                style="margin-left:3px !important">
                                                {{getTimeOptionName(searchCarForm.controls['pickTime']?.value )|
                                                translate}}
                                            </span>
                                        </div>
                                        <ng-select class="" #constraintTimeSelect appendTo="#constraint-time"
                                            dropdownPosition="bottom" [searchable]="false" [closeOnSelect]="true"
                                            [clearable]="false" [items]="timeOptions | translateOptions"
                                            formControlName="pickTime" bindValue="name" bindLabel="" (change)="errorhandled()"
                                            (open)="getTimeOptions()">

                                            <ng-template ng-header-tmp>
                                                <div class="selectox-header">
                                                    <span>{{'search.SelectTime' | translate }}</span>
                                                    <span class="selectBox-remove"
                                                        (click)="constraintTimeSelect.toggle()"><span
                                                            class="material-icons">clear</span></span>
                                                </div>

                                            </ng-template>
                                            <ng-template ng-option-tmp let-option="item">
                                                    <span class="option-title">{{option.title |
                                                        translate}}</span>
                                                   
                                                </ng-template>
                                            <!--   <ng-template ng-option-tmp let-option="item">
                                                <span class="option-title">{{option.title | translate}}</span>
                                                <span>{{getTimeOptionValue(option) | translate}}</span>
                                            </ng-template>-->
                                        </ng-select>
                                        <div class="select-overlay"></div>
                                        <span class="icon-triangle control-icon"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col-6': true, 'col-lg': true}">
                            <div class="date-input">
                                <span class="input-icon material-icons"
                                    (click)="dropOffDatePicker.toggle()">event</span>
                                <div [ngClass]="{'text-input': true, 'invert': true}"
                                    (click)="dropOffDatePicker.toggle()">
                                    <input class="textfield-with-icon" style="padding-right:0px !important;" type="text"
                                        placeholder="{{ 'search.Drop-offDate' | translate }}" value="{{dropOffDate| date: 'EEE MMM d'}}"
                                        readonly="" />
                                </div>
                                <input bsDatepicker #dropOffDatePicker="bsDatepicker"
                                    style="padding-right:0px !important;" formControlName="dropOffDate"
                                    [minDate]="minimumDate3" [maxDate]="maximumDate" [outsideClick]="true"
                                    [bsConfig]="{showWeekNumbers: false}"
                                    (onShown)="onShowPicker($event, dropOffDatePicker)"
                                    (bsValueChange)="setLeg2Date($event)" (onHidden)="onHidePicker()" readonly />
                            </div>
                            <!--     <div class="time-type" id="time-type1">
                                <div class="filter custom-selectbox" (click)="timeTypeFilter1.toggle()">
                                    <div class="select-input">
                                        <ng-select #timeTypeFilter1 appendTo="#time-type1" dropdownPosition="bottom"
                                            [searchable]="false" bindLabel="value" bindValue="id" [clearable]="false"
                                            [items]="returnTimeTypeOptions | translateOptions"
                                            formControlName="timeTypeFilter1"
                                            (change)="setArrivalConstraint($event.id)">
                                            <ng-template ng-header-tmp>
                                                <div class="selectox-header">
                                                    <span>{{'search.SelectTimeType' | translate }}</span>
                                                    <span class="selectBox-remove" (click)="timeTypeFilter1.toggle()">
                                                        <span class="material-icons">clear</span></span>
                                                </div>

                                            </ng-template>
                                        </ng-select>
                                        <div class="select-overlay"></div>
                                    </div>
                                    <div class="field-value custom-selectbox-value">
                                        {{getTimeTypeName(searchCarForm.controls['timeTypeFilter1'].value) | translate}}
                                    </div>
                                    <span class="icon-triangle control-icon"></span>
                                </div>
                            </div>-->
                            <div class="constraint-time" id="constraint-time1" style="position:relative;">
                                <div class="control" (click)="constraintTimeSelect1.toggle()">
                                    <div class="select-input">
                                        <div class="field-value custom-selectbox-value">
                                             {{'search.Drop-off' | translate }}:
                                            <span class="value custom-selectbox-value"
                                                style="margin-left:3px !important;">
                                                {{getTimeOptionName(searchCarForm.controls['dropTime']?.value) |
                                                translate}}
                                            </span>
                                        </div>
                                        <ng-select class="" #constraintTimeSelect1 appendTo="#constraint-time1"
                                            dropdownPosition="bottom" [searchable]="false" [closeOnSelect]="true"
                                            [clearable]="false" [items]="timeOptions | translateOptions"
                                            formControlName="dropTime" bindValue="name" bindLabel="" (change)="errorhandled()"
                                            (open)="getTimeOptions()">

                                            <ng-template ng-header-tmp>
                                                <div class="selectox-header">
                                                    <span>{{'search.SelectTime' | translate }}</span>
                                                    <span class="selectBox-remove"
                                                        (click)="constraintTimeSelect1.toggle()"><span
                                                            class="material-icons">clear</span></span>
                                                </div>

                                            </ng-template>
                                            <ng-template ng-option-tmp let-option="item">
                                                    <span class="option-title">{{option.title |
                                                        translate}}</span>
                                                   
                                                </ng-template>
                                            <!--   <ng-template ng-option-tmp let-option="item">
                                                <span class="option-title">{{option.title | translate}}</span>
                                                <span>{{getTimeOptionValue(option) | translate}}</span>
                                            </ng-template>-->
                                        </ng-select>
                                        <div class="select-overlay"></div>
                                        <span class="icon-triangle control-icon"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-12 col-md-12">
                    <div [ngClass]="{'searchButton text-center': true}">
                        <button type="button" class="btn primary-button" (click)="submitForm()"
                            [disabled]="formIfInvalid">{{'search.Search' | translate }}</button>
                        <!-- <div *ngIf="searchInProgress">
                            <loader-dots></loader-dots>
                        </div> -->
                    </div>
                </div>
                <!--<div class="collapse-control-container" *ngIf="isResult && formArrayLength>0 && !isMobile">
                                        <div class="collapse-control" (click)="setIsCollapsed(!isCollapsed)">
                                            <span [ngClass]="{'icon-down': isCollapsed, 'icon-up': !isCollapsed}"></span>
                                        </div>
                                    </div>-->

                <div class="row" *ngIf="timeDifference">
                    <div class="col">
                        <span class="text-danger" style="padding-left: 0px! important;">{{'search.Thedrop-offmustoccuratleastonehourafterthepick-up' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</form>