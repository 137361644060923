import { Component, Input, EventEmitter, ViewChild } from '@angular/core';
import { Options } from 'ng5-slider';
import { FilterService } from '../filter.service';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html'
})
export class RangeSliderComponent {

  @Input() startAt: string;
  @Input() endAt: string;
  @Input() heading: string;
  @Input() name: string;
  @Input() defaultMinValue: any;
  @Input() defaultHighValue: any;

  private readonly MIN_PER_STEP: number = 30;

  private rangeDataArray: Array<string>;

  minValue: number = 0;
  maxValue: number = 100;
  options: Options = {
    floor: 0,
    ceil: 100,
    step: 5
  };
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();


  constructor(public filterService: FilterService,) {
    setTimeout(() => {
      this.manualRefresh.emit();
    }, 100);
  }

  private buildRangeDataArray() {



    this.rangeDataArray = new Array<string>();
    let date = new Date(this.startAt.split('.')[0]);
    let endDate = new Date(this.endAt.split('.')[0]);
    var toRoundMilis = 1000 * 60 * this.MIN_PER_STEP;
    // var date = new Date();
    var rounded = new Date(date.getTime() - (date.getTime() % toRoundMilis));
    while (rounded < endDate) {
      this.rangeDataArray.push(rounded.toISOString());
      rounded = new Date(rounded.getTime() + this.MIN_PER_STEP * 60000);
    }
    this.rangeDataArray.push(rounded.toISOString());
    rounded = new Date(rounded.getTime() + this.MIN_PER_STEP * 60000);
    this.rangeDataArray.push(rounded.toISOString());
    this.options = {
      floor: 0,
      ceil: this.rangeDataArray.length - 1,
      step: 1
    }
    this.maxValue = this.rangeDataArray.length - 1;


  }

  valueChanged() {
    let newTimeRange = { 'name': this.name, 'starts': new Date(this.rangeDataArray[this.minValue]), 'ends': new Date(this.rangeDataArray[this.maxValue]) };
    this.filterService.timeRangeChangeSubject.next(newTimeRange);
  }

  // highValueChanged(highValue){
  //   
  // }
  ngOnInit() {
    this.buildRangeDataArray();
    if (this.defaultMinValue && this.rangeDataArray.indexOf(this.defaultMinValue.toISOString()) >= 0) this.minValue = this.rangeDataArray.indexOf(this.defaultMinValue.toISOString());
    if (this.defaultHighValue && this.rangeDataArray.indexOf(this.defaultHighValue.toISOString()) >= 0) this.maxValue = this.rangeDataArray.indexOf(this.defaultHighValue.toISOString());
  }


  getHeadingText() {
    return this.heading;
  }

  getMinValueDate() {
    return new Date(this.rangeDataArray[this.minValue]);
  }

  getHighValueDate() {
    return new Date(this.rangeDataArray[this.maxValue]);
  }


}