import { NoOfStops } from '../enum/no-of-stops.type';
import { AddressType } from '../enum/address.type';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';

export class Constants {
static readonly uatpAirlines=[
  {
    "value": "AeroMexico",
    "id": "AM"
  },
  {
    "value": "Air Canada",
    "id": "AC"
  },
  {
    "value": "Air China",
    "id": "CA"
  },
  {
    "value": "Air New Zealand",
    "id": "NZ"
  },
  {
    "value": "Air Niugini",
    "id": "PX"
  },
  {
    "id": "AA",
    "value": "American Airlines"
  },
  {
    "id": "GP",
    "value": "APG AIrlines "
  },
  {
    "id": "OS",
    "value": "Austrian Airlines"
  },
  {
    "value": "Avianca",
    "id": "AV"
  },
  {
    "value": "China Eastern",
    "id": "MU"
  },
  {
    "id": "DL",
    "value": "Delta"
  },
  {
    "value": "Etihad Airways",
    "id": "EY"
  },
  {
    "id": "F9",
    "value": "Frontier"
  },
  
  {
    "id": "G3",
    "value": "GOL Airlines"
  },
  {
    "id": "H1",
    "value": "Hahn Air"
  },
  {
    "value": "Japan Airlines",
    "id": "JL"
  },
  {
    "id": "B6",
    "value": "JetBlue"
  },
  {
    "value": "LATAM",
    "id": "LE"
  },
  {
    "value": "Link airways",
    "id": "FC"
  },
  {
    "value": "Qantas",
    "id": "QF"
  },
  {
    "value": "Shandong Airlines",
    "id": "SC"
  },
  {
    "value": "Sichuan Airlines",
    "id": "3U"
  },
  {
    "value": "Southwest",
    "id": "WN"
  },
  {
    "value": "Sun Country Airlines",
    "id": "SY"
  },
  {
    "value": "TUI FLy",
    "id": "X3"
  },
  {
    "value": "THY - Turkish Airlines",
    "id": "TK"
  },
  {
    "id": "UA",
    "value": "United"
  },
  {
    "value": "WestJet",
    "id": "WS"
  },
]
  static readonly CONSTRAINT_TIME_OPTIONS_FOR_DEPART = [
    {
      id: 0,
      title: _('ngOption.Anytime'),
      value: _('ngOption.Anytime'),
      titleID: 'Anytime',
      hours: { start: 0, end: 24 }

    },
    {
      id: 2,
      titleID: 'Morning',
      title: _('ngOption.Morning'),
      value: _('ngOption.Morning'),
      hours: { start: 4, end: 9 }
    },
    {
      id: 3,
      titleID: 'Mid Day',
      title: _('ngOption.MidDay'),
      value: _('ngOption.MidDay'),
      hours: { start: 11, end: 15 }
    },
    {
      id: 4,
      titleID: 'Evening',
      title: _('ngOption.Evening'),
      value: _('ngOption.Evening'),
      hours: { start: 15, end: 20 }
    },
    {
      id: 5,
      titleID: 'Night',
      title: _('ngOption.Night'),
      value: _('ngOption.Night'),
      hours: { start: 19, end: 23 }
    },
    {
      id: 6,
      titleID: _('ngOption.3AM'),
      title: _('ngOption.3AM'),
      value: _('ngOption.3AM'),
      hours: { start: 3, end: 3 }
    },
    {
      id: 7,
      titleID: '4am',
      title: _('ngOption.4AM'),
      value: _('ngOption.4AM'),
      hours: { start: 4, end: 4 }
    },
    {
      id: 8,
      titleID: '5am',
      title: _('ngOption.5AM'),
      value: _('ngOption.5AM'),
      hours: { start: 5, end: 5 }
    },
    {
      id: 9,
      titleID: '6am',
      title: _('ngOption.6AM'),
      value: _('ngOption.6AM'),
      hours: { start: 6, end: 6 }
    },
    {
      id: 10,
      titleID: '7am',
      title: _('ngOption.7AM'),
      value: _('ngOption.7AM'),
      hours: { start: 7, end: 7 }
    },
    {
      id: 11,
      titleID: '8am',
      title: _('ngOption.8AM'),
      value: _('ngOption.8AM'),
      hours: { start: 8, end: 8 }
    },
    {
      id: 12,
      titleID: '9am',
      title: _('ngOption.9AM'),
      value: _('ngOption.9AM'),
      hours: { start: 9, end: 9 }
    },
    {
      id: 13,
      titleID: '10am',
      title: _('ngOption.10AM'),
      value: _('ngOption.10AM'),
      hours: { start: 10, end: 10 }
    },
    {
      id: 14,
      titleID: '11am',
      title: _('ngOption.11AM'),
      value: _('ngOption.11AM'),
      hours: { start: 11, end: 11 }
    },
    {
      id: 15,
      titleID: '12pm',
      title: _('ngOption.12PM'),
      value: _('ngOption.12PM'),
      hours: { start: 12, end: 12 }
    },
    {
      id: 16,
      titleID: '1pm',
      title: _('ngOption.1PM'),
      value: _('ngOption.1PM'),
      hours: { start: 13, end: 13 }
    },
    {
      id: 17,
      titleID: '2pm',
      title: _('ngOption.2PM'),
      value: _('ngOption.2PM'),
      hours: { start: 14, end: 14 }
    },
    {
      id: 18,
      titleID: '3pm',
      title: _('ngOption.3PM'),
      value: _('ngOption.3PM'),
      hours: { start: 15, end: 15 }
    },
    {
      id: 19,
      titleID: '4pm',
      title: _('ngOption.4PM'),
      value: _('ngOption.4PM'),
      hours: { start: 16, end: 16 }
    },
    {
      id: 20,
      titleID: '5pm',
      title: _('ngOption.5PM'),
      value: _('ngOption.5PM'),
      hours: { start: 17, end: 17 }
    },
    {
      id: 21,
      titleID: '6pm',
      title: _('ngOption.6PM'),
      value: _('ngOption.6PM'),
      hours: { start: 18, end: 18 }
    },
    {
      id: 22,
      titleID: '7pm',
      title: _('ngOption.7PM'),
      value: _('ngOption.7PM'),
      hours: { start: 19, end: 19 }
    },
    {
      id: 23,
      titleID: '8pm',
      title: _('ngOption.8PM'),
      value: _('ngOption.8PM'),
      hours: { start: 20, end: 20 }
    },
    {
      id: 24,
      titleID: '9pm',
      title:_('ngOption.9PM'),
      value: _('ngOption.9PM'),
      hours: { start: 21, end: 21 }
    },
    {
      id: 25,
      titleID: '10pm',
      title: _('ngOption.10PM'),
      value: _('ngOption.10PM'),
      hours: { start: 22, end: 22 }
    },
    {
      id: 26,
      titleID: '11pm',
      title: _('ngOption.11PM'),
      value: _('ngOption.11PM'),
      hours: { start: 23, end: 23 }
    }
  ];
  static readonly CONSTRAINT_TIME_OPTIONS_FOR_ARRIVE = [
    {
      id: 0,
      titleID: 'Anytime',
      title: _('ngOption.Anytime'),
      value: _('ngOption.Anytime'),
      hours: { start: 0, end: 24 }

    },
    {
      id: 2,
      titleID: 'Morning',
      title: _('ngOption.Morning'),
      value: _('ngOption.Morning'),
      hours: { start: 6, end: 11 }
    },
    {
      id: 3,
      titleID: 'Mid Day',
      title: _('ngOption.MidDay'),
      value: _('ngOption.MidDay'),
      hours: { start: 13, end: 17 }
    },
    {
      id: 4,
      titleID: 'Evening',
      title: _('ngOption.Evening'),
      value: _('ngOption.Evening'),
      hours: { start: 17, end: 22 }
    },
    {
      id: 5,
      titleID: 'Night',
      title: _('ngOption.Night'),
      value: _('ngOption.Night'),
      hours: { start: 21, end: 24 }
    },
    {
      id: 6,
      titleID: _('ngOption.3AM'),
      title: _('ngOption.3AM'),
      value: _('ngOption.3AM'),
      hours: { start: 3, end: 3 }
    },
    {
      id: 7,
      titleID: '4am',
      title: _('ngOption.4AM'),
      value: _('ngOption.4AM'),
      hours: { start: 4, end: 4 }
    },
    {
      id: 8,
      titleID: '5am',
      title: _('ngOption.5AM'),
      value: _('ngOption.5AM'),
      hours: { start: 5, end: 5 }
    },
    {
      id: 9,
      titleID: '6am',
      title: _('ngOption.6AM'),
      value: _('ngOption.6AM'),
      hours: { start: 6, end: 6 }
    },
    {
      id: 10,
      titleID: '7am',
      title: _('ngOption.7AM'),
      value: _('ngOption.7AM'),
      hours: { start: 7, end: 7 }
    },
    {
      id: 11,
      titleID: '8am',
      title: _('ngOption.8AM'),
      value: _('ngOption.8AM'),
      hours: { start: 8, end: 8 }
    },
    {
      id: 12,
      titleID: '9am',
      title: _('ngOption.9AM'),
      value: _('ngOption.9AM'),
      hours: { start: 9, end: 9 }
    },
    {
      id: 13,
      titleID: '10am',
      title: _('ngOption.10AM'),
      value: _('ngOption.10AM'),
      hours: { start: 10, end: 10 }
    },
    {
      id: 14,
      titleID: '11am',
      title: _('ngOption.11AM'),
      value: _('ngOption.11AM'),
      hours: { start: 11, end: 11 }
    },
    {
      id: 15,
      titleID: '12pm',
      title: _('ngOption.12PM'),
      value: _('ngOption.12PM'),
      hours: { start: 12, end: 12 }
    },
    {
      id: 16,
      titleID: '1pm',
      title: _('ngOption.1PM'),
      value: _('ngOption.1PM'),
      hours: { start: 13, end: 13 }
    },
    {
      id: 17,
      titleID: '2pm',
      title: _('ngOption.2PM'),
      value: _('ngOption.2PM'),
      hours: { start: 14, end: 14 }
    },
    {
      id: 18,
      titleID: '3pm',
      title: _('ngOption.3PM'),
      value: _('ngOption.3PM'),
      hours: { start: 15, end: 15 }
    },
    {
      id: 19,
      titleID: '4pm',
      title: _('ngOption.4PM'),
      value: _('ngOption.4PM'),
      hours: { start: 16, end: 16 }
    },
    {
      id: 20,
      titleID: '5pm',
      title: _('ngOption.5PM'),
      value: _('ngOption.5PM'),
      hours: { start: 17, end: 17 }
    },
    {
      id: 21,
      titleID: '6pm',
      title: _('ngOption.6PM'),
      value: _('ngOption.6PM'),
      hours: { start: 18, end: 18 }
    },
    {
      id: 22,
      titleID: '7pm',
      title: _('ngOption.7PM'),
      value: _('ngOption.7PM'),
      hours: { start: 19, end: 19 }
    },
    {
      id: 23,
      titleID: '8pm',
      title: _('ngOption.8PM'),
      value: _('ngOption.8PM'),
      hours: { start: 20, end: 20 }
    },
    {
      id: 24,
      titleID: '9pm',
      title:_('ngOption.9PM'),
      value: _('ngOption.9PM'),
      hours: { start: 21, end: 21 }
    },
    {
      id: 25,
      titleID: '10pm',
      title: _('ngOption.10PM'),
      value: _('ngOption.10PM'),
      hours: { start: 22, end: 22 }
    },
    {
      id: 26,
      titleID: '11pm',
      title: _('ngOption.11PM'),
      value: _('ngOption.11PM'),
      hours: { start: 23, end: 23 }
    }
  ];

  static readonly TRIP_OPTIONS = [{ id: 'ONEWAY', value:_('ngOption.One-way'),name:_('ngOption.One-way') }, { id: 'ROUNDTRIP', value: _('ngOption.Roundtrip'),name:_('ngOption.Roundtrip') }, { id: 'MULTICITY', value: _('ngOption.Multicity'),name: _('ngOption.Multicity') }];
  static readonly TRIP_OPTIONS_FOR_NJOY = [{ id: 'ONEWAY', value: _('ngOption.One-way')}, { id: 'ROUNDTRIP', value:  _('ngOption.Roundtrip') }];

  // static readonly TRIP_OPTIONS = [{ id: 'ONEWAY', value: _('ngOption.One-way') }, { id: 'ROUNDTRIP', value: _('ngOption.Roundtrip') }];



  static readonly TIME_FOR_CAR = [{ id: 1, title: _('ngOption.00:00AM'),name: '00:00 AM' }, { id: 2, title: _('ngOption.00:30AM'),name: '00:30 AM' }, { id: 3, title: _('ngOption.01:00AM'),name: '01:00 AM' }, { id: 4, title: _('ngOption.01:30AM'),name: '01:30 AM' },
  { id: 5, title: _('ngOption.02:00AM'),name: '02:00 AM' }, { id: 6, title: _('ngOption.02:30AM'),name: '02:30 AM' }, { id: 7, title: _('ngOption.03:00AM'),name: '03:00 AM' }, { id: 8, title: _('ngOption.03:30AM'),name: '03:30 AM' },
  { id: 9, title: _('ngOption.04:00AM'),name: '04:00 AM' }, { id: 10, title: _('ngOption.04:30AM'),name: '04:30 AM'}, { id: 11, title: _('ngOption.05:00AM'),name: '05:00 AM' }, { id: 12, title: _('ngOption.05:30AM'),name: '05:30 AM' }
    , { id: 13, title: _('ngOption.06:00AM'),name: '06:00 AM' }, { id: 14, title: _('ngOption.06:30AM'),name: '06:30 AM' }, { id: 15, title: _('ngOption.07:00AM'),name: '07:00 AM' }, { id: 16, title:_('ngOption.07:30AM'),name: '07:30 AM' },
  { id: 17, title: _('ngOption.08:00AM'),name: '08:00 AM' }, { id: 18, title: _('ngOption.08:30AM'),name: '08:30 AM' }, { id: 19, title: _('ngOption.09:00AM'),name: '09:00 AM' }, { id: 20, title: _('ngOption.09:30AM') ,name: '09:30 AM'}, { id: 21, title: _('ngOption.10:00AM'),name: '10:00 AM' }, { id: 22,title: _('ngOption.10:30AM'),name: '10:30 AM' }
    , { id: 23, title: _('ngOption.11:00AM'),name: '11:00 AM' }, { id: 24, title: _('ngOption.11:30AM') ,name: '11:30 AM'}, { id: 25,title: _('ngOption.12:00PM') ,name: '12:00 PM'}, { id: 26, title: _('ngOption.12:30PM'),name: '12:30 PM' }, { id: 27, title: _('ngOption.01:00PM'),name: '01:00 PM' }, { id: 28, title: _('ngOption.01:30PM'),name: '01:30 PM' }
    , { id: 30, title: _('ngOption.02:00PM'),name: '02:00 PM' }, { id: 31, title: _('ngOption.02:30PM'),name: '02:30 PM' }, { id: 32, title: _('ngOption.03:00PM'),name: '03:00 PM'}, { id: 33, title: _('ngOption.03:30PM'),name: '03:30 PM' }, { id: 34, title: _('ngOption.04:00PM'),name: '04:00 PM' }, { id: 35, title: _('ngOption.04:30PM'),name: '04:30 PM' },
  { id: 36, title: _('ngOption.05:00PM'),name: '05:00 PM' }, { id: 37, title: _('ngOption.05:30PM'),name: '05:30 PM' }, { id: 38, title: _('ngOption.06:00PM'),name: '06:00 PM' }, { id: 39,title: _('ngOption.06:30PM') ,name: '06:30 PM'},{ id: 48,title:  _('ngOption.07:00PM'),name: '07:00 PM'}
    , { id: 40, title: _('ngOption.07:30PM'),name: '07:30 PM' }, { id: 41, title: _('ngOption.08:00PM'),name: '08:00 PM'}, { id: 41, title: _('ngOption.08:30PM'),name: '08:30 PM' }, { id: 42, title: _('ngOption.09:00PM'),name: '09:00 PM' }, { id: 43, title: _('ngOption.09:30PM'),name: '09:30 PM' }, { id: 44, title: _('ngOption.10:00PM') ,name: '10:00 PM'}
    , { id: 45, title: _('ngOption.10:30PM'),name: '10:30 PM' }, { id: 46, title: _('ngOption.11:00PM') ,name: '11:00 PM'}, { id: 47, title: _('ngOption.11:30PM'),name: '11:30 PM' },]

  static readonly CAR_SPECIFICATION = [{ Name: 'Any', value: 'Any' }, { Name: 'Air Conditioning', value: 'Air Conditioning', Type: 'Specification' }, { Name: 'Automatic Transmission', value: 'Automatic Transmission', Type: 'Specification' }]
  static readonly SORT_OPTIONS = [{ id: 'recommended', value: _('ngOption.Recommended') }, { id: 'price', value: _('ngOption.Price') }, { id: 'duration', value: _('ngOption.Duration') }, { id: 'arrivalTime', value: _('ngOption.ArrivalTime') }, { id: 'departureTime', value: _('ngOption.DepartureTime') }];
  static readonly CAR_LOCATION = [{ Name: 'Any', value: 'Any' }, { Name: 'Terminal', value: 'Terminal', Type: 'Location' }, { Name: 'Shuttle', value: 'Shuttle', Type: 'Location' }]
  static readonly SORT_OPTIONS_HOTEL = [{ id: 'recommended', value: _('ngOption.Recommended') }, { id: 'price', value: _('ngOption.Price') }, { id: 'distance', value: _('ngOption.Distance') }];
  static readonly SORT_OPTIONS_CAR = [{ id: 'price', value: _('ngOption.Price') }, { id: 'distance', value: _('ngOption.Distance') }];

  static readonly CANCELLATION_ITEMS = [{ id: 'ALL', value: _('ngOption.AllCancellationPolicies') }, { id: 'ID1', value: 'Value1' }, { id: 'ID2', value: 'Value2' }];


  static readonly TIME_TYPE = [{ id: 'ARRIVAL_BASED', value: _('ngOption.Arrive') ,name:_('ngOption.Arrive')}, { id: 'DEPARTURE_BASED', value: _('ngOption.Depart'),name:_('ngOption.Depart') }];

  static readonly CLASS_OPTIONS = [{ id: 'ECONOMY', value:'ngOption.Economy',name:'ngOption.Economy' }, { id: 'PREMIUM_ECONOMY', value:_('ngOption.PremiumEconomy'),name:('ngOption.PremiumEconomy') }, { id: 'BUSINESS', value: _('ngOption.Business'),name: _('ngOption.Business') }, { id: 'FIRST_CLASS', value: _('ngOption.FirstClass'),name:_('ngOption.FirstClass') }];

  static readonly PASSENGER_OPTIONS = [{ id: '01', value: _('ngOption.1Passenger'), count: 1 }, { id: '02', value: _('ngOption.2Passengers'), count: 2 }, { id: '03', value: _('ngOption.3Passengers') }, { id: '04', value: _('ngOption.4Passengers') }];
  static readonly EMPLOYEE_OPTIONS = [{ id: '01', value: _('All Employees') }, { id: '02', value: _('Gregory Doyle'), count: 2 }, { id: '03', value: _('Samuel Greenhouse') }, { id: '04', value: _('Niall O Connor') }, { id: '05', value: _('Marry Smith') }, { id: '06', value: _('Dan Tucker') }];
  static readonly STOP_OPTIONS = [{ id: NoOfStops.ANY, value: _('ngOption.Anynumberofstops'), selected: true }, { id: NoOfStops.NONE, value: _('ngOption.Non-stoponly'), selected: false }, { id: NoOfStops.ONE, value: _('ngOption.Oneorfewerstops'), selected: false }];

  static readonly POLICY_OPTIONS = [{ id: 'WITHINPOLICY', value: _('ngOption.WithinPolicyOnly'), selected: false }, { id: 'NOPOLICY', value: _('ngOption.ShowAllOptions'), selected: true }];

  static readonly HOTEL_BRAND_OPTIONS = [{ id: 'ANY', value: 'Any' }, { id: 'Hilton', value: 'Hilton', Type: 'Preferred' }, { id: 'Marriot', value: 'Marriot', Type: 'Preferred' }, { id: 'Starwood', value: 'Starwood', Type: 'Preferred' }, { id: 'b1', value: 'Brand Name', Type: 'Other Brands' }, { id: 'b2', value: 'Brand Name', Type: 'Other Brands' }, { id: 'b3', value: 'Brand Name', Type: 'Other Brands' }, { id: 'Others', value: 'Others', Type: 'Other Brands' }];

  static readonly AMENITIES_OPTIONS = [{ id: 'ANY', value: _('ngOption.Any') }, { id: 'AMENITY_TYPE_BKFAST', value: _('ngOption.Breakfast'), imgSrc: 'assets/images/hotel/local_cafe.svg', Type: _('ngOption.MustInclude') }, { id: 'AMENITY_TYPE_RESTAURANT', value: _('ngOption.Restaurant'), imgSrc: 'assets/images/hotel/local_restaurant.svg', Type: _('ngOption.MustInclude') }, { id: 'AMENITY_TYPE_BAR', value: _('ngOption.Bar'), imgSrc: 'assets/images/hotel/bar.svg', Type:_('ngOption.MustInclude')  }, { id: 'AMENITY_TYPE_WIFI', value: _('ngOption.FreeWiFi'), imgSrc: 'assets/images/hotel/wifi.svg', Type: _('ngOption.MustInclude') }, { id: 'AMENITY_TYPE_ROOMSERVICE', value: _('ngOption.Concierge'), imgSrc: 'assets/images/hotel/bell-call.svg', Type: _('ngOption.MustInclude') }, { id: 'AMENITY_TYPE_GYM', value: _('ngOption.Gym'), imgSrc: 'assets/images/hotel/fitness-dumbbell.svg', Type: _('ngOption.MustInclude') }, { id: 'AMENITY_TYPE_POOL', value: _('ngOption.Pool'), imgSrc: 'assets/images/hotel/swimming-pool-person.svg', Type: _('ngOption.MustInclude') }];
  static readonly FARECLASS_PTRN_TO_IGNORE = ['REFUNDABLE', 'CABIN', 'FLEXIBLE', 'CLASS', 'FLEX', 'FULLY'];

  static readonly HOTEL_BRAND_CODES_NAME_MAP = [
    { id: '', value: _('ngOption.HotelBrand'),name:_('ngOption.HotelBrand') },
    { id: 'SW', value: _('ngOption.MarriottHotels'),name:_('ngOption.MarriottHotels') },
    { id: 'HL', value: _('ngOption.HiltonHotels'),name:_('ngOption.HiltonHotels') },
    { id: 'IC', value: _('ngOption.InterContinentalHotelGroup'),name:_('ngOption.InterContinentalHotelGroup') },
    { id: 'WY', value: _('ngOption.WyndhamHotelGroup'),name: _('ngOption.WyndhamHotelGroup') },
    { id: 'UB', value: _('ngOption.ChoiceHotels'),name:_('ngOption.ChoiceHotels') },
    { id: 'BW', value: _('ngOption.BestWestern'),name:_('ngOption.BestWestern') },
    { id: 'HY', value: _('ngOption.HyattGroup'),name:_('ngOption.HyattGroup') },
    { id: 'CW', value: _('ngOption.CarlsonHospitalityGroup'),name:_('ngOption.CarlsonHospitalityGroup') },
    { id: 'BOUH', value: _('ngOption.BoutiqueGroup'),name:_('ngOption.BoutiqueGroup') },
  ];

  static readonly DATE_OPTIONS = [{ id: 'CURRMONTH', value: _('ngOption.Currentmonth'),name:_('ngOption.Currentmonth') }, { id: 'Currentquarter', value: _('ngOption.Currentquarter'),name: _('ngOption.Currentquarter') }, { id: 'Currentyear', value: _('ngOption.Currentyear'),name:_('ngOption.Currentyear') },{ id: 'TODAY', value: _('ngOption.Today'),name:_('ngOption.Today') },{ id: 'YESTERDAY', value: _('ngOption.Yesterday'),name:_('ngOption.Yesterday') }, 
  { id: 'LASTMONTH', value: _('ngOption.Lastmonth'),name:_('ngOption.Lastmonth') },{ id: 'LAST7DAYS', value: _('ngOption.Last7days'),name:_('ngOption.Last7days') }, { id: 'LAST30DAY', value: _('ngOption.Last30days'),name: _('ngOption.Last30days') }, { id: 'LAST90DAY', value: _('ngOption.Last90days'),name: _('ngOption.Last90days') }];
  static readonly DATE_OPTIONS1 = [ { id: 'CURRMONTH', value:  _('ngOption.Currentmonth'),name:_('ngOption.Currentmonth') },{ id: 'Currentquarter', value: _('ngOption.Currentquarter'),name: _('ngOption.Currentquarter') },{ id: 'Currentyear', value: _('ngOption.Currentyear'),name:_('ngOption.Currentyear') },{ id: 'TODAY', value: _('ngOption.Today'),name:_('ngOption.Today') },{ id: 'YESTERDAY', value: _('ngOption.Yesterday'),name:_('ngOption.Yesterday') },  { id: 'LASTMONTH', value: _('ngOption.Lastmonth'),name:_('ngOption.Lastmonth') },  { id: 'LAST7DAYS', value: _('ngOption.Last7days') ,name:_('ngOption.Last7days')}, { id: 'LAST30DAY', value:_('ngOption.Last30days'),name: _('ngOption.Last30days') }, { id: 'LAST90DAY', value: _('ngOption.Last90days'),name: _('ngOption.Last90days') },{ id: 'Last 12 months', value:  _('ngOption.Last12months'),name: _('ngOption.Last12months') }];
  static readonly CARS_OPTIONS = [{ Name: 'Any', value: 'Any' }, { Name: 'Economy', value: 'Economy', Type: 'Car Options' }, { Name: 'Intermediate', value: 'Intermediate', Type: 'Car Options' }, { Name: 'Standard', value: 'Standard', Type: 'Car Options' }, { Name: 'Full Size', value: 'Fullsize', Type: 'Car Options' }, { Name: 'Premium', value: 'Premium', Type: 'Car Options' }, { Name: 'Luxury', value: 'Luxury', Type: 'Car Options' }];

  static readonly CARS_BRANDS = [{ "value": 'Any', "label": 'Any' }, { "value": "AVS", "label": "Avis", Type: 'Brand Type' }, { "value": "BGT", "label": "Budget", Type: 'Brand Type' }, { "value": "ALM", "label": "Alamo", Type: 'Brand Type' }, { "value": "NNL", "label": "National", Type: 'Brand Type' }, { "value": "ENR", "label": "Enterprise", Type: 'Brand Type' }, { "value": "HRZ", "label": "Hertz", Type: 'Brand Type' }, { "value": "DLR", "label": "Dollar", Type: 'Brand Type' },
  { "value": "THR", "label": "Thrifty", Type: 'Brand Type' }, { "value": "PYL", "label": "Payless", Type: 'Brand Type' }, { "value": "AVT", "label": "Advantage", Type: 'Brand Type' }, { "value": "FX", "label": "Fox", Type: 'Brand Type' }];

  static readonly Hotel_Brand = ['SW', 'AK', 'BG', 'EB', 'RZ', "XR", "MC", "BR", "TX", "WH", "WI", "CY", "GE", "MD", "RC", "SI", "AR", "AL", "EL", "FN", "XV", "TO", "OX", "PR", "HL"
    , "ES", "DT", "HX", "GI", "HG", "HT", "CN", "RU", "IC", "HI", "CP", "YZ", "YO", "WY", "DX", "RA", "BU", "HJ", "OZ", "DI", "BH", "KG", "MT",
    "TL", "WG", 'LQ', "WY", "ACC", "BY", "FA", "SB", "PU", "UB", "CC", "CI", "EO", "QI", "RI", "SZ", "BW", "HY", "CW", "BOUH"];

  static readonly ROOMS_COUNT = [{ id: '01', value: _('ngOption.1Room'), count: 1, }, { id: '02', value: _('ngOption.2Rooms'), count: 2 }, { id: '03', value: _('ngOption.3Rooms'), count: 3 }];
  static readonly PASSENGERS_SPECIFIC = [{ id: 'adults', value: _('ngOption.Adults'), ageRange: _('ngOption.Above12'), number: 1, minValue: 1, maxValue: 4 }, { id: 'children', value: _('ngOption.Children'), ageRange: _('ngOption.Aged2to12'), number: 0, minValue: 0, maxValue: 8 }, { id: 'infants', value: _('ngOption.Infants'), ageRange: _('ngOption.Aged0to2'), number: 0, minValue: 0, maxValue: 8 }];

  static readonly AIRPORT_OPTIONS = [{ id: 'bos', value: 'BOS (Boston, MA)' }, { id: 'pvd', value: 'PVD (Providence, RI)' }, { id: 'man', value: 'MAN (Manchester, NH)' }];
  static readonly ALLIANCE_OPTIONS = [{ id: 'starAlliance', value: 'Star Alliance' }, { id: 'oneworld', value: 'Oneworld' }, { id: 'skyTeam', value: 'SkyTeam' }];
  static readonly FLIGHT_TIME_OPTIONS = [{ id: 'DEPART_TIME', headingText: 'Flight departure time', startDay: 'Friday', endDay: 'Saturday', startTime: '5:00AM', endTime: '10:00PM' }, { id: 'ARRIVE_TIME', headingText: 'Flight arrival time', startDay: 'Friday', endDay: 'Friday', startTime: '5:00AM', endTime: '10:00PM' }];
  static readonly HOTEL_BEDTYPES = [{ Code: "26", Name: "Cribs", Description: "Cribs", Type: "Primary" },
  { Code: "33", Name: "Double beds", Description: "Double beds", Type: "Primary" },
  { Code: "200", Name: "Futon", Description: "Futon", Type: "Primary" },
  { Code: "58", Name: "King bed", Description: "King bed", Type: "Primary" },
  { Code: "86", Name: "Queen bed", Description: "Queen bed", Type: "Primary" },
  { Code: "131", Name: "Extra person charge for rollaway use", Description: "Extra person charge for rollaway use", Type: "Secondary" },
  { Code: "198", Name: "Extra child charge for rollaway use", Description: "Extra child charge for rollaway use", Type: "Secondary" },
  { Code: "203", Name: "Single bed", Description: "Single bed", Type: "Primary" },
  { Code: "113", Name: "Twin bed", Description: "Twin bed", Type: "Primary" },
  { Code: "202", Name: "Tatami mats", Description: "Tatami mats", Type: "Secondary" },
  { Code: "201", Name: "Murphy bed", Description: "Murphy bed", Type: "Primary" },
  { Code: "91", Name: "Rollaway bed", Description: "Rollaway bed", Type: "Secondary" },
  { Code: "102", Name: "Sofa bed", Description: "Sofa bed", Type: "Secondary" },
  ]

  static readonly POSSIBLE_BED_TYPES_TEXTS = ['Cribs', 'Double', 'Futon', 'King', 'Queen', 'Single', 'Twin', 'Tatami', 'Murphy', 'Rollaway', 'Sofa'];

  static readonly ANY = 'Any';

  static readonly RGEX_ONLY_ALPHA = `^[a-zA-Z]*$`;
  static readonly RGEX_ALPHANUMERIC = `^[a-zA-Z0-9]*$`;

  static readonly RGEX_ONLY_ALPHA_AND_SPACE = `^[a-zA-Z\\s\-]*$`;
  static readonly RGEX_ONLY_VALID_DATE_CHECK='\d{2}-\d{2}-\d{4}';
  static readonly RGEX_ONLY_DIGITS = `^[0-9]*$`;
  static readonly RGEX_EMAIL = `^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$`;
  //broadened zip code
  static readonly RGEX_ZIP_CODE = '^[a-zA-Z0-9\\s-]*$';
  static readonly RGEX_NOSPACE = '/^\S*$';
  static readonly PAYMENT = 'payment';
  static readonly BOOK = 'book';

  static readonly ALL = 'All';

  static readonly MAX_STOPS = 10;
  static readonly ERROR = 'ERROR';
  static readonly INPROGRESS = 'INPROGRESS';
  static readonly STRIPE = 'STRIPE';

  static readonly MIN_DOB_AGE = 13;
  static readonly MIN_INF_AGE = 1;

  static readonly GALLOP_HELP_CONTACT_NO = '+1 (571) 310-1294' ;
  static readonly GLOBAL_HOTEL_RATINGS = [1.0, 2.0, 3.0, 4.0, 5.0];
  static readonly OPTION_COUNT_THREADHOLD_AFTER_FILTER_FOR_BG_SEARCH = 10;
  static readonly CompanySpecificHighlightedAirlines = { '9': ["AC", "WS", "TS", "WG", "UA", "AA"] }
  static readonly SEARCH_INPUT_TYPE: AddressType = AddressType.GIVEN_AIRPORT_ONLY;
  static readonly PARAM_APP_VERSION = 'appVersion';
  static readonly PARAM_OTA_BUILD_NUMBER = 'otaBuildNumber';
  static readonly PARAM_APP_PLATFORM = 'platform';
  static readonly  PARAM_APP_LANGUAGE= 'selectedLanguage';
  static readonly PARAM_APPSTORE_VERSION = 'appStoreVersionName';
  static readonly PARAM_APP_BUILD_NUMBER = 'appBuildNumber';
  static readonly PARAM_RS_CLIENT_COOKIE = 'rs-client-cookie';
  static readonly CLASS_NAMES_ARRAY = ['BASIC_ECONOMY','ECONOMY','PREMIUM_ECONOMY','BUSINESS_OR_FIRST_CLASS'];
  static readonly CLASS_STAR_MAP = {'Basic economy' : '1','Economy' : '2','Premium economy' : '3','Business' : '4','First class' : '4'};

  static readonly CLASS_NAMES_DESC_ARRAY = ['result.Economyfareswithnocomplimentaryseatassignment',
                                          'result.Economyfareswithcomplimentaryseatassignment',
                                          'result.Premiumeconomyfares',
                                          'result.Businessclassfares'
                                          ];
   static readonly CLASS_starsArray=['result.Base','result.Standard','result.Enhanced','result.Premium']
  static readonly PARAM_USER_ID = 'userid';
  static readonly PARAM_STOKEN = 'sToken';
  static readonly starsSeatArray=['assets/images/seat-ngs-1-star.svg','assets/images/seat-ngs-2-star.svg','assets/images/seat-ngs-3-star.svg','assets/images/seat-ngs-4-star.svg']
  static readonly DEFAULT_PROFILE_IMAGE = 'assets/images/profile_placeholder.png';
  static readonly ALL_EXPENSE_PROVIDERS =
    [
      {
        'id': 'EXPENSE_EXPENSIFY',
        'name': '',
        'url': 'assets/images/Expensify.png',
        'enable': false
      },
      {
        'id': 'EXPENSE_CONCUR',
        'name': '',
        'url': 'assets/images/sap-concur-dd-logo.png',
        'enable': false
      },
      {
        'id': 'EXPENSE_ZOHO',
        'name': '',
        'url': 'assets/images/zoho.png',
        'enable': false
      }
    ];

  static readonly Merchant_Restriction = [
    {
      "id": "ac_refrigeration_repair",
      "value": "A\/C, Refrigeration Repair"
    },
    {
      "id": "accounting_bookkeeping_services",
      "value": "Accounting\/Bookkeeping Services"
    },
    {
      "id": "advertising_services",
      "value": "Advertising Services"
    },
    {
      "id": "agricultural_cooperative",
      "value": "Agricultural Cooperative"
    },
    {
      "id": "airlines_air_carriers",
      "value": "Airlines, Air Carriers"
    },
    {
      "id": "airports_flying_fields",
      "value": "Airports, Flying Fields"
    },
    {
      "id": "ambulance_services",
      "value": "Ambulance Services"
    },
    {
      "id": "amusement_parks_carnivals",
      "value": "Amusement Parks\/Carnivals"
    },
    {
      "id": "antique_reproductions",
      "value": "Antique Reproductions"
    },
    {
      "id": "antique_shops",
      "value": "Antique Shops"
    },
    {
      "id": "aquariums",
      "value": "Aquariums"
    },
    {
      "id": "architectural_surveying_services",
      "value": "Architectural\/Surveying Services"
    },
    {
      "id": "art_dealers_and_galleries",
      "value": "Art Dealers and Galleries"
    },
    {
      "id": "artists_supply_and_craft_shops",
      "value": "Artists Supply and Craft Shops"
    },
    {
      "id": "auto_body_repair_shops",
      "value": "Auto Body Repair Shops"
    },
    {
      "id": "auto_paint_shops",
      "value": "Auto Paint Shops"
    },
    {
      "id": "auto_service_shops",
      "value": "Auto Service Shops"
    },
    {
      "id": "auto_and_home_supply_stores",
      "value": "Auto and Home Supply Stores"
    },
    {
      "id": "automated_cash_disburse",
      "value": "Automated Cash Disburse"
    },
    {
      "id": "automated_fuel_dispensers",
      "value": "Automated Fuel Dispensers"
    },
    {
      "id": "automobile_associations",
      "value": "Automobile Associations"
    },
    {
      "id": "automotive_parts_and_accessories_stores",
      "value": "Automotive Parts and Accessories Stores"
    },
    {
      "id": "automotive_tire_stores",
      "value": "Automotive Tire Stores"
    },
    {
      "id": "bail_and_bond_payments",
      "value": "Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)"
    },
    {
      "id": "bakeries",
      "value": "Bakeries"
    },
    {
      "id": "bands_orchestras",
      "value": "Bands, Orchestras"
    },
    {
      "id": "barber_and_beauty_shops",
      "value": "Barber and Beauty Shops"
    },
    {
      "id": "betting_casino_gambling",
      "value": "Betting\/Casino Gambling"
    },
    {
      "id": "bicycle_shops",
      "value": "Bicycle Shops"
    },
    {
      "id": "billiard_pool_establishments",
      "value": "Billiard\/Pool Establishments"
    },
    {
      "id": "boat_dealers",
      "value": "Boat Dealers"
    },
    {
      "id": "boat_rentals_and_leases",
      "value": "Boat Rentals and Leases"
    },
    {
      "id": "book_stores",
      "value": "Book Stores"
    },
    {
      "id": "books_periodicals_and_newspapers",
      "value": "Books, Periodicals, and Newspapers"
    },
    {
      "id": "bowling_alleys",
      "value": "Bowling Alleys"
    },
    {
      "id": "bus_lines",
      "value": "Bus Lines"
    },
    {
      "id": "business_secretarial_schools",
      "value": "Business\/Secretarial Schools"
    },
    {
      "id": "buying_shopping_services",
      "value": "Buying\/Shopping Services"
    },
    {
      "id": "cable_satellite_and_other_pay_television_and_radio",
      "value": "Cable, Satellite, and Other Pay Television and Radio"
    },
    {
      "id": "camera_and_photographic_supply_stores",
      "value": "Camera and Photographic Supply Stores"
    },
    {
      "id": "candy_nut_and_confectionery_stores",
      "value": "Candy, Nut, and Confectionery Stores"
    },
    {
      "id": "car_rental_agencies",
      "value": "Car Rental Agencies"
    },
    {
      "id": "car_washes",
      "value": "Car Washes"
    },
    {
      "id": "car_and_truck_dealers_new_used",
      "value": "Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing"
    },
    {
      "id": "car_and_truck_dealers_used_only",
      "value": "Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing"
    },
    {
      "id": "carpentry_services",
      "value": "Carpentry Services"
    },
    {
      "id": "carpet_upholstery_cleaning",
      "value": "Carpet\/Upholstery Cleaning"
    },
    {
      "id": "caterers",
      "value": "Caterers"
    },
    {
      "id": "charitable_and_social_service_organizations_fundraising",
      "value": "Charitable and Social Service Organizations - Fundraising"
    },
    {
      "id": "chemicals_and_allied_products",
      "value": "Chemicals and Allied Products (Not Elsewhere Classified)"
    },
    {
      "id": "child_care_services",
      "value": "Child Care Services"
    },
    {
      "id": "childrens_and_infants_wear_stores",
      "value": "Childrens and Infants Wear Stores"
    },
    {
      "id": "chiropodists_podiatrists",
      "value": "Chiropodists, Podiatrists"
    },
    {
      "id": "chiropractors",
      "value": "Chiropractors"
    },
    {
      "id": "cigar_stores_and_stands",
      "value": "Cigar Stores and Stands"
    },
    {
      "id": "civic_social_fraternal_associations",
      "value": "Civic, Social, Fraternal Associations"
    },
    {
      "id": "cleaning_and_maintenance",
      "value": "Cleaning and Maintenance"
    },
    {
      "id": "clothing_rental",
      "value": "Clothing Rental"
    },
    {
      "id": "colleges_universities",
      "value": "Colleges, Universities"
    },
    {
      "id": "commercial_equipment",
      "value": "Commercial Equipment (Not Elsewhere Classified)"
    },
    {
      "id": "commercial_footwear",
      "value": "Commercial Footwear"
    },
    {
      "id": "commercial_photography_art_and_graphics",
      "value": "Commercial Photography, Art and Graphics"
    },
    {
      "id": "commuter_transport_and_ferries",
      "value": "Commuter Transport, Ferries"
    },
    {
      "id": "computer_network_services",
      "value": "Computer Network Services"
    },
    {
      "id": "computer_programming",
      "value": "Computer Programming"
    },
    {
      "id": "computer_repair",
      "value": "Computer Repair"
    },
    {
      "id": "computer_software_stores",
      "value": "Computer Software Stores"
    },
    {
      "id": "computers_peripherals_and_software",
      "value": "Computers, Peripherals, and Software"
    },
    {
      "id": "concrete_work_services",
      "value": "Concrete Work Services"
    },
    {
      "id": "construction_materials",
      "value": "Construction Materials (Not Elsewhere Classified)"
    },
    {
      "id": "consulting_public_relations",
      "value": "Consulting, Public Relations"
    },
    {
      "id": "correspondence_schools",
      "value": "Correspondence Schools"
    },
    {
      "id": "cosmetic_stores",
      "value": "Cosmetic Stores"
    },
    {
      "id": "counseling_services",
      "value": "Counseling Services"
    },
    {
      "id": "country_clubs",
      "value": "Country Clubs"
    },
    {
      "id": "courier_services",
      "value": "Courier Services"
    },
    {
      "id": "court_costs",
      "value": "Court Costs, Including Alimony and Child Support - Courts of Law"
    },
    {
      "id": "credit_reporting_agencies",
      "value": "Credit Reporting Agencies"
    },
    {
      "id": "cruise_lines",
      "value": "Cruise Lines"
    },
    {
      "id": "dairy_products_stores",
      "value": "Dairy Products Stores"
    },
    {
      "id": "dance_hall_studios_schools",
      "value": "Dance Hall, Studios, Schools"
    },
    {
      "id": "dating_escort_services",
      "value": "Dating\/Escort Services"
    },
    {
      "id": "dentists_orthodontists",
      "value": "Dentists, Orthodontists"
    },
    {
      "id": "department_stores",
      "value": "Department Stores"
    },
    {
      "id": "detective_agencies",
      "value": "Detective Agencies"
    },
    {
      "id": "digital_goods_media",
      "value": "Digital Goods Media - Books, Movies, Music"
    },
    {
      "id": "digital_goods_applications",
      "value": "Digital Goods - Applications (Excludes Games)"
    },
    {
      "id": "digital_goods_games",
      "value": "Digital Goods - Games"
    },
    {
      "id": "digital_goods_large_volume",
      "value": "Digital Goods - Large Digital Goods Merchant"
    },
    {
      "id": "direct_marketing_catalog_merchant",
      "value": "Direct Marketing - Catalog Merchant"
    },
    {
      "id": "direct_marketing_combination_catalog_and_retail_merchant",
      "value": "Direct Marketing - Combination Catalog and Retail Merchant"
    },
    {
      "id": "direct_marketing_inbound_telemarketing",
      "value": "Direct Marketing - Inbound Telemarketing"
    },
    {
      "id": "direct_marketing_insurance_services",
      "value": "Direct Marketing - Insurance Services"
    },
    {
      "id": "direct_marketing_other",
      "value": "Direct Marketing - Other"
    },
    {
      "id": "direct_marketing_outbound_telemarketing",
      "value": "Direct Marketing - Outbound Telemarketing"
    },
    {
      "id": "direct_marketing_subscription",
      "value": "Direct Marketing - Subscription"
    },
    {
      "id": "direct_marketing_travel",
      "value": "Direct Marketing - Travel"
    },
    {
      "id": "discount_stores",
      "value": "Discount Stores"
    },
    {
      "id": "doctors",
      "value": "Doctors"
    },
    {
      "id": "door_to_door_sales",
      "value": "Door-To-Door Sales"
    },
    {
      "id": "drapery_window_covering_and_upholstery_stores",
      "value": "Drapery, Window Covering, and Upholstery Stores"
    },
    {
      "id": "drinking_places",
      "value": "Drinking Places"
    },
    {
      "id": "drug_stores_and_pharmacies",
      "value": "Drug Stores and Pharmacies"
    },
    {
      "id": "drugs_drug_proprietaries_and_druggist_sundries",
      "value": "Drugs, Drug Proprietaries, and Druggist Sundries"
    },
    {
      "id": "dry_cleaners",
      "value": "Dry Cleaners"
    },
    {
      "id": "durable_goods",
      "value": "Durable Goods (Not Elsewhere Classified)"
    },
    {
      "id": "duty_free_stores",
      "value": "Duty Free Stores"
    },
    {
      "id": "eating_places_restaurants",
      "value": "Eating Places, Restaurants"
    },
    {
      "id": "educational_services",
      "value": "Educational Services"
    },
    {
      "id": "electric_razor_stores",
      "value": "Electric Razor Stores"
    },
    {
      "id": "electrical_parts_and_equipment",
      "value": "Electrical Parts and Equipment"
    },
    {
      "id": "electrical_services",
      "value": "Electrical Services"
    },
    {
      "id": "electronics_repair_shops",
      "value": "Electronics Repair Shops"
    },
    {
      "id": "electronics_stores",
      "value": "Electronics Stores"
    },
    {
      "id": "elementary_secondary_schools",
      "value": "Elementary, Secondary Schools"
    },
    {
      "id": "employment_temp_agencies",
      "value": "Employment\/Temp Agencies"
    },
    {
      "id": "equipment_rental",
      "value": "Equipment Rental"
    },
    {
      "id": "exterminating_services",
      "value": "Exterminating Services"
    },
    {
      "id": "family_clothing_stores",
      "value": "Family Clothing Stores"
    },
    {
      "id": "fast_food_restaurants",
      "value": "Fast Food Restaurants"
    },
    {
      "id": "financial_institutions",
      "value": "Financial Institutions"
    },
    {
      "id": "fines_government_administrative_entities",
      "value": "Fines - Government Administrative Entities"
    },
    {
      "id": "fireplace_fireplace_screens_and_accessories_stores",
      "value": "Fireplace, Fireplace Screens, and Accessories Stores"
    },
    {
      "id": "floor_covering_stores",
      "value": "Floor Covering Stores"
    },
    {
      "id": "florists",
      "value": "Florists"
    },
    {
      "id": "florists_supplies_nursery_stock_and_flowers",
      "value": "Florists Supplies, Nursery Stock, and Flowers"
    },
    {
      "id": "freezer_and_locker_meat_provisioners",
      "value": "Freezer and Locker Meat Provisioners"
    },
    {
      "id": "fuel_dealers_non_automotive",
      "value": "Fuel Dealers (Non Automotive)"
    },
    {
      "id": "funeral_services_crematories",
      "value": "Funeral Services, Crematories"
    },
    {
      "id": "furniture_repair_refinishing",
      "value": "Furniture Repair, Refinishing"
    },
    {
      "id": "furniture_home_furnishings_and_equipment_stores_except_appliances",
      "value": "Furniture, Home Furnishings, and Equipment Stores, Except Appliances"
    },
    {
      "id": "furriers_and_fur_shops",
      "value": "Furriers and Fur Shops"
    },
    {
      "id": "general_services",
      "value": "General Services"
    },
    {
      "id": "gift_card_novelty_and_souvenir_shops",
      "value": "Gift, Card, Novelty, and Souvenir Shops"
    },
    {
      "id": "glass_paint_and_wallpaper_stores",
      "value": "Glass, Paint, and Wallpaper Stores"
    },
    {
      "id": "glassware_crystal_stores",
      "value": "Glassware, Crystal Stores"
    },
    {
      "id": "golf_courses_public",
      "value": "Golf Courses - Public"
    },
    {
      "id": "government_services",
      "value": "Government Services (Not Elsewhere Classified)"
    },
    {
      "id": "grocery_stores_supermarkets",
      "value": "Grocery Stores, Supermarkets"
    },
    {
      "id": "hardware_stores",
      "value": "Hardware Stores"
    },
    {
      "id": "hardware_equipment_and_supplies",
      "value": "Hardware, Equipment, and Supplies"
    },
    {
      "id": "health_and_beauty_spas",
      "value": "Health and Beauty Spas"
    },
    {
      "id": "hearing_aids_sales_and_supplies",
      "value": "Hearing Aids Sales and Supplies"
    },
    {
      "id": "heating_plumbing_a_c",
      "value": "Heating, Plumbing, A\/C"
    },
    {
      "id": "hobby_toy_and_game_shops",
      "value": "Hobby, Toy, and Game Shops"
    },
    {
      "id": "home_supply_warehouse_stores",
      "value": "Home Supply Warehouse Stores"
    },
    {
      "id": "hospitals",
      "value": "Hospitals"
    },
    {
      "id": "hotels_motels_and_resorts",
      "value": "Hotels, Motels, and Resorts"
    },
    {
      "id": "household_appliance_stores",
      "value": "Household Appliance Stores"
    },
    {
      "id": "industrial_supplies",
      "value": "Industrial Supplies (Not Elsewhere Classified)"
    },
    {
      "id": "information_retrieval_services",
      "value": "Information Retrieval Services"
    },
    {
      "id": "insurance_default",
      "value": "Insurance - Default"
    },
    {
      "id": "insurance_underwriting_premiums",
      "value": "Insurance Underwriting, Premiums"
    },
    {
      "id": "intra_company_purchases",
      "value": "Intra-Company Purchases"
    },
    {
      "id": "jewelry_stores_watches_clocks_and_silverware_stores",
      "value": "Jewelry Stores, Watches, Clocks, and Silverware Stores"
    },
    {
      "id": "landscaping_services",
      "value": "Landscaping Services"
    },
    {
      "id": "laundries",
      "value": "Laundries"
    },
    {
      "id": "laundry_cleaning_services",
      "value": "Laundry, Cleaning Services"
    },
    {
      "id": "legal_services_attorneys",
      "value": "Legal Services, Attorneys"
    },
    {
      "id": "luggage_and_leather_goods_stores",
      "value": "Luggage and Leather Goods Stores"
    },
    {
      "id": "lumber_building_materials_stores",
      "value": "Lumber, Building Materials Stores"
    },
    {
      "id": "manual_cash_disburse",
      "value": "Manual Cash Disburse"
    },
    {
      "id": "marinas_service_and_supplies",
      "value": "Marinas, Service and Supplies"
    },
    {
      "id": "masonry_stonework_and_plaster",
      "value": "Masonry, Stonework, and Plaster"
    },
    {
      "id": "massage_parlors",
      "value": "Massage Parlors"
    },
    {
      "id": "medical_services",
      "value": "Medical Services"
    },
    {
      "id": "medical_and_dental_labs",
      "value": "Medical and Dental Labs"
    },
    {
      "id": "medical_dental_ophthalmic_and_hospital_equipment_and_supplies",
      "value": "Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies"
    },
    {
      "id": "membership_organizations",
      "value": "Membership Organizations"
    },
    {
      "id": "mens_and_boys_clothing_and_accessories_stores",
      "value": "Mens and Boys Clothing and Accessories Stores"
    },
    {
      "id": "mens_womens_clothing_stores",
      "value": "Mens, Womens Clothing Stores"
    },
    {
      "id": "metal_service_centers",
      "value": "Metal Service Centers"
    },
    {
      "id": "miscellaneous_apparel_and_accessory_shops",
      "value": "Miscellaneous Apparel and Accessory Shops"
    },
    {
      "id": "miscellaneous_auto_dealers",
      "value": "Miscellaneous Auto Dealers"
    },
    {
      "id": "miscellaneous_business_services",
      "value": "Miscellaneous Business Services"
    },
    {
      "id": "miscellaneous_food_stores",
      "value": "Miscellaneous Food Stores - Convenience Stores and Specialty Markets"
    },
    {
      "id": "miscellaneous_general_merchandise",
      "value": "Miscellaneous General Merchandise"
    },
    {
      "id": "miscellaneous_general_services",
      "value": "Miscellaneous General Services"
    },
    {
      "id": "miscellaneous_home_furnishing_specialty_stores",
      "value": "Miscellaneous Home Furnishing Specialty Stores"
    },
    {
      "id": "miscellaneous_publishing_and_printing",
      "value": "Miscellaneous Publishing and Printing"
    },
    {
      "id": "miscellaneous_recreation_services",
      "value": "Miscellaneous Recreation Services"
    },
    {
      "id": "miscellaneous_repair_shops",
      "value": "Miscellaneous Repair Shops"
    },
    {
      "id": "miscellaneous_specialty_retail",
      "value": "Miscellaneous Specialty Retail"
    },
    {
      "id": "mobile_home_dealers",
      "value": "Mobile Home Dealers"
    },
    {
      "id": "motion_picture_theaters",
      "value": "Motion Picture Theaters"
    },
    {
      "id": "motor_freight_carriers_and_trucking",
      "value": "Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services"
    },
    {
      "id": "motor_homes_dealers",
      "value": "Motor Homes Dealers"
    },
    {
      "id": "motor_vehicle_supplies_and_new_parts",
      "value": "Motor Vehicle Supplies and New Parts"
    },
    {
      "id": "motorcycle_shops_and_dealers",
      "value": "Motorcycle Shops and Dealers"
    },
    {
      "id": "motorcycle_shops_dealers",
      "value": "Motorcycle Shops, Dealers"
    },
    {
      "id": "music_stores_musical_instruments_pianos_and_sheet_music",
      "value": "Music Stores-Musical Instruments, Pianos, and Sheet Music"
    },
    {
      "id": "news_dealers_and_newsstands",
      "value": "News Dealers and Newsstands"
    },
    {
      "id": "non_fi_money_orders",
      "value": "Non-FI, Money Orders"
    },
    {
      "id": "non_fi_stored_value_card_purchase_load",
      "value": "Non-FI, Stored Value Card Purchase\/Load"
    },
    {
      "id": "nondurable_goods",
      "value": "Nondurable Goods (Not Elsewhere Classified)"
    },
    {
      "id": "nurseries_lawn_and_garden_supply_stores",
      "value": "Nurseries, Lawn and Garden Supply Stores"
    },
    {
      "id": "nursing_personal_care",
      "value": "Nursing\/Personal Care"
    },
    {
      "id": "office_and_commercial_furniture",
      "value": "Office and Commercial Furniture"
    },
    {
      "id": "opticians_eyeglasses",
      "value": "Opticians, Eyeglasses"
    },
    {
      "id": "optometrists_ophthalmologist",
      "value": "Optometrists, Ophthalmologist"
    },
    {
      "id": "orthopedic_goods_prosthetic_devices",
      "value": "Orthopedic Goods - Prosthetic Devices"
    },
    {
      "id": "osteopaths",
      "value": "Osteopaths"
    },
    {
      "id": "package_stores_beer_wine_and_liquor",
      "value": "Package Stores-Beer, Wine, and Liquor"
    },
    {
      "id": "paints_varnishes_and_supplies",
      "value": "Paints, Varnishes, and Supplies"
    },
    {
      "id": "parking_lots_garages",
      "value": "Parking Lots, Garages"
    },
    {
      "id": "passenger_railways",
      "value": "Passenger Railways"
    },
    {
      "id": "pawn_shops",
      "value": "Pawn Shops"
    },
    {
      "id": "pet_shops_pet_food_and_supplies",
      "value": "Pet Shops, Pet Food, and Supplies"
    },
    {
      "id": "petroleum_and_petroleum_products",
      "value": "Petroleum and Petroleum Products"
    },
    {
      "id": "photo_developing",
      "value": "Photo Developing"
    },
    {
      "id": "photographic_studios",
      "value": "Photographic Studios"
    },
    {
      "id": "photographic_photocopy_microfilm_equipment_and_supplies",
      "value": "Photographic, Photocopy, Microfilm Equipment, and Supplies"
    },
    {
      "id": "picture_video_production",
      "value": "Picture\/Video Production"
    },
    {
      "id": "piece_goods_notions_and_other_dry_goods",
      "value": "Piece Goods, Notions, and Other Dry Goods"
    },
    {
      "id": "plumbing_heating_equipment_and_supplies",
      "value": "Plumbing, Heating Equipment, and Supplies"
    },
    {
      "id": "political_organizations",
      "value": "Political Organizations"
    },
    {
      "id": "postal_services_government_only",
      "value": "Postal Services - Government Only"
    },
    {
      "id": "precious_stones_and_metals_watches_and_jewelry",
      "value": "Precious Stones and Metals, Watches and Jewelry"
    },
    {
      "id": "professional_services",
      "value": "Professional Services"
    },
    {
      "id": "public_warehousing_and_storage",
      "value": "Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage"
    },
    {
      "id": "quick_copy_repro_and_blueprint",
      "value": "Quick Copy, Repro, and Blueprint"
    },
    {
      "id": "railroads",
      "value": "Railroads"
    },
    {
      "id": "real_estate_agents_and_managers_rentals",
      "value": "Real Estate Agents and Managers - Rentals"
    },
    {
      "id": "record_stores",
      "value": "Record Stores"
    },
    {
      "id": "recreational_vehicle_rentals",
      "value": "Recreational Vehicle Rentals"
    },
    {
      "id": "religious_goods_stores",
      "value": "Religious Goods Stores"
    },
    {
      "id": "religious_organizations",
      "value": "Religious Organizations"
    },
    {
      "id": "roofing_siding_sheet_metal",
      "value": "Roofing\/Siding, Sheet Metal"
    },
    {
      "id": "secretarial_support_services",
      "value": "Secretarial Support Services"
    },
    {
      "id": "security_brokers_dealers",
      "value": "Security Brokers\/Dealers"
    },
    {
      "id": "service_stations",
      "value": "Service Stations"
    },
    {
      "id": "sewing_needlework_fabric_and_piece_goods_stores",
      "value": "Sewing, Needlework, Fabric, and Piece Goods Stores"
    },
    {
      "id": "shoe_repair_hat_cleaning",
      "value": "Shoe Repair\/Hat Cleaning"
    },
    {
      "id": "shoe_stores",
      "value": "Shoe Stores"
    },
    {
      "id": "small_appliance_repair",
      "value": "Small Appliance Repair"
    },
    {
      "id": "snowmobile_dealers",
      "value": "Snowmobile Dealers"
    },
    {
      "id": "special_trade_services",
      "value": "Special Trade Services"
    },
    {
      "id": "specialty_cleaning",
      "value": "Specialty Cleaning"
    },
    {
      "id": "sporting_goods_stores",
      "value": "Sporting Goods Stores"
    },
    {
      "id": "sporting_recreation_camps",
      "value": "Sporting\/Recreation Camps"
    },
    {
      "id": "sports_clubs_fields",
      "value": "Sports Clubs\/Fields"
    },
    {
      "id": "sports_and_riding_apparel_stores",
      "value": "Sports and Riding Apparel Stores"
    },
    {
      "id": "stamp_and_coin_stores",
      "value": "Stamp and Coin Stores"
    },
    {
      "id": "stationary_office_supplies_printing_and_writing_paper",
      "value": "Stationary, Office Supplies, Printing and Writing Paper"
    },
    {
      "id": "stationery_stores_office_and_school_supply_stores",
      "value": "Stationery Stores, Office, and School Supply Stores"
    },
    {
      "id": "swimming_pools_sales",
      "value": "Swimming Pools Sales"
    },
    {
      "id": "t_ui_travel_germany",
      "value": "TUI Travel - Germany"
    },
    {
      "id": "tailors_alterations",
      "value": "Tailors, Alterations"
    },
    {
      "id": "tax_payments_government_agencies",
      "value": "Tax Payments - Government Agencies"
    },
    {
      "id": "tax_preparation_services",
      "value": "Tax Preparation Services"
    },
    {
      "id": "taxicabs_limousines",
      "value": "Taxicabs\/Limousines"
    },
    {
      "id": "telecommunication_equipment_and_telephone_sales",
      "value": "Telecommunication Equipment and Telephone Sales"
    },
    {
      "id": "telecommunication_services",
      "value": "Telecommunication Services"
    },
    {
      "id": "telegraph_services",
      "value": "Telegraph Services"
    },
    {
      "id": "tent_and_awning_shops",
      "value": "Tent and Awning Shops"
    },
    {
      "id": "testing_laboratories",
      "value": "Testing Laboratories"
    },
    {
      "id": "theatrical_ticket_agencies",
      "value": "Theatrical Ticket Agencies"
    },
    {
      "id": "timeshares",
      "value": "Timeshares"
    },
    {
      "id": "tire_retreading_and_repair",
      "value": "Tire Retreading and Repair"
    },
    {
      "id": "tolls_bridge_fees",
      "value": "Tolls\/Bridge Fees"
    },
    {
      "id": "tourist_attractions_and_exhibits",
      "value": "Tourist Attractions and Exhibits"
    },
    {
      "id": "towing_services",
      "value": "Towing Services"
    },
    {
      "id": "trailer_parks_campgrounds",
      "value": "Trailer Parks, Campgrounds"
    },
    {
      "id": "transportation_services",
      "value": "Transportation Services (Not Elsewhere Classified)"
    },
    {
      "id": "travel_agencies_tour_operators",
      "value": "Travel Agencies, Tour Operators"
    },
    {
      "id": "truck_stop_iteration",
      "value": "Truck StopIteration"
    },
    {
      "id": "truck_utility_trailer_rentals",
      "value": "Truck\/Utility Trailer Rentals"
    },
    {
      "id": "typesetting_plate_making_and_related_services",
      "value": "Typesetting, Plate Making, and Related Services"
    },
    {
      "id": "typewriter_stores",
      "value": "Typewriter Stores"
    },
    {
      "id": "u_s_federal_government_agencies_or_departments",
      "value": "U.S. Federal Government Agencies or Departments"
    },
    {
      "id": "uniforms_commercial_clothing",
      "value": "Uniforms, Commercial Clothing"
    },
    {
      "id": "used_merchandise_and_secondhand_stores",
      "value": "Used Merchandise and Secondhand Stores"
    },
    {
      "id": "utilities",
      "value": "Utilities"
    },
    {
      "id": "variety_stores",
      "value": "Variety Stores"
    },
    {
      "id": "veterinary_services",
      "value": "Veterinary Services"
    },
    {
      "id": "video_amusement_game_supplies",
      "value": "Video Amusement Game Supplies"
    },
    {
      "id": "video_game_arcades",
      "value": "Video Game Arcades"
    },
    {
      "id": "video_tape_rental_stores",
      "value": "Video Tape Rental Stores"
    },
    {
      "id": "vocational_trade_schools",
      "value": "Vocational\/Trade Schools"
    },
    {
      "id": "watch_jewelry_repair",
      "value": "Watch\/Jewelry Repair"
    },
    {
      "id": "welding_repair",
      "value": "Welding Repair"
    },
    {
      "id": "wholesale_clubs",
      "value": "Wholesale Clubs"
    },
    {
      "id": "wig_and_toupee_stores",
      "value": "Wig and Toupee Stores"
    },
    {
      "id": "wires_money_orders",
      "value": "Wires, Money Orders"
    },
    {
      "id": "womens_accessory_and_specialty_shops",
      "value": "Womens Accessory and Specialty Shops"
    },
    {
      "id": "womens_ready_to_wear_stores",
      "value": "Womens Ready-To-Wear Stores"
    },
    {
      "id": "wrecking_and_salvage_yards",
      "value": "Wrecking and Salvage Yards"
    }
  ];
  static  NGS_UI_STAR_MAPPING =  {
    "AA": {
      "FIRST FLEXIBLE": "4",
      "BASIC ECONOMY": "1",
      "PREMIUM ECONOMY": "3",
      "MAIN PLUS": "3",
      "BUSINESS": "4",
      "MAIN SELECT": "3",
      "FIRST": "4",
      "FLAGSHIP BUSINESS FLEXIBLE": "4",
      "MAIN CABIN EXTRA": "3",
      "FLAGSHIP BUSINESS": "4",
      "MAIN CABIN FLEXIBLE": "2",
      "FLAGSHIP FIRST": "4",
      "MAIN CABIN": "2"
    },
    "KL": {
      "ECONOMY STANDARD": "2",
      "ECONOMY STANDARD PLUS": "2",
      "BUSINESS STANDARD": "4",
      "ECONOMY FLEX": "2",
      "ECONOMY LIGHT": "1",
      "BUSINESS FLEX": "4",
      "BUSINESS": "4",
      "WORLD BUSINESS": "4",
      "PREMIUM COMFORT": "3"
    },
    "AC": {
      "COMFORT": "2",
      "FLEX": "2",
      "LATITUDE": "2"
    },
    "AF": {
      "PREMIERE": "4",
      "ECONOMY FLEX": "2",
      "ECONOMY LIGHT": "1",
      "PREMIUM": "3",
      "BUSINESS FLEX": "4",
      "BUSINESS": "4",
      "PREMIUM STANDARD": "3",
      "ECONOMY STANDARD": "2",
      "ECONOMY STANDARD PLUS": "2",
      "BUSINESS STANDARD": "4",
      "LA PREMIÈRE": "4",
      "PREMIUM FLEX": "3",
      "LA PREMIERE": "4"
    },
    "DL": {
      "COMFORT+": "3",
      "DELTA PREMIUM SELECT": "4",
      "DELTA COMFORT+": "3",
      "COMFORT PLUS": "3",
      "DELTA COMFORT PLUS": "3",
      "DELTA ONE": "4",
      "MAIN CABIN": "2"
    },
    "AL": {
      "BASIC ECONOMY": "1",
      "PREMIUM ECONOMY": "3",
      "ECONOMY LIGHT": "1",
      "FIRST CLASS": "4",
      "BUSINESS": "4",
      "MAIN": "2",
      "FIRST": "4",
      "BASIC": "1",
      "ECONOMY": "2",
      "MAIN CABIN": "2",
      "STANDARD": "2"
    },
    "AM": {
      "PREMIER ONE": "4",
      "CLASSICA": "2",
      "CLASSICA FLEX": "2",
      "PREMIER LIGHT": "3",
      "PREMIER FLEX": "4",
      "BASICA": "1",
      "AM PLUS": "3",
      "PREMIER": "3",
      "CLASSIC FLEX": "2",
      "AM PLUS FLEX": "3",
      "BASIC": "1",
      "CLASSIC": "2"
    },
    "UA": {
      "POLARIS": "4",
      "ECONOMY PLUS": "3",
      "PREMIUM PLUS": "3"
    },
    "AS": {
      "SAVER": "1",
      "MAIN": "2",
      "REFUNDABLE MAIN": "2",
      "FIRST": "4",
      "REFUNDABLE FIRST ": "4"
    },
    "B6": {
      "BLUE BASIC": "1",
      "BLUE": "2",
      "BLUE PLUS": "2",
      "MINT": "4",
      "BLUE EXTRA": "2"
    },
    "LA": {
      "ECONOMY PLUS": "2",
      "ECONOMY TOP": "3",
      "BUSINESS PLUS": "4"
    },
    "WN": {
      "BUSINESS SELECT": "4",
      "WANNA GET AWAY": "1",
      "WANNA GET AWAY PLUS": "2",
      "ANYTIME": "2"
    },
    "IB": {
      "OPTIMAL": "2"
    },
    "WS": {
      "BUSINESSFLEX": "4",
      "PREMIUM": "3",
      "ECONO": "2",
      "ECONOFLEX": "2",
      "PREMIUMFLEX": "3"
    },
    "VS": {
      "UPPER CLASS FLEX": "4",
      "ECONOMY DELIGHT": "3",
      "PREMIUM FLEX": "3",
      "ECONOMY CLASSIC": "2",
      "ECONOMY LIGHT": "1",
      "PREMIUM": "3",
      "ECONOMY CLASSIC FLEX": "2",
      "UPPER CLASS": "4"
    },
    "BA": {
      "PREMIUM ECONOMY": "3",
      "STANDARD ECONOMY": "2",
      "BUSINESS": "4",
      "PLUS FLEX": "2",
      "FIRST": "4",
      "BASIC": "1",
      "STANDARD": "2",
      "PLUS": "2"
    }
  };
}
