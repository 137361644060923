<!--<ng-container>
  <div class="row" [formGroup]="searchForm" style="float: left; width: 100%;">
    <div class="col-3">
      <div class="switch">
        <span [ngClass]="{'icon': true, 'icon-setting': true, 'selected': isShownFilter('SERVER')}" (click)="showFilter('SERVER')"></span>
        <span [ngClass]="{'icon': true, 'icon-filter': true, 'selected': isShownFilter('CLIENT')}" (click)="showFilter('CLIENT')"></span>
      </div>
    </div>

    <div class="col-9" id="filters">
      <div class="filter-list-container" *ngIf="isShownFilter('SERVER')">
        <ul class="filter-list">
           <li (click)="passengers.toggle()">
            <div class="select-input">
              <div class="label">Passengers</div>
              <ng-select  #passengers  appendTo="#filters" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="passengerOptions" formControlName="passengers">
              </ng-select>
            </div>
            <div class="field-value">{{searchForm.controls['passengers'].value}}</div>
          </li>

          <li (click)="class.toggle()">
            <div class="select-input">
              <div class="label">Class</div>
              <ng-select class=" class-seater" #class appendTo="#filters" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="classOptions" bindLabel="value" bindValue="id" (change)="onSelectClass($event)" formControlName="class">
              </ng-select>
            </div>
            <div class="field-value">{{getClassName(searchForm.controls['class'].value)}}</div>
          </li>

          <li (click)="stops.toggle()">
              <div class="select-input">
                <div class="label">Stops</div>
                <ng-select class="class-seater" #stops appendTo="#filters" [(ngModel)]="noOfStops" [ngModelOptions]="{standalone: true}" [searchable]="false" [clearable]="false"  dropdownPosition="bottom" [items]="stopOptions" bindLabel="value" bindValue="id">
                </ng-select>
              </div>
              <div class="field-value">{{this.stopsLabel}}</div>
          </li>

          <li>
              <div class="select-input">
                <div class="label">Airlines</div>
                <ng-select class="class-seater" #airlines appendTo="#filters" [(ngModel)]="preferredAirline" [ngModelOptions]="{standalone: true}" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="airlineOptions" bindLabel="value" bindValue="id">
                </ng-select>
              </div>
              <div class="field-value" (click)="airlines.toggle()">{{preferredAirline}}</div>
          </li>
        </ul>
      </div>

      <div class="filter-list-container" *ngIf="isShownFilter('CLIENT')">
        <ul class="filter-list single-line">
          <li *ngFor="let score of scoringOptions" (click)="optimize(score.label)" [ngClass]="{'selected':isActiveFilter(score.label)}">
            {{score.label}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>

<ng-container>



  <div class="filters-container">
    <div class="server-filter">
      <div class="select-input" id="idfor-stops">
        <div class="label">Stops</div>
        <div class="field-value" (click)="stops.toggle()" >{{stopsLabel}}</div>
        <ng-select  #stops [(ngModel)]="noOfStops" [ngModelOptions]="{standalone: true}" appendTo="#idfor-stops" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="stopOptions" bindLabel="value" bindValue="id">
        </ng-select>
      </div>

      <div class="select-input" id="idfor-airlines">
        <div class="label">Airlines</div>
        <ng-select class="open-right" #airlines appendTo="#idfor-airlines" [(ngModel)]="preferredAirline" [ngModelOptions]="{standalone: true}" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="airlineOptions" bindLabel="value" bindValue="id">
        </ng-select>
        <div class="field-value" (click)="airlines.toggle()">{{preferredAirline}}</div>
      </div>
    </div>

    <div class="client-filter">
      <div class="score-filter" *ngFor="let score of scoringOptions" (click)="optimize(score.label)" [ngClass]="{'active':isActiveFilter(score.label)}">
          {{score.label}}
        </div>
    </div>
  </div>



  <div class="row" [formGroup]="searchForm" style="float: left; width: 100%;">
    <div class="col-12 col-lg-6">
      <div class="filters">
        <div class="select-input" id="idfor-passengers">
          <div class="label">Passengers</div>
          <div class="field-value" (click)="passengers.toggle()">{{searchForm.controls['passengers'].value}}</div>
          <ng-select  #passengers  appendTo="#idfor-passengers" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="passengerOptions" formControlName="passengers">
          </ng-select>
        </div>

        <div class="select-input" id="idfor-class">
          <div class="label">Class</div>
          <div class="field-value" (click)="class.toggle()">{{getClassName(searchForm.controls['class'].value)}}</div>
          <ng-select  #class  appendTo="#idfor-class" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="classOptions" bindLabel="value" bindValue="id" (change)="onSelectClass($event)" formControlName="class">
          </ng-select>
        </div>

        <div class="select-input" id="idfor-stops">
          <div class="label">Stops</div>
          <div class="field-value" (click)="stops.toggle()" >{{stopsLabel}}</div>
          <ng-select  #stops [(ngModel)]="noOfStops" [ngModelOptions]="{standalone: true}" appendTo="#idfor-stops" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="stopOptions" bindLabel="value" bindValue="id">
          </ng-select>
        </div>

        <div class="select-input" id="idfor-airlines">
          <div class="label">Airlines</div>
          <ng-select class="open-right" #airlines appendTo="#idfor-airlines" [(ngModel)]="preferredAirline" [ngModelOptions]="{standalone: true}" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="airlineOptions" bindLabel="value" bindValue="id">
          </ng-select>
          <div class="field-value" (click)="airlines.toggle()">{{preferredAirline}}</div>
        </div>

      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="score-filters">
        <div class="score-filter" *ngFor="let score of scoringOptions" (click)="optimize(score.label)" [ngClass]="{'active':isActiveFilter(score.label)}">
          {{score.label}}
        </div>
      </div>
    </div>
  </div>
</ng-container>-->



<div class="filter-strip">
    <div class="container">
        <div class="filter-strip-inner">
            <ul>
                <li class="filter-item">
                    <span class="filter-item-link" href="javascript:void(0)"><span
                            [ngClass]="{'icon': true, 'icon-setting': true, 'selected': isShownFilter('SERVER')}"
                            (click)="showFilter('SERVER')"></span></span>
                </li>
                <li class="filter-item" id="filter-container-airlines">
                    <!--                <ng-select class="filter-select-box" #airlines appendTo="#filter-container-airlines" [closeOnSelect]="false" dropdownPosition="bottom" [searchable]="false" [clearable]="false" [items]="airlineOptions" bindLabel="value" bindValue="id">
                    <ng-template ng-header-tmp>
                        <div class="selectox-header">
                            <span>Filter by Airlines</span>
                            <span class="selectBox-remove" (click)="airlines.toggle()"><span class="material-icons">clear</span></span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-option="item">
                        <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="{{option.id}}">
                            <input type="checkbox" id="{{option.id}}" class="mdl-checkbox__input">
                            <span class="mdl-checkbox__label">{{option.value}}</span>
                        </label>
                    </ng-template>
                    <ng-template ng-footer-tmp>
                        <div class="selectBox-footer-filter-button">
                            <button class="btn primary-button">Apply</button>
                        </div>
                    </ng-template>
                </ng-select>-->


                    <!--                <ng-template #airlineModal let-modal>
                    <div class="modal-container flight-modal-container">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myModalLabel">
                                Filter by Airlines
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
                                <i class="material-icons">close</i>
                            </button>
                        </div>
                        <div class="modal-body">

                            <div class="checkbox-group-container">

                                <div class="checkbox-group-items">
                                    <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="allAirlines">
                                        <input type="checkbox" id="allAirlines" class="mdl-checkbox__input" (change)= "onShowAllAirlinesChange($event)" [checked]="isShowAllAirlineChecked()">
                                        <span class="mdl-checkbox__label">Show All Airlines</span>
                                    </label>
                                </div>
                            </div>

                            <div class="checkbox-group-container">
                                <div class="checkbox-group-header">Alliance</div>
                                <div class="checkbox-group-items">
                                    <label  *ngFor="let allainceOption of allainceOptions" class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="{{allainceOption.id}}">
                                        <input type="checkbox" id="1" class="mdl-checkbox__input">
                                        <span class="mdl-checkbox__label">{{allainceOption.value}}</span>
                                    </label>
                                </div>
                            </div>

                            <div class="checkbox-group-container">
                                <div class="checkbox-group-header">Airlines</div>
                                <div class="checkbox-group-items">
                                    <label *ngFor="let airlineOption of airlineOptions" class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="{{airlineOption.id}}">
                                        <input type="checkbox" id="{{airlineOption.id}}" class="mdl-checkbox__input" (change)="onAirlineCheckClicked(airlineOption.id, $event)" [checked]="isAirlineChecked(airlineOption.id)">
                                        <span class="mdl-checkbox__label">{{airlineOption.value}}</span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <div class="selectBox-footer-filter-button">
                                <button class="btn primary-button" (click)="applyAirlineFilter()">Apply</button>
                            </div>
                        </div>
                    </div>
                </ng-template>-->





                    <a class="filter-item-link" href="javascript:void(0)" attr.data-track="FlightResultFilter"
                        attr.data-params="filter=Airline"
                        (click)="ngxSmartModalService.getModal('airlineTopModal').open()">{{'filter.AIRLINES' |
                        translate}}
                    </a>
                    <ngx-smart-modal (onOpen)="handleModalEvents('onOpen', 'airlineTopModal')"
                        (onDismiss)="handleModalEvents('onDismiss', 'airlineTopModal')" [hideDelay]="0"
                        [closable]="false" [dismissable]="true" class="" #airlineTopModal identifier="airlineTopModal">
                        <div class="modal-link-underline-div">
                            <div class="modal-link-underline"></div>
                        </div>

                        <div class="modal-container flight-modal-container filter-modal modalAirportFilterInfo"
                            (click)="$event.stopPropagation();" >
                            <div class="modal-header">
                                <h5 class="modal-title">{{'filter.FilterbyAirlines' | translate}}</h5>
                                <button type="button" class="close" data-dismiss="modal"
                                    (click)="onSmartModelCancel('airlineTopModal')">
                                    <i class="material-icons">close</i>
                                </button>
                            </div>
                            <div class="modal-body" style="max-height: 400px; overflow-y:auto;">
                                <div class="checkbox-group-container">
                                    <div class="checkbox-group-items">
                                        <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                                            for="allAirlines">
                                            <input type="checkbox" id="allAirlines" class="mdl-checkbox__input"
                                                (change)="onShowAllAirlinesChange($event)"
                                                [checked]="isShowAllAirlineChecked()">
                                            <span class="mdl-checkbox__label">{{'filter.ShowAllAirlines' |
                                                translate}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="checkbox-group-container">
                                    <div class="checkbox-group-header">{{'filter.Alliance' | translate}}</div>
                                    <div class="checkbox-group-items">
                                        <label *ngFor="let allainceOption of allainceOptions"
                                            class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                                            for="{{allainceOption.id}}">
                                            <input type="checkbox" id="{{allainceOption.id}}"
                                                class="mdl-checkbox__input"
                                                (change)="onAllianceAirlineCheckClicked(allainceOption.id, $event)"
                                                [checked]="isAllianceAirlineChecked(allainceOption.id)">
                                            <span class="mdl-checkbox__label">{{allainceOption.value}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="checkbox-group-container">
                                    <div class="checkbox-group-header">{{'filter.Airlines' | translate}}
                                    </div>
                                    <div class="checkbox-group-items">
                                        <label *ngFor="let airlineOption of airlineOptions"
                                            class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                                            for="{{airlineOption.id}}">
                                            <input type="checkbox" id="{{airlineOption.id}}" class="mdl-checkbox__input"
                                                (change)="onAirlineCheckClicked(airlineOption.id, $event)"
                                                [checked]="isAirlineChecked(airlineOption.id)">
                                            <span class="mdl-checkbox__label"><span>{{airlineOption.value}}</span>
                                                ({{airlineOption.id}})</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="selectBox-footer-filter-button">
                                    <button class="btn primary-button" (click)="applyAirlineFilter()">{{'filter.Apply' |
                                        translate}}</button>
                                </div>
                            </div>
                        </div>
                    </ngx-smart-modal>


                </li>
                <li class="filter-item">
                    <a class="filter-item-link" href="javascript:void(0)" attr.data-track="FlightResultFilter"
                        attr.data-params="filter=Airport"
                        (click)="ngxSmartModalService.getModal('airportTopModal').open()">{{'filter.AIRPORTS' |
                        translate}}</a>

                    <ngx-smart-modal (onOpen)="handleModalEvents('onOpen', 'airportTopModal')"
                        (onDismiss)="handleModalEvents('onDismiss', 'airportTopModal')" [hideDelay]="0"
                        [closable]="false" [dismissable]="true" class="" #airportTopModal identifier="airportTopModal">
                        <div class="modal-link-underline-div">
                            <div class="modal-link-underline"></div>
                        </div>

                        <div class="modal-container flight-modal-container filter-modal modalAirportFilterInfo"
                            (click)="$event.stopPropagation();">
                            <div class="modal-header">
                                <h5 class="modal-title" id="myModalLabel">
                                    {{'filter.FilterbyAirports' | translate}}
                                </h5>
                                <button type="button" class="close" data-dismiss="modal"
                                    (click)="onSmartModelCancel('airportTopModal')">
                                    <i class="material-icons">close</i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="checkbox-group-container">
                                    <div class="checkbox-group-header">{{'filter.Departure' | translate}}
                                    </div>
                                    <div class="checkbox-group-items">
                                        <label *ngFor="let airportOption of airportOptions"
                                            class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                                            for="{{airportOption.id}}">
                                            <input type="checkbox" id="{{airportOption.id}}" class="mdl-checkbox__input"
                                                (change)="onAirportCheckClicked(airportOption.id, $event)"
                                                [checked]="isAirportChecked(airportOption.id)">
                                            <span class="mdl-checkbox__label">{{airportOption.value}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="checkbox-group-container">
                                    <div class="checkbox-group-header">{{'filter.Destination' | translate}}
                                    </div>
                                    <div class="checkbox-group-items">
                                        <label *ngFor="let airportOption of destinationAirportOptions"
                                            class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                                            for="{{airportOption.id}}">
                                            <input type="checkbox" id="{{airportOption.id}}" class="mdl-checkbox__input"
                                                (change)="onDestinationAirportCheckClicked(airportOption.id, $event)"
                                                [checked]="isDestinationAirportChecked(airportOption.id)">
                                            <span class="mdl-checkbox__label">{{airportOption.value}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="selectBox-footer-filter-button">
                                    <button class="btn primary-button" (click)="applyAirportFilter()">{{'filter.Apply' |
                                        translate}}</button>
                                </div>
                            </div>
                        </div>
                    </ngx-smart-modal>

                    <!--                <ng-template #airportModal let-modal>
                    <div class="modal-container flight-modal-container">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myModalLabel">
                                Filter by Airports
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
                                <i class="material-icons">close</i>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="checkbox-group-container">
                                <div class="checkbox-group-header">Departure</div>
                                <div class="checkbox-group-items">
                                    <label *ngFor="let airportOption of airportOptions" class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="{{airportOption.id}}">
                                        <input type="checkbox" id="{{airportOption.id}}" class="mdl-checkbox__input" (change)="onAirportCheckClicked(airportOption.id, $event)" [checked]="isAirportChecked(airportOption.id)">
                                        <span class="mdl-checkbox__label">{{airportOption.value}}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="checkbox-group-container">
                                <div class="checkbox-group-header">Destination</div>
                                <div class="checkbox-group-items">
                                    <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="1">
                                        <input type="checkbox" id="1" class="mdl-checkbox__input">
                                        <span class="mdl-checkbox__label">MSY (New Orleans, LA)</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="selectBox-footer-filter-button">
                                <button class="btn primary-button" (click)="applyAirportFilter()">Apply</button>
                            </div>
                        </div>
                    </div>
                </ng-template>-->




                </li>
                <li class="filter-item" id="filter-container-stops">
                    <ng-select class="filter-select-box" [closeOnSelect]="false" [multiple]="true" #stops
                        appendTo="#filter-container-stops" dropdownPosition="bottom" [searchable]="false"
                        [clearable]="false" [items]="stopOptions" bindLabel="value" bindValue="id"
                        (open)="handleModalEvents('onOpen', undefined)">
                        <ng-template ng-header-tmp>
                            <div class="selectox-header">
                                <span style="width:100%;white-space: nowrap;">{{'filter.FilterbyNumberofStops' |
                                    translate}}
                                </span>
                                <span class="selectBox-remove" (click)="stops.toggle()"><span
                                        class="material-icons">clear</span></span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-option="item">
                            <label class="mdl-radio mdl-js-radio mdl-js-ripple-effect" for="{{option.id}}">
                                <input type="radio" id="{{option.id}}" name="stopsFilterName" class="mdl-radio__button"
                                    (change)="onStopSelectionChange(option.id)" [checked]="option.selected">
                                <span class="mdl-radio__label">{{option.value | translate}}</span>
                            </label>
                        </ng-template>
                        <ng-template ng-footer-tmp>
                            <div class="selectBox-footer-filter-button">
                                <button class="btn primary-button"
                                    (click)="[stops.toggle(),applyStopsFilter(false)]">{{'filter.Apply' |
                                    translate}}</button>
                            </div>
                        </ng-template>
                    </ng-select>
                    <a class="filter-item-link" href="javascript:void(0)" attr.data-track="FlightResultFilter"
                        attr.data-params="filter=stops" (click)="stops.toggle()">{{'filter.STOPS' | translate}}</a>
                </li>
                <li class="filter-item" id="filter-container-time">
                    <ng-select class="filter-select-box flightTimeTemplate ngSelectStatic" [closeOnSelect]="false"
                        [multiple]="true" #flightTime appendTo="#filter-container-time" dropdownPosition="bottom"
                        [searchable]="false" [clearable]="false" [items]="flightTimeOptions" bindLabel="value"
                        bindValue="id" (open)="handleModalEvents('onOpen', undefined)">
                        <ng-template ng-header-tmp>
                            <div class="selectox-header">
                                <span>{{'filter.FilterbyTime' | translate}}
                                </span>
                                <span class="selectBox-remove" (click)="flightTime.toggle()"><span
                                        class="material-icons">clear</span></span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-option="item">
                            <div class="flightTimeOptionDiv" id="{{option.id}}">
                                <app-range-slider [heading]="option.heading" [startAt]="option.starts"
                                    [endAt]="option.ends" [name]="option.id"
                                    [defaultMinValue]="getSliderDefaultMinValue(option.id)"
                                    [defaultHighValue]="getSliderDefaultHighValue(option.id)"></app-range-slider>
                            </div>
                        </ng-template>
                        <ng-template ng-footer-tmp>
                            <div class="selectBox-footer-filter-button">
                                <button class="btn primary-button"
                                    (click)="[flightTime.toggle(),applyTimeFilter(false)]">{{'filter.Apply' |
                                    translate}}</button>
                            </div>
                        </ng-template>
                    </ng-select>
                    <a class="filter-item-link" href="javascript:void(0)" attr.data-track="FlightResultFilter"
                        attr.data-params="filter=time" (click)="flightTime.toggle()">{{'filter.TIME' | translate}}</a>
                </li>
                <li *ngIf="canShowPolicyFilter()" class="filter-item" id="filter-container-policy">
                    <ng-select class="filter-select-box" [closeOnSelect]="false" [multiple]="true" #policy
                        appendTo="#filter-container-policy" dropdownPosition="bottom" [searchable]="false"
                        [clearable]="false" [items]="policyOptions" bindLabel="value" bindValue="id"
                        (open)="handleModalEvents('onOpen', undefined)">
                        <ng-template ng-header-tmp>
                            <div class="selectox-header">
                                <span>{{'filter.FilterbyPolicy' | translate}}</span>
                                <span class="selectBox-remove" (click)="policy.toggle()"><span
                                        class="material-icons">clear</span></span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-option="item">
                            <label class="mdl-radio mdl-js-radio mdl-js-ripple-effect" for="{{option.id}}">
                                <input type="radio" id="{{option.id}}" name="stopsFilterName" class="mdl-radio__button"
                                    (change)="onPolicySelectionChange(option.id)" [checked]="option.selected">
                                <span class="mdl-radio__label">{{option.value | translate}}</span>
                            </label>
                        </ng-template>
                        <ng-template ng-footer-tmp>
                            <!-- <div class="policy-link-container">
                            <a href="javascript:void(0)"><span>configure policy</span> <img src="assets/images/arrow-right.svg" /></a>
                        </div> -->

                            <div class="selectBox-footer-filter-button">
                                <button class="btn primary-button"
                                    (click)="[policy.toggle(),applyPolicyFilter(false)]">{{'filter.Apply' |
                                    translate}}</button>
                            </div>
                        </ng-template>
                    </ng-select>
                    <a class="filter-item-link" href="javascript:void(0)" attr.data-track="FlightResultFilter"
                        attr.data-params="filter=policy" (click)="policy.toggle()">{{'filter.POLICY' | translate}}</a>
                </li>


                <li style="display:none;" class="filter-item" id="filter-container-demo">
                    <ng-select class="filter-select-box" groupBy="Type" [closeOnSelect]="false" [multiple]="true" #demo
                        appendTo="#filter-container-demo" dropdownPosition="bottom" [searchable]="false"
                        [clearable]="false" [items]="demoOptions | translateOptions" bindLabel="value" bindValue="id"
                        (open)="handleModalEvents('onOpen', undefined)">
                        <ng-template ng-header-tmp>
                            <div class="selectox-header">
                                <span>{{'filter.FilterbyAirlines' | translate}}
                                </span>
                                <span class="selectBox-remove" (click)="demo.toggle()"><span
                                        class="material-icons">clear</span></span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-option="item">
                            <label class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" for="{{option.id}}">
                                <input type="radio" id="{{option.id}}" name="stopsFilterName"
                                    class="mdl-checkbox__input">
                                <span class="mdl-checkbox__label"><span
                                        style="text-transform: capitalize">{{option.value}}</span>
                                    ({{option.id}})</span>
                            </label>
                        </ng-template>
                        <ng-template ng-footer-tmp>
                            <div class="selectBox-footer-filter-button">
                                <button class="btn primary-button">{{'filter.Apply' | translate}}</button>
                            </div>
                        </ng-template>
                    </ng-select>
                    <a class="filter-item-link" href="javascript:void(0)" (click)="demo.toggle()">{{'filter.DEMO' |
                        translate}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>